import React from "react";
import { Line } from "react-chartjs-2";
import { formatCurrency } from "utilities/utils";
import { colors } from "variables/charts";

export const LineChart = ({ data }) => {
  const tooltipLabels = function (item, data) {
    var label = data.datasets[item.datasetIndex].label || "";
    var yLabel = item.yLabel;
    var content = "";

    if (data.datasets.length > 1) {
      content += label;
    }
    content += formatCurrency(yLabel);
    return content;
  };
  const formatLineChartData = function (data) {
    // in case of one point, display one point instead of line
    if (data.hasOwnProperty("labels") && data.labels?.length && data.labels.length === 1) {
      data.datasets[0] = {
        ...data.datasets[0],
        pointRadius: 5,
      };
    }
    return data;
  };
  const chartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          offset: true,
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: colors.gray[700],
            zeroLineColor: colors.gray[700],
          },
          ticks: {
            callback: function (value) {
              if (!(value % 10)) {
                return formatCurrency(value);
              }
            },
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    tooltips: {
      callbacks: {
        label: tooltipLabels,
      },
    },
  };
  return (
    <Line data={formatLineChartData(data)} options={chartOptions} id="chart-sales-dark" className="chart-canvas" />
  );
};
