import React from "react";

import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { PieChart } from "./PieChart";
import { KeynameToTitle, KeynameToSubtitle } from "utilities/enums/Analytics";
import { useTranslation } from "react-i18next";

export const SalesOriginChart = ({
  chartData,
  chartNav,
  chartType,
  key_name,
  chartFormData,
  report_period = "day",
}) => {
  const { t } = useTranslation();
  const title = t(KeynameToTitle[key_name]);
  const subtitle = t(KeynameToSubtitle[key_name]);
  const [currentChart, setCurrentChart] = React.useState();
  const [currentChartNav, setCurrentChartNav] = React.useState();
  const chartDataRef = React.useRef(chartData);
  const chartNavRef = React.useRef(chartNav);
  const [totalSales , setTotalSales] = React.useState(0);

  React.useEffect(() => {
    chartNavRef.current = chartNav;
    setCurrentChartNav(chartNavRef.current);
  }, [chartNav]);
  React.useEffect(() => {
    const translatedLabels = chartData.labels.map((label) => t(label.charAt(0).toUpperCase() + label.slice(1)));
    chartDataRef.current = {
        ...chartData,
        labels: translatedLabels,
    };
    setTotalSales(chartDataRef.current.datasets[0].data.reduce((a, b) => a + b, 0));
    setCurrentChart(chartDataRef.current);
  }, [chartData]);

  return (
    <>
      <Card className="position-relative">
        <CardHeader className="bg-transparent">
          <Row className="align-items-center">
            <Col xs="8" md="6">
              {report_period === "custom" && <h6 className="text-light text-uppercase ls-1 mb-1">{subtitle}</h6>}
              <h5 className="h3 mb-0">{title}</h5>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <div className="chart">
            {(currentChart && totalSales > 0 &&
              JSON.parse(JSON.stringify(currentChart)) &&
              {
                pie: <PieChart data={JSON.parse(JSON.stringify(currentChart))} />,
              }[chartType]) || <div className="text-center">No Data</div>}
          </div>
        </CardBody>
      </Card>
    </>
  );
};
