import axios from "axios";
import { store } from "redux/store";
import * as Sentry from "@sentry/browser";

export function get(url) {
  const token = store.getState().auth.token;
  return axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      Sentry.captureException(error);
      console.log(error);
    });
}

export function put(url, data, options) {
  return axios
    .put(url, data, {
      headers: {
        "content-type": "application/json",
        // ...(process.env.NODE_ENV === "production" && { backend: BACKEND_HEADER_IDENTIFIER }),
        ...options,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      Sentry.captureException(error);
      console.log(error);
    });
}

export function post(url, data, options = {}) {
  return axios
    .post(url, data, {
      ...options,
      // headers: {
      //   ...(process.env.NODE_ENV === "production" && { backend: BACKEND_HEADER_IDENTIFIER }),
      // },
    })
    .then((res) => res.data)
    .catch((error) => {
      Sentry.captureException(error);
      console.log(error);
    });
}

export function axiosDelete(url) {
  axios.delete(url).then((response) => {
    console.log(response);
  });
}
