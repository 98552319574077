import React from "react";
import { Row, Button, Col } from "reactstrap";
import useNewOrderProduct from "views/pages/tables/NewOrder/Product/useNewOrderProduct";
import VariantsRadio from "views/pages/components/VariantsRadio";
import VariantsCheckboxGroup from "views/pages/components/VariantsCheckboxGroup";
import { TextArea } from "components/TextAreas/TextArea";
import { ListItem } from "@mui/material";
import PuffLoader from "react-spinners/PuffLoader";
import { useTranslation } from "react-i18next";
import { MODE_CREATE } from "utilities/constants";

export default function NewOrderProduct(props) {
  const {
    product,
    handleChange,
    decreaseCartQuantity,
    increaseCartQuantity,
    productInfo,
    totalAlreadyOrdered,
    handleNotesChange,
    isLoading,
    handleSubmit,
    removeVariationOption,
    addVariationOption,
  } = useNewOrderProduct(props);

  const { t } = useTranslation();

  const variantBlocks =
    product && product.variants
      ? product.variants.data.map((variant) => {
          if (variant.attributes.type === "or") {
            return (
              <Row key={variant.id} className="mt-3">
                <Col lg="12">
                  <VariantsRadio
                    title={variant.attributes.label}
                    handleChange={handleChange}
                    componentID={variant.id}
                    options={variant.attributes.variant_options.data}
                    selectedOptions={productInfo.variants}
                  />
                </Col>
              </Row>
            );
          } else if (variant.attributes.type === "and") {
            return (
              <Row key={variant.id} className="mt-3">
                <Col lg="12">
                  <VariantsCheckboxGroup
                    title={variant.attributes.label}
                    options={variant.attributes.variant_options.data}
                    componentID={variant.id}
                    handleChange={handleChange}
                    checkedOptions={productInfo.variants}
                    removeVariationOption={removeVariationOption}
                    addVariationOption={addVariationOption}
                  />
                </Col>
              </Row>
            );
          }

          return [];
        })
      : [];

  if (isLoading) {
    return (
      <div className="w-100 d-flex justify-content-center">
        <PuffLoader loading={isLoading} color="#d9d9d9" size={50} aria-label="Loading Spinner" data-testid="loader" />
      </div>
    );
  }

  return (
    <>
      {product && (
        <>
          <Row>
            <Col className="row-shadow">{variantBlocks}</Col>
          </Row>
          <ListItem
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <div className="d-flex flex-grow-1">
              <div className="d-flex flex-column mb-5 mt-3 flex-grow-1">
                <p className="mb-0 font-weight-bold mb-1">{t("Comments")}</p>
                <div className="">
                  <TextArea
                    onChange={(e) => handleNotesChange(e.target.value)}
                    className="w-100 pt-2 form-control"
                    value={productInfo.notes}
                    placeholder={t("Add_comments")}
                  />
                </div>
              </div>
            </div>
          </ListItem>
          <Row className="product-footer mr-0 ml-0 row position-sticky w-100 bottom-0 pb-3 pt-3 mb-n4 bg-white">
            {product.inventoryEnabled && product.inventory > 0 ? (
              <div className="my-3 col-12">
                <div className="text-success">
                  {product.inventoryEnabled ? `${t("Quantity")}: ${product.inventory}` : null}
                </div>
              </div>
            ) : (
              <></>
            )}
            <Col xs="6" className="d-flex align-items-center pl-0">
              {product.inventoryEnabled && product.inventory <= 0 ? (
                <div className="text-danger">Out of stock</div>
              ) : (
                <div className="d-flex">
                  <Button onClick={() => decreaseCartQuantity()} className="mr-3" disabled={productInfo.quantity <= 0}>
                    -
                  </Button>
                  <div className="d-flex align-items-center quantity">{productInfo.quantity}</div>
                  <Button
                    onClick={() => increaseCartQuantity()}
                    className="ml-3"
                    disabled={
                      product.inventoryEnabled && product.inventory <= totalAlreadyOrdered + productInfo.quantity
                    }
                  >
                    +
                  </Button>
                </div>
              )}
            </Col>
            <Col xs="6" className="pl-0 pr-0">
              <Button
                id="add-item-btn"
                color="primary"
                className="w-100"
                disabled={
                  productInfo.quantity <= 0 || (product.inventoryEnabled && productInfo.quantity > product.inventory)
                }
                onClick={handleSubmit}
              >
                {props.mode === MODE_CREATE ? t("Add") : t("Save")}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
