import { TagTypes } from "utilities/enums/TagTypes";
import { apiSlice } from "./apiSlice";
import { store } from "redux/store";
import { sortByRankAsc } from "utilities/utils";

export const apiTables = apiSlice.injectEndpoints({
  tagTypes: [TagTypes.Tables],
  endpoints: (builder) => ({
    getTables: builder.query({
      query: () =>
        `/tables?sort[0]=id&sort[1]=rank&populate=floor&pagination[pageSize]=150&filters[organisation][id]=${
          store.getState().auth.organisationId
        }`,
      transformResponse: (responseData) => {
        return sortByRankAsc(responseData.data, true);
      },
      providesTags: [TagTypes.Tables],
    }),
    addTable: builder.mutation({
      invalidatesTags: [TagTypes.Tables],
      query: (table) => {
        return {
          url: `/tables`,
          method: "POST",
          body: {
            data: {
              name: table.name,
              rank: table.rank ? table.rank : null,
              floor: table.floor,
              organisation: store.getState().auth.organisationId,
            },
          },
        };
      },
    }),
    updateTable: builder.mutation({
      invalidatesTags: [TagTypes.Tables],
      query: (table) => {
        return {
          url: `/tables/${table.id}`,
          method: "PUT",
          body: {
            data: {
              name: table.name,
              rank: table.rank ? table.rank : null,
              floor: table.floor,
              organisation: store.getState().auth.organisationId,
            },
          },
        };
      },
    }),
    deleteTable: builder.mutation({
      invalidatesTags: [TagTypes.Tables],
      query: (table) => {
        return {
          url: `/tables/${table.id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const { useGetTablesQuery, useAddTableMutation, useUpdateTableMutation, useDeleteTableMutation } = apiTables;
