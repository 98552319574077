import React from "react";
import { useSelector } from "react-redux";
import { Drawer } from "@mui/material";
import Box from "@mui/material/Box";
import { Row } from "reactstrap";
import Cart from "./Cart";
import { useTranslation } from "react-i18next";

export const CartDrawer = ({ cartItems, open, onClose, onCheckout }) => {
  const { t } = useTranslation();
  const { cloud_cash_register_enabled } = useSelector((state) => state.settings);
  let additionalClasses = "";
  if (cloud_cash_register_enabled) additionalClasses += "invoice-selection-enabled";

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      className={`cart-container ${additionalClasses}`}
      style={{ zIndex: 1000 }}
    >
      <Box sx={{ width: "90vw", padding: 3 }} role="presentation">
        <Row className="cart-header bg-white position-relative d-flex justify-content-center">
          <span aria-hidden="true" className="close-btn" onClick={onClose}></span>
          <h3>{t("Cart")}</h3>
        </Row>
        <Cart cartItems={cartItems} onCheckout={onCheckout} />
      </Box>
    </Drawer>
  );
};
