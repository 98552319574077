import React, { useEffect } from "react";
import { Views } from "utilities/enums/Views";
import DefaultLayout from "templates/DefaultLayout";
import {
  useGetOrganisationQuery,
  useAddOrganisationMutation,
  useUpdateOrganisationMutation,
} from "api/organisationSlice";
import { Row, Col, FormGroup, Input, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { broadCastSettingsChangeWS } from "config/websocket";
import { PRINTING_CASES } from "utilities/constants";
import { useSelector } from "react-redux";
import { useOrdersBulkInvoiceMutation } from "api/ordersSlice";
import AlertModal from "components/Modals/AlertModal";

export default function General() {
  const [addOrganisation, { isLoading: addOrganisationLoading }] = useAddOrganisationMutation();
  const [updateOrganisation, { isLoading: updateOrganisationLoading }] = useUpdateOrganisationMutation();
  const [ordersBulkInvoice, { isLoading: ordersBulkInvoiceLoading }] = useOrdersBulkInvoiceMutation();
  const { data: organisation, isError, isLoading, isSuccess } = useGetOrganisationQuery();
  const { cloud_cash_register_enabled, manual_cloud_cash_register_enabled, print_scenarios_disabled } = useSelector(
    (state) => state.settings
  );
  const [submitModal, setSubmitModal] = React.useState(false);
  const [inputs, setInputs] = React.useState({
    id: 1,
    name: "",
    vat_number: "",
    mydata_gr_enabled: false,
    payment_method_enabled: false,
    customers_per_order_enabled: false,
    discounts_enabled: false,
    manual_cloud_cash_register_enabled: false,
    doy: "",
    address: "",
    city: "",
    postcode: "",
    phone_number: "",
    gemi_number: "",
    end_of_day: "",
    receipt_printing: PRINTING_CASES.ORDER_PAYMENT,
  });
  const [resultMsg, setResultMsg] = React.useState({
    isError: false,
    message: "",
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (isSuccess && organisation) {
      const organisationAttrs = organisation.attributes;
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          id: organisation.id ? organisation.id : prevInputs.id,
          name: organisationAttrs.name ? organisationAttrs.name : prevInputs.name,
          vat_number: organisationAttrs.vat_number ? organisationAttrs.vat_number : prevInputs.vat_number,
          mydata_gr_enabled: organisationAttrs.mydata_gr_enabled
            ? organisationAttrs.mydata_gr_enabled
            : prevInputs.mydata_gr_enabled,
          payment_method_enabled: organisationAttrs.payment_method_enabled
            ? organisationAttrs.payment_method_enabled
            : prevInputs.payment_method_enabled,
          customers_per_order_enabled: organisationAttrs.customers_per_order_enabled
            ? organisationAttrs.customers_per_order_enabled
            : prevInputs.customers_per_order_enabled,
          discounts_enabled: organisationAttrs.discounts_enabled
            ? organisationAttrs.discounts_enabled
            : prevInputs.discounts_enabled,
          manual_cloud_cash_register_enabled: organisationAttrs.manual_cloud_cash_register_enabled
            ? organisationAttrs.manual_cloud_cash_register_enabled
            : prevInputs.manual_cloud_cash_register_enabled,
          doy: organisationAttrs.doy ? organisationAttrs.doy : prevInputs.doy,
          address: organisationAttrs.address ? organisationAttrs.address : prevInputs.address,
          city: organisationAttrs.city ? organisationAttrs.city : prevInputs.city,
          postcode: organisationAttrs.postcode ? organisationAttrs.postcode : prevInputs.postcode,
          phone_number: organisationAttrs.phone_number ? organisationAttrs.phone_number : prevInputs.phone_number,
          gemi_number: organisationAttrs.gemi_number ? organisationAttrs.gemi_number : prevInputs.gemi_number,
          receipt_printing: organisationAttrs.receipt_printing
            ? organisationAttrs.receipt_printing
            : prevInputs.receipt_printing,
          end_of_day: organisationAttrs.end_of_day ? organisationAttrs.end_of_day : prevInputs.end_of_day,
        };
      });
    }
  }, [organisation, isSuccess]);

  function handleInputChange(event) {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        [event.target.name]: event.target.value,
      };
    });
  }

  function handleFeedbackMsg(result) {
    if (result?.data?.data?.attributes) {
      setResultMsg({ msg: t("Saving_successful"), isError: false });
    } else {
      setResultMsg({ msg: t("Saving_error"), isError: true });
    }
  }

  async function submitSaveOrganisation() {
    let result = null;
    if (!organisation) {
      result = await addOrganisation(inputs);
    } else {
      result = await updateOrganisation(inputs);
    }
    handleFeedbackMsg(result);
    broadCastSettingsChangeWS(result?.data?.data?.id);
  }

  const header = (
    <Row>
      <Col xs="6">
        <h3 className="mb-0">{t(Views.GENERAL_SETTINGS)}</h3>
      </Col>
    </Row>
  );

  return (
    <>
      <DefaultLayout
        name={Views.GENERAL_SETTINGS}
        parentName={Views.SETTINGS}
        isLoading={isLoading}
        isError={isError}
        header={header}
      >
        <Row>
          <Col xs="12" md="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="name">
                {t("Organisation_name")}
              </label>
              <Input
                className="form-control-alternative"
                id="name"
                name="name"
                placeholder={t("Name")}
                type="text"
                value={inputs.name}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="doy">
                {t("DOY")}
              </label>
              <Input
                className="form-control-alternative"
                id="doy"
                name="doy"
                placeholder={t("DOY")}
                type="text"
                value={inputs.doy}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="address">
                {t("Address")}
              </label>
              <Input
                className="form-control-alternative"
                id="address"
                name="address"
                placeholder={t("Address")}
                type="text"
                value={inputs.address}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="city">
                {t("City")}
              </label>
              <Input
                className="form-control-alternative"
                id="city"
                name="city"
                placeholder={t("City")}
                type="text"
                value={inputs.city}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="postcode">
                {t("Postcode")}
              </label>
              <Input
                className="form-control-alternative"
                id="postcode"
                name="postcode"
                placeholder={t("Postcode")}
                type="text"
                value={inputs.postcode}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <label className="form-control-label" htmlFor="phone_number">
                {t("Phone")}
              </label>
              <Input
                className="form-control-alternative"
                id="phone_number"
                name="phone_number"
                placeholder={t("Phone")}
                type="text"
                value={inputs.phone_number}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="gemi_number">
                {t("GEMH")}
              </label>
              <Input
                className="form-control-alternative"
                id="gemi_number"
                name="gemi_number"
                placeholder={t("GEMH")}
                type="text"
                value={inputs.gemi_number}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="name">
                {t("AFM")}
              </label>
              <Input
                className="form-control-alternative"
                id=""
                name="vat_number"
                placeholder={t("AFM")}
                type="text"
                value={inputs.vat_number}
                onChange={handleInputChange}
              />
            </FormGroup>

            {cloud_cash_register_enabled && !print_scenarios_disabled && (
              <FormGroup>
                <label className="form-control-label" htmlFor="name">
                  {t("Print_Receipts")}
                </label>
                <Input
                  className="form-control-alternative"
                  id="receipt_printing"
                  name="receipt_printing"
                  placeholder={t("Print_Receipts")}
                  type="select"
                  bsSize="sm"
                  value={inputs.receipt_printing}
                  onChange={handleInputChange}
                >
                  <option value={PRINTING_CASES.ORDER_SUBMITTION}>{t("Print_Receipts_on_order_submission")}</option>
                  <option value={PRINTING_CASES.ORDER_PAYMENT}>{t("Print_Receipts_on_order_payment")}</option>
                </Input>
              </FormGroup>
            )}

            <FormGroup>
              <label className="form-control-label" htmlFor="endofday">
                {t("End_of_day")}
              </label>
              <Input
                className="form-control-alternative"
                id="endofday"
                name="end_of_day"
                placeholder={t("End_of_day")}
                type="time"
                value={inputs.end_of_day}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <div className="custom-control custom-checkbox d-inline">
                <input
                  className="custom-control-input"
                  type="checkbox"
                  name="customers_per_order_enabled"
                  id="customers_per_order_enabled"
                  onChange={(e) => {
                    setInputs((prevInputs) => ({
                      ...prevInputs,
                      customers_per_order_enabled: e.target.checked ? true : false,
                    }));
                  }}
                  checked={inputs.customers_per_order_enabled ? true : false}
                />
                <label className="custom-control-label" htmlFor="customers_per_order_enabled">
                  {t(`Define_customers_per_order`)}
                </label>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="custom-control custom-checkbox d-inline">
                <input
                  className="custom-control-input"
                  type="checkbox"
                  name="discounts_enabled"
                  id="discounts_enabled"
                  onChange={(e) => {
                    setInputs((prevInputs) => ({
                      ...prevInputs,
                      discounts_enabled: e.target.checked,
                    }));
                  }}
                  checked={inputs.discounts_enabled}
                />
                <label className="custom-control-label" htmlFor="discounts_enabled">
                  {t(`Define_discounts_enabled`)}
                </label>
              </div>
            </FormGroup>
          </Col>
        </Row>
        {cloud_cash_register_enabled && (
          <Row>
            <Col xs="12" md="4">
              <FormGroup>
                <div className="custom-control custom-checkbox d-inline">
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    name="manual_cloud_cash_register_enabled"
                    id="manual_cloud_cash_register_enabled"
                    onChange={(e) => {
                      setInputs((prevInputs) => ({
                        ...prevInputs,
                        manual_cloud_cash_register_enabled: e.target.checked,
                      }));
                    }}
                    checked={inputs.manual_cloud_cash_register_enabled}
                  />
                  <label className="custom-control-label" htmlFor="manual_cloud_cash_register_enabled">
                    {t(`manual_cloud_cash_register_enabled_label`)}
                  </label>
                </div>
              </FormGroup>
            </Col>
          </Row>
        )}
        {manual_cloud_cash_register_enabled && (
          <Row>
            <Col xs="12">
              <FormGroup className="mt-2">
                <Button color="danger" onClick={() => setSubmitModal(true)} disabled={ordersBulkInvoiceLoading}>
                  {t("Panic")}
                </Button>
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs="12">
            <FormGroup className="mt-5">
              <Button
                color="primary"
                onClick={submitSaveOrganisation}
                disabled={updateOrganisationLoading || addOrganisationLoading}
              >
                {t("Save")}
              </Button>
            </FormGroup>
            <p className={resultMsg.isError ? "text-danger" : "text-success"}>{resultMsg.msg}</p>
          </Col>
        </Row>
      </DefaultLayout>
      <AlertModal
        title={"Panic"}
        isOpen={submitModal}
        cancelText={t("Cancel")}
        okText={t("Submit")}
        okDisabled={ordersBulkInvoiceLoading}
        onCancel={() => setSubmitModal(false)}
        onOk={async () => {
          const result = await ordersBulkInvoice({
            organisationId: organisation.id,
          });
          console.log(result);
          setSubmitModal(false);
        }}
        body={<p>{t("Submit_All_Orders_Warning")}</p>}
      />
    </>
  );
}
