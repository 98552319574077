import React, { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetPendingOrdersByTableQuery,
  useUpdateOrderStatusMutation,
  useAddOrderMutation,
  useOrdersBulkUpdateMutation,
  useOrdersPartialUpdateMutation,
} from "api/ordersSlice";
import { OrderStatus } from "utilities/enums/OrderStatus";
import { useSelector } from "react-redux";
import { broadCastOrdersChangeWS } from "config/websocket";
import { CANCELLATION_REASON_GIFT } from "utilities/constants";
import { formatCurrency } from "utilities/utils";
import { selectCurrentUser, selectCurrentUserRules, selectCurrentOrganisation } from "auth/authSlice";
import ROUTES_OBJ from "utilities/enums/Routes";
import { useUpdateProductMutation } from "api/productsSlice";
import { logError } from "utilities/utils";
import { useTranslation } from "react-i18next";
import { InvoiceType } from "utilities/enums/InvoiceType";
import { PaymentMethods } from "utilities/enums/PaymentMethods";
import { generateUUID } from "utilities/utils";

const usePayItems = () => {
  const { t } = useTranslation();
  const { table } = useParams();
  const navigate = useNavigate();
  const { username: user } = useSelector(selectCurrentUser);
  const organisationId = useSelector(selectCurrentOrganisation);
  const user_rules = useSelector(selectCurrentUserRules);
  const discount_rule_enabled = user_rules.find(
    (rule) => rule.name === `Route:${ROUTES_OBJ.TABLE_ACTIONS_DISCOUNT.path}`
  )?.value;
  const submit_invoice_enabled = user_rules.find(
    (rule) => rule.name === `Route:${ROUTES_OBJ.TABLE_ACTIONS_SUBMIT_RECEIPT.path}`
  )?.value;
  const { data: pendingOrders, isLoading, isError } = useGetPendingOrdersByTableQuery(parseInt(table));
  const { payment_method_enabled, auto_invoice_payment_card } = useSelector((state) => state.settings);
  const [cancellationModalOpen, setCancellationModalOpen] = useState(false);
  const [giftModalOpen, setGiftModalOpen] = useState(false);
  const {
    discounts_enabled,
    manual_cloud_cash_register_enabled,
    cloud_cash_register_enabled,
    wholesale_enabled,
    pos_integration_enabled,
    external_source_types_enabled,
  } = useSelector((state) => state.settings);
  const [discount, setDiscount] = React.useState(0);
  const [discountReason, setDiscountReason] = useState("");
  const [discountAccordionOpen, setDiscountAccordionOpen] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [paymentMethodDialogOpen, setPaymentMethodDialogOpen] = React.useState(false);
  const [forceSubmit, setForceSubmit] = React.useState(false);
  const [submitInvoiceType, setSubmitInvoiceType] = useState(
    manual_cloud_cash_register_enabled ? "" : InvoiceType.Retail
  );
  const [selectedNewOrderItems, setSelectedNewOrderItems] = React.useState([]);
  const [selectedOrderTotal, setSelectedOrderTotal] = React.useState(0);
  const [orderGrandTotal, setOrderGrandTotal] = React.useState(0);
  const [paymentErrorMsg, setPaymentErrorMsg] = React.useState("");
  const [addOrder, { isLoading: addOrderLoading }] = useAddOrderMutation();
  const [ordersPartialUpdate, { isLoading: ordersPartialUpdateLoading }] = useOrdersPartialUpdateMutation();
  const [updateOrderStatus, { isLoading: updateOrderLoading }] = useUpdateOrderStatusMutation();
  const [updateProduct, { isLoading: isUpdateProductLoading }] = useUpdateProductMutation();
  const [ordersBulkUpdate, { isLoading: isBulkUpdateLoading }] = useOrdersBulkUpdateMutation();
  const [isDelivery, setIsDelivery] = useState(false);
  const [hasPredefindDiscount, setHasPredefindDiscount] = useState(false);
  const allow_discount = discounts_enabled && discount_rule_enabled;
  //If order has receipt printing on submission, we hide the submit invoice button
  const allow_submit_invoice = submit_invoice_enabled && manual_cloud_cash_register_enabled;
  const [customerInputs, setCustomerInputs] = useState({
    name: "",
    vat_number: "",
    doy: "",
    gemi_number: "",
    address: "",
    city: "",
    postcode: "",
    phone_number: "",
    errors: {},
    organisation: organisationId,
  });
  useEffect(() => {
    if (allow_discount && discount < 0) {
      setErrorMessage("Translation_cannot_be_negative");
    }
    if (allow_discount && discount >= 0) {
      let orderTotal = selectedOrderTotal;
      let newTotal = orderTotal - discount;
      if (newTotal < 0) {
        setErrorMessage("Translation_cannot_be_larger_than_order");
      } else {
        setErrorMessage(null);
      }
      setOrderGrandTotal(newTotal);
    }
  }, [discount, selectedOrderTotal, allow_discount]);

  useEffect(() => {
    if (cloud_cash_register_enabled && external_source_types_enabled) {
      setIsDelivery(
        selectedNewOrderItems.some(
          (item) =>
            item?.parent_invoice?.data?.attributes?.external_source &&
            item?.parent_invoice?.data?.attributes?.invoice_type === InvoiceType.Retail
        )
      );
    }
    setSelectedOrderTotal(
      selectedNewOrderItems
        .reduce((total, item) => {
          let v = item.variant.reduce((x, variant) => {
            let variantOptionPrice = variant.variant_option.data?.attributes.price;
            if (variant.quantity && variant.quantity > 1) {
              variantOptionPrice = variantOptionPrice * variant.quantity;
            }
            return (x += variantOptionPrice * item.quantity);
          }, 0);
          return total + item.product.data?.attributes.price * item.quantity + v;
        }, 0)
        .toFixed(2)
    );
    const totalDiscount = selectedNewOrderItems.reduce((total, item) => total + item.item_discount, 0);
    setDiscount(totalDiscount);
    setHasPredefindDiscount(selectedNewOrderItems.some((item) => item.item_discount > 0));
  }, [selectedNewOrderItems]);

  function handleOrderItemQuantityChange(id, q) {
    setSelectedNewOrderItems(
      selectedNewOrderItems.map((item) => {
        if (item.id === id) {
          return { ...item, quantity: item.quantity + q };
        }
        return item;
      })
    );
  }

  function handleCheckItemToggle(item) {
    if (item === "all") {
      let allOrderDetailsItems = pendingOrders.data.map((order) => order.orderDetails).flat();
      if (selectedNewOrderItems.length === allOrderDetailsItems.length) {
        setSelectedNewOrderItems([]);
      } else {
        setSelectedNewOrderItems(allOrderDetailsItems);
      }
    } else {
      if (selectedNewOrderItems.filter((i) => i.id === item.id).length > 0) {
        setSelectedNewOrderItems(() => {
          return selectedNewOrderItems.filter((i) => i.id !== item.id);
        });
        setDiscount(discount - item.item_discount);
      } else {
        setSelectedNewOrderItems(() => {
          return [...selectedNewOrderItems, item];
        });
        setDiscount(discount + item.item_discount);
      }
    }
  }

  function updateQuantityDifferenceObject(array1, array2) {
    return array1.map((object1) => {
      return array2.map((object2) => {
        if (object1.id === object2.id && object1.quantity !== object2.quantity) {
          return {
            ...object1,
            quantity: object2.quantity < object1.quantity ? object1.quantity - object2.quantity : 0, // if he increase quantity, old quantity is 0 else old quantity is old quantity - new quantity
          };
        } else if (object1.id === object2.id && object1.quantity === object2.quantity) {
          return {
            ...object1,
            quantity: 0,
          };
        }
        return null;
      });
    });
  }

  function handlePayButtonPress() {
    if (submitInvoiceType === InvoiceType.Wholesale && !customerFieldsValidator()) {
      return;
    }
    if (payment_method_enabled) {
      setPaymentMethodDialogOpen(true);
    } else if (payment_method_enabled === false) {
      payOrder();
    }
  }

  /**
   * If the organisation is configured to have "send auto invoice on payment method card" we force send
   * Or if the invoiceType is not empty we force send
   * @returns if we force send the cloud invoice
   */
  function getForceSendCloudInvoice(paymentMethod) {
    let paymentCardAndAutoPaymentCard = auto_invoice_payment_card && paymentMethod === PaymentMethods.CARD.value;
    return cloud_cash_register_enabled && (submitInvoiceType?.length > 0 || paymentCardAndAutoPaymentCard);
  }

  /**
   * If the set invoice type from the user is empty and
   * the organisation is configured to have "send auto invoice on payment method card" and payment method card
   * we submit with invoice type "Retail" because we should submit invoice
   * @param {*} paymentMethod
   * @returns the invoiceType to send for the order(s) to be submitted
   */
  function getInvoiceType(paymentMethod) {
    let finalInvoiceType = submitInvoiceType;
    let paymentCardAndAutoPaymentCard = auto_invoice_payment_card && paymentMethod === PaymentMethods.CARD.value;
    if (!submitInvoiceType && paymentCardAndAutoPaymentCard) {
      finalInvoiceType = InvoiceType.Retail;
    }
    return finalInvoiceType;
  }

  /**
   * Handle a set of orders and an action upon them (Pay, Cancel, Gift) and on their items
   *
   * Handles also the case that we have multiple orders for a table, and we want to pay just some items from those orders
   * In this case we disable all the orders at hand, and we create (1) One payed order with the payed items and (2) One pending orders with the rest upaid items
   */
  function partialUpdateOrder(
    resultStatus,
    paymentMethod = null,
    cancellationReason = null,
    notes = null,
    terminalId = null,
    tipAmount = null
  ) {
    const UUID = generateUUID(user, organisationId);
    const orderstoBeDisabled = pendingOrders.data.filter((order) =>
      order.orderDetails.some(
        (item) => selectedNewOrderItems.filter((i) => parseInt(i.id) === parseInt(item.id)).length > 0
      )
    );

    //get all orderDetails
    const allItems = [];
    orderstoBeDisabled.forEach((order) => allItems.push(...order.orderDetails));
    //get all orderDetails that are not in the new order
    const quantityChangedItems = updateQuantityDifferenceObject(allItems, selectedNewOrderItems)
      .flat(1)
      .filter((item) => item != null)
      .filter((item) => item.quantity > 0);

    const oldItems = allItems.filter(
      (item) => selectedNewOrderItems.filter((i) => parseInt(i.id) === parseInt(item.id)).length === 0
    );

    const pendingOrderItems = quantityChangedItems.concat(oldItems);

    const uniqueOrderIds = [...new Set(orderstoBeDisabled.map((order) => order.id))];
    if (pendingOrderItems.length > 0) {
      let partialUpdateData = {
        uniqueOrderIds: uniqueOrderIds,
        details: selectedNewOrderItems,
        selectedOrderTotal: selectedOrderTotal,
        orderstoBeDisabled: orderstoBeDisabled,
        selectedNewOrderItems: selectedNewOrderItems,
        pendingOrderItems: pendingOrderItems,
        table: table,
        submitted_by: user,
        discount: discount, // TODO: handle discount in splitted orders with invoices and no invoices
        discount_reason: discountReason,
        resultStatus: resultStatus,
        paymentMethod: paymentMethod,
        cancellationReason: cancellationReason,
        notes: notes,
        force_send_cloud_invoice: getForceSendCloudInvoice(paymentMethod),
        invoice_type: getInvoiceType(paymentMethod),
        customer: submitInvoiceType !== InvoiceType.Retail ? customerInputs : null,
        terminalId: terminalId,
        tipAmount: tipAmount,
        uuid: UUID,
      };

      ordersPartialUpdate(partialUpdateData).then((response) => {
        if (response?.data?.error) {
          setPaymentErrorMsg(response?.data?.msg);
          setSelectedNewOrderItems([]);

          logError("partialUpdate Error", {
            user: user,
            errorFunction: "partialUpdate",
            msg: "Error returned from partial update, msg: " + response?.data?.msg,
            pos_integration_enabled: pos_integration_enabled,
            ...partialUpdateData,
          });
        } else {
          broadCastOrdersChangeWS(organisationId);
          navigate("/admin/tables");
        }
      });
    } else {
      // updating all items
      let data = {
        orders: uniqueOrderIds,
        paymentMethod: paymentMethod,
        resultStatus: resultStatus,
        cancellationReason: cancellationReason,
        notes: notes,
        submittedBy: user,
        discount: discount,
        discount_reason: discountReason,
        allowDiscount: allow_discount,
        force_send_cloud_invoice: getForceSendCloudInvoice(paymentMethod),
        organisationId: organisationId,
        invoice_type: getInvoiceType(paymentMethod),
        customer: submitInvoiceType !== InvoiceType.Retail ? customerInputs : null,
        terminalId: terminalId,
        tipAmount: tipAmount,
        uuid: UUID,
      };
      // when we pay/cancel multiple orders, we do it in bulk from the backend
      ordersBulkUpdate(data).then((response) => {
        if (response?.data?.error) {
          setPaymentErrorMsg(response?.data?.msg);
          setSelectedNewOrderItems([]);
          logError("bulkupdate error", {
            user: user,
            errorFunction: "bulkUpdate",
            pos_integration_enabled: pos_integration_enabled,
            msg: "Error returned from bulkUpdate, msg: " + response?.data?.msg,
            ...data,
          });
        } else {
          broadCastOrdersChangeWS(organisationId);
          navigate("/admin/tables");
        }
      });
    }
  }

  function payOrder(paymentMethod = null, terminalId = null, tipAmount = null) {
    setPaymentErrorMsg("");
    partialUpdateOrder(OrderStatus.Payed, paymentMethod, null, null, terminalId, tipAmount);
  }

  function handleGiftOrderClick() {
    setGiftModalOpen(true);
  }

  function handleCancelOrderClick() {
    setCancellationModalOpen(true);
  }

  function handleGiftOrderSubmit(notes = null) {
    giftOrder(notes);
  }
  const checkInventory = (orderItems) => {
    if (orderItems && orderItems.length > 0) {
      return orderItems.some((item) => item.product.data.attributes.inventoryEnabled);
    }
    return false;
  };
  const canReturnInventory = useMemo(() => {
    return checkInventory(selectedNewOrderItems);
  }, [selectedNewOrderItems]);

  function handleInventory(return_inventory, orderItems, updateProduct) {
    if (return_inventory && orderItems && orderItems.length > 0) {
      let productUpdates = new Map(); // Use a Map to store updates by product ID
      orderItems.forEach((item) => {
        if (item.product.data.attributes.inventoryEnabled) {
          const productId = item.product.data.id;
          const newQuantity = item.product.data.attributes.inventory + item.quantity;

          // Check if there's already an update for this product ID
          if (productUpdates.has(productId)) {
            // If an update exists, add the quantity to it
            productUpdates.set(productId, productUpdates.get(productId) + item.quantity);
          } else {
            // Otherwise, create a new entry for this product ID
            productUpdates.set(productId, newQuantity);
          }
        }
      });

      // Now, you have a Map with product IDs and their corresponding quantities to update
      // Iterate through the map and perform one update for each product
      const updatePromises = Array.from(productUpdates.entries()).map(([productId, newQuantity]) => {
        return updateProduct({
          id: productId,
          inventory: newQuantity,
        });
      });

      Promise.all(updatePromises).catch((error) => {
        logError(error);
      });
    }
  }

  function handleCancelOrderSubmit(reason, return_inventory = false) {
    handleInventory(return_inventory, selectedNewOrderItems, updateProduct);
    cancelOrder(reason);
  }

  function cancelOrder(reason = null) {
    partialUpdateOrder(OrderStatus.Cancelled, null, reason);
  }

  function giftOrder(notes = null) {
    partialUpdateOrder(OrderStatus.Cancelled, null, CANCELLATION_REASON_GIFT, notes);
  }

  function getProductPrice(orderItem) {
    let basePrice = orderItem?.product?.data?.attributes?.price || 0;

    let productVariants = orderItem?.variant;
    const optionsExtraPrice = productVariants
      ? productVariants.reduce((totalExtra, variant) => {
          let variantOption = variant.variant_option;
          let variantOptionPrice = variantOption.data?.attributes.price;
          if (variantOptionPrice && variant.quantity && variant.quantity > 1) {
            variantOptionPrice = variantOptionPrice * variant.quantity;
          }
          return totalExtra + (variantOptionPrice || 0);
        }, 0)
      : 0;
    return formatCurrency(basePrice + optionsExtraPrice);
  }
  function customerFieldsValidator() {
    let isValid = true;
    if (customerInputs.name.length === 0) {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, name: t("Name_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, name: "" },
        };
      });
    }
    if (customerInputs.vat_number.length === 0) {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, vat_number: t("Vat_number_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, vat_number: "" },
        };
      });
    }

    if (customerInputs.doy.length === 0) {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, doy: t("Doy_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, doy: "" },
        };
      });
    }

    if (customerInputs.address.length === 0) {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, address: t("Address_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, address: "" },
        };
      });
    }

    if (customerInputs.city.length === 0) {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, city: t("City_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, city: "" },
        };
      });
    }

    if (customerInputs.postcode.length === 0) {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, postcode: t("Postcode_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, postcode: "" },
        };
      });
    }

    return isValid;
  }
  function handleCustomerInputChange(event) {
    setCustomerInputs((prevInputs) => {
      return {
        ...prevInputs,
        [event.target.name]: event.target.value,
      };
    });
  }
  return {
    pendingOrders,
    isLoading,
    isError,
    selectedNewOrderItems,
    selectedOrderTotal,
    handleOrderItemQuantityChange,
    handleCheckItemToggle,
    handlePayButtonPress,
    paymentMethodDialogOpen,
    setPaymentMethodDialogOpen,
    payOrder,
    cancelOrder,
    giftOrder,
    cancellationModalOpen,
    setCancellationModalOpen,
    handleCancelOrderClick,
    handleCancelOrderSubmit,
    giftModalOpen,
    handleGiftOrderClick,
    handleGiftOrderSubmit,
    setGiftModalOpen,
    payOrderLoading: addOrderLoading || updateOrderLoading || isBulkUpdateLoading || ordersPartialUpdateLoading,
    getProductPrice,
    hasPredefindDiscount,
    discount,
    setDiscount,
    errorMessage,
    setErrorMessage,
    orderGrandTotal,
    allow_discount,
    discountAccordionOpen,
    setDiscountAccordionOpen,
    payment_method_enabled,
    handleInventory,
    canReturnInventory,
    checkInventory,
    allow_submit_invoice,
    forceSubmit,
    setForceSubmit,
    cloud_cash_register_enabled,
    manual_cloud_cash_register_enabled,
    discountReason,
    setDiscountReason,
    customerInputs,
    setCustomerInputs,
    handleCustomerInputChange,
    customerFieldsValidator,
    submitInvoiceType,
    setSubmitInvoiceType,
    wholesale_enabled,
    paymentErrorMsg,
    isDelivery,
  };
};

export default usePayItems;
