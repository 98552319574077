import React from "react";
import {
  useGetOptionsForVariantQuery,
  useAddOptionsMutation,
  useUpdateVariantOptionsMutation,
  useDeleteVariantOptionsMutation,
} from "api/variantsSlice";
import { t } from "i18next";

const useVariationOptions = () => {
  const [inputs, setInputs] = React.useState({
    name: "",
    label: "",
    price: 0,
    enabled: true,
    is_fee: false,
    quantity_support: false,
    errors: {},
  });
  const { data: options, isLoading, isError } = useGetOptionsForVariantQuery();
  const [addOptions] = useAddOptionsMutation();
  const [updateOptions] = useUpdateVariantOptionsMutation();
  const [deleteOptions] = useDeleteVariantOptionsMutation();
  const [selectedOption, setSelectedOption] = React.useState({});
  const [addOptionDialogOpen, setAddOptionDialogOpen] = React.useState(false);
  const [editOptionDialogOpen, setEditOptionDialogOpen] = React.useState(false);
  const [deleteOptionDialogOpen, setDeleteOptionDialogOpen] = React.useState(false);

  function clearInputs() {
    setInputs({
      name: "",
      label: "",
      price: 0,
      enabled: true,
      quantity_support: false,
      is_fee: false,
      errors: {},
    });
  }

  function handleAddOptionDialogOpen() {
    setAddOptionDialogOpen(true);
  }

  function handleAddOptionDialogClose() {
    setAddOptionDialogOpen(false);
    clearInputs();
  }

  function handleEditOptionDialogOpen(selectedRow) {
    setSelectedOption(selectedRow);
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        name: selectedRow.name,
        label: selectedRow.label,
        price: selectedRow.price,
        enabled: selectedRow.enabled,
        quantity_support: selectedRow.quantity_support,
        is_fee: selectedRow.is_fee,
      };
    });
    setEditOptionDialogOpen(true);
  }

  function handleEditOptionDialogClose() {
    setEditOptionDialogOpen(false);
    clearInputs();
  }

  function handleDeleteOptionDialogOpen(selectedRow) {
    setSelectedOption(selectedRow);
    setDeleteOptionDialogOpen(true);
  }

  function handleDeleteOptionDialogClose() {
    setDeleteOptionDialogOpen(false);
  }

  function handleInputChange(event) {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  function handleCheckboxChange(event) {
    const { name, checked } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: checked }));
  }

  function handleAddOption() {
    if (!validateFields()) {
      return;
    }

    addOptions(inputs);
    handleAddOptionDialogClose();
  }

  function validateFields() {
    let isValid = true;
    if (inputs.name.length === 0) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, name: t("Name_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      if (inputs.errors.name?.length > 0) {
        setInputs((prevInputs) => {
          return {
            ...prevInputs,
            errors: { ...prevInputs.errors, name: "" },
          };
        });
      }
    }

    if (inputs.label.length === 0) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, label: "The Label field cannot be empty" },
        };
      });
      isValid = false;
    } else {
      if (inputs.errors.label?.length > 0) {
        setInputs((prevInputs) => {
          return {
            ...prevInputs,
            errors: { ...prevInputs.errors, label: "" },
          };
        });
      }
    }

    if (inputs.price < 0) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, price: t("Price_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      if (inputs.errors.price?.length > 0) {
        setInputs((prevInputs) => {
          return {
            ...prevInputs,
            errors: { ...prevInputs.errors, price: "" },
          };
        });
      }
    }

    return isValid;
  }

  function handleUpdateOption() {
    if (!validateFields()) {
      return;
    }

    updateOptions({ ...inputs, id: selectedOption.id });
    handleEditOptionDialogClose();
  }

  function handleDeleteOption() {
    deleteOptions(selectedOption.id);
    handleDeleteOptionDialogClose();
  }

  return {
    inputs,
    options,
    isLoading,
    isError,
    selectedOption,
    addOptionDialogOpen,
    editOptionDialogOpen,
    deleteOptionDialogOpen,
    handleAddOptionDialogOpen,
    handleAddOptionDialogClose,
    handleEditOptionDialogOpen,
    handleEditOptionDialogClose,
    handleDeleteOptionDialogOpen,
    handleDeleteOptionDialogClose,
    handleInputChange,
    handleCheckboxChange,
    handleAddOption,
    handleUpdateOption,
    handleDeleteOption,
  };
};

export default useVariationOptions;
