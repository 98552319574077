import { CANCELLATION_REASON_GIFT } from "utilities/constants";
export const OrderStatus = Object.freeze({
  Pending: "pending",
  Cancelled: "cancelled",
  Payed: "payed",
  Disabled: "disabled",
});

/**
 * Returns the order status of an order
 * If an order is cancelled with reaosn 'Gift' it returns status 'Gift'
 */
export function getOrderStatus(status, cancellationReason) {
  if (status === OrderStatus.Cancelled && cancellationReason === CANCELLATION_REASON_GIFT) {
    status = "Gift";
  }
  return status;
}
