import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useGetPrinterByIdQuery,
  useUpdatePrinterMutation,
  useAddPrinterMutation,
  useGetPrintServerPrintersQuery,
} from "api/printerSlice";
import { useGetAllCategoriesQuery } from "api/categoriesSlice";
import ROUTES_OBJ from "utilities/enums/Routes";
import { useGetFloorsWithTablesQuery } from "api/floorsSlice";
import { useTranslation } from "react-i18next";

const useEditPrinter = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { cloud_cash_register_enabled, helper_wholesale_receipts_enabled, course_stages_enabled } = useSelector(
    (state) => state.settings
  );
  const { data: printServerPrinters } = useGetPrintServerPrintersQuery();
  const { data: categories } = useGetAllCategoriesQuery();
  const { data: floorsWithTables } = useGetFloorsWithTablesQuery();
  const {
    data: printer,
    isError: printerError,
    isLoading: printerLoading,
  } = useGetPrinterByIdQuery(id?.toString() || "");
  const [updatePrinter, { isLoading: updatePrinterLoading }] = useUpdatePrinterMutation();
  const [addPrinter] = useAddPrinterMutation();
  const [open, setOpen] = useState("");
  const { t } = useTranslation();
  const [inputs, setInputs] = React.useState({
    name: "",
    activePrinter: false,
    receiptPrinter: false,
    openTablePrinter: false,
    hide_total: false,
    printSoundNotif: false,
    courseStagesPrinter: false,
    extraTopSpace: 0,
    extraBottomSpace: 0,
    printerId: "",
    printer_encoding: "iso-8859-7",
    columnWidth: 48,
    printFontSize: "medium",
    receiptGroups: [],
    errors: {},
  });
  const [resultMsg, setResultMsg] = React.useState({
    isError: false,
    message: "",
  });

  React.useEffect(() => {
    // we want the displayed name to be the latest up-to-date name on the printServer
    if (printServerPrinters?.length > 0) {
      // we want to get the Printer instance from our PrintServer, to save the up-to-date name & id to db
      const printServerPrinter = printServerPrinters?.find((printer) => printer.id === parseInt(id));

      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          name: printServerPrinter?.name,
        };
      });
    }
  }, [printServerPrinters]);

  React.useEffect(() => {
    if (printer) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          name: printer.attributes.name,
          printerId: printer.attributes.id,
          activePrinter: printer.attributes?.activePrinter || false,
          receiptPrinter: printer.attributes?.receiptPrinter || false,
          openTablePrinter: printer.attributes?.openTablePrinter || false,
          hide_total: printer.attributes?.hide_total || false,
          printSoundNotif: printer.attributes?.printSoundNotif || false,
          extraTopSpace: printer.attributes?.extraTopSpace || 0,
          extraBottomSpace: printer.attributes?.extraBottomSpace || 0,
          courseStagesPrinter: printer.attributes?.courseStagesPrinter || false,
          columnWidth: printer.attributes?.columnWidth || 48,
          printFontSize: printer.attributes?.printFontSize || "medium",
          printer_encoding: printer.attributes?.printer_encoding || "iso-8859-7",
          receiptGroups: printer.attributes.receipt_groups.map((group) => {
            return {
              id: group.id,
              categories: group.categories.data.map((category) => category.id),
              table_filters_enabled: group.table_filters_enabled,
              table_filters: group.table_filters.data.map((table_filter) => table_filter.id),
            };
          }),
        };
      });
    }
  }, [printer]);

  const handleAddOrUpdatePrinter = () => {
    setResultMsg({ msg: "", isError: false });
    // we want to get the Printer instance from our PrintServer, to save the up-to-date name & id to db
    const printServerPrinter = printServerPrinters?.find((printer) => printer.id === parseInt(id));

    const {
      activePrinter,
      receiptGroups,
      receiptPrinter,
      openTablePrinter,
      printSoundNotif,
      extraTopSpace,
      extraBottomSpace,
      columnWidth,
      printFontSize,
      printer_encoding,
      courseStagesPrinter,
      hide_total,
    } = inputs;
    if (printer) {
      updatePrinter({
        id: printer.id,
        name: printServerPrinter?.name,
        printerId: printServerPrinter?.id.toString(),
        activePrinter: activePrinter,
        receiptPrinter: receiptPrinter,
        openTablePrinter: openTablePrinter,
        printSoundNotif: printSoundNotif,
        extraTopSpace: extraTopSpace,
        extraBottomSpace: extraBottomSpace,
        courseStagesPrinter: courseStagesPrinter,
        printer_encoding: printer_encoding,
        hide_total: hide_total,
        columnWidth: columnWidth?.toString(),
        printFontSize: printFontSize?.toString(),
        receiptGroups: receiptGroups.map((group) => {
          return {
            categories: group.categories,
            table_filters_enabled: group.table_filters_enabled,
            table_filters: group.table_filters,
          };
        }),
      }).then(() => {
        setResultMsg({ msg: t("Saving_successful"), isError: false });
      });
    } else {
      addPrinter({
        name: printServerPrinter?.name,
        printerId: printServerPrinter?.id.toString(),
        activePrinter: activePrinter,
        receiptPrinter: receiptPrinter,
        openTablePrinter: openTablePrinter,
        printSoundNotif: printSoundNotif,
        extraTopSpace: extraTopSpace,
        extraBottomSpace: extraBottomSpace,
        courseStagesPrinter: courseStagesPrinter,
        printer_encoding: printer_encoding,
        hide_total: hide_total,
        columnWidth: columnWidth?.toString(),
        printFontSize: printFontSize?.toString(),
        receiptGroups: receiptGroups.map((group) => {
          return {
            categories: group.categories,
            table_filters_enabled: group.table_filters_enabled,
            table_filters: group.table_filters,
          };
        }),
      }).then(() => {
        setResultMsg({ msg: t("Saving_successful"), isError: false });
      });
    }
  };

  function goBack() {
    navigate("/admin/" + ROUTES_OBJ.SETTINGS_PRINTER_MANAGEMENT.path);
  }

  function handleCancelClick() {
    goBack();
  }

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const handleFloorCheckboxChange = (e, groupIndx, floor) => {
    const group = inputs.receiptGroups[groupIndx];
    const isChecked =
      e.target.checked && !floor.attributes.tables.data.every((table) => group.table_filters.includes(table.id));
    const tableIds = floor.attributes.tables.data.map((table) => table.id);

    if (isChecked) {
      setInputs((prev) => ({
        ...prev,
        receiptGroups: [
          ...prev.receiptGroups.slice(0, groupIndx),
          {
            ...group,
            table_filters: [...group.table_filters, ...tableIds],
          },
          ...prev.receiptGroups.slice(groupIndx + 1),
        ],
      }));
    } else {
      setInputs((prev) => ({
        ...prev,
        receiptGroups: [
          ...prev.receiptGroups.slice(0, groupIndx),
          {
            ...group,
            table_filters: group.table_filters.filter((id) => !tableIds.includes(id)),
          },
          ...prev.receiptGroups.slice(groupIndx + 1),
        ],
      }));
    }
  };

  function printerGroupCategoryClicked(group, category) {
    setInputs((prev) => ({
      ...prev,
      receiptGroups: prev.receiptGroups.map((receiptGroup) => {
        if (receiptGroup.id !== group.id) return receiptGroup;

        let inputChecked = receiptGroup.categories.find((cat) => cat === category.id);
        if (!inputChecked) {
          return {
            ...receiptGroup,
            categories: [...receiptGroup.categories, category.id],
          };
        } else {
          return {
            ...receiptGroup,
            categories: receiptGroup.categories.filter((id) => id !== category.id),
          };
        }
      }),
    }));
  }

  function handleSelectAllClicked(group) {
    setInputs((prev) => ({
      ...prev,
      receiptGroups: prev.receiptGroups.map((receiptGroup) => {
        if (receiptGroup.id !== group.id) return receiptGroup;

        return {
          ...receiptGroup,
          categories:
            receiptGroup.categories.length === categories.length ? [] : categories.map((category) => category.id),
        };
      }),
    }));
  }

  function handleAddGroup() {
    setInputs((prevInputs) => ({
      ...prevInputs,
      receiptGroups: [
        ...prevInputs.receiptGroups,
        {
          id: prevInputs.receiptGroups.length,
          categories: [],
          table_filters: [],
          table_filters_enabled: false,
          hide_total: false,
        },
      ],
    }));
  }

  function handleRemoveGroup(group) {
    setInputs((prevInputs) => ({
      ...prevInputs,
      receiptGroups: prevInputs.receiptGroups.filter((currentGroup) => currentGroup.id !== group.id),
    }));
  }

  return {
    inputs,
    setInputs,
    categories,
    handleAddOrUpdatePrinter,
    handleCancelClick,
    printerError,
    printerLoading,
    toggle,
    printerGroupCategoryClicked,
    handleSelectAllClicked,
    handleAddGroup,
    handleRemoveGroup,
    open,
    cloud_cash_register_enabled,
    helper_wholesale_receipts_enabled,
    floorsWithTables,
    handleFloorCheckboxChange,
    updatePrinterLoading,
    resultMsg,
    course_stages_enabled,
  };
};

export default useEditPrinter;
