import React, { useEffect } from "react";
import { VatCategory } from "utilities/enums/VatCategory";
import { t } from "i18next";
import {
  useGetAllProductsQuery,
  useGetCategoriesForProductQuery,
  useGetVariantsForProductQuery,
  useAddProductMutation,
} from "api/productsSlice";
import { reoderArrayOnDrop } from "utilities/utils";
import { useSelector } from "react-redux";

const useNewProduct = (props) => {
  const [filterCategory, setFilterCategory] = React.useState("");
  const { data: categories } = useGetCategoriesForProductQuery();
  const { data: variants } = useGetVariantsForProductQuery();
  const [addProduct, { isLoading: addProductLoading }] = useAddProductMutation();
  const [inputs, setInputs] = React.useState({
    active: true,
    name: "",
    description: "",
    image: null,
    price: "",
    vat_category: VatCategory.VAT_13.value,
    category: "",
    variants: [],
    selectVariation: "",
    inventoryEnabled: false,
    inventoryQuantity: 0,
    errors: {},
    rank: null,
  });
  const { data: products } = useGetAllProductsQuery(filterCategory ? parseInt(filterCategory) : "all");

  const [selectImageDialog, setSelectImageDialog] = React.useState(false);
  const { inventory_management_disabled } = useSelector((state) => state.settings);

  useEffect(() => {
    if (variants) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          avialableVariants: [...variants].filter((variant) => {
            return !inputs.variants.includes(variant.id);
          }),
        };
      });
    }
  }, [inputs.variants, variants]);

  function clearInputs() {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        active: true,
        name: "",
        description: "",
        image: null,
        price: "",
        vat_category: VatCategory.VAT_13.value,
        category: "",
        inventoryEnabled: false,
        inventoryQuantity: 0,
        selectVariation: "",
        variants: [],
        errors: {},
        rank: null,
      };
    });
  }

  function productExists() {
    return products?.some((product) => {
      return product.name === inputs.name;
    });
  }

  function handleAddProductSubmit() {
    if (productExists()) {
      alert("This product name already exists. Please choose another one.");
      return;
    }

    if (!validateFields()) {
      return;
    }

    let newProduct = {
      name: inputs.name,
      disabled: !inputs.active,
      description: inputs.description,
      image: inputs.image,
      price: inputs.price,
      vat_category: inputs.vat_category,
      category: inputs.category,
      variants: inputs.variants,
      inventoryEnabled: inputs.inventoryEnabled,
      inventory: inputs.inventory,
      rank: inputs.rank != "" ? inputs.rank : null,
    };

    addProduct(newProduct).then(() => {
      clearInputs();
      props?.onCancel();
    });
  }

  function handleInputChange(event) {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        [event.target.name]: event.target.value,
      };
    });
  }

  function validateFields() {
    let isValid = true;
    if (inputs.name.length === 0) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, name: t("Name_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      if (inputs.errors.name?.length > 0) {
        setInputs((prevInputs) => {
          return {
            ...prevInputs,
            errors: { ...prevInputs.errors, name: "" },
          };
        });
      }
    }

    if (parseFloat(inputs.price) < 0 || inputs.price === "") {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, price: t("Price_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      if (inputs.errors.price?.length > 0) {
        setInputs((prevInputs) => {
          return {
            ...prevInputs,
            errors: { ...prevInputs.errors, price: "" },
          };
        });
      }
    }

    if (inputs.category === "" || inputs.category === null) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, category: t("Category_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      if (inputs.errors.category?.length > 0) {
        setInputs((prevInputs) => {
          return {
            ...prevInputs,
            errors: { ...prevInputs.errors, category: "" },
          };
        });
      }
    }

    if (inputs.inventoryEnabled && (inputs.inventoryQuantity < 0 || inputs.inventoryQuantity === "")) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, inventoryQuantity: t("Inventory_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      if (inputs.errors.inventoryQuantity?.length > 0) {
        setInputs((prevInputs) => {
          return {
            ...prevInputs,
            errors: { ...prevInputs.errors, inventoryQuantity: "" },
          };
        });
      }
    }

    if (inputs.rank && inputs.rank <= 0) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, rank: t("Rank_error_message") },
        };
      });
      isValid = false;
    } else {
      if (inputs.errors.rank?.length > 0) {
        setInputs((prevInputs) => {
          return {
            ...prevInputs,
            errors: { ...prevInputs.errors, rank: "" },
          };
        });
      }
    }

    return isValid;
  }
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = reoderArrayOnDrop(inputs.variants, result);
    setInputs({ ...inputs, variants: items });
  };

  return {
    inputs,
    variants,
    categories,
    setInputs,
    handleAddProductSubmit,
    handleInputChange,
    selectImageDialog,
    setSelectImageDialog,
    addProductLoading,
    handleDragEnd,
    inventory_management_disabled,
  };
};

export default useNewProduct;
