import React from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { Card, CardHeader, CardBody, NavItem, NavLink, Nav, Row, Col, Table, Button, FormGroup } from "reactstrap";
import { KeynameToTitle, KeynameToSubtitle } from "utilities/enums/Analytics";
import { useTranslation } from "react-i18next";
import { useUpdateUserSalesMutation } from "api/analyticsSlice";
import AlertModal from "./Modals/AlertModal";
import { logError, formatDate } from "utilities/utils";
import { DatePicker } from "./DatePicker/DatePicker";
import moment from "moment";

export const CardTable = ({ chartData, chartNav, chartType, key_name, chartFormData, report_period = "day" }) => {
  const { t } = useTranslation();
  const title = t(KeynameToTitle[key_name]);
  const subtitle = t(KeynameToSubtitle[key_name]);
  const { locale } = useSelector((state) => state.locale);
  const [editModal, setEditModal] = React.useState(false);
  const [currentTable, setCurrentTable] = React.useState(chartData);
  const { payment_method_enabled, pos_integration_enabled, external_source_types_enabled } = useSelector(
    (state) => state.settings
  );
  const [updateUserSales, { isSuccess: isUpdateSuccess, isLoading: updateTableLoading, isError: isUpdateError }] =
    useUpdateUserSalesMutation();
  const [formData, setFormData] = React.useState({
    start_date: chartFormData?.start_date || null,
    end_date: chartFormData?.end_date || null,
    groupby: chartFormData?.groupby || "day",
  });
  const chartDataRef = React.useRef(chartData);
  const chartFormDataRef = React.useRef(chartFormData);
  const [activeNav, setActiveNav] = React.useState(0);
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
  };
  React.useEffect(() => {
    chartDataRef.current = chartData;
    setCurrentTable(chartDataRef.current);
  }, [chartData]);
  React.useEffect(() => {
    chartFormDataRef.current = chartFormData;
    setFormData(chartFormDataRef.current);
  }, [chartFormData]);

  return (
    <>
      <Card className={chartType === "line" ? "bg-default" : ""}>
        <CardHeader className="bg-transparent">
          <Row className="align-items-center">
            <Col xs="8" lg="6" className="pr-0">
              <h5 className={chartType === "line" ? "h3 text-white mb-0" : "h3 mb-0"}>{title}</h5>
              <h6 className="text-light text-uppercase ls-1 mb-1">{subtitle}</h6>
              {currentTable && chartType === "users_stats" && report_period !== "day" && (
                <>
                  {formData.start_date && formData.end_date && (
                    <div className="filter-tag text-light text-sm">
                      <div className="text">
                        {t("Date", { date: formatDate(formData.start_date, locale) })} -{" "}
                        {t("Date", { date: formatDate(formData.end_date, locale) })}
                      </div>
                    </div>
                  )}
                </>
              )}
            </Col>
            {chartNav && chartNav.length > 1 ? (
              <Col xs="4" lg="6">
                <Nav className="justify-content-end flex-nowrap" pills>
                  {chartNav.map((prop, key) => {
                    return (
                      <NavItem key={key}>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === key,
                          })}
                          href="#"
                          onClick={(e) => toggleNavs(e, key)}
                        >
                          <span className="d-none d-md-block">{t(prop.title)}</span>
                          <span className="d-md-none">{prop.subtitle}</span>
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>
              </Col>
            ) : null}

            {currentTable && chartType === "users_stats" && report_period !== "custom" && report_period !== "day" ? (
              <Col xs="4" lg="6" className="pl-0">
                <div className="d-flex justify-content-end flex-nowrap">
                  <Button
                    className="btn btn-primary filter-icon"
                    type="button"
                    color="primary"
                    size="sm"
                    onClick={() => setEditModal(true)}
                  >
                    {t("Filters")}
                  </Button>
                </div>
              </Col>
            ) : null}
          </Row>
        </CardHeader>
        <CardBody>
          <div className="chart h-100">
            {chartData && chartData.length > 0 ? (
              {
                table_trending: (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">{t("Product")}</th>
                        <th scope="col">{t("price")}</th>
                        <th scope="col">{t("Quantity")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {chartData[activeNav] &&
                        chartData[activeNav].map((prop, key) => {
                          return (
                            <tr key={key}>
                              <th scope="row">
                                <div className="media align-items-center">
                                  <div className="media-body">
                                    <span className="mb-0 text-sm">{prop.name}</span>
                                  </div>
                                </div>
                              </th>
                              <td>{prop.price}</td>
                              <td>{prop.quantity_sum}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                ),
                table_top_ten: (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">{t("Product")}</th>
                        <th scope="col">{t("price")}</th>
                        <th scope="col">{t("Quantity")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {chartData[activeNav] &&
                        chartData[activeNav].map((prop, key) => {
                          return (
                            <tr key={key}>
                              <th scope="row">
                                <div className="media align-items-center">
                                  <div className="media-body">
                                    <span className="mb-0 text-sm">{prop.name}</span>
                                  </div>
                                </div>
                              </th>
                              <td>{prop.price}</td>
                              <td>{prop.quantity_sum}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                ),
                users_stats: (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">{t("Username")}</th>
                        <th scope="col">{t("Total")}</th>
                        {payment_method_enabled && (
                          <>
                            <th scope="col">{t("Cash")}</th>
                            <th scope="col">{t("Card")}</th>
                            {external_source_types_enabled && <th scope="col">{t("Bank_statement")}</th>}
                          </>
                        )}
                        {pos_integration_enabled && <th scope="col">{t("Tip")}</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {currentTable[activeNav] &&
                        currentTable[activeNav].map((prop, key) => {
                          return (
                            <tr key={key}>
                              <th scope="row">
                                <div className="media align-items-center">
                                  <div className="media-body">
                                    <span className="mb-0 text-sm">{prop.username}</span>
                                  </div>
                                </div>
                              </th>
                              <td>{parseFloat(prop.total_sum).toFixed(2)}</td>
                              {payment_method_enabled && (
                                <>
                                  <td>{prop?.cash ? parseFloat(prop.cash).toFixed(2) : "0.00"}</td>
                                  <td>{prop?.card ? parseFloat(prop.card).toFixed(2) : "0.00"}</td>
                                  {external_source_types_enabled && (
                                    <td>
                                      {prop?.bank_statement ? parseFloat(prop.bank_statement).toFixed(2) : "0.00"}
                                    </td>
                                  )}
                                </>
                              )}
                              {pos_integration_enabled && (
                                <td>{prop?.tip_amount ? parseFloat(prop.tip_amount).toFixed(2) : "0.00"}</td>
                              )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                ),
              }[chartType]
            ) : (
              <div className="text-center">No data</div>
            )}
          </div>
        </CardBody>
      </Card>
      <AlertModal
        title={t("Edit_Charts")}
        isOpen={editModal}
        cancelText={t("Cancel")}
        okText={t("Save")}
        okDisabled={updateTableLoading}
        onCancel={() => setEditModal(false)}
        onOk={async () => {
          let endFormatted = moment(formData.end_date);
          moment.locale("LL");
          endFormatted.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
          setFormData({ ...formData, end_date: endFormatted.toISOString() });
          const updateTable = await updateUserSales({ formData: formData });
          if (updateTable && updateTable.hasOwnProperty("data") && updateTable.data) {
            setCurrentTable(updateTable?.data?.chartData?.data || [{}]);
            setEditModal(false);
          } else if (updateTable && updateTable.hasOwnProperty("error")) {
            logError(updateTable.error);
            setEditModal(false);
          } else {
            setEditModal(false);
          }
        }}
        body={
          <>
            <Row>
              <Col md="6" sm="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="start-date">
                    {t("From")}
                  </label>
                  <DatePicker
                    id="start-date"
                    selectedDate={formData.start_date ? new Date(formData.start_date) : ""}
                    startDate={new Date(formData.start_date)}
                    endDate={new Date(formData.end_date)}
                    onChange={(e) => {
                      setFormData({ ...formData, start_date: e });
                    }}
                    placeholder={t("From")}
                  />
                </FormGroup>
              </Col>
              <Col md="6" sm="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="end-date">
                    {t("To")}
                  </label>
                  <DatePicker
                    selectedDate={formData.end_date ? new Date(formData.end_date) : ""}
                    startDate={new Date(formData.start_date)}
                    endDate={new Date(formData.end_date)}
                    onChange={(e) => {
                      setFormData({ ...formData, end_date: e });
                    }}
                    placeholder={t("To")}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        }
      />
    </>
  );
};
