import { apiSlice } from "./apiSlice";
import { TagTypes } from "utilities/enums/TagTypes";
import { store } from "redux/store";
import { sortByRankAsc } from "utilities/utils";

export const ProductService = apiSlice.injectEndpoints({
  tagTypes: [TagTypes.Products],
  endpoints: (builder) => ({
    getAllProducts: builder.query({
      query: (category) => {
        return category !== "all"
          ? `products?sort[0]=disabled&sort[1]=rank%3Aasc&populate=*&pagination[limit]=900&filters[category][id][$eq]=${category}&filters[organisation][id]=${
              store.getState().auth.organisationId
            }`
          : `products?sort[0]=disabled&sort[1]=rank%3Aasc&populate=*&pagination[limit]=900&filters[organisation][id]=${
              store.getState().auth.organisationId
            }`;
      },
      providesTags: [TagTypes.Products, TagTypes.Categories, TagTypes.Variants, TagTypes.Orders],
      transformResponse: (responseData) => {
        let products = responseData.data.map((product) => {
          return {
            id: product.id,
            rank: product.attributes.rank,
            name: product.attributes.name,
            enabled: !product.attributes.disabled,
            description: product.attributes.description,
            image: product.attributes.image?.data,
            price: product.attributes.price,
            vat_category: product.attributes.vat_category,
            category: product.attributes.category?.data?.attributes.name,
            variants: product.attributes.variants.data.map((variant) => variant.attributes.name).join(", "),
            inventoryEnabled: product.attributes.inventoryEnabled,
            inventory: product.attributes.inventory,
          };
        });

        return sortByRankAsc(products);
      },
    }),
    getAllEnabledProducts: builder.query({
      query: (category) => {
        return category !== "all"
          ? `products?sort[0]=rank%3Aasc&populate=*&pagination[limit]=900&filters[$or][0][disabled][$eq]=false&filters[$or][1][disabled][$null]=true&filters[category][id][$eq]=${category}&filters[organisation][id]=${
              store.getState().auth.organisationId
            }`
          : `products?sort[0]=rank%3Aasc&populate=*&pagination[limit]=900&filters[$or][0][disabled][$eq]=false&filters[$or][1][disabled][$null]=true&filters[organisation][id]=${
              store.getState().auth.organisationId
            }`;
      },
      providesTags: [TagTypes.Products, TagTypes.Categories, TagTypes.Variants, TagTypes.Orders],
      transformResponse: (responseData) => {
        let products = responseData.data.map((product) => {
          return {
            id: product.id,
            rank: product.attributes.rank,
            name: product.attributes.name,
            description: product.attributes.description,
            image: product.attributes.image?.data,
            price: product.attributes.price,
            vat_category: product.attributes.vat_category,
            category: product.attributes.category?.data?.attributes.name,
            variants: product.attributes.variants.data.map((variant) => variant.attributes.name).join(", "),
            inventoryEnabled: product.attributes.inventoryEnabled,
            inventory: product.attributes.inventory,
            enabled: !product.attributes.disabled,
          };
        });

        return sortByRankAsc(products);
      },
    }),
    getCategoriesForProduct: builder.query({
      query: () =>
        `categories?populate=*&pagination[limit]=100&filters[organisation][id]=${store.getState().auth.organisationId}`,
      providesTags: [TagTypes.Categories],
      transformResponse: (responseData) => {
        return responseData.data.map((category) => {
          return {
            id: category.id,
            name: category.attributes.name,
          };
        });
      },
    }),
    getVariantsForProduct: builder.query({
      query: () =>
        `variants?populate=*&pagination[limit]=100&filters[organisation][id]=${store.getState().auth.organisationId}`,
      providesTags: [TagTypes.Variants],
      transformResponse: (responseData) => {
        return responseData.data.map((variant) => {
          return {
            id: variant.id,
            name: variant.attributes.name,
            label: variant.attributes.label,
            enabled: variant.attributes.enabled,
          };
        });
      },
    }),
    getProductById: builder.query({
      query: (productID) =>
        `/products?populate[0]=variants.variant_options&populate[1]=category&filters[id][$eq]=${productID}&populate[2]=image&filters[organisation][id]=${
          store.getState().auth.organisationId
        }`,
      providesTags: [TagTypes.Products, TagTypes.Orders],
      transformResponse: (responseData) => {
        let response = {};
        if (responseData.data.length > 0) {
          const product = responseData.data[0];
          response = {
            id: product.id,
            rank: product.attributes.rank,
            name: product.attributes.name,
            description: product.attributes.description,
            image: product.attributes.image,
            price: product.attributes.price,
            vat_category: product.attributes.vat_category,
            category: product.attributes.category.data ? parseInt(product.attributes.category?.data?.id) : "",
            variants: product.attributes.variants,
            inventoryEnabled: product.attributes.inventoryEnabled,
            inventory: product.attributes.inventory,
            enabled: !product.attributes.disabled,
          };
        }
        return response;
      },
    }),
    addProduct: builder.mutation({
      query: (product) => ({
        url: "/products",
        method: "POST",
        body: {
          data: {
            name: product.name,
            price: product.price,
            description: product.description,
            category: product.category,
            vat_category: product.vat_category,
            variants: product.variants,
            image: product.image ? product.image : null,
            inventoryEnabled: product.inventoryEnabled,
            inventory: product.inventory,
            organisation: store.getState().auth.organisationId,
            rank: product.rank,
            disabled: product.disabled,
          },
        },
      }),
      invalidatesTags: [TagTypes.Products],
    }),
    updateProduct: builder.mutation({
      query: (product) => ({
        url: `/products/${product.id}`,
        method: "PUT",
        body: {
          data: {
            name: product.name,
            price: product.price,
            description: product.description,
            category: product.category,
            vat_category: product.vat_category,
            variants: product.variants,
            image: product.image ? product.image : null,
            inventoryEnabled: product.inventoryEnabled,
            inventory: product.inventory,
            rank: product.rank,
            disabled: product.disabled,
            organisation: store.getState().auth.organisationId,
          },
        },
      }),
      invalidatesTags: [TagTypes.Products],
      transformResponse: (responseData) => {
        return {
          id: responseData.data.id,
          name: responseData.data.attributes.name,
          description: responseData.data.attributes.description,
          price: responseData.data.attributes.price,
        };
      },
    }),

    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `/products/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [TagTypes.Products],
    }),
    searchProduct: builder.query({
      query: (searchTerm) =>
        `products?filters[organisation][id]=${
          store.getState().auth.organisationId
        }&filters[$or][0][disabled][$eq]=false&filters[$or][1][disabled][$null]=true&filters[name][$containsi]=${searchTerm}&populate=*&sort[0]=name:asc&pagination[limit]=50`,
      providesTags: [TagTypes.Products],
      transformResponse: (responseData) => {
        let products = responseData.data.map((product) => {
          return {
            id: product.id,
            rank: product.attributes.rank,
            name: product.attributes.name,
            description: product.attributes.description,
            image: product.attributes.image?.data,
            price: product.attributes.price,
            vat_category: product.attributes.vat_category,
            category: product.attributes.category?.data?.attributes.name,
            variants: product.attributes.variants.data.map((variant) => variant.attributes.name).join(", "),
            inventoryEnabled: product.attributes.inventoryEnabled,
            inventory: product.attributes.inventory,
            disabled: product.attributes.disabled,
          };
        });

        return products;
      },
    }),
  }),
});

export const {
  useGetAllEnabledProductsQuery,
  useGetAllProductsQuery,
  useGetCategoriesForProductQuery,
  useGetVariantsForProductQuery,
  useAddProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useGetProductByIdQuery,
  useLazySearchProductQuery,
} = ProductService;
