import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials, logOut } from "auth/authSlice";
import { refreshToken } from "./ApiCallerHelper";
import { REACT_APP_BACKEND_URL } from "utilities/constants";
import { store } from "redux/store";
import { logError } from "utilities/utils";

const baseQuery = fetchBaseQuery({
  baseUrl: REACT_APP_BACKEND_URL,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

/**
 * Wrapper for every query made to the Backend
 * Checks if our JWT Token has expired
 * If our token has expired, we get a new Refresh Token from the Backend and repeat the initial
 * API request
 */
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 401) {
    // send refresh token to get new access token
    const refreshResult = await refreshToken(api.getState().auth.refreshToken);
    if (refreshResult?.jwt) {
      const user = api.getState().auth.user;
      // store the new token
      api.dispatch(setCredentials({ jwt: refreshResult.jwt, user, refreshToken: refreshResult.refreshToken }));
      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  } else if (result?.error?.status === "FETCH_ERROR") {
    // in case of fetch error, retry
    logError(
      `Got fetch error for organisation id: ${store.getState()?.auth?.organisationId}. Request endpoint: ${
        result?.meta?.request?.url
      }. Retrying...`
    );
    result = await baseQuery(args, api, extraOptions);
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
