import React, { useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetProductByIdQuery, ProductService } from "api/productsSlice";
import { addItem, editItem } from "redux/cart/cartSlice";
import { TagTypes } from "utilities/enums/TagTypes";
import { apiOrders } from "api/ordersSlice";
import { subscribeToOrdersChangeWS } from "config/websocket";
import { stripHTML } from "utilities/utils";
import { MODE_CREATE } from "utilities/constants";
import { MODE_UPDATE } from "utilities/constants";
import { selectCurrentOrganisation } from "auth/authSlice";

const useNewOrderProduct = (props) => {
  const { table } = useParams();
  let productID = props.productID;
  const [initialProductInfo, setInitialProductInfo] = React.useState(null);
  const [productInfo, setProductInfo] = React.useState({
    quantity: 1,
    variants: [],
    notes: "",
    category: "",
    vat_category: "",
  });
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.cart);
  const organisationId = useSelector(selectCurrentOrganisation);
  const { data: product, isLoading, isError } = useGetProductByIdQuery(parseInt(productID, 10));
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  function refreshComponent() {
    dispatch(apiOrders.util.invalidateTags([TagTypes.Orders]));
    dispatch(
      ProductService.util.invalidateTags([TagTypes.Products, TagTypes.Categories, TagTypes.Variants, TagTypes.Orders])
    );
    forceUpdate();
  }

  useEffect(() => {
    subscribeToOrdersChangeWS(organisationId, refreshComponent);
  }, []);

  useEffect(() => {
    if (props.productInfo) {
      setInitialProductInfo(props.productInfo);
      setProductInfo(props.productInfo);
    }
  }, [props.productInfo]);

  const totalAlreadyOrdered = cartItems.reduce((total, item) => {
    return total + (item.id === parseInt(productID) ? item.quantity : 0);
  }, 0);
  function increaseCartQuantity() {
    setProductInfo((prevProductInfo) => {
      return {
        ...prevProductInfo,
        quantity: prevProductInfo.quantity + 1,
        category: product.category,
        vat_category: product?.vat_category,
      };
    });
  }

  function decreaseCartQuantity() {
    setProductInfo((prevProductInfo) => {
      return {
        ...prevProductInfo,
        quantity: prevProductInfo.quantity > 0 ? prevProductInfo.quantity - 1 : 0,
        category: product.category,
        vat_category: product?.vat_category,
      };
    });
  }

  function handleSubmit() {
    let currentCartItem = getCartItemToSubmit();
    if (props.mode === MODE_CREATE) {
      dispatch(addItem(currentCartItem));
    } else if (props.mode === MODE_UPDATE) {
      dispatch(editItem({ oldCartItem: initialProductInfo, newCartItem: currentCartItem }));
    }
    props.addproductCallback({ newItem: currentCartItem });
  }

  function getCartItemToSubmit() {
    // compute the extra charge from selected variant option (might be extra ingredients etc)
    const optionsExtraPrice = productInfo.variants
      ? productInfo.variants.reduce((totalExtra, info) => {
          let quantity = 1;
          if (info.quantity && info.quantity > 1) {
            quantity = info.quantity;
          }
          return totalExtra + (info.optionPrice ? info.optionPrice * quantity : 0);
        }, 0)
      : 0;

    const price = typeof product.price === "number" ? product.price : 0;
    const extraPrice = typeof optionsExtraPrice === "number" ? optionsExtraPrice : 0;

    let totalPrice = price + extraPrice;
    totalPrice = Math.round(totalPrice * 100) / 100; // round to 2 decimals
    return {
      id: product.id,
      name: product.name,
      category: product.category,
      ...productInfo,
      price: totalPrice,
      quantity: productInfo.quantity,
      vat_category: product.vat_category,
      notes: stripHTML(productInfo.notes),
    };
  }

  function handleNotesChange(newNotes) {
    setProductInfo((prevProductInfo) => {
      return {
        ...prevProductInfo,
        notes: newNotes,
      };
    });
  }

  function handleChange(event, variantId, price, variantLabel, optionLabel) {
    const { value, type, checked } = event.target;

    setProductInfo((prevProductInfo) => {
      if (type === "checkbox") {
        if (checked) {
          return {
            ...prevProductInfo,
            variants: [
              ...prevProductInfo.variants,
              {
                variantId: variantId,
                optionId: value,
                optionPrice: price, // the extra price that an extra ingredient can have
                variantLabel: variantLabel,
                optionLabel: optionLabel,
                quantity: 1,
              },
            ],
          };
        } else {
          return {
            ...prevProductInfo,
            variants: [
              ...prevProductInfo.variants.filter((variant) => {
                return !(variant.variantId === variantId && variant.optionId === value);
              }),
            ],
          };
        }
      } else {
        // if type === 'radio'
        if (prevProductInfo.variants.find((variant) => variant.variantId === variantId) == null) {
          // if variant not set yet or we changed a checkbox - add the {variant, variantOption} pair
          return {
            ...prevProductInfo,
            variants: [
              ...prevProductInfo.variants,
              {
                variantId: variantId,
                optionId: value,
                optionPrice: price, // the extra price that an extra ingredient can have
                variantLabel: variantLabel,
                optionLabel: optionLabel,
              },
            ],
          };
        } else {
          // if variant already set, change it's value
          const newProductInfo = prevProductInfo.variants.map((variant) => {
            if (variant.variantId === variantId) {
              return {
                ...variant,
                optionId: value,
                optionPrice: price, // the extra price that an extra ingredient can have
                variantLabel: variantLabel,
                optionLabel: optionLabel,
              };
            }
            return variant;
          });

          return {
            ...prevProductInfo,
            variants: newProductInfo,
          };
        }
      }
    });
  }

  function removeVariationOption(option) {
    let optionId = option.id?.toString();
    setProductInfo((prevProductInfo) => {
      let foundVariationOption = prevProductInfo.variants.find((variant) => variant.optionId === optionId);
      if (foundVariationOption && foundVariationOption.quantity === 1) {
        return {
          ...prevProductInfo,
          variants: [
            ...prevProductInfo.variants.filter((variant) => {
              return !(variant.optionId === optionId);
            }),
          ],
        };
      } else if (foundVariationOption && foundVariationOption.quantity > 1) {
        return {
          ...prevProductInfo,
          variants: [
            ...prevProductInfo.variants.map((variant) => {
              if (variant.optionId === optionId) {
                return {
                  ...variant,
                  quantity: variant.quantity - 1,
                };
              } else {
                return variant;
              }
            }),
          ],
        };
      }
    });
  }

  function addVariationOption(option, variantId) {
    let optionId = option.id?.toString();
    setProductInfo((prevProductInfo) => {
      let variationOptionExists = prevProductInfo.variants.find((variant) => variant.optionId === optionId);
      if (variationOptionExists) {
        return {
          ...prevProductInfo,
          variants: prevProductInfo.variants.map((variant) => {
            if (variant.optionId === optionId) {
              return {
                ...variant,
                quantity: variant.quantity + 1,
              };
            } else {
              return variant;
            }
          }),
        };
      } else {
        return {
          ...prevProductInfo,
          variants: [
            ...prevProductInfo.variants,
            {
              variantId: variantId,
              optionId: optionId,
              optionPrice: option.attributes.price, // the extra price that an extra ingredient can have
              variantLabel: null,
              optionLabel: option.attributes.label,
              quantity: 1,
            },
          ],
        };
      }
    });
  }

  return {
    table,
    product,
    handleChange,
    decreaseCartQuantity,
    increaseCartQuantity,
    totalAlreadyOrdered,
    productInfo,
    isLoading,
    isError,
    handleNotesChange,
    handleSubmit,
    removeVariationOption,
    addVariationOption,
  };
};

export default useNewOrderProduct;
