import React from "react";
import { useGetAllCategoriesQuery, useUpdateCategoryMutation } from "api/categoriesSlice";
import { useGetCategoryQuery } from "api/categoriesSlice";
import { t } from "i18next";

const useEditCategory = (props) => {
  const id = props?.category;
  const [inputs, setInputs] = React.useState({
    editCategory: "",
    categoryImage: null,
    errors: {},
    rank: null,
  });
  const { data: categories } = useGetAllCategoriesQuery();

  const {
    data: category,
    isFetching: categoryFetching,
    isSuccess: isSuccessCategory,
  } = useGetCategoryQuery(id?.toString(), {
    skip: !id,
  });
  const [updateCategory, { isLoading: updateCategoryLoading }] = useUpdateCategoryMutation();
  const [selectImageDialog, setSelectImageDialog] = React.useState(false);

  React.useEffect(() => {
    if (isSuccessCategory) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          editCategory: category.name,
          categoryImage: category.photo
            ? {
                id: category.photo.id,
                ...category.photo.attributes,
              }
            : null,
          rank: category.rank,
        };
      });
    }
  }, [category, isSuccessCategory]);

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleImageChange = (image) => {
    setInputs((inputs) => ({
      ...inputs,
      categoryImage: image,
    }));
  };

  const handleImageDialogClose = () => {
    setSelectImageDialog(false);
  };
  function handleCancel() {
    props.onCancel();
  }
  function categoryExists() {
    return categories.some((category) => {
      if (category.name === inputs.newCategory) {
        return true;
      }
      return false;
    });
  }

  function validateEditCategoryFields() {
    let isValid = true;

    if (inputs.editCategory.length === 0) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, name: t("Name_invalid_msg") },
        };
      });
      isValid = false;
    } else if (inputs.rank && inputs.rank <= 0) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, rank: t("Rank_error_message") },
        };
      });
      isValid = false;
    } else {
      if (inputs.errors.name?.length > 0) {
        setInputs((prevInputs) => {
          return {
            ...prevInputs,
            errors: { ...prevInputs.errors, name: "" },
          };
        });
      }
    }

    return isValid;
  }

  const handleImageDialogOpen = () => {
    setSelectImageDialog(true);
  };

  function handleEditSubmit() {
    if (!validateEditCategoryFields()) {
      return;
    }
    let updatedCategory = {
      id: id,
    };
    if (inputs.categoryImage && inputs.categoryImage.id !== null) {
      updatedCategory.photo = inputs.categoryImage.id;
    }
    if (inputs.editCategory !== category.name) {
      updatedCategory.name = inputs.editCategory;
    }

    if (inputs.rank !== category.rank) {
      updatedCategory.rank = inputs.rank || null;
    }

    if (categoryExists()) {
      alert("Η κατηγορία με το όνομα που επιλέξατε ήδη υπάρχει");
    } else {
      updateCategory(updatedCategory).then(() => {
        props.onCancel();
      });
    }
  }

  return {
    inputs,
    handleInputChange,
    handleImageChange,
    handleImageDialogClose,
    handleImageDialogOpen,
    selectImageDialog,
    handleEditSubmit,
    handleCancel,
    updateCategoryLoading,
    categoryFetching,
  };
};

export default useEditCategory;
