import React from "react";
import { Col, Button, Row, Input } from "reactstrap";
import AlertModal from "components/Modals/AlertModal";
import CrudTable from "components/Tables/CrudTable";
import useTablesPage from "./useTablesPage";
import DefaultLayout from "templates/DefaultLayout";
import { Views } from "utilities/enums/Views";
import { useTranslation } from "react-i18next";

function TablesPage() {
  const {
    dialogOpen,
    editDialogOpen,
    deleteDialogOpen,
    filterModel,
    inputs,
    tables,
    floors,
    isLoading,
    isError,
    isLoadingFloors,
    setFilterModel,
    handleEditClose,
    handleNewTableClose,
    handleNewTableOpen,
    handleEditDialogOpen,
    handleInputChange,
    handleDeleteDialogOpen,
    handleDeleteDialogClose,
    handleDelete,
    handleAddTable,
    handleEditTable,
    rowClicked,
  } = useTablesPage();
  const { t } = useTranslation();

  const tableCols = [
    { label: "id", hidden: true },
    { label: t("Rank") },
    { label: t("Name") },
    { label: t("Floor") },
    { label: t("Actions") },
  ];

  const header = (
    <Row>
      <Col xs="8">
        <h3 className="mb-0">{t(Views.TABLES)}</h3>
      </Col>
      <Col xs="12" sm="4" className="d-flex mt-2 mt-sm-0 justify-content-between justify-content-sm-end">
        <Input
          type="select"
          name="floor"
          id="floor"
          onChange={(e) => setFilterModel(e.target.value)}
          value={filterModel}
          bsSize="sm"
          disabled={isLoadingFloors}
          className="col-6 mr-4"
        >
          <option value="">{t("All")}</option>
          {floors?.map((floor) => (
            <option key={floor.id} value={floor.id}>
              {floor.attributes.name}
            </option>
          ))}
        </Input>
        <Button
          className="btn-round btn-icon"
          color="primary"
          id="add-btn"
          onClick={() => {
            handleNewTableOpen();
          }}
          size="sm"
        >
          <span className="btn-inner--icon mr-1">
            <i className="fas fa-user-edit" />
          </span>
          <span className="btn-inner--text">{t("Add")}</span>
        </Button>
      </Col>
    </Row>
  );
  return (
    <>
      <DefaultLayout
        name={Views.TABLES}
        parentName={Views.TABLE_MANEGEMENT}
        isLoading={isLoading}
        isError={isError}
        header={header}
      >
        <CrudTable
          columns={tableCols}
          data={tables
            ?.filter((table) =>
              filterModel !== ""
                ? table.attributes.floor.data !== null &&
                  parseInt(table.attributes.floor.data.id) === parseInt(filterModel)
                : table
            )
            .map((table) => ({
              id: table.id,
              rank: table.attributes.rank,
              name: table.attributes.name,
              floor: table.attributes.floor.data !== null ? table.attributes.floor.data.attributes.name : "No Floor",
            }))}
          pageSize={7}
          onEdit={handleEditDialogOpen}
          onDelete={handleDeleteDialogOpen}
        />
      </DefaultLayout>
      <AlertModal
        title={`${t("Delete_table_confirmation")} "${rowClicked.name}"`}
        isOpen={deleteDialogOpen}
        onCancel={handleDeleteDialogClose}
        onOk={handleDelete}
        okText={t("Delete")}
        cancelText={t("Cancel")}
      />
      <AlertModal
        title={t("Add_table")}
        isOpen={dialogOpen}
        onCancel={handleNewTableClose}
        onOk={handleAddTable}
        okText={t("Add")}
        cancelText={t("Cancel")}
        textFields={[
          {
            label: t("Name"),
            name: "name",
            value: inputs.name,
            handleChange: handleInputChange,
            errorMsg: inputs.errors.name,
          },
        ]}
        selects={[
          {
            label: t("Floor"),
            name: "floor",
            value: inputs.floor,
            handleChange: handleInputChange,
            errorMsg: inputs.errors.floor,
            options: floors?.map((floor) => ({
              id: floor.id,
              name: floor.attributes.name,
            })),
          },
        ]}
        body={
          <>
            <div className="mt-3">
              <label className="form-control-label">{t("Rank_label")}</label>
              <input
                type="number"
                name="rank"
                value={inputs.rank ? inputs.rank : ""}
                onChange={handleInputChange}
                className="form-control"
              />
              <p className="text-danger">{inputs.errors.rank}</p>
            </div>
          </>
        }
      />
      <AlertModal
        title={t("Edit_table")}
        isOpen={editDialogOpen}
        onCancel={handleEditClose}
        onOk={handleEditTable}
        okText={t("Save")}
        cancelText={t("Cancel")}
        textFields={[
          {
            label: t("Name"),
            name: "name",
            value: inputs.name,
            handleChange: handleInputChange,
            errorMsg: inputs.errors.name,
          },
        ]}
        selects={[
          {
            label: t("Floor"),
            name: "floor",
            value: inputs.floor,
            handleChange: handleInputChange,
            errorMsg: inputs.errors.floor,
            options: floors?.map((floor) => ({
              id: floor.id,
              name: floor.attributes.name,
            })),
          },
        ]}
        body={
          <>
            <div className="mt-3">
              <label className="form-control-label">{t("Rank_label")}</label>
              <input
                type="number"
                name="rank"
                value={inputs.rank ? inputs.rank : ""}
                onChange={handleInputChange}
                className="form-control"
              />
              <p className="text-danger">{inputs.errors.rank}</p>
            </div>
          </>
        }
      />
    </>
  );
}

export default TablesPage;
