import { TagTypes } from "utilities/enums/TagTypes";
import { apiSlice } from "./apiSlice";
import { store } from "redux/store";

export const apiUpload = apiSlice.injectEndpoints({
  tagTypes: [TagTypes.Upload],
  endpoints: (builder) => ({
    uploadFile: builder.mutation({
      invalidatesTags: [TagTypes.Upload],
      query: (file) => ({
        url: "/upload",
        method: "POST",
        body: file,
      }),
    }),
    getUploaded: builder.query({
      providesTags: [TagTypes.Upload],
      query: () => ({
        url: `/upload/files?sort=createdAt%3Adesc`,
        method: "GET",
      }),
      transformResponse: (responseData) => {
        const stringToCheck = `amazonaws.com//${store.getState().auth.organisationId}/`;
        return responseData.filter((img) => {
          return img.url.includes(stringToCheck);
        });
      },
    }),
    deleteUploaded: builder.mutation({
      invalidatesTags: [TagTypes.Upload],
      query: (id) => ({
        url: `/upload/files/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useUploadFileMutation, useGetUploadedQuery, useDeleteUploadedMutation } = apiUpload;
