import { useGetUploadedQuery, useUploadFileMutation } from "api/uploadSlice";
import { useReducer, useState } from "react";
import { apiUpload } from "api/uploadSlice";
import { useDispatch } from "react-redux";
import { TagTypes } from "utilities/enums/TagTypes";
import { useDeleteUploadedMutation } from "api/uploadSlice";
import { Button } from "reactstrap";
import AlertModal from "components/Modals/AlertModal";
import { useTranslation } from "react-i18next";
import { store } from "redux/store";

const MediaManager = ({ isOpen, onCancel, onOk, selectedImage, setSelectedImage }) => {
  const { data, error, isLoading } = useGetUploadedQuery();
  const [currentImage, setCurrentImage] = useState(selectedImage);
  const [uploadFile] = useUploadFileMutation();
  const [deleteUploaded] = useDeleteUploadedMutation();
  const dispatch = useDispatch();
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const { t } = useTranslation();

  function refreshComponent() {
    dispatch(apiUpload.util.invalidateTags([TagTypes.Upload]));
    forceUpdate();
  }
  function onDeleteImage() {
    if (currentImage) {
      deleteUploaded(currentImage.id)
        .then((res) => {
          if (selectedImage?.id === currentImage.id) {
            setSelectedImage(null);
          }
          setCurrentImage(null);
          refreshComponent();
        })
        .catch((err) => {
          //TODO: send error to sentry
        });
    }
  }
  function onUploadImage(e) {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("files", file);
    formData.append("fileInfo.name", file.name);
    formData.append("path", `/${store.getState().auth.organisationId}`);
    uploadFile(formData)
      .then((res) => {
        refreshComponent();
      })
      .catch((err) => {
        //TODO: send error to sentry
      });
  }

  return (
    <AlertModal
      onCancel={() => {
        setCurrentImage(null);
        onCancel();
      }}
      isOpen={isOpen}
      size="lg"
      title={t("Images")}
      cancelText={t("Close")}
      body={
        <>
          <div className="d-flex flex-column align-items-end">
            <div className="d-flex flex-row justify-content-end mb-2">
              <div className="d-flex flex-row flex-wrap media-manager-btn-container">
                <Button
                  color="success"
                  onClick={() => {
                    setSelectedImage(currentImage);
                    onOk();
                  }}
                  disabled={currentImage ? false : true}
                >
                  {t("Select")}
                </Button>
                <Button
                  color="danger"
                  onClick={() => {
                    onDeleteImage();
                  }}
                  disabled={currentImage ? false : true}
                >
                  {t("Delete")}
                </Button>
                <div className="cursor-pointer p-x-3 btn btn-primary ml-0">
                  <span className="btn-inner--icon">{t("Upload")}</span>
                  <input
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      opacity: 0,
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                    }}
                    type="file"
                    name="file"
                    id="file"
                    onChange={(e) => onUploadImage(e)}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-wrap flex-row justify-content-start align-items-center gap-1 m-1 w-100 media-manager-container scrollable-y">
              {!data && !isLoading && (
                <div className="d-flex flex-row justify-content-center align-items-center w-100">
                  <h3>No Images</h3>
                </div>
              )}
              {isLoading && (
                <div className="d-flex flex-row justify-content-center align-items-center w-100">
                  <h3>Loading...</h3>
                </div>
              )}
              {data &&
                data?.map((image) => (
                  <div
                    className={`m-1 flex-grow-1/3 pointer-courser`}
                    key={image.id}
                    onClick={() => {
                      setCurrentImage(image);
                    }}
                  >
                    <img
                      className={`border border-4 p-1 rounded-4 ${
                        currentImage?.id === image.id ? "border-primary bg-primary" : ""
                      }`}
                      src={image.url}
                      alt="..."
                      style={{ maxHeight: 80 }}
                    />
                  </div>
                ))}
            </div>
          </div>
        </>
      }
    />
  );
};

export default MediaManager;
