import React from "react";
import { useGetPrintScenariosQuery, useDeletePrintScenarioMutation } from "api/printScenariosSlice";
import { useUpdatePrintScenarioMutation } from "api/printScenariosSlice";

const usePrintScenarios = () => {
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [newPrintScenarioDialogOpen, setNewPrintScenarioDialogOpen] = React.useState(false);
  const [editScenarioDialogOpen, setEditScenarioDialogOpen] = React.useState(false);
  const [editCategoryDialogOpen, setEditCategoryDialogOpen] = React.useState(false);
  const [rowClicked, setRowClicked] = React.useState({});
  const [selectedScenario, setSelectedScenario] = React.useState(null);
  const { data: printScenarios, isLoading: scenariosLoading, isError: scenariosError } = useGetPrintScenariosQuery();
  const [deletePrintScenario, { isLoading: deleteScenarioLoading }] = useDeletePrintScenarioMutation();
  const [updatePrintScenario] = useUpdatePrintScenarioMutation();

  function handleEditCategoryClick(row) {
    setRowClicked(row);
    setEditCategoryDialogOpen(true);
  }

  async function handleDeleteScenario() {
    await deletePrintScenario(selectedScenario.id);
    setDeleteDialogOpen(false);
  }

  function handleScenarioClick(scenario) {
    setEditScenarioDialogOpen(true);
    setSelectedScenario(scenario);
  }

  function handleDeleteDialogOpen(scenario) {
    setSelectedScenario(scenario);
    setDeleteDialogOpen(true);
  }

  async function handleUpdateToggleScenario(scenarioInfo) {
    await updatePrintScenario({ id: scenarioInfo.id, active: !scenarioInfo.active });
  }

  return {
    newPrintScenarioDialogOpen,
    setNewPrintScenarioDialogOpen,
    editCategoryDialogOpen,
    setEditCategoryDialogOpen,
    handleEditCategoryClick,
    deleteDialogOpen,
    setDeleteDialogOpen,
    handleDeleteDialogOpen,
    handleDeleteScenario,
    rowClicked,
    scenariosLoading,
    scenariosError,
    printScenarios,
    selectedScenario,
    handleScenarioClick,
    editScenarioDialogOpen,
    setEditScenarioDialogOpen,
    deleteScenarioLoading,
    handleUpdateToggleScenario,
  };
};

export default usePrintScenarios;
