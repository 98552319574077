import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
function TimelineHeader({ hasBackButton }) {
  const navigate = useNavigate();
  return (
    <>
      <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="d-none align-items-center py-4">
              <Col lg="6" xs="7">
                {hasBackButton && (
                  <Button className="btn-icon-only mr-0" color="secondary" type="button" onClick={() => navigate(-1)}>
                    <span className="btn-inner--icon">
                      <i className="fa fa-arrow-left" />
                    </span>
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default TimelineHeader;
