import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import PuffLoader from "react-spinners/PuffLoader";
import { useNavigate } from "react-router-dom";
import CartSidebar from "components/ShoppingCart/CartSidebar";
import { useTranslation } from "react-i18next";

export default function DefaultLayout(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  function handleGoBack() {
    if (props.backPath) {
      navigate(props.backPath);
    } else {
      navigate(-1);
    }
  }

  let content = <p></p>;
  if (props.isError) {
    content = <p>{props.errorMsg ? props.errorMsg : "Could not load data. Please retry"}</p>;
  } else if (props.isLoading) {
    content = (
      <div className="w-100 d-flex justify-content-center">
        <PuffLoader
          loading={props.isLoading}
          color="#d9d9d9"
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  } else {
    content = props.children;
  }
  return (
    <>
      <SimpleHeader
        name={props.name}
        parentName={props.parentName}
        hasBackButton={props.hasBackButton}
        backPath={props.backPath}
      />
      <Container className={`mt--6 ${props.classNames} card-container`} fluid>
        <Row className="justify-content-center">
          <Col className="card-wrapper" xs="12" lg={props.hasSideBar ? "8" : "12"}>
            <Card className="h-100">
              {props.header && (
                <CardHeader className={props.headerClassNames}>
                  <div className={props.table ? "d-flex justify-content-between w-100" : ""}>
                    <div
                      className={
                        props.hasBackButton
                          ? "card-header-back-button ml--4 pl-3 pl-md-4 mt--4 pt-4 mr--4 pr-4 mb--4 pb-4 hoverable"
                          : ""
                      }
                      onClick={props.hasBackButton ? handleGoBack : null}
                    >
                      {props.hasBackButton && <span className="back-button"></span>}
                      {props.header}
                    </div>
                    {props.table && (
                      <div className="d-flex justify-content-end ml-4 w-50">
                        <h3 className="text-primary mb-0">{props.table?.attributes?.name}</h3>
                      </div>
                    )}
                  </div>
                </CardHeader>
              )}
              <CardBody>{content}</CardBody>
            </Card>
          </Col>
          {props.hasSideBar && (
            <Col className="card-wrapper d-none d-lg-block" lg="4">
              <CartSidebar mode={props.sidebarMode} table={props.table} />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
}
