import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Nav, NavItem, NavLink, Row, Card, CardHeader, CardBody, Collapse } from "reactstrap";
import classnames from "classnames";
import DefaultLayout from "templates/DefaultLayout";
import { Views } from "utilities/enums/Views";
import ROUTES_OBJ from "utilities/enums/Routes";
import { useLazyGetUserPermissionsQuery, useUpdateUserPermissionsMutation } from "api/usersSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const PermissionManagement = () => {
  const availableRoles = ["manager", "staff"];
  const publicRoutes = ["login", "unauthorized"];
  const [open, setOpen] = useState("");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  const [resultMsg, setResultMsg] = React.useState({
    isError: false,
    message: "",
  });
  const [errMsg, setErrMsg] = useState("");
  const [getUserPermissions] = useLazyGetUserPermissionsQuery();
  const [updateUserRoles, { isLoading: updateUserRolesLoading }] = useUpdateUserPermissionsMutation();
  const [currentRules, setCurrentRules] = useState({});
  const [activeNav, setActiveNav] = useState(0);
  const { print_scenarios_disabled } = useSelector((state) => state.settings);
  const [inputs, setInputs] = useState({
    selectRule: "",
    selectedRules: [],
    avialableRules: Object.entries(ROUTES_OBJ)
      .filter(
        ([key, value]) =>
          publicRoutes.includes(value.path) === false &&
          !(print_scenarios_disabled && value.path === ROUTES_OBJ.SETTINGS_PRINT_SCENARIOS.path)
      )
      .map(([key, value]) => {
        return {
          label: value.label,
          path: value.path,
        };
      }),
  });
  const { t } = useTranslation();

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setResultMsg("");
  };

  function handleFeedbackMsg(result) {
    if (result?.data?.data?.attributes) {
      setResultMsg({ msg: t("Saving_successful"), isError: false });
    } else {
      setResultMsg({ msg: t("Saving_error"), isError: true });
    }
  }
  const handleSave = async () => {
    setResultMsg("");
    const result = await updateUserRoles({
      id: currentRules.id,
      rules: inputs.selectedRules.map((rule) => ({
        name: rule,
        value: true,
      })),
    });
    handleFeedbackMsg(result);
  };

  function handleEnabledSwitchChange(rule, e) {
    if (e.target.checked) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          selectedRules: [...prevInputs.selectedRules, `Route:${rule.path}`],
        };
      });
    } else {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          selectedRules: prevInputs.selectedRules.filter((selectedRule) => selectedRule !== `Route:${rule.path}`),
        };
      });
    }
  }

  useEffect(() => {
    try {
      getUserPermissions(availableRoles[activeNav])
        .unwrap()
        .then(({ data }) => {
          if (data[0]) {
            setCurrentRules(data[0]);
            setInputs((prevInputs) => {
              return {
                ...prevInputs,
                selectedRules: data[0].attributes.rules.filter((rule) => rule.value).map((rule) => rule.name), // we shall set as selected only the routes that are "enabled" for this user
              };
            });
          }
        });
    } catch (e) {
      setErrMsg("Error while fetching User Permissions");
    }
  }, [activeNav]);

  function ruleEnabled(rule) {
    return inputs.selectedRules.includes(`Route:${rule.path}`);
  }

  const header = (
    <Row>
      <Col xs="12">
        <h3 className="mb-0">{t(Views.PERMISSION_MANAGEMENT)}</h3>
      </Col>
    </Row>
  );

  return (
    <>
      <DefaultLayout
        name={t(Views.PERMISSION_MANAGEMENT)}
        parentName={Views.SETTINGS}
        header={header}
        isError={errMsg.length > 0}
        errorMsg={errMsg}
      >
        <Row>
          <Col>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={`capitalize hoverable ${classnames({ active: activeNav === 0 })}`}
                  onClick={(e) => toggleNavs(e, 0)}
                >
                  {t(availableRoles[0])}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`capitalize hoverable ${classnames({ active: activeNav === 1 })}`}
                  onClick={(e) => toggleNavs(e, 1)}
                >
                  {t(availableRoles[1])}
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Row className="align-items-center d-flex">
                {inputs.avialableRules && (
                  <div className="accordion permissions-accordion pt-4 col-12 col-md-6">
                    {inputs.avialableRules.map((rule, index) => (
                      <Card className="card-plain mb-2" key={index}>
                        <CardHeader
                          className={`p-3 ${ruleEnabled(rule) ? "" : "disabled"}`}
                          role="tab"
                          onClick={() => toggle(index)}
                          aria-expanded={open === index}
                        >
                          <h5 className="mb-0">{t(rule.label)}</h5>
                        </CardHeader>
                        <Collapse role="tabpanel" isOpen={open === index}>
                          <CardBody className="p-3">
                            <p className="mb-1">{t("Enabled")}</p>
                            <label className="custom-toggle mr-1">
                              <input
                                type="checkbox"
                                checked={ruleEnabled(rule)}
                                onChange={(e) => handleEnabledSwitchChange(rule, e)}
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off={t("No")}
                                data-label-on={t("Yes")}
                              />
                            </label>
                          </CardBody>
                        </Collapse>
                      </Card>
                    ))}
                  </div>
                )}
              </Row>
            </FormGroup>
          </Col>
          <Col xs="12" className="mt-4">
            <Button className="mb-3" color="primary" onClick={handleSave} disabled={updateUserRolesLoading}>
              {t("Save")}
            </Button>
            <p className={resultMsg.isError ? "text-danger" : "text-success"}>{resultMsg.msg}</p>
          </Col>
        </Row>
      </DefaultLayout>
    </>
  );
};
