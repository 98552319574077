import React from "react";
import { Col, Button, Row, Input } from "reactstrap";
import DefaultLayout from "templates/DefaultLayout";
import useSubCategories from "./useSubcategories";
import AlertModal from "components/Modals/AlertModal";
import CrudTable from "components/Tables/CrudTable";
import { Views } from "utilities/enums/Views";
import { useTranslation } from "react-i18next";
import EditSubcategory from "./EditSubcategory";
import NewSubcategory from "./NewSubcategory";

function Subcategories() {
  const {
    categories,
    handleEditDialogOpen,
    deleteDialogOpen,
    handleDeleteDialogOpen,
    handleDeleteSubCategory,
    filterModel,
    subcategories,
    selectedRow,
    setFilterModel,
    isLoading,
    isError,
    setDeleteDialogOpen,
    newSubcategoryDialogOpen,
    setNewSubcategoryDialogOpen,
    editSubcategoryDialogOpen,
    setEditSubcategoryDialogOpen,
    isLoadingCategories,
    isErrorCategories,
  } = useSubCategories();
  const { t } = useTranslation();

  const tableCols = [
    { label: "id", mobileVisible: false },
    { label: t("Name") },
    {
      label: t("Image"),
      hidden: true,
      formatter: (cell) =>
        cell !== null ? (
          <img
            src={`${cell.attributes.url}`}
            alt="subcategory"
            className="rounded-circle image-preview"
            style={{ width: "50px", height: "50px" }}
          />
        ) : null,
    },
    { label: t("Category") },
    { label: "Parent_Category_id", hidden: true },
    { label: "Rank", hidden: true },
    { label: t("Actions"), hidden: true },
  ];

  let header = (
    <Row>
      <Col xs="8">
        <h3 className="mb-0">{t(Views.SUBCATEGORIES)}</h3>
      </Col>
      <Col xs="12" sm="4" className="d-flex mt-2 mt-sm-0 justify-content-between justify-content-sm-end">
        <Input
          type="select"
          name="category"
          id="category"
          onChange={(e) => setFilterModel(e.target.value)}
          value={filterModel}
          bsSize="sm"
          className="col-6 mr-4"
        >
          <option value="">{t("Choose_category")}</option>
          {categories?.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </Input>
        <Button
          className="btn-round btn-icon"
          color="primary"
          id="add-btn"
          onClick={() => setNewSubcategoryDialogOpen(true)}
          size="sm"
        >
          <span className="btn-inner--icon mr-1">
            <i className="fas fa-user-edit" />
          </span>
          <span className="btn-inner--text">{t("Add")}</span>
        </Button>
      </Col>
    </Row>
  );
  return (
    <>
      <DefaultLayout
        name={t(Views.SUBCATEGORIES)}
        parentName={Views.MENU_MANAGEMENT}
        isLoading={isLoading || isLoadingCategories}
        isError={isError || isErrorCategories}
        header={header}
      >
        <CrudTable columns={tableCols} data={subcategories} pageSize={7} onEdit={handleEditDialogOpen} />
      </DefaultLayout>

      <EditSubcategory
        isOpen={editSubcategoryDialogOpen}
        onCancel={() => setEditSubcategoryDialogOpen(false)}
        subcategory={selectedRow.id}
      />

      <NewSubcategory isOpen={newSubcategoryDialogOpen} onCancel={() => setNewSubcategoryDialogOpen(false)} />

      <AlertModal
        isOpen={deleteDialogOpen}
        title={`${t("Delete_subcategory_msg")} "${selectedRow?.name}"?`}
        okText={t("Delete")}
        cancelText={t("Cancel")}
        onCancel={() => setDeleteDialogOpen(false)}
        onOk={handleDeleteSubCategory}
      />
    </>
  );
}

export default Subcategories;
