import React from "react";
import { useTranslation } from "react-i18next";
// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

function AdminFooter() {
  const { t } = useTranslation();

  return (
    <>
      <Container fluid>
        <footer className="footer pt-3 pb-4">
          <Row className="align-items-center justify-content-lg-between">
            <Col lg="6">
              <div className="copyright text-center text-lg-left text-muted">
                © {new Date().getFullYear()}{" "}
                <a className="font-weight-bold ml-1" href="https://ditoapp.gr" target="_blank">
                  DitoApp
                </a>
              </div>
            </Col>
            <Col lg="6">
              <Nav className="nav-footer justify-content-center justify-content-lg-end">
                <NavItem>
                  <NavLink href="https://ditoapp.gr" target="_blank">
                    DitoApp
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="https://ditoapp.gr" target="_blank">
                    {t("About_us")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="https:/ditoapp.gr" target="_blank">
                    {t("Terms_of_service")}
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </footer>
      </Container>
    </>
  );
}

export default AdminFooter;
