import { TagTypes } from "utilities/enums/TagTypes";
import { apiSlice } from "./apiSlice";
import { store } from "redux/store";

export const PosTerminalsSclice = apiSlice.injectEndpoints({
  tagTypes: [TagTypes.PosTerminals],
  endpoints: (builder) => ({
    getPosTerminals: builder.query({
      providesTags: [TagTypes.PosTerminals],
      query: () => `/pos-terminals?filters[organisation][id]=${store.getState().auth.organisationId}`,
      transformResponse: (responseData) => {
        return responseData.data;
      },
    }),
    getPosTerminal: builder.query({
      providesTags: [TagTypes.PosTerminals],
      query: (posTerminalId) =>
        `/pos-terminals/${posTerminalId}?filters[organisation][id]=${store.getState().auth.organisationId}`,
      transformResponse: (responseData) => {
        let posTerminal = responseData.data;
        return {
          id: posTerminal.id,
          name: posTerminal?.attributes?.name,
          active: posTerminal?.attributes?.active,
          description: posTerminal?.attributes?.description,
          terminal_id: posTerminal?.attributes?.terminal_id,
        };
      },
    }),
    addPosTerminal: builder.mutation({
      query: (posTerminal) => ({
        url: "/pos-terminals",
        method: "POST",
        body: {
          data: {
            name: posTerminal.name,
            description: posTerminal.description,
            organisation: store.getState().auth.organisationId,
            active: posTerminal.active,
            terminal_id: posTerminal?.terminal_id,
          },
        },
      }),
      invalidatesTags: [TagTypes.PosTerminals],
    }),
    updatePosTerminal: builder.mutation({
      invalidatesTags: [TagTypes.PosTerminals],
      query: (posTerminal) => {
        return {
          url: `/pos-terminals/${posTerminal.id}`,
          method: "PUT",
          body: {
            data: {
              ...(posTerminal.name !== undefined && { name: posTerminal.name }),
              ...(posTerminal.description !== undefined && { description: posTerminal.description }),
              ...(posTerminal.active !== undefined && { active: posTerminal.active }),
              ...(posTerminal.terminal_id !== undefined && { terminal_id: posTerminal.terminal_id }),
              organisation: store.getState().auth.organisationId,
            },
          },
        };
      },
    }),
    deletePosTerminal: builder.mutation({
      query: (id) => ({
        url: `/pos-terminals/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: [TagTypes.PosTerminals],
    }),
    getTipsPercentages: builder.query({
      providesTags: [TagTypes.Organisation],
      query: () => `/tips-percentages?filters[organisation][id]=${store.getState().auth.organisationId}`,
      transformResponse: (responseData) => {
        return responseData;
      },
    }),
  }),
});

export const {
  useGetPosTerminalsQuery,
  useGetPosTerminalQuery,
  useAddPosTerminalMutation,
  useUpdatePosTerminalMutation,
  useDeletePosTerminalMutation,
  useGetTipsPercentagesQuery,
} = PosTerminalsSclice;
