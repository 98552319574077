import React from "react";
import { checkSubCategoryExists } from "api/ApiCallerHelper";
import { useAddSubCategoryMutation } from "api/subcategoriesSlice";
import { useGetAllCategoriesQuery, useUpdateCategoryMutation } from "api/categoriesSlice";
import { t } from "i18next";

const useNewSubcategory = (props) => {
  const [selectImageDialog, setSelectImageDialog] = React.useState(false);
  const { data: categories, isLoading: isLoadingCategories, isError: isErrorCategories } = useGetAllCategoriesQuery();
  const [inputs, setInputs] = React.useState({
    newSubcategory: "",
    photo: null,
    parentCategory: "",
    rank: null,
    errors: {},
  });
  const [addSubCategory, { isLoading: addSubcategoryLoading }] = useAddSubCategoryMutation();
  const [updateCategory, { isLoading: updateCategoryLoading }] = useUpdateCategoryMutation();

  function handleInputChange(event) {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        [event.target.name]: event.target.value,
      };
    });
  }

  function handleAddSubCategory() {
    const parentCategory = categories.filter((category) => category.id === parseInt(inputs.parentCategory))[0];
    const newSubcategory = {
      name: inputs.newSubcategory,
      parent_category: parentCategory,
      photo: inputs.photo,
      rank: inputs.rank || null,
    };

    addSubCategory(newSubcategory).then((newSubcategoryReturned) => {
      const newSubcategoryID = newSubcategoryReturned.data.data.id;
      updateCategory({ ...parentCategory, categories: [...parentCategory.categories, newSubcategoryID] }).then(() => {
        clearInputs();
        props.onCancel();
      });
    });
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (!validateNewSubcategoryFields()) {
      return;
    }

    const existsCheck = checkSubCategoryExists(inputs.parentCategory, inputs.newSubcategory);
    existsCheck.then((res) => {
      if (res) {
        alert("Subcategory already exists in this category");
      } else {
        handleAddSubCategory();
      }
    });
  }

  const handleImageDialogOpen = () => {
    setSelectImageDialog(true);
  };
  const handleImageDialogClose = () => {
    setSelectImageDialog(false);
  };

  const handleImageChange = (image) => {
    setInputs((inputs) => ({
      ...inputs,
      photo: image,
    }));
  };

  function validateNewSubcategoryFields() {
    let isValid = true;
    if (inputs.newSubcategory.length === 0) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, name: t("Name_invalid_msg") },
        };
      });
      isValid = false;
    } else if (inputs.rank && inputs.rank <= 0) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, rank: t("Rank_error_message") },
        };
      });
      isValid = false;
    } else {
      if (inputs.errors.name?.length > 0) {
        setInputs((prevInputs) => {
          return {
            ...prevInputs,
            errors: { ...prevInputs.errors, name: "" },
          };
        });
      }
    }

    if (inputs.parentCategory === "") {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, parentCategory: "The Parent Category field cannot be empty" },
        };
      });
      isValid = false;
    } else {
      if (inputs.errors.parentCategory?.length > 0) {
        setInputs((prevInputs) => {
          return {
            ...prevInputs,
            errors: { ...prevInputs.errors, parentCategory: "" },
          };
        });
      }
    }

    return isValid;
  }

  function clearInputs() {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        newSubcategory: "",
        parentCategory: "",
        editSubcategory: "",
        errors: {},
        rank: null,
      };
    });
  }

  function handleCancel() {
    props.onCancel();
  }

  return {
    inputs,
    handleSubmit,
    handleInputChange,
    categories,
    selectImageDialog,
    handleImageDialogOpen,
    handleImageDialogClose,
    handleImageChange,
    isLoadingCategories,
    isErrorCategories,
    addSubcategoryLoading,
    updateCategoryLoading,
    handleCancel,
  };
};

export default useNewSubcategory;
