import { createSlice } from "@reduxjs/toolkit";

/**
 * Redux Store Slice for Authorization global state
 * We keep here all the info about the logged in user
 * and also the JWT token for accessing the backend
 */
const authSlice = createSlice({
  name: "auth",
  initialState: { user: null, token: null, refreshToken: null, user_rules: null, organisationId: -1 },
  reducers: {
    setCredentials: (state, action) => {
      const { user, jwt, refreshToken } = action.payload;
      state.user = user;
      state.token = jwt;
      state.refreshToken = refreshToken;
      localStorage.setItem("isLoggedIn", true);
    },
    setUserRules: (state, action) => {
      const { user_rules } = action.payload;
      state.user_rules = user_rules;
    },
    setOrganisation: (state, action) => {
      const { organisationId } = action.payload;
      state.organisationId = organisationId;
    },
    logOut: (state, action) => {
      state.user = null;
      state.token = null;
      state.refreshToken = null;
      state.user_rules = null;
      localStorage.setItem("isLoggedIn", false);
    },
  },
});

export const { setCredentials, logOut, setUserRules, setOrganisation } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentRole = (state) => state.auth.user.user_role;
export const selectCurrentUserRules = (state) => state.auth.user_rules;
export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentOrganisation = (state) => state.auth.organisationId;
