import { TagTypes } from "utilities/enums/TagTypes";
import { apiSlice } from "./apiSlice";
import { store } from "redux/store";

export const printerSlice = apiSlice.injectEndpoints({
  tagTypes: [TagTypes.Printers],
  endpoints: (builder) => ({
    getActivePrinters: builder.query({
      providesTags: [TagTypes.Printers],
      query: () =>
        `/printers?filters[activePrinter][$eq]=true&populate[0]=receipt_groups.categories&populate[1]=receipt_groups.table_filters&filters[organisation][id]=${
          store.getState().auth.organisationId
        }`,
      transformResponse: (responseData) => {
        return responseData.data;
      },
    }),
    getPrinters: builder.query({
      providesTags: [TagTypes.Printers],
      query: () =>
        `/printers?populate[0]=receipt_groups.categories&populate[1]=receipt_groups.table_filters&filters[organisation][id]=${
          store.getState().auth.organisationId
        }`,
      transformResponse: (responseData) => {
        return responseData.data;
      },
    }),
    getPrintServerPrinters: builder.query({
      providesTags: [],
      query: () => `/printserver-printers?filters[organisation][id]=${store.getState().auth.organisationId}`,
      transformResponse: (responseData) => {
        return responseData;
      },
    }),
    getPrinterById: builder.query({
      providesTags: [TagTypes.Printers],
      query: (id) =>
        `/printers?filters[printerId][$eq]=${id}&populate[0]=receipt_groups.categories&populate[1]=receipt_groups.table_filters&filters[organisation][id]=${
          store.getState().auth.organisationId
        }`,
      transformResponse: (responseData) => {
        return responseData.data.length > 0 ? responseData.data[0] : null;
      },
    }),
    addPrinter: builder.mutation({
      invalidatesTags: [TagTypes.Printers],
      query: (printer) => {
        return {
          url: `/printers`,
          method: "POST",
          body: {
            data: {
              name: printer.name,
              printerId: printer.printerId,
              activePrinter: printer.activePrinter,
              receiptPrinter: printer.receiptPrinter,
              openTablePrinter: printer.openTablePrinter,
              printSoundNotif: printer.printSoundNotif,
              extraTopSpace: printer.extraTopSpace,
              extraBottomSpace: printer.extraBottomSpace,
              categories: printer.categories,
              printer_encoding: printer.printer_encoding,
              columnWidth: printer.columnWidth,
              printFontSize: printer.printFontSize,
              organisation: store.getState().auth.organisationId,
            },
          },
        };
      },
    }),
    updatePrinter: builder.mutation({
      invalidatesTags: [TagTypes.Printers],
      query: (printer) => {
        return {
          url: `/printers/${printer.id}`,
          method: "PUT",
          body: {
            data: {
              name: printer.name,
              printerId: printer.printerId,
              activePrinter: printer.activePrinter,
              receiptPrinter: printer.receiptPrinter,
              openTablePrinter: printer.openTablePrinter,
              printSoundNotif: printer.printSoundNotif,
              extraTopSpace: printer.extraTopSpace,
              extraBottomSpace: printer.extraBottomSpace,
              courseStagesPrinter: printer.courseStagesPrinter,
              receipt_groups: printer.receiptGroups,
              printer_encoding: printer.printer_encoding,
              columnWidth: printer.columnWidth,
              printFontSize: printer.printFontSize,
              organisation: store.getState().auth.organisationId,
            },
          },
        };
      },
    }),
    deletePrinter: builder.mutation({
      invalidatesTags: [TagTypes.Printers],
      query: (printer) => {
        return {
          url: `/printers/${printer.id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetActivePrintersQuery,
  useGetPrintersQuery,
  useGetPrintServerPrintersQuery,
  useGetPrinterByIdQuery,
  useAddPrinterMutation,
  useUpdatePrinterMutation,
  useDeletePrinterMutation,
} = printerSlice;
