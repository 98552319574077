import { apiSlice } from "./apiSlice";
import { TagTypes } from "utilities/enums/TagTypes";
import { store } from "redux/store";

export const VariantService = apiSlice.injectEndpoints({
  tagTypes: [TagTypes.Variants, TagTypes.VariantOptions],
  endpoints: (builder) => ({
    getAllVariants: builder.query({
      query: () =>
        `/variants?populate=*&pagination[pageSize]=150&filters[organisation][id]=${
          store.getState().auth.organisationId
        }`,
      providesTags: [TagTypes.Variants],
      transformResponse: (responseData) => {
        return responseData.data.map((variant) => {
          return {
            id: variant.id,
            name: variant.attributes.name,
            label: variant.attributes.label,
            type: variant.attributes.type === "or" ? "Single" : "Multiple",
            enabled: variant.attributes.enabled,
            options: variant.attributes.variant_options.data.map((option) => option.attributes.name).join(", "),
          };
        });
      },
    }),
    getOptionsForVariant: builder.query({
      query: () =>
        `/variant-options?populate=*&pagination[pageSize]=450&filters[organisation][id]=${
          store.getState().auth.organisationId
        }`,
      providesTags: [TagTypes.VariantOptions],
      transformResponse: (responseData) => {
        return responseData.data.map((option) => {
          return {
            id: option.id,
            label: option.attributes.label,
            name: option.attributes.name,
            price: option.attributes.price,
            enabled: option.attributes.enabled,
            quantity_support: option.attributes.quantity_support,
            is_fee: option.attributes.is_fee,
          };
        });
      },
    }),
    addVariant: builder.mutation({
      query: (variant) => ({
        url: "/variants",
        method: "POST",
        body: {
          data: {
            name: variant.name,
            label: variant.label,
            type: variant.type,
            enabled: variant.enabled,
            variant_options: variant.variant_options,
            organisation: store.getState().auth.organisationId,
          },
        },
      }),
      invalidatesTags: [TagTypes.Variants],
    }),
    addOptions: builder.mutation({
      query: (option) => ({
        url: "/variant-options",
        method: "POST",
        body: {
          data: { ...option, organisation: store.getState().auth.organisationId },
        },
      }),
      invalidatesTags: [TagTypes.VariantOptions],
    }),
    updateVariantOptions: builder.mutation({
      query: (option) => ({
        url: `/variant-options/${option.id}`,
        method: "PUT",
        body: {
          data: { ...option, organisation: store.getState().auth.organisationId },
        },
      }),
      invalidatesTags: [TagTypes.Variants, TagTypes.VariantOptions],
    }),
    deleteVariantOptions: builder.mutation({
      query: (optionId) => ({
        url: `/variant-options/${optionId}`,
        method: "DELETE",
      }),
      invalidatesTags: [TagTypes.VariantOptions],
    }),
    updateVariant: builder.mutation({
      query: (variant) => ({
        url: `/variants/${variant.id}`,
        method: "PUT",
        body: {
          data: {
            name: variant.name,
            label: variant.label,
            type: variant.type,
            enabled: variant.enabled,
            variant_options: variant.variant_options,
            organisation: store.getState().auth.organisationId,
          },
        },
      }),
      invalidatesTags: [TagTypes.Variants],
    }),
    deleteVariant: builder.mutation({
      query: (variant) => ({
        url: `/variants/${variant.id}`,
        method: "DELETE",
      }),
      invalidatesTags: [TagTypes.Variants],
    }),
  }),
});

export const {
  useGetAllVariantsQuery,
  useGetOptionsForVariantQuery,
  useAddVariantMutation,
  useAddOptionsMutation,
  useUpdateVariantOptionsMutation,
  useDeleteVariantOptionsMutation,
  useUpdateVariantMutation,
  useDeleteVariantMutation,
} = VariantService;
