import React, { useState } from "react";
import { Table, CardFooter, Badge } from "reactstrap";
import Pagination from "./Pagination/Pagination";
import { useTranslation } from "react-i18next";

export default function CrudTable(props) {
  const [currentPage, setCurrentPage] = useState(0);
  const pagesCount = props.data ? Math.ceil(props.data.length / props.pageSize) : 0;
  const mobileHiddenClass = "d-none d-md-table-cell";
  const { t } = useTranslation();

  const headers = props.columns.map((column, i) => {
    return (
      <th
        key={i}
        className={`${column.hidden ? "d-none" : ""} ${column.mobileVisible === false ? mobileHiddenClass : ""}`}
      >
        {column.label}
      </th>
    );
  });

  function handlePaginationClick(index) {
    setCurrentPage(index);
  }

  const rows = props.data?.slice(currentPage * props.pageSize, (currentPage + 1) * props.pageSize).map((row) => {
    const cols = Object.values(row).map((td, dataIndex) => {
      let value = td;
      if (props.columns && props.columns.length > dataIndex) {
        const formatter = props.columns[dataIndex].formatter;
        value = formatter && value ? formatter(value) : value;
      }

      if (props.columns[dataIndex].slug === "active-label") {
        return (
          <td key={dataIndex}>
            <Badge color={`${value ? "primary" : "badge-default"} text-xs`} size="lg">
              {value ? t("Active_sub") : t("Inactive_sub")}
            </Badge>
          </td>
        );
      } else {
        if (typeof value === "boolean") {
          value = t(`${value?.toString() ?? ""}_val`); // convert the boolean value to a string
        }
        return (
          <td
            key={dataIndex}
            className={`${props.columns[dataIndex].hidden ? "d-none" : ""} ${
              props.columns[dataIndex].mobileVisible === false ? mobileHiddenClass : ""
            }`}
          >
            {value}
          </td>
        );
      }
    });

    function handleDelete(row, e) {
      e.stopPropagation();
      props.onDelete(row);
    }

    const isEditable =
      (props.disabledEditCondition && !props.disabledEditCondition(row)) || !props.disabledEditCondition;

    return (
      <tr className="hoverable-table-row" key={row.id} onClick={() => props.onEdit(row)}>
        {cols}
        {props.onDelete ? (
          <td className="table-actions">
            <>
              <span
                className="table-action table-action-delete hoverable"
                id="tooltip12475020"
                onClick={(e) => handleDelete(row, e)}
              >
                <i className="fas fa-trash" />
              </span>
            </>
          </td>
        ) : null}
      </tr>
    );
  });

  return (
    <>
      <Table className="responsive-table align-items-center table-flush" responsive striped>
        <thead className="thead-light">
          <tr>{headers}</tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>

      <CardFooter className="py-4">
        <Pagination
          className="pagination-bar"
          currentPage={currentPage + 1}
          totalCount={pagesCount * props.pageSize}
          pageSize={props.pageSize}
          onPageChange={(page) => handlePaginationClick(page - 1)}
        />
      </CardFooter>
    </>
  );
}
