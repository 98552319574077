import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { useGetAllCategoriesQuery } from "api/categoriesSlice";
import DefaultLayout from "templates/DefaultLayout";
import NewOrderFooter from "components/Footers/NewOrderFooter";
import { Views } from "utilities/enums/Views";
import { useTranslation } from "react-i18next";
import { selectCartTable } from "redux/cart/cartSlice";
import { useSelector } from "react-redux";

export default function NewOrderCategories() {
  const navigate = useNavigate();
  const { table } = useParams();
  const { data: categories, isLoading, isError } = useGetAllCategoriesQuery();
  const { t } = useTranslation();
  const cartTable = useSelector(selectCartTable);

  let header = <h3 className="mb-0">{t(Views.CATEGORIES)}</h3>;

  function handleCategoryClick(category) {
    let url = `/admin/table-actions/new-order/${table}/${category.id}/${
      category.hasSubcategories ? "subcategories" : "products"
    }`;
    navigate(url, {
      state: { parentCategoryName: category.name },
    });
  }
  return (
    <>
      <DefaultLayout
        name={Views.CATEGORIES}
        parentName={Views.NEW_ORDER}
        isLoading={isLoading}
        isError={isError}
        header={header}
        hasBackButton={true}
        hasSideBar={true}
        table={cartTable}
        classNames="cart-sidebar-enabled"
      >
        <Row className="row-shadow">
          {categories?.map((category) => {
            return (
              <Col sm="6" md="4" lg="3" key={category.id} onClick={() => handleCategoryClick(category)}>
                <span className="category-card-container btn text-center d-flex flex-wrap flex-md-row justify-content-center align-items-center p-1">
                  {category.photo ? (
                    <div className="col-4 col-md-auto image">
                      <img
                        alt="..."
                        className="image-preview rounded shadow"
                        src={`${category.photo?.attributes.url}`}
                      />
                    </div>
                  ) : null}
                  <div
                    className={`${
                      category.photo ? "col-8 justify-content-start" : "col-12 justify-content-center"
                    } col-md-12 d-flex justify-content-start justify-content-md-center mt-1`}
                  >
                    {category.name}
                  </div>
                </span>
              </Col>
            );
          })}
        </Row>
      </DefaultLayout>
      <NewOrderFooter />
    </>
  );
}
