import React from "react";
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import { formatCurrency } from "utilities/utils";
import { KeynameToIcon, KeynameToIconColor, ReportPeriodFooter, KeynameToTitle } from "utilities/enums/Analytics";
import { useTranslation } from "react-i18next";
import { normalizeGreek } from "utilities/utils";
export const CardStats = ({ value, percentage, color, key_name, report_period, iconSize, ...rest }) => {
  const { t } = useTranslation();
  const title = normalizeGreek(t(KeynameToTitle[key_name]));
  const icon = KeynameToIcon[key_name];
  const iconColor = KeynameToIconColor[key_name];
  const footerIcon = color === "green" ? "fa fa-arrow-up" : "fa fa-arrow-down";
  const footer = t(ReportPeriodFooter[report_period]);
  const currencyRequiredMetrics = [
    "total_sales",
    "total_net_sales",
    "average_revenue_per_customer",
    "total_gifted_orders",
    "total_pending_revenue",
    "total_discount",
    "total_cancelled_revenue",
  ];

  let iconRenderSize = iconSize ? `-${iconSize}` : "";
  return (
    <Card className="card-stats mb-3">
      <CardBody>
        <Row>
          <div className="col-8 pr-0">
            <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
              {title}
            </CardTitle>
            <span className="h2 font-weight-bold mb-0">
              {currencyRequiredMetrics.includes(key_name) ? formatCurrency(value) : value}
            </span>
          </div>
          <Col className="col-4 ">
            <div className={`icon${iconRenderSize} icon-shape ${iconColor} text-white rounded-circle shadow`}>
              <i className={icon} />
            </div>
          </Col>
        </Row>
        {footer && (
          <p className="mt-3 mb-0 text-sm">
            <span
              className={
                color === "red"
                  ? "text-danger mr-2"
                  : color === "green"
                  ? "text-success mr-2"
                  : color === "yellow"
                  ? "text-warning mr-2"
                  : "text-primary mr-2"
              }
            >
              <i className={footerIcon} /> {percentage}
            </span>{" "}
            <span className="text-nowrap">{footer}</span>
          </p>
        )}
      </CardBody>
    </Card>
  );
};
