import { apiSlice } from "./apiSlice";

export const apiApp = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVersion: builder.query({
      query: () => ({
        url: `/ui-version`,
      }),
    }),
  }),
});

export const { useGetVersionQuery } = apiApp;
