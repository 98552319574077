import DefaultLayout from "templates/DefaultLayout";
import { Views } from "utilities/enums/Views";
import { Col, Row } from "reactstrap";

const Unauthorized = () => {
  const header = (
    <Row>
      <Col xs="6">
        <h3 className="mb-0">{Views.UNAUTHORIZED}</h3>
      </Col>
    </Row>
  );

  return (
    <>
      <DefaultLayout name={Views.UNAUTHORIZED} parentName={Views.UNAUTHORIZED} header={header}>
        <div className="text-center">
          <h2>Your Account is not authorized to access this page. Please contact your administrator.</h2>
        </div>
      </DefaultLayout>
    </>
  );
};

export default Unauthorized;
