import React, { useEffect } from "react";

import { Card, CardHeader, CardBody, Row, Col, Button } from "reactstrap";
import { LineChart } from "./LineChart";
import { BarChart } from "./BarChart";
import { KeynameToTitle, KeynameToSubtitle } from "utilities/enums/Analytics";
import AlertModal from "./Modals/AlertModal";
import { useTranslation } from "react-i18next";
import { logError } from "utilities/utils";
import { Form } from "@rjsf/bootstrap-4";
import validator from "@rjsf/validator-ajv8";
import moment from "moment";
import { useUpdateSalesOverviewMutation } from "api/analyticsSlice";

export const SalesCardChart = ({ chartData, chartNav, chartType, key_name, chartFormData, report_period = "day" }) => {
  const { t } = useTranslation();
  const title = t(KeynameToTitle[key_name]);
  const subtitle = t(KeynameToSubtitle[key_name]);
  const [selectedChart, setSelectedChart] = React.useState(0);
  const [editModal, setEditModal] = React.useState(false);
  const [currentChart, setCurrentChart] = React.useState();
  const [currentChartNav, setCurrentChartNav] = React.useState();
  const [formData, setFormData] = React.useState();
  const chartDataRef = React.useRef(chartData);
  const chartNavRef = React.useRef(chartNav);
  const formDataRef = React.useRef(chartFormData);
  const [updateSales, { isLoading: updateChartLoading }] = useUpdateSalesOverviewMutation();
  const [FormSchema, setFormSchema] = React.useState({
    type: "object",
    properties: {},
  });
  useEffect(() => {
    if (report_period === "day") {
      setFormSchema({
        type: "object",
        properties: {
          start_date: {
            title: t("From"),
            type: "string",
            format: "date",
          },
          end_date: {
            title: t("To"),
            type: "string",
            format: "date",
          },
          groupby: {
            title: t("Group_By"),
            type: "string",
            oneOf: [
              {
                const: "day",
                title: t("Day"),
              },
              {
                const: "week",
                title: t("Week"),
              },
              {
                const: "month",
                title: t("Month"),
              },
              {
                const: "year",
                title: t("Year"),
              },
            ],
          },
        },
      });
    } else if (report_period === "custom") {
      setFormSchema({
        type: "object",
        properties: {
          groupby: {
            title: t("Group_By"),
            type: "string",
            oneOf: [
              {
                const: "day",
                title: t("Day"),
              },
              {
                const: "week",
                title: t("Week"),
              },
              {
                const: "month",
                title: t("Month"),
              },
              {
                const: "year",
                title: t("Year"),
              },
            ],
          },
        },
      });
    }
  }, [t, report_period]);
  React.useEffect(() => {
    formDataRef.current = chartFormData;
    setFormData(formDataRef.current);
  }, [chartFormData]);
  React.useEffect(() => {
    chartNavRef.current = chartNav;
    setCurrentChartNav(chartNavRef.current);
  }, [chartNav]);
  React.useEffect(() => {
    chartDataRef.current = chartData;
    setCurrentChart(chartDataRef.current);
  }, [chartData]);

  return (
    <>
      <Card className="bg-default position-relative">
        <CardHeader className="bg-transparent">
          <Row className="align-items-center">
            <Col xs="8" md="6">
              <h6 className="text-light text-uppercase ls-1 mb-1">{subtitle}</h6>
              <h5 className="h3 text-white mb-0">{title}</h5>
            </Col>
            {currentChartNav && (
              <Col xs="4" md="6" className="justify-content-end d-flex">
                <Button color="primary" size="sm" className="translate-middle" onClick={() => setEditModal(true)}>
                  <i className="fas fa-edit" />
                </Button>
              </Col>
            )}
          </Row>
        </CardHeader>
        <CardBody>
          <div className="chart">
            {(currentChart &&
              currentChart[selectedChart] &&
              JSON.parse(JSON.stringify(currentChart[selectedChart])) &&
              {
                line: <LineChart data={JSON.parse(JSON.stringify(currentChart[selectedChart]))} />,
                bar: <BarChart data={JSON.parse(JSON.stringify(currentChart[selectedChart]))} />,
              }[chartType]) || <div className="text-center">No Data</div>}
          </div>
        </CardBody>
      </Card>
      <AlertModal
        title={t("Edit_Charts")}
        isOpen={editModal}
        cancelText={t("Cancel")}
        okText={t("Save")}
        okDisabled={updateChartLoading}
        onCancel={() => setEditModal(false)}
        onOk={async () => {
          if (report_period === "custom") {
            setFormData({
              ...formData,
              start_date: moment(formData?.start_date).format("YYYY-MM-DD"),
              end_date: moment(formData?.end_date).format("YYYY-MM-DD"),
            });
          }
          const updatedChart = await updateSales({ chart_key: key_name, formData: formData });
          if (updatedChart && updatedChart.hasOwnProperty("data") && updatedChart.data) {
            setCurrentChart(updatedChart?.data?.chartData?.data || [{}]);
            setCurrentChartNav(updatedChart?.data?.chartData?.chartNav || [{}]);
            setEditModal(false);
          } else if (updatedChart && updatedChart.hasOwnProperty("error")) {
            logError(updatedChart.error);
            setEditModal(false);
          } else {
            setEditModal(false);
          }
        }}
        body={
          <Form
            schema={FormSchema}
            formData={formData}
            onChange={(newdata) => {
              setFormData(newdata.formData);
            }}
            uiSchema={{
              groupby: {
                "ui:widget": "select",
              },
              "ui:options": {
                title: "",
                description: "",
                classNames: "form-group",
                submitButtonOptions: {
                  props: {
                    disabled: false,
                    className: "btn btn-info",
                  },
                  norender: true,
                  submitText: "Submit",
                },
              },
            }}
            validator={validator}
          />
        }
      />
    </>
  );
};
