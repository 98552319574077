import { TagTypes } from "utilities/enums/TagTypes";
import { apiSlice } from "./apiSlice";
import { store } from "redux/store";
import { sortByRankAsc } from "utilities/utils";

export const apiSubcategories = apiSlice.injectEndpoints({
  tagTypes: [TagTypes.SubCategories],
  endpoints: (builder) => ({
    getSubCategories: builder.query({
      query: (category) => {
        const url =
          category !== ""
            ? `/categories?populate=*&filters[category][id][$eq]=${category}&filters[organisation][id]=${
                store.getState().auth.organisationId
              }`
            : `categories?populate=*&filters[category][id][$notNull]=true&filters[organisation][id]=${
                store.getState().auth.organisationId
              }`;
        return url;
      },
      providesTags: [TagTypes.SubCategories],
      transformResponse: (responseData) => {
        let subcategories = responseData.data.map((category) => {
          return {
            id: category.id,
            name: category.attributes.name,
            photo: category.attributes.photo?.data,
            parent_category: category.attributes.category.data.attributes.name,
            parent_category_id: category.attributes.category.data.id,
            rank: category.attributes.rank,
          };
        });
        return sortByRankAsc(subcategories);
      },
    }),
    getSubCategory: builder.query({
      query: (id) => `/categories/${id}?populate=*`,
      providesTags: [TagTypes.SubCategories],
      transformResponse: (responseData) => {
        return {
          id: responseData.data.id,
          name: responseData.data.attributes.name,
          photo: responseData.data.attributes.photo?.data,
          rank: responseData.data.attributes.rank,
          parent_category: {
            id: responseData.data.attributes.category.data.id,
            name: responseData.data.attributes.category.data.attributes.name,
          },
        };
      },
    }),
    addSubCategory: builder.mutation({
      query: (subcategory) => ({
        url: "/categories",
        method: "POST",
        body: {
          data: {
            name: subcategory.name,
            photo: subcategory.photo,
            category: subcategory.parent_category.id,
            organisation: store.getState().auth.organisationId,
            rank: subcategory.rank,
          },
        },
      }),
      invalidatesTags: [TagTypes.SubCategories],
    }),
    updateSubCategory: builder.mutation({
      query: (category) => ({
        url: `/categories/${category.id}`,
        method: "PUT",
        body: {
          data: {
            name: category.name,
            photo: category.photo,
            category: category.parent_category,
            organisation: store.getState().auth.organisationId,
            rank: category.rank,
          },
        },
      }),
      invalidatesTags: [TagTypes.SubCategories],
    }),
    deleteSubCategory: builder.mutation({
      query: (id) => ({
        url: `/categories/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: [TagTypes.SubCategories],
    }),
  }),
});

export const {
  useGetSubCategoriesQuery,
  useGetSubCategoryQuery,
  useAddSubCategoryMutation,
  useDeleteSubCategoryMutation,
  useUpdateSubCategoryMutation,
} = apiSubcategories;
