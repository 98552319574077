import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { formatCurrency } from "utilities/utils";
import { Button } from "reactstrap";
import { selectCartItems } from "redux/cart/cartSlice";

export default function CartButton(props) {
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const cartItems = useSelector(selectCartItems);
  const { discount } = useSelector((state) => state.cart);
  const totalQuantity = useMemo(() => cartItems?.reduce((acc, item) => acc + item.quantity, 0) || 0, [cartItems]);

  const totalPrice = useMemo(
    () =>
      formatCurrency(
        (cartItems?.reduce((total, cartItem) => total + cartItem.price * cartItem.quantity, 0) || 0) - (discount || 0)
      ),
    [cartItems, discount]
  );

  function handleClick() {
    setButtonDisabled(true);
    props.onClick();
    setTimeout(() => {
      // enable the cart button after 3 seconds
      setButtonDisabled(false);
    }, 1000);
  }

  return (
    <Button
      id={props.id}
      className="cart-button left-0 right-0 ml-auto mr-auto col-12 ml-md-auto btn-icon mb-0 mb-md-3 btn btn-primary d-flex justify-content-between"
      color="primary"
      type="button"
      onClick={handleClick}
      disabled={(props.disabled ? props.disabled : cartItems?.length <= 0) || buttonDisabled}
    >
      <span className="btn-inner--text mr-1">{totalQuantity}</span>
      <span className="btn-inner--text">{props.text}</span>
      <span className="btn-inner--text mr-1">{totalPrice}</span>
    </Button>
  );
}
