import { formatCurrency, capitalizeFirstLetter, normalizeTipAmount } from "utilities/utils";
import { TagTypes } from "utilities/enums/TagTypes";
import { apiSlice } from "./apiSlice";
import { getOrderUrl } from "utilities/api/helpers";
import { store } from "redux/store";
import { getOrderStatus } from "utilities/enums/OrderStatus";
import { getOrderObejectFromOrderDetails, getOrderObejectfromCartItems } from "utilities/api/helpers";
import { OrderStatus } from "utilities/enums/OrderStatus";
import { InvoiceType } from "utilities/enums/InvoiceType";

export const apiOrders = apiSlice.injectEndpoints({
  tagTypes: [TagTypes.Orders],
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: ({ page, pageSize, sort, filters }) =>
        getOrderUrl({ page: page, pageSize: pageSize, sort: sort, filters: filters }),
      providesTags: [TagTypes.Orders],
      transformResponse: (responseData) => {
        return {
          meta: responseData.meta,
          data: responseData.data.map((order) => {
            return {
              id: order.id,
              dateSubmitted: order.attributes.publishedAt,
              submittedBy: order.attributes.submitted_by,
              table: order.attributes.table.data?.attributes.name,
              totalPrice: formatCurrency(order.attributes.total),
              status: capitalizeFirstLetter(
                getOrderStatus(order.attributes.status, order.attributes.cancellation_reason)
              ),
            };
          }),
        };
      },
    }),
    addCustomerIfNotExists: builder.mutation({
      query(data) {
        return {
          url: "/add-ifnot-exists",
          method: "POST",
          body: {
            data: {
              organisation: {
                id: store.getState().auth.organisationId,
              },
              entity_name: "customer",
              entity_data: data.entity_data,
            },
          },
        };
      },
    }),
    addOrder: builder.mutation({
      query(orderData) {
        const { details, total, net_revenue } =
          orderData.details && orderData.details.length > 0
            ? getOrderObejectFromOrderDetails(orderData.details, orderData.discount)
            : getOrderObejectfromCartItems(orderData.cartItems, orderData.discount);
        return {
          url: "/orders",
          method: "POST",
          body: {
            data: {
              uuid: orderData.uuid,
              organisation: {
                id: store.getState().auth.organisationId,
              },
              notes: orderData.notes ? orderData.notes : "",
              total: total.toFixed(2),
              net_revenue: net_revenue.toFixed(2),
              table: {
                id: orderData.tableId,
              },
              details: details,
              submitted_by: orderData.submittedBy,
              origin: orderData.origin,
              payment_method: orderData.paymentMethod,
              number_of_customers: orderData.numberOfCustomers,
              status: orderData.status ? orderData.status : OrderStatus.Pending,
              discount: orderData.discount ? orderData.discount : 0,
              discount_reason: orderData.discount_reason,
              force_send_cloud_invoice: orderData.force_send_cloud_invoice,
              cancellation_reason: orderData.cancellationReason,
              invoice_type: orderData.invoice_type,
              customer: orderData.customer,
              external_source: orderData.external_source,
            },
          },
        };
      },
      invalidatesTags: [TagTypes.Orders, TagTypes.Analytics],
    }),
    getOrder: builder.query({
      query: (id) =>
        `/orders/${id}?populate[0]=table&populate[1]=details.variant.variant_option&populate[2]=details.product&populate[3]=customer&populate[4]=details.parent_invoice&populate[5]=invoicePaymentMethods&filters[organisation][id]=${
          store.getState().auth.organisationId
        }`,
      providesTags: [TagTypes.Orders],
      transformResponse: (responseData) => {
        const order = responseData.data;
        return {
          id: order.id,
          dateSubmitted: order.attributes.publishedAt,
          submittedBy: order.attributes.submitted_by,
          table: order.attributes.table.data?.attributes?.name,
          status: capitalizeFirstLetter(order.attributes.status),
          cancellationReason: order.attributes.cancellation_reason,
          totalPrice: formatCurrency(order.attributes.total),
          discount: formatCurrency(order.attributes.discount),
          discount_reason: order.attributes.discount_reason,
          orderDetails: order.attributes.details,
          notes: order.attributes.notes,
          mydata_gr_mark: order.attributes.mydata_gr_mark,
          mydata_gr_invoice_uuid: order.attributes.mydata_gr_invoice_uuid,
          payment_method: order.attributes.payment_method,
          number_of_customers: order.attributes.number_of_customers,
          invoice_error_msg: order.attributes.invoice_error_msg,
          mydata_status: order.attributes.mydata_status,
          authcode: order.attributes.authcode,
          mydata_gr_cancellation_uid: order.attributes.mydata_gr_cancellation_uid,
          mydata_gr_cancellation_mark: order.attributes.mydata_gr_cancellation_mark,
          cancellation_authcode: order.attributes.cancellation_authcode,
          receipt_id: order.attributes.receipt_id,
          invoice_series: order.attributes.invoice_series,
          cancel_receipt_id: order.attributes.cancel_receipt_id,
          force_send_cloud_invoice: order.attributes.force_send_cloud_invoice,
          invoice_type: order.attributes.invoice_type,
          customer: order.attributes.customer?.data?.attributes,
          invoice_payment_methods: order.attributes.invoicePaymentMethods,
          external_source: order.attributes.external_source,
        };
      },
    }),
    getPendingOrdersByTable: builder.query({
      query: (tableId) =>
        `/orders?sort=id:DESC&filters[$and][0][table][id][$eq]=${tableId}&filters[$and][1][status][$eq]=pending&populate[0]=table&populate[1]=details.variant.variant_option&populate[2]=details.product&populate[3]=details.variant.variant&populate[4]=details.parent_invoice&pagination[limit]=-1&filters[organisation][id]=${
          store.getState().auth.organisationId
        }`,
      providesTags: [TagTypes.Orders],
      transformResponse: (responseData) => {
        return {
          meta: responseData.meta,
          data: responseData.data.map((order) => {
            return {
              id: order.id,
              dateSubmitted: order.attributes.publishedAt,
              table: order.attributes.table.data?.attributes?.name,
              totalPrice: formatCurrency(order.attributes.total),
              status: capitalizeFirstLetter(order.attributes.status),
              orderDetails: order.attributes.details,
              number_of_customers: order.attributes.number_of_customers,
              mydata_gr_mark: order.attributes.mydata_gr_mark,
              external_source: order.attributes.external_source,
            };
          }),
        };
      },
    }),
    getOtherTables: builder.query({
      query: (tableId) =>
        `/tables?pagination[limit]=900&filters[id][$ne]=${tableId}&filters[organisation][id]=${
          store.getState().auth.organisationId
        }`,
      providesTags: [TagTypes.Tables],
      transformResponse: (responseData) => {
        return {
          meta: responseData.meta,
          data: responseData.data.map((table) => {
            return {
              id: table.id,
              name: table.attributes.name,
              status: table.attributes.status,
            };
          }),
        };
      },
    }),
    updateOrderStatus: builder.mutation({
      invalidatesTags: [TagTypes.Orders, TagTypes.Analytics],
      query: (orderData) => ({
        url: `/orders/${orderData.orderId}`,
        method: "PUT",
        body: {
          data: {
            status: orderData.status,
            organisation: store.getState().auth.organisationId,
            payment_method: orderData.paymentMethod,
            cancellation_reason: orderData.cancellationReason,
            force_send_cloud_invoice: orderData.force_send_cloud_invoice,
            ...(orderData.submittedBy ? { submitted_by: orderData.submittedBy } : {}),
            ...(orderData.total !== undefined ? { total: orderData.total } : {}),
            ...(orderData.discount !== undefined ? { discount: orderData.discount } : {}),
            ...(orderData.discount_reason !== undefined ? { discount: orderData.discount_reason } : {}),
            ...(orderData.details !== undefined ? { details: orderData.details } : {}),
            ...(orderData.net_revenue !== undefined ? { net_revenue: orderData.net_revenue } : {}),
            ...(orderData.notes !== undefined ? { notes: orderData.notes } : {}),
          },
        },
      }),
    }),
    ordersBulkUpdate: builder.mutation({
      invalidatesTags: [TagTypes.Orders, TagTypes.Analytics],
      query: (data) => ({
        url: `/orders-bulk-update`,
        method: "POST",
        body: {
          data: {
            ...data,
            tipAmount: normalizeTipAmount(data.tipAmount),
          },
        },
      }),
    }),
    ordersPartialUpdate: builder.mutation({
      invalidatesTags: [TagTypes.Orders, TagTypes.Analytics],
      query(orderData) {
        const { details, total, net_revenue } =
          orderData.details && orderData.details.length > 0
            ? getOrderObejectFromOrderDetails(orderData.details, orderData.discount)
            : getOrderObejectfromCartItems(orderData.cartItems);
        return {
          url: "/orders-partial-update",
          method: "POST",
          body: {
            data: {
              organisation: {
                id: store.getState().auth.organisationId,
              },
              notes: orderData.notes ? orderData.notes : "",
              total: total.toFixed(2),
              net_revenue: net_revenue.toFixed(2),
              table: {
                id: orderData.table,
              },
              details: details,
              submitted_by: orderData.submitted_by,
              origin: orderData.origin,
              payment_method: orderData.paymentMethod,
              number_of_customers: orderData.numberOfCustomers,
              status: orderData.status ? orderData.status : OrderStatus.Pending,
              discount: orderData.discount ? orderData.discount : 0,
              discount_reason: orderData.discount_reason,
              force_send_cloud_invoice: orderData.force_send_cloud_invoice,
              cancellation_reason: orderData.cancellationReason,
              invoice_type: orderData.invoice_type,
              customer: orderData.invoice_type !== InvoiceType.Retail ? orderData.customer : null,
              selectedOrderTotal: orderData.selectedOrderTotal,
              orderstoBeDisabled: orderData.orderstoBeDisabled,
              pendingOrderItems: orderData.pendingOrderItems.map((item) => {
                return {
                  ...item,
                  product: {
                    id: item.product.data.id,
                    ...item.product.data.attributes,
                  },
                  variant: item.variant.map((variant) => {
                    return {
                      ...variant,
                      variant_option: {
                        id: variant.variant_option.data.id,
                        ...variant.variant_option.data.attributes,
                      },
                    };
                  }),
                  parent_invoice: { id: item?.parent_invoice?.data?.id, ...item?.parent_invoice?.data?.attributes },
                };
              }),
              selectedNewOrderItems: orderData.selectedNewOrderItems.map((item) => {
                return {
                  ...item,
                  product: {
                    id: item.product.data.id,
                    ...item.product.data.attributes,
                  },
                  variant: item.variant.map((variant) => {
                    return {
                      ...variant,
                      variant_option: {
                        id: variant.variant_option.data.id,
                        ...variant.variant_option.data.attributes,
                      },
                    };
                  }),
                  parent_invoice: { id: item?.parent_invoice?.data?.id, ...item?.parent_invoice?.data?.attributes },
                };
              }),
              resultStatus: orderData.resultStatus,
              totalCustomers: orderData.totalCustomers,
              uniqueOrderIds: orderData.uniqueOrderIds,
              terminalId: orderData.terminalId,
              // make sure tipAmount is a string with a dot as a decimal separator and only numbers
              tipAmount: normalizeTipAmount(orderData.tipAmount),
              uuid: orderData.uuid,
            },
          },
        };
      },
    }),
    ordersBulkInvoice: builder.mutation({
      invalidatesTags: [TagTypes.Orders, TagTypes.Analytics],
      query: (data) => ({
        url: `/orders-bulk-invoice`,
        method: "POST",
        body: {
          data: {
            ...data,
          },
        },
      }),
    }),
    getHasPendingOrders: builder.query({
      query: () =>
        `/orders?populate=*&filters[status][$eq]=pending&pagination[limit]=-1&filters[organisation][id]=${
          store.getState().auth.organisationId
        }`,
      providesTags: [TagTypes.Orders],
      transformResponse: (responseData) => {
        let pendingTables = [];
        responseData.data.forEach((order) => {
          const orderTable = order?.attributes?.table?.data?.id;
          if (!pendingTables.includes(orderTable)) {
            pendingTables.push(orderTable);
          }
        });
        return pendingTables;
      },
    }),
    getPendingTables: builder.query({
      query: () => `/pending-tables?filters[organisation][id]=${store.getState().auth.organisationId}`,
      providesTags: [TagTypes.Orders, TagTypes.Tables],
      transformResponse: (responseData) => {
        return responseData;
      },
    }),
    resubmitOrder: builder.query({
      query: ({ orderId, invoiceType, customer }) => ({
        url: `/cloud-cash-register/primer/send-invoice?order_id=${orderId}&reprint=true&invoice_type=${invoiceType}`,
        method: "POST",
        body: {
          customer: { ...customer, organisation: store.getState().auth.organisationId },
        },
      }),
      transformResponse: (responseData) => {
        return responseData;
      },
    }),
    reprintOrder: builder.mutation({
      query: ({ reprint_type, order_id, orderData = null }) => ({
        url: `/printer-management/reprint/${reprint_type}/${order_id}`,
        method: "POST",
        body: {
          order: orderData,
        },
      }),
      transformResponse: (responseData) => {
        return responseData;
      },
    }),
    notifyCourseStage: builder.mutation({
      query: ({ table, user }) => ({
        url: `/notify-course-stage`,
        method: "POST",
        body: {
          organisation: {
            id: store.getState().auth.organisationId,
          },
          table: table,
          user: user,
        },
      }),
      transformResponse: (responseData) => {
        return responseData;
      },
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useGetOrderQuery,
  useLazyGetOrderQuery,
  useAddOrderMutation,
  useAddCustomerIfNotExistsMutation,
  useGetPendingOrdersByTableQuery,
  useGetOtherTablesQuery,
  useUpdateOrderStatusMutation,
  useGetHasPendingOrdersQuery,
  useGetPendingTablesQuery,
  useLazyResubmitOrderQuery,
  useReprintOrderMutation,
  useNotifyCourseStageMutation,
  useOrdersBulkUpdateMutation,
  useOrdersBulkInvoiceMutation,
  useOrdersPartialUpdateMutation,
} = apiOrders;
