import React from "react";
import { formatCurrency } from "utilities/utils";
import { Col, Row } from "reactstrap";

export default function VariantsRadio({ title, options, componentID, handleChange, selectedOptions }) {
  return (
    <>
      <h4 className="mb-3">{title}</h4>
      {options.map((option) => {
        return (
          <div className="custom-control custom-radio mb-3" key={option.id}>
            <input
              className="custom-control-input"
              id={`customRadio-${option.id}`}
              name={`custom-radio-${componentID}`}
              type="radio"
              value={option.id}
              disabled={!option?.attributes?.enabled}
              checked={
                selectedOptions.find((currentOption) => currentOption.optionId === option.id.toString()) || false
              }
              onChange={(e) => handleChange(e, componentID, option.attributes.price, title, option.attributes.label)}
            />

            <label className="custom-control-label large w-100" htmlFor={`customRadio-${option.id}`}>
              <Row>
                <Col>
                  <Row>
                    <Col xs="8" className="label-text">
                      {option.attributes.label}
                    </Col>
                    <Col xs="4" className="pl-0">
                      <p className="text-sm text-right text-muted mb-0 ml-4">
                        {option.attributes.price > 0 ? `+ ${formatCurrency(option.attributes.price)}` : ""}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </label>
          </div>
        );
      })}
    </>
  );
}
