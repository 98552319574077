import React from "react";
import { useGetAllCategoriesQuery, useAddCategoryMutation } from "api/categoriesSlice";
import { t } from "i18next";

const useNewCategory = (props) => {
  const [inputs, setInputs] = React.useState({
    newCategory: "",
    categoryImage: null,
    errors: {},
    rank: null,
  });
  const { data: categories } = useGetAllCategoriesQuery();
  const [addCategory, { isLoading: addCategoryLoading }] = useAddCategoryMutation();
  const [selectImageDialog, setSelectImageDialog] = React.useState(false);

  function handleInputChange(event) {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        [event.target.name]: event.target.value,
      };
    });
  }

  function categoryExists() {
    return (
      categories &&
      categories.some((category) => {
        if (category.name === inputs.newCategory) {
          return true;
        }
        return false;
      })
    );
  }

  function validateNewCategoryFields() {
    let isValid = true;
    if (inputs.newCategory.length === 0) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, name: t("Name_invalid_msg") },
        };
      });
      isValid = false;
    } else if (inputs.rank && inputs.rank <= 0) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, rank: t("Rank_error_message") },
        };
      });
      isValid = false;
    } else {
      if (inputs.errors.name?.length > 0) {
        setInputs((prevInputs) => {
          return {
            ...prevInputs,
            errors: { ...prevInputs.errors, name: "" },
          };
        });
      }
    }

    return isValid;
  }

  function handleAddCategory() {
    const newCategory = {
      name: inputs.newCategory,
      photo: inputs.categoryImage ? inputs.categoryImage.id : null,
      rank: inputs.rank || null,
    };

    addCategory(newCategory).then((res) => {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          newCategory: "",
          categoryImage: null,
        };
      });
      props?.onCancel();
    });
  }

  function handleNewCategorySubmit() {
    if (!validateNewCategoryFields()) {
      return;
    }

    if (categoryExists()) {
      alert("Η κατηγορία με το όνομα που επιλέξατε ήδη υπάρχει");
    } else {
      handleAddCategory();
    }
  }

  return {
    selectImageDialog,
    setSelectImageDialog,
    handleNewCategorySubmit,
    addCategoryLoading,
    inputs,
    setInputs,
    handleInputChange,
    categories,
  };
};

export default useNewCategory;
