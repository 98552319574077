import React from "react";
import { Col, Button, Row } from "reactstrap";
import CrudTable from "components/Tables/CrudTable";
import useCategories from "./useCategories";
import AlertModal from "components/Modals/AlertModal";
import DefaultLayout from "templates/DefaultLayout";
import { Views } from "utilities/enums/Views";
import { useTranslation } from "react-i18next";
import NewCategory from "./NewCategory";
import EditCategory from "./EditCategory";

function Categories() {
  const {
    deleteDialogOpen,
    setDeleteDialogOpen,
    handleDeleteDialogOpen,
    handleEditCategoryClick,
    rowClicked,
    handleDeleteCategory,
    categories,
    isLoading,
    isError,
    newCategoryDialogOpen,
    setNewCategoryDialogOpen,
    editCategoryDialogOpen,
    setEditCategoryDialogOpen,
  } = useCategories();
  const { t } = useTranslation();

  const tableCols = [
    { label: "id", mobileVisible: false },
    { label: t("Name") },
    {
      label: t("Image"),
      hidden: true,
      formatter: (cell) =>
        cell !== null ? (
          <img
            src={`${cell.attributes.url}`}
            alt="category"
            className="rounded-circle image-preview"
            style={{ width: "50px", height: "50px" }}
          />
        ) : null,
    },
    { label: t("Actions"), hidden: true },
  ];
  let header = (
    <Row>
      <Col xs="6">
        <h3 className="mb-0">{t(Views.CATEGORIES)}</h3>
      </Col>
      <Col className="text-right" xs="6">
        <Button
          className="btn-round btn-icon"
          color="primary"
          id="add-btn"
          onClick={() => setNewCategoryDialogOpen(true)}
          size="sm"
        >
          <span className="btn-inner--icon mr-1">
            <i className="fas fa-user-edit" />
          </span>
          <span className="btn-inner--text">{t("Add")}</span>
        </Button>
      </Col>
    </Row>
  );
  return (
    <>
      <DefaultLayout
        name={Views.CATEGORIES}
        parentName={Views.MENU_MANAGEMENT}
        isLoading={isLoading}
        isError={isError}
        header={header}
      >
        {categories?.length > 0 ? (
          <CrudTable
            columns={tableCols}
            data={categories?.map((category) => ({
              id: category.id,
              name: category.name,
              photo: category.photo,
            }))}
            pageSize={7}
            onEdit={handleEditCategoryClick}
          />
        ) : (
          <div className="text-center">{t("No_Categories_found")}</div>
        )}
      </DefaultLayout>

      <EditCategory
        isOpen={editCategoryDialogOpen}
        category={rowClicked?.id}
        onCancel={() => setEditCategoryDialogOpen(false)}
      />

      <NewCategory isOpen={newCategoryDialogOpen} onCancel={() => setNewCategoryDialogOpen(false)} />

      <AlertModal
        isOpen={deleteDialogOpen}
        title={`${t("Delete_category_msg")} "${rowClicked?.name}"?`}
        okText={t("Delete")}
        cancelText={t("Cancel")}
        onCancel={() => setDeleteDialogOpen(false)}
        onOk={handleDeleteCategory}
      />
    </>
  );
}

export default Categories;
