import { TagTypes } from "utilities/enums/TagTypes";
import { apiSlice } from "./apiSlice";
import { store } from "redux/store";

export const printScenariosSclice = apiSlice.injectEndpoints({
  tagTypes: [TagTypes.PrintScenarios],
  endpoints: (builder) => ({
    getPrintScenarios: builder.query({
      providesTags: [TagTypes.PrintScenarios],
      query: () =>
        `/print-scenarios?populate[0]=configurations.receipt_groups.categories&populate[1]=configurations.receipt_groups.table_filters&populate[2]=configurations.receipt_groups.funnel_type&filters[organisation][id]=${
          store.getState().auth.organisationId
        }`,
      transformResponse: (responseData) => {
        return responseData.data;
      },
    }),
    getPrintScenario: builder.query({
      providesTags: [TagTypes.PrintScenarios],
      query: (scenarioId) =>
        `/print-scenarios/${scenarioId}?populate[0]=configurations.receipt_groups.categories&populate[1]=configurations.receipt_groups.table_filters&populate[2]=configurations.receipt_groups.funnel_type&&populate[3]=configurations.printer&filters[organisation][id]=${
          store.getState().auth.organisationId
        }`,
      transformResponse: (responseData) => {
        let scenario = responseData.data;
        return {
          id: scenario.id,
          name: scenario?.attributes?.name,
          active: scenario?.attributes?.active,
          description: scenario?.attributes?.description,
          configurations: scenario?.attributes?.configurations.map((configuration) => {
            return {
              id: configuration.id,
              printer: { id: configuration?.printer?.data?.id, attributes: configuration?.printer?.data?.attributes },
              receipt_type: configuration?.receipt_type,
              hide_total: configuration?.hide_total,
              receiptGroups: configuration.receipt_groups.map((group) => {
                return {
                  ...group,
                  categories: group.categories.data.map((category) => {
                    return category.id;
                  }),
                  table_filters: group.table_filters.data.map((table_filter) => {
                    return table_filter.id;
                  }),
                };
              }),
            };
          }),
        };
      },
    }),
    addPrintScenario: builder.mutation({
      query: (scenario) => ({
        url: "/print-scenarios",
        method: "POST",
        body: {
          data: {
            name: scenario.name,
            description: scenario.description,
            organisation: store.getState().auth.organisationId,
            active: scenario.active,
            configurations: scenario.configurations.map(({ receiptGroups, ...configuration }) => {
              return {
                ...configuration,
                receipt_groups: receiptGroups.map(({ id, ...rest }) => rest),
              };
            }),
            printer: scenario.printer,
          },
        },
      }),
      invalidatesTags: [TagTypes.PrintScenarios],
    }),
    updatePrintScenario: builder.mutation({
      invalidatesTags: [TagTypes.PrintScenarios],
      query: (scenario) => {
        return {
          url: `/print-scenarios/${scenario.id}`,
          method: "PUT",
          body: {
            data: {
              ...(scenario.name !== undefined && { name: scenario.name }),
              ...(scenario.description !== undefined && { description: scenario.description }),
              ...(scenario.active !== undefined && { active: scenario.active }),
              ...(scenario.configurations !== undefined && {
                configurations: scenario.configurations.map((configuration) => ({
                  printer: configuration.printer,
                  receipt_type: configuration.receipt_type,
                  receipt_groups: configuration.receiptGroups,
                  hide_total: configuration.hide_total,
                })),
              }),
              organisation: store.getState().auth.organisationId,
            },
          },
        };
      },
    }),
    deletePrintScenario: builder.mutation({
      query: (id) => ({
        url: `/print-scenarios/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: [TagTypes.PrintScenarios],
    }),
  }),
});

export const {
  useGetPrintScenariosQuery,
  useGetPrintScenarioQuery,
  useAddPrintScenarioMutation,
  useUpdatePrintScenarioMutation,
  useDeletePrintScenarioMutation,
} = printScenariosSclice;
