import React, { useEffect, useReducer, useState } from "react";
import { TextArea } from "components/TextAreas/TextArea";
import CartButton from "components/Buttons/CartButton";
import { Alert, Divider, ListItem } from "@mui/material";
import { useUpdateProductMutation, ProductService } from "api/productsSlice";
import { TagTypes } from "utilities/enums/TagTypes";
import CartListItem from "components/ShoppingCart/CartListItem";
import { useDispatch, useSelector } from "react-redux";
import { useAddOrderMutation, useAddCustomerIfNotExistsMutation, apiOrders } from "api/ordersSlice";
import { useNavigate } from "react-router-dom";
import { selectCurrentUser } from "auth/authSlice";
import { broadCastOrdersChangeWS, subscribeToOrdersChangeWS } from "config/websocket";
import { clearCart, setDiscount } from "redux/cart/cartSlice";
import { Col, FormGroup, Input, Card, CardHeader, CardBody, Row, Collapse, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import AlertModal from "components/Modals/AlertModal";
import NewOrderProduct from "views/pages/tables/NewOrder/Product/NewOrderProduct";
import { MODE_UPDATE } from "utilities/constants";
import { selectCurrentOrganisation } from "auth/authSlice";
import { formatCurrency } from "utilities/utils";
import { InvoiceType } from "utilities/enums/InvoiceType";
import { generateUUID } from "utilities/utils";
import SearchVatButton from "components/Buttons/SearchVatButton";
import CustomerSearch from "components/Modals/CustomerSearch";
import { selectCurrentUserRules } from "auth/authSlice";
import ROUTES_OBJ from "utilities/enums/Routes";
import DiscountInput from "components/Inputs/DiscountInput";

export default function Cart({ cartItems, onCheckout, className, onCartItemClick }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addOrder] = useAddOrderMutation();
  const [addCustomerIfNotExists] = useAddCustomerIfNotExistsMutation();
  const [updateProduct] = useUpdateProductMutation();
  const { productQuantities } = useSelector((state) => state.productQuantities);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const { cartTable, discount } = useSelector((state) => state.cart);
  const { username: submittedBy } = useSelector(selectCurrentUser);
  const [notes, setNotes] = useState("");
  const { t } = useTranslation();
  const user_rules = useSelector(selectCurrentUserRules);
  const discount_rule_enabled = user_rules.find(
    (rule) => rule.name === `Route:${ROUTES_OBJ.TABLE_ACTIONS_DISCOUNT.path}`
  )?.value;
  const {
    discounts_enabled,
    customers_per_order_enabled,
    cloud_cash_register_enabled,
    manual_cloud_cash_register_enabled,
    wholesale_enabled,
    external_source_types_enabled,
    default_option_without_invoice,
  } = useSelector((state) => state.settings);
  const [submitInvoiceType, setSubmitInvoiceType] = useState(() => getDefaultSubmitInvoiceType());
  const organisationId = useSelector(selectCurrentOrganisation);
  const [deliveryMethod, setDeliveryMethod] = useState("efood"); // Default to 'Efood'
  const [customerAccordionOpen, setCustomerAccordionOpen] = React.useState(false);
  const [newOrderCustomers, setNewOrderCustomers] = useState(0);
  const [editProductOpen, setEditProductOpen] = useState(false);
  const [selectedProductInfo, setSelectedProductInfo] = useState({
    id: -1,
    quantity: 1,
    variants: [],
    notes: "",
    category: "",
    vat_category: "",
    name: "",
  });

  const [customerInputs, setCustomerInputs] = useState({
    name: "",
    vat_number: "",
    doy: "",
    gemi_number: "",
    address: "",
    city: "",
    postcode: "",
    phone_number: "",
    errors: {},
    organisation: organisationId,
  });
  const [discountReason, setDiscountReason] = useState("");
  const [discountAccordionOpen, setDiscountAccordionOpen] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [calculateModalOpen, setCalculateModalOpen] = React.useState(false);
  const allow_discount = discounts_enabled && discount_rule_enabled;

  function getDefaultSubmitInvoiceType() {
    // Primer is disabled
    if (!cloud_cash_register_enabled) return "";

    // Primer is enabled but there's no option for without invoice
    if (cloud_cash_register_enabled && !manual_cloud_cash_register_enabled) {
      return InvoiceType.OpenTable;
    }

    // Primer is enabled and there is option for without invoice
    if (cloud_cash_register_enabled && manual_cloud_cash_register_enabled) {
      if (default_option_without_invoice) {
        return "";
      } else {
        return InvoiceType.OpenTable;
      }
    }
  }

  function handleCustomerInputChange(event) {
    setCustomerInputs((prevInputs) => {
      return {
        ...prevInputs,
        [event.target.name]: event.target.value,
      };
    });
  }

  function onCartItemClick(productInfo) {
    setSelectedProductInfo(productInfo);
    setEditProductOpen(true);
  }

  function refreshComponent() {
    //Invalidates cache for refreshing the cart data
    dispatch(
      ProductService.util.invalidateTags([TagTypes.Products, TagTypes.Categories, TagTypes.Variants, TagTypes.Orders])
    );
    //Force updates the cart component when an orderhas been received from an external client.
    forceUpdate();
    cartQuantitiesValid();
  }
  useEffect(() => {
    subscribeToOrdersChangeWS(organisationId, refreshComponent);
  }, []);

  const cartItemsList = cartItems.map((cartItem, cartItemIndex) => {
    return <CartListItem cartItem={cartItem} key={cartItemIndex} onCartItemClick={onCartItemClick} />;
  });
  const [selectedOrderTotal, setSelectedOrderTotal] = useState(0);
  const [orderGrandTotal, setOrderGrandTotal] = useState(0);

  useEffect(() => {
    const total = cartItems.reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0);
    setSelectedOrderTotal(total);
    setOrderGrandTotal(total - discount);
  }, [cartItems, discount]);

  const getInvoiceType = () => {
    if (submitInvoiceType === "delivery") {
      return InvoiceType.Retail;
    }
    return submitInvoiceType?.length > 0 ? submitInvoiceType : InvoiceType.OpenTable;
  };

  const submitOrder = () => {
    if (cartTable?.id === -1) {
      navigate("/admin/tables");
      return;
    }

    handleAddCustomer().then((addedCustomer) => {
      let customerId = addedCustomer?.data?.id;
      const UUID = generateUUID(submittedBy, organisationId);

      addOrder({
        uuid: UUID,
        tableId: cartTable?.id,
        cartItems: cartItems,
        submittedBy: submittedBy,
        notes: notes,
        origin: "new",
        invoice_type: getInvoiceType(),
        numberOfCustomers: newOrderCustomers,
        force_send_cloud_invoice: submitInvoiceType?.length > 0 || submitInvoiceType === "delivery",
        ...(submitInvoiceType === "delivery" && {
          external_source: deliveryMethod,
          discount: discount,
          discount_reason: discountReason,
          total: orderGrandTotal,
        }),
        ...(customerId && { customer: { id: customerId } }),
      }).then(() => {
        dispatch(apiOrders.util.invalidateTags([TagTypes.Orders]));
      });
    });

    navigate("/admin/tables");
    dispatch(clearCart());
  };

  /**
   * In case we submit a wholesale receipt, we should have a customer for it
   * Before submitting the order we should save (or retrieve if existing) the customer from our database
   * This function handles the retrieval of the customer in general or after they are added from the backend
   * @returns
   */
  async function handleAddCustomer() {
    if (submitInvoiceType === InvoiceType.Wholesale) {
      return await addCustomerIfNotExists({ entity_data: customerInputs });
    }
  }

  let customerSelector = (
    <div className="accordion col-12 mr-auto pl-0 pr-0 ml-0 mt-3">
      <Card className="card-plain discount-dropdown-card mb-0" key={0}>
        <CardHeader
          className={`p-3 customer-selector-card-header`}
          role="tab"
          onClick={() => setCustomerAccordionOpen(!customerAccordionOpen)}
          aria-expanded={customerAccordionOpen}
        >
          <h5 className="mb-0">{t("Customer_info")}</h5>
        </CardHeader>
        <Collapse role="tabpanel" isOpen={customerAccordionOpen}>
          <CardBody className="p-3">
            <Row>
              <CustomerSearch
                customerInputs={customerInputs}
                setCustomerInputs={setCustomerInputs}
                initialName={customerInputs.name}
              />
              <Col xs="12" md="6" className="pl-2 pr-3">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="name">
                    {t("AFM")}
                  </label>
                  <div className="position-relative">
                    <Input
                      className="form-control-alternative"
                      id=""
                      name="vat_number"
                      placeholder={t("AFM")}
                      type="text"
                      value={customerInputs.vat_number}
                      onChange={handleCustomerInputChange}
                      pattern="[0-9]*"
                    />
                    <SearchVatButton vatNumber={customerInputs.vat_number} setCustomerInputs={setCustomerInputs} />
                  </div>
                  <p className="text-danger text-sm">{customerInputs.errors.vat_number}</p>
                </FormGroup>
              </Col>
              <Col xs="12" md="6" className="pl-2 pr-3">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="doy">
                    {t("DOY")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="doy"
                    name="doy"
                    placeholder={t("DOY")}
                    type="text"
                    value={customerInputs.doy}
                    onChange={handleCustomerInputChange}
                  />
                  <p className="text-danger text-sm">{customerInputs.errors.doy}</p>
                </FormGroup>
              </Col>
              <Col xs="12" md="6" className="pl-2 pr-3">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="gemi_number">
                    {t("GEMH")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="gemi_number"
                    name="gemi_number"
                    placeholder={t("GEMH")}
                    type="text"
                    value={customerInputs.gemi_number}
                    onChange={handleCustomerInputChange}
                  />
                  <p className="text-danger text-sm">{customerInputs.errors.gemi_number}</p>
                </FormGroup>
              </Col>
              <Col xs="12" md="6" className="pl-2 pr-3">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="address">
                    {t("Address")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="address"
                    name="address"
                    placeholder={t("Address")}
                    type="text"
                    value={customerInputs.address}
                    onChange={handleCustomerInputChange}
                  />
                  <p className="text-danger text-sm">{customerInputs.errors.address}</p>
                </FormGroup>
              </Col>
              <Col xs="12" md="6" className="pl-2 pr-3">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="city">
                    {t("City")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="city"
                    name="city"
                    placeholder={t("City")}
                    type="text"
                    value={customerInputs.city}
                    onChange={handleCustomerInputChange}
                  />
                  <p className="text-danger text-sm">{customerInputs.errors.city}</p>
                </FormGroup>
              </Col>
              <Col xs="12" md="6" className="pl-2 pr-3">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="postcode">
                    {t("Postcode")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="postcode"
                    name="postcode"
                    placeholder={t("Postcode")}
                    type="text"
                    value={customerInputs.postcode}
                    onChange={handleCustomerInputChange}
                  />
                  <p className="text-danger text-sm">{customerInputs.errors.postcode}</p>
                </FormGroup>
              </Col>
              <Col xs="12" md="6" className="pl-2 pr-3">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="phone_number">
                    {t("Phone")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="phone_number"
                    name="phone_number"
                    placeholder={t("Phone")}
                    type="text"
                    value={customerInputs.phone_number}
                    onChange={handleCustomerInputChange}
                  />
                  <p className="text-danger text-sm">{customerInputs.errors.phone_number}</p>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Collapse>
      </Card>
    </div>
  );
  let discountSelector = (
    <>
      <div className="accordion payitems-accordion col-12 mr-auto pl-0 pr-0 ml-0 mb-3 mt-2 mt-md-3">
        <Card className="card-plain discount-dropdown-card mb-2" key={0}>
          <CardHeader
            className={`p-3`}
            role="tab"
            onClick={() => setDiscountAccordionOpen(!discountAccordionOpen)}
            aria-expanded={discountAccordionOpen}
          >
            <h5 className="mb-0">
              {t("Discount")}{" "}
              <span className="ml-2 text-primary">
                {discount > 0 && !discountAccordionOpen ? `${formatCurrency(discount)}` : ""}
              </span>
            </h5>
          </CardHeader>
          <Collapse role="tabpanel" isOpen={discountAccordionOpen}>
            <CardBody className="p-3">
              <Row>
                <Col className="d-flex flex-column align-items-end justify-content-end pl-3 pr-3">
                  <DiscountInput
                    selectedOrderTotal={selectedOrderTotal}
                    initialDiscount={discount}
                    onDiscountChange={(discounted) => {
                      dispatch(setDiscount(discounted));
                    }}
                  />

                  {errorMessage && <span className="text-danger text-sm mt-2">{t(errorMessage)}</span>}
                </Col>
              </Row>
              <FormGroup className="mb-1 mt-2">
                <label className="form-control-label" htmlFor="name">
                  {t("Discount_reason")}
                </label>
                <Input
                  className="form-control-alternative"
                  id="name"
                  name="name"
                  placeholder={t("Discount_reason")}
                  type="text"
                  value={discountReason}
                  disabled={!(discount && discount > 0)}
                  onChange={(e) => setDiscountReason(e.target.value)}
                />
              </FormGroup>
            </CardBody>
          </Collapse>
        </Card>
      </div>
    </>
  );

  let invoiceSelector = (
    <>
      <div>
        {/* <label className="form-control-label mr-2">{t("Submit_invoice")}</label> */}

        <div className="d-flex flex-column col-12 pr-0 pl-0">
          <Input
            type="select"
            name="select"
            id="exampleSelect"
            value={submitInvoiceType}
            onChange={(e) => setSubmitInvoiceType(e.target.value)}
          >
            {manual_cloud_cash_register_enabled && <option value={""}>{t("Without_Invoice")}</option>}
            <option value={InvoiceType.OpenTable}>{t("OpenTable_invoice")}</option>
            {wholesale_enabled && <option value={InvoiceType.Wholesale}>{t(InvoiceType.Wholesale)}</option>}
            {external_source_types_enabled && <option value={"delivery"}>{t("Delivery")}</option>}
          </Input>

          {external_source_types_enabled && submitInvoiceType === "delivery" && (
            <>
              <div className="pt-3 d-flex">
                <div className="custom-control custom-radio">
                  <input
                    id="efood"
                    className="custom-control-input"
                    type="radio"
                    value="efood"
                    checked={deliveryMethod === "efood"}
                    onChange={(e) => setDeliveryMethod(e.target.value)}
                  />
                  <label className="custom-control-label" htmlFor="efood">
                    Efood
                  </label>
                </div>
                <div className="custom-control custom-radio ml-4">
                  <input
                    id="wolt"
                    className="custom-control-input"
                    type="radio"
                    value="wolt"
                    checked={deliveryMethod === "wolt"}
                    onChange={(e) => setDeliveryMethod(e.target.value)}
                  />
                  <label className="custom-control-label" htmlFor="wolt">
                    Wolt
                  </label>
                </div>
              </div>
              {allow_discount && <div className="w-full">{discountSelector}</div>}
            </>
          )}
        </div>
        {submitInvoiceType === InvoiceType.Wholesale && <div className="mt-2 ml-0">{customerSelector}</div>}
      </div>
    </>
  );

  function customerFieldsValidator() {
    let isValid = true;
    if (customerInputs.name.length === 0) {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, name: t("Name_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, name: "" },
        };
      });
    }
    if (customerInputs.vat_number.length === 0) {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, vat_number: t("Vat_number_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, vat_number: "" },
        };
      });
    }

    if (customerInputs.doy.length === 0) {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, doy: t("Doy_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, doy: "" },
        };
      });
    }

    if (customerInputs.address.length === 0) {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, address: t("Address_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, address: "" },
        };
      });
    }

    if (customerInputs.city.length === 0) {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, city: t("City_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, city: "" },
        };
      });
    }

    if (customerInputs.postcode.length === 0) {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, postcode: t("Postcode_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, postcode: "" },
        };
      });
    }
    return isValid;
  }

  const handleSubmitOrder = () => {
    if (submitInvoiceType === InvoiceType.Wholesale && !customerFieldsValidator()) {
      return;
    }
    submitOrder();
    if (onCheckout) {
      onCheckout();
    }
  };

  const cartQuantitiesValid = () => {
    // check if any cart item quantity is greater than the product quantity
    // if so, return true to disable the checkout button
    return cartItems.some((cartItem) => {
      const totalAlreadyOrdered = cartItems.reduce((total, item) => {
        return total + (item.id === cartItem.id ? item.quantity : 0);
      }, 0);
      const productQuantity = productQuantities?.find((item) => item.id === cartItem.id);
      return productQuantity && productQuantity.quantity < totalAlreadyOrdered;
    });
  };

  const customerAmounts = [];
  for (let i = 0; i <= 50; i++) {
    customerAmounts.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  return (
    <div className={className}>
      <div className="scollable-panel mt-4 mt-md-0 d-flex flex-column align-items-center scrollable-y">
        {cartItemsList.length ? (
          <>
            {cartItemsList}
            <Divider />
            <ListItem
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <div className="d-flex flex-grow-1">
                <div className="d-flex flex-column dynamic-padding-bottom mt-3 flex-grow-1">
                  <p className="mb-0 font-weight-bold mb-1 form-control-label">{t("Comments")}</p>
                  <div>
                    <TextArea
                      className="w-100 pt-2 form-control"
                      value={notes}
                      onChange={(e) => {
                        setNotes(e.target.value);
                      }}
                      placeholder={t("Comments")}
                    />
                  </div>

                  {customers_per_order_enabled && (
                    <FormGroup className="mt-4">
                      <label className="form-control-label" htmlFor="customersAmountSelect">
                        {t("Customers_of_new_order_msg")}
                      </label>
                      <Input
                        type="select"
                        name="select"
                        id="customersAmountSelect"
                        value={newOrderCustomers}
                        onChange={(e) => setNewOrderCustomers(e.target.value)}
                      >
                        {customerAmounts}
                      </Input>
                    </FormGroup>
                  )}
                </div>
              </div>
            </ListItem>
          </>
        ) : (
          <ListItem>{t("cart_empty_msg")}</ListItem>
        )}
        {cartQuantitiesValid() && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {t("cart_inventory_invalid")}
          </Alert>
        )}
      </div>
      <Col
        xs="12"
        className=" pl-4 pr-4 bg-white position-absolute left-0 bottom-0 dynamic-cart-padding-bottom pb-md-0 pt-3 box-shadow-medium"
      >
        {cloud_cash_register_enabled && (
          <div className="w-100 pb-3 scrollable-cart scrollbar-hidden">{invoiceSelector}</div>
        )}

        <CartButton
          id="submit-order-btn"
          text={t("Checkout")}
          onClick={handleSubmitOrder}
          disabled={cartQuantitiesValid() || newOrderCustomers < 0}
        />
      </Col>

      <AlertModal
        isOpen={editProductOpen}
        zIndex={2000}
        onCancel={() => setEditProductOpen(false)}
        title={selectedProductInfo?.name}
        stickyHeaderEnabled={true}
        header={
          <div className="position-relative w-100 margin-top--35">
            <button
              aria-label="Close"
              className="close position-absolute right-3 top-0"
              data-dismiss="modal"
              type="button"
              onClick={() => setEditProductOpen(false)}
            ></button>
            <div className="modal-header pb-0 d-flex flex-column pt-0">
              <h2 className="modal-title pr-4 mt-1" id="modal-title-default">
                {selectedProductInfo?.name}
              </h2>
              <h5 className="modal-subtitle text-muted font-weight-light mt-2">{selectedProductInfo?.description}</h5>
              <div className="text-primary">{formatCurrency(selectedProductInfo?.price)}</div>
            </div>
          </div>
        }
        stickyHeader={
          <div>
            <button
              aria-label="Close"
              className="close position-absolute right-3 top-2-5"
              data-dismiss="modal"
              type="button"
              onClick={() => setEditProductOpen(false)}
            ></button>
            <div className="modal-header pb-0 d-flex flex-column pt-3">
              <h2 className="modal-title pr-4" id="modal-title-default">
                {selectedProductInfo?.name}
              </h2>
            </div>
          </div>
        }
        body={
          <NewOrderProduct
            mode={MODE_UPDATE}
            productID={selectedProductInfo.id}
            productInfo={selectedProductInfo}
            addproductCallback={() => setEditProductOpen(false)}
          />
        }
      />
    </div>
  );
}
