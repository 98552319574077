import React, { useEffect, useState, useRef } from "react";
import { Input, Card, CardBody, Row, Button, Col, InputGroupAddon, InputGroupText } from "reactstrap";
import { formatCurrency } from "utilities/utils";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { increaseCartQuantity, decreaseCartQuantity } from "redux/cart/cartSlice";
import { MODE_CREATE } from "utilities/constants";
import { useLazySearchProductQuery } from "api/productsSlice";
import useNewOrderProducts from "views/pages/tables/NewOrder/Products/useNewOrderProducts";
import AlertModal from "components/Modals/AlertModal";
import NewOrderProduct from "views/pages/tables/NewOrder/Product/NewOrderProduct";

export default function SearchModal(props) {
  const {
    dispatch,
    handleProductClick,
    getTotalAlreadyOrdered,
    getItemQuantity,
    selectedProduct,
    handleProductClose,
    parentCategory,
    cartTable,
  } = useNewOrderProducts();
  const { t } = useTranslation();
  const location = useLocation();
  const showSearch = location?.pathname.includes("/admin/table-actions/new-order");
  const [activeSearch, setActiveSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchProducts, { data: searchResults, isLoading: isLoadingSearch }] = useLazySearchProductQuery();
  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const collapsible = props.collapsible ?? true;
  const [showSearchInput, setShowSearchInput] = useState(!collapsible); // TODO: remove this state and use [activeSearch, setActiveSearch

  function getProductQuantity(product) {
    return (
      <div className="quantity-container pl-0 pt-1 ml-auto">
        {product.inventoryEnabled && product.inventory <= 0 ? (
          <div className="quantity text-danger">{t("Out_of_stock")}</div>
        ) : (
          <div className="quantity text-success">
            {product.inventoryEnabled ? `${t("Quantity")}: ${product.inventory}` : null}
          </div>
        )}
      </div>
    );
  }
  useEffect(() => {
    if (searchValue !== "") {
      searchProducts(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    // If we are on collapsible mode (magnifier button need to be clicked for the search to open) we focus the input field when it's initially shown
    if (collapsible && showSearchInput && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showSearchInput]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isClickInsideModal = event.target.closest(".modal-dialog");
      if (containerRef.current && !containerRef.current.contains(event.target) && !isClickInsideModal) {
        // Clicked outside the component, hide search results
        setActiveSearch(false);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={containerRef} className={`search-container ${props.className}`}>
      {showSearch && (
        <>
          {!showSearchInput && (
            <Button
              className="btn-icon-only search-btn"
              color="primary"
              onClick={() => {
                setShowSearchInput(true);
                setActiveSearch(true);
              }}
            >
              <i className="fa fa-search" />
            </Button>
          )}
          <div
            className={`search-dialog bg-info ${showSearchInput ? "" : "hidden"} d-flex flex-column ${props.className}`}
          >
            <div className="search-header d-flex flex-row justify-content-between align-items-center">
              {
                <div
                  className={`search-input-container flex-grow-1 position-relative d-flex flex-row justify-content-between align-items-center ${
                    activeSearch ? "active" : ""
                  } ${searchValue != "" ? "search-results-visible" : ""}`}
                >
                  <InputGroupAddon addonType="prepend" className="magnifier">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    ref={inputRef}
                    className="search-input pl-0 w-100 h-100"
                    type="text"
                    name="search"
                    id="search"
                    autoComplete="off"
                    placeholder={t("Search_product")}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onFocus={() => setActiveSearch(true)}
                  />
                  {activeSearch && searchValue?.length > 0 && (
                    <span
                      className="btn-icon-only search-close hoverable"
                      color="primary"
                      onClick={() => {
                        setSearchValue("");
                        inputRef.current.focus();
                      }}
                    >
                      <i className="close-icon fa fa-times-circle" />
                    </span>
                  )}
                </div>
              }
              {collapsible && (
                <span
                  className="text-white text-sm font-weight-bold ml-2"
                  onClick={() => {
                    setActiveSearch(false);
                    if (collapsible) setShowSearchInput(false);
                  }}
                >
                  {t("Cancel")}
                </span>
              )}
            </div>
            {activeSearch && searchValue !== "" && (
              <div className="search-results-container">
                <div className="search-results">
                  {isLoadingSearch && <div className="loading pl-4 pb-0 pt-2">Loading...</div>}
                  {!searchResults && !isLoadingSearch && (
                    <div className="loading pl-4 pb-0 pt-2">{t("No_results_found")}</div>
                  )}
                  {searchResults?.map((product, loopIndex) => {
                    const cartProduct = {
                      id: product.id,
                      name: product.name,
                      variants: [],
                      price: product.price,
                      category: parentCategory,
                      vat_category: product.vat_category,
                    };
                    return (
                      <div className="search-result" key={loopIndex}>
                        <Col key={product.id} xs="12" className="pl-2 pr-2">
                          <Card className="mb-2">
                            <CardBody
                              className="d-flex flex-wrap flex-sm-row text-center pt-3-md pb-4-md p-2 justify-content-between justify-content-md-start align-items-center"
                              onClick={() => handleProductClick(product)}
                              role="button"
                            >
                              <div className="d-flex col-md-12 flex-wrap flex-row justify-content-start align-items-center pr-0 pl-2">
                                <div className="pl-0 col-8 product-info d-flex w-100 flex-column align-items-start pr-2">
                                  <h5 className="h3 title mb-1 text-left product-name" role="button">
                                    {product.name}
                                  </h5>
                                  <div className="price text-left text-md-center">{formatCurrency(product.price)}</div>
                                </div>

                                <div className="col-4 p-0 d-flex flex-wrap justify-content-column">
                                  <div className="pl-0 pr-0 d-flex justify-content-end align-items-center buttons-container w-100 mt-md-2">
                                    <Button
                                      className="btn-icon-only product-quantity-btn-circle rounded-circle"
                                      color="danger"
                                      onClick={(e) => {
                                        dispatch(decreaseCartQuantity(cartProduct));
                                        e.stopPropagation();
                                      }}
                                      disabled={getItemQuantity(cartProduct) <= 0}
                                    >
                                      <i className="fa fa-minus" />
                                    </Button>
                                    <div className="quantity">{getItemQuantity(cartProduct)}</div>
                                    <Button
                                      className="btn-icon-only product-quantity-btn-circle rounded-circle ml-2"
                                      color="success"
                                      onClick={(e) => {
                                        dispatch(increaseCartQuantity(cartProduct));
                                        e.stopPropagation();
                                      }}
                                      disabled={
                                        product.inventoryEnabled &&
                                        product.inventory <= getTotalAlreadyOrdered(cartProduct)
                                      }
                                    >
                                      <i className="fa fa-plus" />
                                    </Button>
                                  </div>
                                  {getProductQuantity(product)}
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </div>
                    );
                  })}

                  <AlertModal
                    isOpen={selectedProduct != null}
                    onCancel={handleProductClose}
                    headerImage={selectedProduct?.image?.attributes.url}
                    stickyHeaderEnabled={true}
                    header={
                      <div className="position-relative w-100 margin-top--35">
                        <button
                          aria-label="Close"
                          className="close position-absolute right-3 top-0"
                          data-dismiss="modal"
                          type="button"
                          onClick={handleProductClose}
                        ></button>
                        <div className="modal-header pb-0 d-flex flex-column pt-0">
                          <h2 className="modal-title pr-4 mt-1" id="modal-title-default">
                            {selectedProduct?.name}
                          </h2>
                          <h5 className="modal-subtitle text-muted font-weight-light mt-2">
                            {selectedProduct?.description}
                          </h5>
                          <div className="text-primary">{formatCurrency(selectedProduct?.price)}</div>
                        </div>
                      </div>
                    }
                    stickyHeader={
                      <div>
                        <button
                          aria-label="Close"
                          className="close position-absolute right-3 top-2-5"
                          data-dismiss="modal"
                          type="button"
                          onClick={handleProductClose}
                        ></button>
                        <div className="modal-header pb-0 d-flex flex-column pt-3">
                          <h2 className="modal-title pr-4" id="modal-title-default">
                            {selectedProduct?.name}
                          </h2>
                        </div>
                      </div>
                    }
                    body={
                      <NewOrderProduct
                        mode={MODE_CREATE}
                        productID={selectedProduct ? selectedProduct.id : -1}
                        addproductCallback={handleProductClose}
                      />
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
