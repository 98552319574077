import React from "react";
import { Row, Button, Col, FormGroup, Input } from "reactstrap";
import useEditCategory from "./useEditCategory";
import MediaManager from "components/MediaManager/MediaManager";
import { useTranslation } from "react-i18next";
import AlertModal from "components/Modals/AlertModal";

function EditCategory(props) {
  const {
    inputs,
    handleInputChange,
    handleImageChange,
    handleImageDialogClose,
    handleImageDialogOpen,
    selectImageDialog,
    handleEditSubmit,
    updateCategoryLoading,
    categoryFetching,
  } = useEditCategory(props);
  const { t } = useTranslation();

  return (
    <>
      <AlertModal
        isLoading={categoryFetching}
        isOpen={props.isOpen}
        title={t("Edit_Category")}
        okText={t("Save")}
        cancelText={t("Cancel")}
        onCancel={props?.onCancel}
        onOk={handleEditSubmit}
        okDisabled={updateCategoryLoading}
        body={
          <>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    {t("Name")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="editCategory"
                    name="editCategory"
                    placeholder={t("Name")}
                    type="text"
                    value={inputs.editCategory}
                    onChange={handleInputChange}
                  />
                  <p className="text-danger">{inputs.errors.newCategory}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="mb-4">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="name">
                    {t("Rank_label")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="rank"
                    name="rank"
                    min="1"
                    placeholder={t("Rank_label")}
                    type="number"
                    value={inputs.rank ? inputs.rank : ""}
                    onChange={handleInputChange}
                  />
                  <p className="text-danger m-0">{inputs.errors.rank}</p>
                </FormGroup>
                <div className="hint">{t("Rank_product_field_hint")}</div>
              </Col>
            </Row>
          </>
        }
      ></AlertModal>
      <MediaManager
        isOpen={selectImageDialog}
        onCancel={() => {
          handleImageDialogClose();
        }}
        onOk={() => {
          handleImageDialogClose();
        }}
        selectedImage={inputs.categoryImage}
        setSelectedImage={(image) => {
          handleImageChange(image);
        }}
      />
    </>
  );
}

export default EditCategory;
