import React from "react";
import { Table } from "reactstrap";

export default function ScrollableTable(props) {
  const { children, ...restprops } = props;

  return (
    <div className="scrollable-table-responsive scrollable-y">
      <Table {...restprops}>{children}</Table>
    </div>
  );
}
