import React from "react";
import { UncontrolledTooltip, Col, Button, Row } from "reactstrap";
import CrudTable from "components/Tables/CrudTable";
import AlertModal from "components/Modals/AlertModal";
import useFloors from "./useFloors";
import DefaultLayout from "templates/DefaultLayout";
import { Views } from "utilities/enums/Views";
import { useTranslation } from "react-i18next";

function Floors() {
  const {
    floors,
    isLoading,
    isError,
    deleteDialogOpen,
    editDialogOpen,
    newFloorDialogOpen,
    inputs,
    rowClicked,
    handleEditClose,
    handleEditDialogOpen,
    handleNewFloorClose,
    handleNewFloorOpen,
    handleInputChange,
    handleNewFloorSubmit,
    handleEditSubmit,
    handleDeleteOpen,
    handleDeleteClose,
    handleDeleteSubmit,
  } = useFloors();
  const { t } = useTranslation();

  const tableCols = [{ label: "id", hidden: true }, { label: t("Rank") }, { label: t("Name") }, { label: "" }];
  const header = (
    <>
      {" "}
      <Row>
        <Col xs="6">
          <h3 className="mb-0">{t(Views.FLOORS)}</h3>
        </Col>
        <Col className="text-right" xs="6">
          <Button
            className="btn-round btn-icon"
            color="primary"
            href="#"
            id="tooltip443412080"
            onClick={handleNewFloorOpen}
            size="sm"
          >
            <span className="btn-inner--icon mr-1">
              <i className="fas fa-user-edit" />
            </span>
            <span className="btn-inner--text">{t("Add")}</span>
          </Button>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      <DefaultLayout
        name={Views.FLOORS}
        parentName={Views.TABLE_MANAGEMENT}
        isLoading={isLoading}
        isError={isError}
        header={header}
      >
        <CrudTable
          columns={tableCols}
          data={floors?.map((floor) => {
            return { id: floor.id, rank: floor.attributes.rank, name: floor.attributes.name };
          })}
          pageSize={7}
          onEdit={handleEditDialogOpen}
          onDelete={handleDeleteOpen}
        />
      </DefaultLayout>
      <AlertModal
        isOpen={newFloorDialogOpen}
        onCancel={handleNewFloorClose}
        onOk={handleNewFloorSubmit}
        title={t("New_floor")}
        cancelText={t("Cancel")}
        okText={t("Add")}
        textFields={[
          {
            label: t("Name"),
            value: inputs.newFloor,
            handleChange: handleInputChange,
            name: "newFloor",
            errorMsg: inputs.errors.name,
          },
        ]}
        body={
          <>
            <div>
              <label className="form-control-label">{t("Rank_label")}</label>
              <input
                type="number"
                name="rank"
                value={inputs.rank ? inputs.rank : ""}
                onChange={handleInputChange}
                className="form-control"
              />
              <p className="text-danger">{inputs.errors.rank}</p>
            </div>
          </>
        }
      />
      <AlertModal
        isOpen={editDialogOpen}
        onCancel={handleEditClose}
        onOk={handleEditSubmit}
        title={t("Edit_floor")}
        cancelText={t("Cancel")}
        okText={t("Save")}
        textFields={[
          {
            label: t("Name"),
            value: inputs.editFloor,
            handleChange: handleInputChange,
            name: "editFloor",
            errorMsg: inputs.errors.name,
          },
        ]}
        body={
          <>
            <div>
              <label className="form-control-label">{t("Rank_label")}</label>
              <input
                type="number"
                name="rank"
                value={inputs.rank ? inputs.rank : ""}
                onChange={handleInputChange}
                className="form-control"
              />
              <p className="text-danger">{inputs.errors.rank}</p>
            </div>
          </>
        }
      />
      <AlertModal
        isOpen={deleteDialogOpen}
        onCancel={handleDeleteClose}
        onOk={handleDeleteSubmit}
        title={`${t("Delete_floor_confirmation")} "${rowClicked.name}"?`}
        okText={t("Delete")}
        cancelText={t("Cancel")}
      />
    </>
  );
}

export default Floors;
