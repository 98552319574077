import React from "react";
import DefaultLayout from "templates/DefaultLayout";
import useProducts from "./useProducts";
import CrudTable from "components/Tables/CrudTable";
import { Button, Col, Input, Row } from "reactstrap";
import AlertModal from "components/Modals/AlertModal";
import { formatCurrency } from "utilities/utils";
import { Views } from "utilities/enums/Views";
import { useTranslation } from "react-i18next";
import EditProduct from "./EditProduct";
import NewProduct from "./NewProduct";

function Products() {
  const {
    deleteProductDialogOpen,
    products,
    isLoading,
    isError,
    filterCategory,
    categories,
    selectedProduct,
    handleDeleteProductClose,
    handleDeleteProductSubmit,
    handleEditProductClick,
    handleDeleteProductClick,
    handleFilterCategoryChange,
    editProductParams,
    handleEditProductClose,
    newProductOpen,
    setNewProductOpen,
  } = useProducts();
  const { t } = useTranslation();

  const tableCols = [
    { label: t("id"), hidden: true },
    { label: t("Rank") },
    { label: t("Name") },
    { label: t("status"), slug: "active-label" },
    { label: t("Description"), hidden: true },
    {
      label: t("Image"),
      hidden: true,
      formatter: (cell) =>
        cell !== null ? (
          <img
            src={`${cell.attributes.url}`}
            alt="product"
            className="rounded-circle image-preview"
            style={{ width: "50px", height: "50px" }}
          />
        ) : null,
    },
    { label: t("price"), formatter: formatCurrency, mobileVisible: false },
    { label: "vat_category", hidden: true },
    { label: t("Category"), hidden: true },
    { label: "variants", hidden: true },
    { label: "Inventory Management", formatter: (cell) => (cell ? "Yes" : "No"), hidden: true },
    { label: t("Quantity"), mobileVisible: false },
  ];

  let header = (
    <Row>
      <Col xs="8">
        <h3 className="mb-0">{t(Views.PRODUCTS)}</h3>
      </Col>
      <Col xs="12" sm="4" className="d-flex mt-2 mt-sm-0 justify-content-between justify-content-sm-end">
        <Input
          type="select"
          name="category"
          id="category"
          onChange={handleFilterCategoryChange}
          value={filterCategory}
          bsSize="sm"
          className="col-6 mr-4"
        >
          <option value="">{t("All_fem")}</option>
          {categories &&
            categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
        </Input>

        <Button
          color="primary"
          size="sm"
          onClick={() => {
            setNewProductOpen(true);
          }}
          id="addProduct"
        >
          <span className="btn-inner--icon mr-1">
            <i className="fas fa-user-edit" />
          </span>
          <span className="btn-inner--text">{t("Add")}</span>
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      <DefaultLayout
        name="Products"
        parentName={Views.MENU_MANAGEMENT}
        isLoading={isLoading}
        isError={isError}
        header={header}
      >
        {products?.length > 0 ? (
          <CrudTable columns={tableCols} data={products} pageSize={7} onEdit={handleEditProductClick} />
        ) : (
          <div className="text-center">{t("No_products_found")}</div>
        )}
      </DefaultLayout>

      <EditProduct
        isOpen={editProductParams.isOpen}
        productId={editProductParams.productId}
        onCancel={handleEditProductClose}
      />

      <NewProduct isOpen={newProductOpen} onCancel={() => setNewProductOpen(false)} />

      <AlertModal
        isOpen={deleteProductDialogOpen}
        title={`${t("Delete_product_msg")} "${selectedProduct.name}"?`}
        okText={t("Delete")}
        cancelText={t("Cancel")}
        onCancel={() => handleDeleteProductClose()}
        onOk={handleDeleteProductSubmit}
      />
    </>
  );
}

export default Products;
