import { TagTypes } from "utilities/enums/TagTypes";
import { apiSlice } from "./apiSlice";
import { store } from "redux/store";

export const apiPrimer = apiSlice.injectEndpoints({
  tagTypes: [TagTypes.Primer],
  endpoints: (builder) => ({
    getPrimerData: builder.mutation({
      providesTags: [TagTypes.Primer],
      query: (vatNumber) => {
        return {
          url: `/company-info`,
          method: "POST",
          body: {
            vatNumber: vatNumber,
            organisation: {
              id: store.getState().auth.organisationId,
            },
          },
        };
      },
    }),
  }),
});

export const { useGetPrimerDataMutation } = apiPrimer;
