import React from "react";
import { Pie } from "react-chartjs-2";
import { formatCurrency } from "utilities/utils";

export const PieChart = ({ data }) => {
  const tooltipLabels = function (item, data) {
    var label = data.labels[item.index] || "";
    var yLabel = label
      ? label + " : " + formatCurrency(data.datasets[0].data[item.index])
      : "";
    var content = "";
    if (data.datasets.length > 1) {
      content += label;
    }
    content += yLabel;
    return content;
  };
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      // Change options for ALL labels of THIS CHART
      datalabels: {
        color: "#fff",
        formatter: (value, ctx) => {
          let percentage = (value * 100) / data.datasets[0].data.reduce((a, b) => a + b, 0);
          return percentage.toFixed(2) + "%";
        },
      },
    },
    tooltips: {
      callbacks: {
        label: tooltipLabels,
      },
    },
  };
  return <Pie data={data} options={chartOptions} id="chart-bars" className="chart-canvas" />;
};
