import { createSlice } from "@reduxjs/toolkit";
import { cartItemsEqual } from "utilities/utils";
const initialState = {
  cartItems: [],
  cartTable: { id: -1, attributes: { name: "" } },
  isOpen: false,
  discount: 0,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {
    toggleCartOpen: (state) => {
      state.isOpen = !state.isOpen;
    },
    addItem: (state, action) => {
      if (state.cartItems.find((item) => cartItemsEqual(item, action.payload))) {
        state.cartItems = state.cartItems.map((item) => {
          if (cartItemsEqual(item, action.payload)) {
            return {
              ...item,
              quantity: item.quantity + action.payload.quantity,
            };
          } else {
            return item;
          }
        });
      } else {
        state.cartItems = [
          ...state.cartItems,
          {
            ...action.payload,
            quantity: action.payload.quantity,
            variants: action.payload.variants,
            vat_category: action.payload.vat_category,
            notes: action.payload.notes,
          },
        ];
      }
    },
    editItem: (state, action) => {
      let oldCartItem = action.payload.oldCartItem;
      let newCartItem = action.payload.newCartItem;
      if (state.cartItems.find((item) => cartItemsEqual(item, oldCartItem))) {
        state.cartItems = state.cartItems.map((item) => {
          if (cartItemsEqual(item, oldCartItem)) {
            return {
              ...item,
              quantity: newCartItem.quantity,
              variants: newCartItem.variants,
              vat_category: newCartItem.vat_category,
              notes: newCartItem.notes,
              price: newCartItem.price,
            };
          } else {
            return item;
          }
        });
      }
    },
    decreaseCartQuantity: (state, action) => {
      state.cartItems = state.cartItems
        .map((item) => {
          if (cartItemsEqual(item, action.payload)) {
            return {
              ...item,
              quantity: item.quantity - 1,
              vat_category: action.payload.vat_category,
            };
          } else {
            return item;
          }
        })
        .filter((item) => item.quantity > 0);
    },
    increaseCartQuantity: (state, action) => {
      if (state.cartItems.find((item) => cartItemsEqual(item, action.payload))) {
        state.cartItems = state.cartItems
          .map((item) => {
            if (cartItemsEqual(item, action.payload)) {
              return {
                ...item,
                name: item.name,
                quantity: item.quantity + 1,
                vat_category: action.payload.vat_category,
              };
            } else {
              return item;
            }
          })
          .filter((item) => item.quantity > 0);
      } else {
        state.cartItems = [...state.cartItems, { ...action.payload, quantity: 1 }];
      }
    },
    removeFromCart: (state, action) => {
      state.cartItems = state.cartItems.filter((item) => !cartItemsEqual(item, action.payload));
    },
    clearCart: (state) => {
      state.cartItems = [];
      state.discount = 0; // reset discount when cart is cleared
    },
    setCartTable: (state, action) => {
      state.cartTable = action.payload;
    },
    setCartItems: (state, action) => {
      state.cartItems = action.payload;
    },
    setDiscount: (state, action) => {
      state.discount = action.payload;
    },
  },
});

export const {
  increaseCartQuantity,
  decreaseCartQuantity,
  toggleCartOpen,
  addItem,
  editItem,
  removeFromCart,
  clearCart,
  setCartTable,
  setDiscount,
} = cartSlice.actions;

export default cartSlice.reducer;

export const selectCartItems = (state) => state.cart.cartItems;
export const selectCartTable = (state) => state.cart.cartTable;
