import { useAddPosTerminalMutation } from "api/posTerminalSlice";
import React from "react";

const useNewPrintScenario = (props) => {
  const [inputs, setInputs] = React.useState({
    name: "",
    description: "",
    active: true,
    terminal_id: "",
    errors: {},
  });
  const [addPosTerminal, { isLoading: addPosTerminalLoading }] = useAddPosTerminalMutation();

  function clearInputs() {
    setInputs({
      name: "",
      description: "",
      active: false,
      terminal_id: "",
      errors: {},
    });
  }

  function setDescription(description) {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        description: description,
      };
    });
  }

  function handleInputChange(event) {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        [event.target.name]: event.target.value,
      };
    });
  }

  async function handleNewPosTerminalSubmit() {
    await addPosTerminal(inputs);
    clearInputs();
    props?.onCancel();
  }

  return {
    handleNewPosTerminalSubmit,
    inputs,
    setInputs,
    handleInputChange,
    setDescription,
    addPosTerminalLoading,
  };
};

export default useNewPrintScenario;
