import { TagTypes } from "utilities/enums/TagTypes";
import { apiSlice } from "./apiSlice";
import { store } from "redux/store";

export const organisationSlice = apiSlice.injectEndpoints({
  tagTypes: [TagTypes.Printers],
  endpoints: (builder) => ({
    getOrganisation: builder.query({
      providesTags: [TagTypes.Organisation],
      query: () => `/organisations/${store.getState().auth.organisationId}`,
      transformResponse: (responseData) => {
        return responseData.data;
      },
    }),
    addOrganisation: builder.mutation({
      invalidatesTags: [TagTypes.Printers],
      query: (organisation) => {
        return {
          url: `/organisations`,
          method: "POST",
          body: {
            data: {
              ...organisation,
            },
          },
        };
      },
    }),
    updateOrganisation: builder.mutation({
      invalidatesTags: [TagTypes.Organisation],
      query: (organisation) => {
        return {
          url: `/organisations/${organisation?.id}`,
          method: "PUT",
          body: {
            data: {
              ...organisation,
            },
          },
        };
      },
    }),
    saveSubscription: builder.mutation({
      query: (subscription) => {
        return {
          url: `/save-subscription`,
          method: "POST",
          body: subscription,
        };
      },
    }),
    sendNotification: builder.mutation({
      query: (notification) => {
        return {
          url: `/send-notification`,
          method: "POST",
          body: {
            ...notification,
          },
        };
      },
    }),
    checkSubscription: builder.query({
      query: (filter) => {
        return {
          url: `/check-subscription`,
          method: "POST",
          body: {
            filter: filter,
          },
        };
      },
    }),
    removeUserSubscriptions: builder.mutation({
      query: (userId) => {
        return {
          url: `/remove-subscription`,
          method: "POST",
          body: {
            userId,
          },
        };
      },
    }),
  }),
});

export const {
  useGetOrganisationQuery,
  useUpdateOrganisationMutation,
  useAddOrganisationMutation,
  useSaveSubscriptionMutation,
  useSendNotificationMutation,
  useCheckSubscriptionQuery,
  useRemoveUserSubscriptionsMutation,
} = organisationSlice;
