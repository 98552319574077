import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";
import { draggableCategoriesDraggingStyles } from "utilities/constants";

export const SortableCategories = ({
  categories,
  configuration,
  onDragEnd,
  group,
  groupIndex,
  handleSelectAllClicked,
  printerGroupCategoryClicked,
}) => {
  const { internal_receipt_printby_category_enabled } = useSelector((state) => state.settings);
  const { t } = useTranslation();
  return (
    <DragDropContext
      onDragEnd={(result) => {
        onDragEnd(result, configuration.id);
      }}
    >
      <Table className="group-categories-table">
        <thead>
          <tr className="hoverable" onClick={() => handleSelectAllClicked(configuration.id, group)}>
            <th>{t("Select_categories")}</th>
            <th className="d-flex justify-content-end">
              <div className="custom-control custom-checkbox">
                <input
                  className="custom-control-input"
                  id={`config-${configuration.id}-group-${groupIndex}-selectAll`}
                  type="checkbox"
                  onChange={() => {}}
                  checked={group.categories.length === categories.length}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`config-${configuration.id}-group-${groupIndex}-selectAll`}
                ></label>
              </div>
            </th>
          </tr>
        </thead>
        <Droppable droppableId={`config-${configuration.id}-group-${groupIndex}`}>
          {(droppableProvided) => (
            <tbody
              {...droppableProvided.droppableProps}
              ref={(ref) => {
                droppableProvided.innerRef(ref);
              }}
            >
              {configuration.categories_order.map((categoryId, i) => {
                const category = categories.find((cat) => cat.id === categoryId);
                if (!category) {
                  return null;
                }
                return (
                  <Draggable
                    draggableId={`${configuration.id}-${groupIndex}-${categoryId}`}
                    key={`${configuration.id}-${groupIndex}-${categoryId}`}
                    index={i}
                    isDragDisabled={!internal_receipt_printby_category_enabled}
                  >
                    {(provided, snapshot) => {
                      return (
                        <tr
                          ref={provided.innerRef}
                          isdragging={snapshot.isDragging.toString()}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            ...(snapshot.isDragging ? draggableCategoriesDraggingStyles : {}),
                          }}
                          key={category?.id}
                          onClick={(e) => {
                            printerGroupCategoryClicked(configuration.id, group, category);
                          }}
                        >
                          <td>{category.name}</td>
                          <td className="d-flex justify-content-end">
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                id={`config-${configuration.id}-group-${groupIndex}-category-${category?.id}`}
                                type="checkbox"
                                onChange={(e) => {}}
                                checked={group.categories.includes(category?.id)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`config-${configuration.id}-group-${groupIndex}-category-${category?.id}`}
                              ></label>
                            </div>
                          </td>
                        </tr>
                      );
                    }}
                  </Draggable>
                );
              })}
              {droppableProvided.placeholder}
            </tbody>
          )}
        </Droppable>
      </Table>
    </DragDropContext>
  );
};
