import { useEffect, useMemo, useReducer, useState } from "react";
import { OrderStatus } from "utilities/enums/OrderStatus";
import {
  useGetOrdersQuery,
  useUpdateOrderStatusMutation,
  apiOrders,
  useOrdersBulkUpdateMutation,
} from "api/ordersSlice";
import { broadCastOrdersChangeWS, subscribeToOrdersChangeWS } from "config/websocket";
import { TagTypes } from "utilities/enums/TagTypes";
import { useDispatch } from "react-redux";
import { safeToString } from "utilities/utils";
import { useGetTablesQuery } from "api/tablesSlice";
import { CANCELLATION_REASON_GIFT } from "utilities/constants";
import { useGetUsersQuery } from "api/usersSlice";
import { useSelector } from "react-redux";
import { selectCurrentUser, selectCurrentUserRules } from "auth/authSlice";
import ROUTES_OBJ from "utilities/enums/Routes";
import { selectCurrentOrganisation } from "auth/authSlice";
import usePayItems from "views/pages/tables/PayItems/usePayItems";
import { useLazyGetOrderQuery } from "api/ordersSlice";
import { useUpdateProductMutation } from "api/productsSlice";
import { generateUUID } from "utilities/utils";

const useOrdersTable = ({ pageSize }) => {
  const dispatch = useDispatch();
  const { data: tables } = useGetTablesQuery();
  const [currentOrdersPage, setCurrentOrdersPage] = useState(0);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const { username: user } = useSelector(selectCurrentUser);
  const [filterModel, setFilterModel] = useState({
    status: "",
    table: "",
    startDate: "",
    endDate: "",
    filters: "",
    user: "",
    invoice_select: "",
    order_type: "",
  });
  const user_rules = useSelector(selectCurrentUserRules);
  const discount_rule_enabled = user_rules.find(
    (rule) => rule.name === `Route:${ROUTES_OBJ.TABLE_ACTIONS_DISCOUNT.path}`
  )?.value;
  const { discounts_enabled, wholesale_enabled, helper_wholesale_receipts_enabled } = useSelector(
    (state) => state.settings
  );
  const organisationId = useSelector(selectCurrentOrganisation);

  const allow_discount = discounts_enabled && discount_rule_enabled;
  const [ordersBulkUpdate, { isLoading: bulkUpdateLoading }] = useOrdersBulkUpdateMutation();
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [canReturnInventory, setCanReturnInventory] = useState(false);
  const [tempFilterModel, setTempFilterModel] = useState(filterModel);
  const [cancellationModalOpen, setCancellationModalOpen] = useState(false);
  const [viewOrderModalOpen, setViewOrderModalOpen] = useState(false);
  const [giftModalOpen, setGiftModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const { data: users } = useGetUsersQuery();
  const { handleInventory, checkInventory } = usePayItems();
  const [getOrder] = useLazyGetOrderQuery();
  const [updateProduct] = useUpdateProductMutation();

  const {
    data: orders,
    isLoading,
    isError,
  } = useGetOrdersQuery({
    page: currentOrdersPage + 1,
    pageSize: pageSize,
    filters: filterModel.filters,
    sort: "createdAt:desc",
  });
  const [updateOrderStatus, { isLoading: updateOrderStatusLoading }] = useUpdateOrderStatusMutation();

  function refreshComponent() {
    dispatch(apiOrders.util.invalidateTags([TagTypes.Orders]));
    forceUpdate();
  }
  useEffect(() => {
    subscribeToOrdersChangeWS(organisationId, refreshComponent);
  });

  function handleViewOrder(currentRow) {
    setSelectedRow(currentRow);
    setViewOrderModalOpen(true);
  }

  async function handleCancelOrder(currentRow) {
    setSelectedRow(currentRow);
    const orderData = await getOrder(currentRow?.id);
    setCanReturnInventory(checkInventory(orderData?.data?.orderDetails));
    setCancellationModalOpen(true);
  }

  async function cancelOrder(reason = null, return_inventory = false) {
    const UUID = generateUUID(user, organisationId);
    // selectedRow has no details so we need to fetch the order details
    const orderData = await getOrder(selectedRow?.id);
    handleInventory(return_inventory, orderData?.data?.orderDetails, updateProduct);
    if (orderData?.data?.status === OrderStatus.Pending) {
      let data = {
        orders: [selectedRow?.id],
        resultStatus: OrderStatus.Cancelled,
        cancellationReason: reason,
        organisationId: organisationId,
        uuid: UUID,
      };
      ordersBulkUpdate(data).then(() => {
        broadCastOrdersChangeWS(organisationId);
        setCancellationModalOpen(false);
      });
    } else {
      updateOrderStatus({ orderId: selectedRow?.id, status: OrderStatus.Cancelled, cancellationReason: reason }).then(
        () => {
          broadCastOrdersChangeWS(organisationId);
          setCancellationModalOpen(false);
        }
      );
    }
  }

  function handleGiftOrder(currentRow) {
    setSelectedRow(currentRow);
    setGiftModalOpen(true);
  }

  async function giftOrder(notes = null) {
    const UUID = generateUUID(user, organisationId);
    const orderData = await getOrder(selectedRow?.id);

    if (orderData?.data?.status === OrderStatus.Pending) {
      let data = {
        orders: [selectedRow?.id],
        resultStatus: OrderStatus.Cancelled,
        cancellationReason: CANCELLATION_REASON_GIFT,
        organisationId: organisationId,
        uuid: UUID,
      };
      ordersBulkUpdate(data).then(() => {
        broadCastOrdersChangeWS(organisationId);
        setCancellationModalOpen(false);
      });
    } else {
      updateOrderStatus({
        orderId: selectedRow?.id,
        status: OrderStatus.Cancelled,
        cancellationReason: CANCELLATION_REASON_GIFT,
        notes: notes,
      }).then(() => {
        broadCastOrdersChangeWS(organisationId);
        setGiftModalOpen(false);
      });
    }
  }

  function isOrderPending(order) {
    return order.status.toLowerCase() === OrderStatus.Pending.toLowerCase();
  }

  function handlePagination(currectPage) {
    setCurrentOrdersPage(currectPage);
  }

  function handleFiltersModalOpen() {
    setTempFilterModel(filterModel);
    setFiltersModalOpen(true);
  }
  const getInvoiceTypeFilter = (invoice_select) => {
    switch (invoice_select) {
      case "Without_Invoice":
        return `&filters[mydata_status][$ne]=sent&filters[details][parent_invoice][id][$null]=true`;
      case "retail":
        return `&filters[invoice_type][$eq]=retail&filters[mydata_status][$eq]=sent&filters[mydata_gr_cancellation_mark][$null]=true`;
      case "opentable":
        return `&filters[invoice_type][$eq]=opentable&filters[mydata_status][$eq]=sent&filters[mydata_gr_cancellation_mark][$null]=true`;
      case "wholesale":
        return `&filters[invoice_type][$eq]=wholesale&filters[mydata_status][$eq]=sent`;
      case "helperWholesale":
        return `&filters[invoice_type][$eq]=helperWholesale`;
      case "cancelled_invoice":
        return `&filters[mydata_gr_cancellation_mark][$ne]=null`;
      case "error_invoice":
        return `&filters[mydata_status][$eq]=sent_with_error`;
      default:
        return "";
    }
  };
  const handleReactDatetimeChange = (who, date) => {
    if (
      tempFilterModel.startDate &&
      who === "endDate" &&
      new Date(safeToString(tempFilterModel.startDate._d)) > new Date(safeToString(date._d))
    ) {
      setTempFilterModel({
        ...filterModel,
        startDate: date,
        endDate: date,
        filters: getFilters({ ...tempFilterModel, startDate: date, endDate: date }),
      });
    } else if (
      tempFilterModel.endDate &&
      who === "startDate" &&
      new Date(safeToString(tempFilterModel.endDate._d)) < new Date(safeToString(date._d))
    ) {
      setTempFilterModel({
        ...tempFilterModel,
        startDate: date,
        endDate: date,
        filters: getFilters({ ...tempFilterModel, startDate: date, endDate: date }),
      });
    } else {
      setTempFilterModel({ ...tempFilterModel, [who]: date, filters: getFilters({ ...tempFilterModel, [who]: date }) });
    }
  };

  const getStatusFilter = (status) => {
    if (status === "") return "";
    if (status === "discounted") return `&filters[discount][$gt]=0&filters[status][$eq]=${OrderStatus.Payed}`;
    if (status === "gifted")
      return `&filters[$and][0][cancellation_reason][$eq]=${CANCELLATION_REASON_GIFT}&filters[$and][1][status][$eq]=${OrderStatus.Cancelled}`;
    if (status === OrderStatus.Cancelled) return `&filters[$and][0][status][$eq]=${OrderStatus.Cancelled}`;
    else return `&filters[status][$eq]=${status}`;
  };

  const getOrderTypeFilter = (order_type) => {
    if (order_type === "") return "";
    return `&filters[external_source][$eq]=${order_type}`;
  };

  const getFilters = (filterObj) => {
    let filters = "";
    filters += getOrderTypeFilter(filterObj.order_type);
    filters += getStatusFilter(filterObj.status);
    filters += getInvoiceTypeFilter(filterObj.invoice_select);
    filters += filterObj.user === "" ? "" : `&filters[submitted_by][$eq]=${filterObj.user}`;
    filters += filterObj.table === "" ? "" : `&filters[table][name][$eq]=${filterObj.table}`;
    filters +=
      filterObj.startDate === "" ? "" : `&filters[createdAt][$gte]=${filterObj.startDate.startOf("day").toISOString()}`;
    filters +=
      filterObj.endDate === "" ? "" : `&filters[createdAt][$lte]=${filterObj.endDate.endOf("day").toISOString()}`;
    return filters;
  };

  function resetFilterModel() {
    setTempFilterModel({
      status: "",
      startDate: "",
      endDate: "",
      filters: "",
      user: "",
      table: "",
      invoice_select: "",
      order_type: "",
    });
  }

  function saveFilters() {
    setCurrentOrdersPage(0); // Reset table's pagination to be at page 1
    setFilterModel(tempFilterModel);
    setFiltersModalOpen(false);
  }

  function removeFilterTag(filter) {
    switch (filter) {
      case "dates":
        setFilterModel({
          ...filterModel,
          startDate: "",
          endDate: "",
          filters: getFilters({ ...filterModel, startDate: "", endDate: "" }),
        });
        break;
      case "status":
        setFilterModel({
          ...filterModel,
          status: "",
          filters: getFilters({ ...filterModel, status: "" }),
        });
        break;
      case "table":
        setFilterModel({
          ...filterModel,
          table: "",
          filters: getFilters({ ...filterModel, table: "" }),
        });
        break;
      case "user":
        setFilterModel({
          ...filterModel,
          user: "",
          filters: getFilters({ ...filterModel, user: "" }),
        });
        break;
      case "invoice_select":
        setFilterModel({
          ...filterModel,
          invoice_select: "",
          filters: getFilters({ ...filterModel, invoice_select: "" }),
        });
        break;
      case "order_type":
        setFilterModel({
          ...filterModel,
          order_type: "",
          filters: getFilters({ ...filterModel, order_type: "" }),
        });
        break;
      default:
        break;
    }
    setCurrentOrdersPage(0); // When a filter is removed reset table's pagination to be at page 1
  }

  return {
    currentOrdersPage,
    handlePagination,
    orders,
    users,
    isLoading,
    isError,
    handleViewOrder,
    handleCancelOrder,
    isOrderPending,
    setCurrentOrdersPage,
    filterModel,
    setFilterModel,
    handleFiltersModalOpen,
    handleReactDatetimeChange,
    resetFilterModel,
    saveFilters,
    removeFilterTag,
    tempFilterModel,
    setTempFilterModel,
    filtersModalOpen,
    setFiltersModalOpen,
    getFilters,
    tables,
    cancellationModalOpen,
    setCancellationModalOpen,
    giftModalOpen,
    setGiftModalOpen,
    selectedRow,
    setSelectedRow,
    cancelOrder,
    updateOrderStatusLoading,
    handleGiftOrder,
    giftOrder,
    viewOrderModalOpen,
    setViewOrderModalOpen,
    allow_discount,
    canReturnInventory,
    wholesale_enabled,
    helper_wholesale_receipts_enabled,
    bulkUpdateLoading,
  };
};

export default useOrdersTable;
