import React from "react";
import classnames from "classnames";

import { Card, CardHeader, CardBody, Row, Col, Button, Input } from "reactstrap";
import { LineChart } from "./LineChart";
import { BarChart } from "./BarChart";
import { KeynameToTitle, KeynameToSubtitle } from "utilities/enums/Analytics";
import AlertModal from "./Modals/AlertModal";
import { DEFAULT_CHARTS_SCHEMA, DEFAULT_UI_SCHEMA } from "utilities/analytics/charts";
import JSForm from "./JSForm/JSForm";
import { useUpdateCustomChartMutation, useUpdateChartByIdMutation } from "api/analyticsSlice";
import { useTranslation } from "react-i18next";
import { logError } from "utilities/utils";

export const CardChart = ({ chartData, chartNav, chartType, key_name, chartFormData, chartId, prevSelectedChart }) => {
  const { t } = useTranslation();
  const title = t(KeynameToTitle[key_name]);
  const subtitle = t(KeynameToSubtitle[key_name]);
  const [selectedChart, setSelectedChart] = React.useState(prevSelectedChart || 0);
  const [editModal, setEditModal] = React.useState(false);
  const [updateCustomChart, { isSuccess, isError }] = useUpdateCustomChartMutation();
  const [updateChartById, { isSuccess: isUpdateSuccess, isError: isUpdateError }] = useUpdateChartByIdMutation();
  const [currentChart, setCurrentChart] = React.useState();
  const [currentChartNav, setCurrentChartNav] = React.useState();
  const [formData, setFormData] = React.useState();
  const chartDataRef = React.useRef(chartData);
  const chartNavRef = React.useRef(chartNav);
  const formDataRef = React.useRef(chartFormData);
  React.useEffect(() => {
    formDataRef.current = chartFormData;
    setFormData(formDataRef.current);
  }, [chartFormData]);
  React.useEffect(() => {
    chartNavRef.current = chartNav;
    setCurrentChartNav(chartNavRef.current);

    if (prevSelectedChart >= chartNav.length) {
      setSelectedChart(0); // Update selectedChart to zero
      updateChartById({ chartId: chartId, body: { selectedChart: 0 } }); // reset selectedChart to zero
    } else {
      setSelectedChart(prevSelectedChart || 0);
    }
  }, [chartId, chartNav, prevSelectedChart]);
  React.useEffect(() => {
    chartDataRef.current = chartData;
    setCurrentChart(chartDataRef.current);
  }, [chartData]);
  return (
    <>
      <Card className={chartType === "line" ? "bg-default position-relative" : "position-relative"}>
        <CardHeader className="bg-transparent">
          <Row className="align-items-center">
            <Col xs="8" md="6">
              <h6 className="text-light text-uppercase ls-1 mb-1">{subtitle}</h6>
              <h5 className={chartType === "line" ? "h3 text-white mb-0" : "h3 mb-0"}>{title}</h5>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <div className="chart">
            {(currentChart &&
              currentChart[selectedChart] &&
              JSON.parse(JSON.stringify(currentChart[selectedChart])) &&
              {
                line: <LineChart data={JSON.parse(JSON.stringify(currentChart[selectedChart]))} />,
                bar: <BarChart data={JSON.parse(JSON.stringify(currentChart[selectedChart]))} />,
              }[chartType]) || <div className="text-center">No Data</div>}
          </div>
        </CardBody>
      </Card>
      <AlertModal
        title={t("Edit_Charts")}
        isOpen={editModal}
        cancelText={t("Cancel")}
        okText={t("Save")}
        onCancel={() => setEditModal(false)}
        onOk={async () => {
          const updatedChart = await updateCustomChart({ chart_key: key_name, formData: formData });
          if (isSuccess) {
            setCurrentChart(updatedChart?.data?.chartData?.data || [{}]);
            setCurrentChartNav(updatedChart?.data?.chartData?.chartNav || [{}]);
            setEditModal(false);
          } else if (isError) {
            logError(updatedChart.error);
            setEditModal(false);
          } else {
            setEditModal(false);
          }
        }}
        body={
          <JSForm
            schema={DEFAULT_CHARTS_SCHEMA}
            uiSchema={DEFAULT_UI_SCHEMA}
            formDataObj={formData}
            onChange={(newformData) => {
              setFormData(newformData);
            }}
            chartNav={chartNav}
          />
        }
      />
    </>
  );
};
