import { axiosDelete, get, post, put } from "api/ApiCallerHelper.core";
import { REACT_APP_BACKEND_URL, REACT_APP_DITOPW_URL } from "utilities/constants";
import { store } from "redux/store";

export function getTablesData() {
  const url = `${REACT_APP_BACKEND_URL}/tables`;
  return get(url);
}

export function getLocalPrinters() {
  const url = `${REACT_APP_DITOPW_URL}/printers`;
  return get(url);
}

export function printOrderLocal(ip) {
  const url = `${REACT_APP_DITOPW_URL}/print`;
  const data = {
    printerIp: ip,
    orderData: {
      id: "1234",
    },
  };

  post(url, data);
}

export function printOrder(data) {
  const url = `${process.env.REACT_APP_PRINTNODE_URL}/printjobs`;
  post(url, data, {
    auth: {
      username: process.env.REACT_APP_PRINTNODE_USERNAME,
      password: process.env.REACT_APP_PRINTNODE_PASSWORD,
    },
  });
}

export function getMenu() {
  const url = `${REACT_APP_BACKEND_URL}/categories?populate[0]=category&populate[1]=products.variants.variant_options&populate[2]=products.category`;
  return get(url).then((res) => {
    return {
      categories: res.data
        .filter((category) => category.attributes.category.data === null)
        .map((category) => {
          return {
            id: category.id,
            name: category.attributes.name,
          };
        }),
      subcategories: res.data
        .filter((category) => category.attributes.category.data !== null)
        .map((category) => {
          return {
            id: category.id,
            name: category.attributes.name,
            parent_category: category.attributes.category.data.attributes.name,
          };
        }),
      products: res.data
        .filter((category) => category.attributes.products.data.length > 0)
        .flatMap((category) =>
          category.attributes.products.data.map((product) => {
            return {
              id: product.id,
              name: product.attributes.name,
              price: product.attributes.price,
              description: product.attributes.description,
              parent_category: product.attributes.category.data.attributes.name,
              variants: product.attributes.variants,
            };
          })
        ),
    };
  });
}

export async function getMenuCategories() {
  const url = `${REACT_APP_BACKEND_URL}/categories?populate=*`;
  return get(url).then((res) => {
    return res.data
      .filter((category) => category.attributes.category.data === null)
      .map((category) => {
        return {
          id: category.id,
          name: category.attributes.name,
        };
      });
  });
}

export function addCategory(category) {
  const url = `${REACT_APP_BACKEND_URL}/categories`;
  const data = {
    data: {
      name: category.name,
    },
  };

  post(url, data);
}

export function updateCategory(id, name) {
  const url = `${REACT_APP_BACKEND_URL}/categories/${id}`;
  const data = {
    data: {
      name: name,
    },
  };

  put(url, data);
}

export function deleteCategory(id) {
  const url = `${REACT_APP_BACKEND_URL}/categories/${id}`;
  axiosDelete(url);
}

export async function getMenuSubCategories(category) {
  const url =
    category !== ""
      ? `${REACT_APP_BACKEND_URL}/categories?populate=*&filters[category][id][$eq]=${category}`
      : `${REACT_APP_BACKEND_URL}/categories?populate=*`;
  return get(url).then((res) => {
    return res.data
      .filter((category) => category.attributes.category.data !== null)
      .map((category) => {
        return {
          id: category.id,
          name: category.attributes.name,
          parent_category: category.attributes.category.data.attributes.name,
        };
      });
  });
}

export function checkSubCategoryExists(category, name) {
  const url = `${REACT_APP_BACKEND_URL}/categories?filters[name][$eq]=${name}&filters[category][id][$eq]=${category}`;
  return get(url).then((res) => {
    return res.data !== null && res.data.length > 0;
  });
}

export function checkSubCategoryExistsUpdate(category, name, id) {
  const url = `${REACT_APP_BACKEND_URL}/categories?filters[name][$eq]=${name}&filters[category][id][$eq]=${category}&filters[id][$ne]=${id}`;
  return get(url).then((res) => {
    return res.data !== null && res.data?.length > 0;
  });
}

export function deleteSubCategoryApi(id) {
  const url = `${REACT_APP_BACKEND_URL}/categories/${id}`;
  axiosDelete(url);
}

export function addSubCategoryApi(subcategory) {
  const url = `${REACT_APP_BACKEND_URL}/categories`;
  const data = {
    data: {
      name: subcategory.name,
      category: subcategory.parent_category.id,
    },
  };

  return post(url, data).then((res) => {
    return res.data;
  });
}

export function updateSubCategoryApi(id, name, parent_category) {
  const url = `${REACT_APP_BACKEND_URL}/categories/${id}`;
  const data = {
    data: {
      name: name,
      category: parent_category,
    },
  };

  return put(url, data).then((res) => {
    return res.data;
  });
}

export async function getMenuProducts() {
  const url = `${REACT_APP_BACKEND_URL}/products?populate[0]=variants.variant_options&populate[1]=category`;
  return get(url).then((res) => {
    return res.data.map((product) => {
      return {
        id: product.id,
        name: product.attributes.name,
        price: product.attributes.price,
        description: product.attributes.description,
        parent_category: product.attributes.category.data.attributes.name,
        variants: product.attributes.variants,
      };
    });
  });
}

export async function getOrganisation() {
  const url = `${REACT_APP_BACKEND_URL}/organisations/${store.getState().auth.organisationId}`;
  return get(url).then((res) => {
    return res?.data;
  });
}

export async function refreshToken(refreshToken) {
  const url = `${REACT_APP_BACKEND_URL}/token/refresh`;
  const options = {
    withCredentials: true,
  };
  return post(
    url,
    {
      refreshToken: refreshToken,
    },
    options
  );
}
