import React from "react";
import { Button, Col, Row } from "reactstrap";
import DefaultLayout from "templates/DefaultLayout";
import { Views } from "utilities/enums/Views";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import CrudTable from "components/Tables/CrudTable";
import AlertModal from "components/Modals/AlertModal";
import usePosTerminals from "./usePosTerminals";
import NewPosTerminal from "./NewPosTerminal/NewPosTerminal";
import EditPosTerminal from "./EditPosTerminal/EditPosTerminal";
export const PosTerminals = () => {
  const {
    newPosTerminalDialogOpen,
    setNewPosTerminalDialogOpen,
    handleEditCategoryClick,
    deleteDialogOpen,
    setDeleteDialogOpen,
    handleDeleteDialogOpen,
    handleDeletePosTerminal,
    rowClicked,
    posTerminalsLoading,
    posTerminalsError,
    posTerminals,
    selectedPosTerminal,
    handleScenarioClick,
    editPosTerminalDialogOpen,
    setEditPosTerminalDialogOpen,
    deletePosTerminalLoading,
    handleUpdateToggleScenario,
    handleNewPosTerminalSubmit,
    addPosTerminalLoading,
  } = usePosTerminals();
  const { t } = useTranslation();

  const tableCols = [
    { label: t("ID"), hidden: true },
    { label: "terminal_id" },
    { label: t("Name") },
    { label: t("status"), slug: "active-label" },
    { label: t("Actions") },
  ];

  let header = (
    <Row>
      <Col xs="6">
        <h3 className="mb-0">{t(Views.POS_MANAGEMENT)}</h3>
      </Col>
      <Col className="text-right" xs="6">
        <Button
          className="btn-round btn-icon"
          color="primary"
          id="add-btn"
          onClick={() => setNewPosTerminalDialogOpen(true)}
          size="sm"
        >
          <span className="btn-inner--icon mr-1">
            <i className="fas fa-user-edit" />
          </span>
          <span className="btn-inner--text">{t("Add")}</span>
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      <DefaultLayout
        name={Views.PRINT_SCENARIOS}
        parentName={Views.SETTINGS}
        isLoading={posTerminalsLoading}
        isError={posTerminalsError}
        header={header}
      >
        {posTerminals && posTerminals.length === 0 && <h3>{t("No_pos_terminals_msg")}</h3>}
        {posTerminals?.length && (
          <div className="print-scenarios-table-container">
            <CrudTable
              header={header}
              columns={tableCols}
              onEdit={handleScenarioClick}
              data={posTerminals.map((scenario) => {
                return {
                  id: scenario.id,
                  terminal_id: scenario.attributes.terminal_id,
                  name: scenario.attributes.name,
                  active: scenario.attributes.active,
                };
              })}
              totalData={posTerminals.length || 0}
              pageSize={7}
              onDelete={handleDeleteDialogOpen}
            />
          </div>
        )}
      </DefaultLayout>
      <NewPosTerminal isOpen={newPosTerminalDialogOpen} onCancel={() => setNewPosTerminalDialogOpen(false)} />
      {selectedPosTerminal && (
        <EditPosTerminal
          isOpen={editPosTerminalDialogOpen}
          posTerminalId={selectedPosTerminal?.id}
          onCancel={() => setEditPosTerminalDialogOpen(false)}
        />
      )}

      <AlertModal
        isOpen={deleteDialogOpen}
        title={`${t("Delete_scenario_msg")} "${selectedPosTerminal?.name}"?`}
        okText={t("Delete")}
        cancelText={t("Cancel")}
        onCancel={() => setDeleteDialogOpen(false)}
        onOk={handleDeletePosTerminal}
        okDisabled={deletePosTerminalLoading}
      />
    </>
  );
};
