import { useEffect, useReducer, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { useDispatch } from "react-redux";
import { triggerNotification, urlBase64ToUint8Array } from "utilities/utils";
import { useGetUsersQuery } from "api/usersSlice";
import { selectCurrentUser } from "auth/authSlice";
import { useSendNotificationMutation, useSaveSubscriptionMutation } from "api/organisationSlice";
import { setIsSubscribed } from "redux/generic/generic";
import { apiUsers } from "api/usersSlice";
import { TagTypes } from "utilities/enums/TagTypes";
import { subscribeToUserChangeWS } from "config/websocket";
import { WEB_PUSH_PUBLIC_KEY } from "utilities/constants";
import ROUTES_OBJ from "utilities/enums/Routes";

export const PushNotification = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data: allusers, isError, isLoading } = useGetUsersQuery();
  const [sendNotification, { isLoading: isSending }] = useSendNotificationMutation();
  const [saveSubscription, { isLoading: isSaving }] = useSaveSubscriptionMutation();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const currentuser = useSelector(selectCurrentUser);
  const { user_notifications_enabled } = useSelector((state) => state.settings);
  const isSubscribed = useSelector((state) => state.genericState.isSubscribed);
  const notificationAlertRef = useRef(null);
  const organisationId = useSelector((state) => state.auth.organisationId);
  let allowedUsers = allusers?.filter(
    (user) => user.user_role === "staff" && user.subscriptions?.length > 0 && user.id !== currentuser.id
  );
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedUsers(allowedUsers.map((user) => user.id));
    } else {
      setSelectedUsers([]);
    }
  };
  const handleUserSelect = (event, user) => {
    event.preventDefault();
    if (selectedUsers.includes(user.id)) {
      setSelectedUsers(selectedUsers.filter((selectedUser) => selectedUser !== user.id));
    } else {
      setSelectedUsers([...selectedUsers, user.id]);
    }
  };

  const sendNotificationCallback = async (e) => {
    e.preventDefault();
    await sendNotification({
      filter: {
        users: selectedUsers,
      },
      title: t("Ping_From"),
      body: `${t("Ping_From")} ${currentuser?.username}`,
    });
    triggerNotification(notificationAlertRef, "success", t("Notification"), t("Notification_SENT_SUCCESSFULLY"));
  };

  const handleSubscriptionChange = async (e) => {
    e.preventDefault();
    if ("serviceWorker" in navigator && "PushManager" in window) {
      const registration = await navigator.serviceWorker.getRegistration();
      const permission = await Notification.requestPermission();
      if (!registration || permission !== "granted") {
        triggerNotification(
          notificationAlertRef,
          "danger",
          t("Notification"),
          <>
            {t("Notification_permission_not_granted")}{" "}
            <a className="text-neutral" href={ROUTES_OBJ.GUIDES.path}>
              {t("guides_to_enable_notifs")}
            </a>
          </>
        );
        return;
      }
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(WEB_PUSH_PUBLIC_KEY),
      });
      const savedSubscription = await saveSubscription({
        ...subscription.toJSON(),
        user: currentuser.id,
        org: organisationId,
      });
      dispatch(setIsSubscribed(true));
    }
  };

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  function refreshComponent() {
    dispatch(apiUsers.util.invalidateTags([TagTypes.Users]));
    forceUpdate();
  }
  useEffect(() => {
    subscribeToUserChangeWS(organisationId, refreshComponent);
  });

  return (
    <>
      {user_notifications_enabled && isSubscribed && (
        <>
          <UncontrolledDropdown nav>
            <DropdownToggle className="nav-link mr-2 hoverable" color="" tag="a">
              <i className="ni ni-bell-55 text-lg mt-1 text-neutral" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-xl py-0 overflow-hidden" right>
              <div className="px-3 py-3">
                <h6 className="text-md text-muted m-0">{t("SELECT_USER_NOTIFY")}</h6>
              </div>

              <ListGroup flush>
                <ListGroupItem key={`all`}>
                  <div className="custom-control custom-checkbox">
                    <input className="custom-control-input" type="checkbox" id="selectAll" onChange={handleSelectAll} />
                    <label className="custom-control-label" htmlFor="selectAll">
                      {t("SELECT_ALL")}
                    </label>
                  </div>
                </ListGroupItem>
                {isLoading && <ListGroupItem>Loading...</ListGroupItem>}
                {isError && <ListGroupItem>Error fetching users</ListGroupItem>}
                {allowedUsers?.map((user) => (
                  <ListGroupItem
                    className="list-group-item-action"
                    href="#pablo"
                    onClick={(e) => handleUserSelect(e, user)}
                    tag="a"
                    key={user.id}
                  >
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <div className="custom-control custom-checkbox d-inline">
                          <input
                            className="custom-control-input z-index--1"
                            type="checkbox"
                            name={`user-${user.username}`}
                            id={`user-${user.username}`}
                            checked={selectedUsers.includes(user.id)}
                            onChange={(e) => {
                              e.stopPropagation();
                            }}
                          />
                          <label className="custom-control-label" htmlFor={`user-${user.username}`}>
                            <div className="d-flex justify-content-between align-items-center">
                              <h4 className="mb-0 text-sm">{user.username}</h4>
                            </div>
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </ListGroupItem>
                ))}
              </ListGroup>

              <DropdownItem
                className="text-center text-info font-weight-bold py-3 text-white bg-primary"
                disabled={isSending || selectedUsers.length === 0}
                onClick={async (e) => {
                  await sendNotificationCallback(e);
                }}
              >
                {t("Notify")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      )}

      {user_notifications_enabled && !isSubscribed && (
        <Button
          className="btn-icon btn-2 bg-neutral text-dark"
          type="button"
          onClick={async (e) => {
            await handleSubscriptionChange(e);
          }}
        >
          <span className="btn-inner--icon">
            <i className="ni ni-bell-55" />
          </span>
          <span className="btn-inner--text">{t("Subscribe")}</span>
        </Button>
      )}

      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
    </>
  );
};
