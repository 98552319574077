import { TagTypes } from "utilities/enums/TagTypes";
import { apiSlice } from "./apiSlice";
import { store } from "redux/store";
import { sortByRankAsc } from "utilities/utils";

export const apiCategories = apiSlice.injectEndpoints({
  tagTypes: [TagTypes.Categories],
  endpoints: (builder) => ({
    getAllCategories: builder.query({
      query: () =>
        `categories?populate=*&pagination[limit]=100&filters[category][id][$null]=true&filters[organisation][id]=${
          store.getState().auth.organisationId
        }`,
      providesTags: [TagTypes.Categories],
      transformResponse: (responseData) => {
        let categories = responseData.data.map((category) => {
          const hasSubcategories = category.attributes.categories.data.length > 0;
          return {
            id: category.id,
            name: category.attributes.name,
            photo: category.attributes.photo?.data,
            categories: category.attributes.categories.data,
            hasSubcategories: hasSubcategories,
            rank: category.attributes.rank,
          };
        });

        return sortByRankAsc(categories);
      },
    }),
    getCategories: builder.query({
      query: () =>
        `categories?populate=*&pagination[limit]=100&filters[category][id][$null]=true&filters[$or][0][categories][id][$ne]=null&filters[$or][1][products][id][$ne]=null&filters[organisation][id]=${
          store.getState().auth.organisationId
        }`,
      providesTags: [TagTypes.Categories],
      transformResponse: (responseData) => {
        return responseData.data.map((category) => {
          const hasSubcategories = category.attributes.categories.data.length > 0;
          let categories = {
            id: category.id,
            name: category.attributes.name,
            photo: category.attributes.photo?.data,
            hasSubcategories: hasSubcategories,
            rank: category.attributes.rank,
          };

          return sortByRankAsc(categories);
        });
      },
    }),
    getCategoriesWithProducts: builder.query({
      query: () =>
        `categories-with-products?populate=*&pagination[limit]=100&filters[organisation][id]=${
          store.getState().auth.organisationId
        }`,
      providesTags: [TagTypes.Categories],
      transformResponse: (responseData) => {
        return responseData.map((category) => {
          return {
            id: category.id,
            name: category.name,
          };
        });
      },
    }),
    getCategory: builder.query({
      query: (id) => `categories/${id}?populate=*&filters[organisation][id]=${store.getState().auth.organisationId}`,
      providesTags: [TagTypes.Categories],
      transformResponse: (responseData) => {
        return {
          id: responseData.data.id,
          name: responseData.data.attributes.name,
          photo: responseData.data.attributes.photo?.data,
          categories: responseData.data.attributes.categories.data,
          rank: responseData.data.attributes.rank,
        };
      },
    }),
    addCategory: builder.mutation({
      query: (category) => ({
        url: "/categories",
        method: "POST",
        body: {
          data: {
            name: category.name,
            photo: category.photo,
            organisation: store.getState().auth.organisationId,
            rank: category.rank,
          },
        },
      }),
      invalidatesTags: [TagTypes.Categories],
    }),
    updateCategory: builder.mutation({
      query: (category) => ({
        url: `/categories/${category.id}`,
        method: "PUT",
        body: {
          data: {
            name: category.name,
            photo: category.photo ? category.photo : null,
            categories: category.categories,
            organisation: store.getState().auth.organisationId,
            rank: category.rank,
          },
        },
      }),
      invalidatesTags: [TagTypes.Categories],
      transformResponse: (responseData) => {
        return responseData.data.data;
      },
    }),
    deleteCategory: builder.mutation({
      query: (id) => ({
        url: `/categories/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: [TagTypes.Categories],
    }),
  }),
});

export const {
  useGetAllCategoriesQuery,
  useGetCategoriesQuery,
  useGetCategoriesWithProductsQuery,
  useGetCategoryQuery,
  useAddCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = apiCategories;
