import React from "react";
import {
  useGetAllVariantsQuery,
  useGetOptionsForVariantQuery,
  useAddVariantMutation,
  useUpdateVariantMutation,
  useDeleteVariantMutation,
} from "api/variantsSlice";
import { t } from "i18next";
import { reoderArrayOnDrop } from "utilities/utils";
const useVariations = () => {
  const [inputs, setInputs] = React.useState({
    name: "",
    label: "",
    type: "or",
    enabled: true,
    options: [],
    availableOptions: [],
    errors: {},
  });
  const { data: variants, isLoading, isSuccess, isError } = useGetAllVariantsQuery();
  const { data: options } = useGetOptionsForVariantQuery();
  const [addVariant] = useAddVariantMutation();
  const [updateVariant] = useUpdateVariantMutation();
  const [deleteVariant] = useDeleteVariantMutation();
  const [selectedVariant, setSelectedVariant] = React.useState({});
  const [addVariantDialogOpen, setAddVariantDialogOpen] = React.useState(false);
  const [editVariantDialogOpen, setEditVariantDialogOpen] = React.useState(false);
  const [deleteVariantDialogOpen, setDeleteVariantDialogOpen] = React.useState(false);

  React.useEffect(() => {
    if (options) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          availableOptions: [...options].filter((option) => {
            return !inputs.options.includes(parseInt(option.id));
          }),
        };
      });
    }
  }, [options, inputs.options]);

  function clearInput() {
    setInputs({
      name: "",
      label: "",
      type: "or",
      enabled: true,
      options: [],
      availableOptions: [],
      errors: {},
    });
  }

  function handleAddVariantDialogOpen() {
    setAddVariantDialogOpen(true);
  }

  function handleAddVariantDialogClose() {
    setAddVariantDialogOpen(false);
  }

  function handleEditVariantDialogOpen(selectedRow) {
    setSelectedVariant(selectedRow);
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        name: selectedRow.name,
        label: selectedRow.label,
        type: selectedRow.type === "Single" ? "or" : "and",
        enabled: selectedRow.enabled === "Yes" ? true : false,
        options: selectedRow.options
          ? selectedRow.options.split(", ").map((option) => {
              return parseInt(options.filter((o) => o.name === option)[0].id);
            })
          : [], // this variation that has been added to this variation
        availableOptions: [...options].filter((option) => {
          return !selectedRow.options.split(", ").includes(option.name);
        }), // this not added to this variation yet
      };
    });
    setEditVariantDialogOpen(true);
  }
  function handleEditVariantDialogClose() {
    setEditVariantDialogOpen(false);
    clearInput();
  }

  function handleDeleteVariantDialogOpen(selectedRow) {
    setSelectedVariant(selectedRow);
    setDeleteVariantDialogOpen(true);
  }

  function handleDeleteVariantDialogClose() {
    setDeleteVariantDialogOpen(false);
  }

  function handleVariantSelect(variant) {
    setSelectedVariant(variant);
  }

  function handleInputChange(event) {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        [event.target.name]: event.target.value,
      };
    });
  }

  function handleAddVariantSubmit() {
    if (!validateFields()) {
      return;
    }

    let newVariant = {
      name: inputs.name,
      label: inputs.label,
      type: inputs.type,
      enabled: inputs.enabled,
      variant_options: inputs.options,
    };
    addVariant(newVariant);
    setAddVariantDialogOpen(false);
    clearInput();
  }

  function handleUpdateVariantSubmit() {
    if (!validateFields()) {
      return;
    }

    let updatedVariant = {
      id: selectedVariant.id,
      name: inputs.name,
      label: inputs.label,
      type: inputs.type,
      enabled: inputs.enabled,
      variant_options: inputs.options,
    };
    updateVariant(updatedVariant);
    setEditVariantDialogOpen(false);
    clearInput();
  }

  function handleDeleteVariantSubmit() {
    deleteVariant(selectedVariant);
    setDeleteVariantDialogOpen(false);
  }

  function validateFields() {
    let isValid = true;
    if (inputs.name.length === 0) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, name: t("Name_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      if (inputs.errors.name?.length > 0) {
        setInputs((prevInputs) => {
          return {
            ...prevInputs,
            errors: { ...prevInputs.errors, name: "" },
          };
        });
      }
    }

    if (inputs.label.length === 0) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, label: "The Label field cannot be empty" },
        };
      });
      isValid = false;
    } else {
      if (inputs.errors.label?.length > 0) {
        setInputs((prevInputs) => {
          return {
            ...prevInputs,
            errors: { ...prevInputs.errors, label: "" },
          };
        });
      }
    }

    return isValid;
  }
    const handleDragEnd = (result) => {
      if (!result.destination) return;
      const items = reoderArrayOnDrop(inputs.options, result);
      setInputs({ ...inputs, options: items });
    };

  return {
    variants,
    options,
    isLoading,
    isSuccess,
    isError,
    inputs,
    selectedVariant,
    addVariantDialogOpen,
    editVariantDialogOpen,
    deleteVariantDialogOpen,
    setInputs,
    handleAddVariantDialogOpen,
    handleAddVariantDialogClose,
    handleEditVariantDialogOpen,
    handleEditVariantDialogClose,
    handleDeleteVariantDialogOpen,
    handleDeleteVariantDialogClose,
    handleVariantSelect,
    handleInputChange,
    handleAddVariantSubmit,
    handleUpdateVariantSubmit,
    handleDeleteVariantSubmit,
    handleDragEnd,
  };
};

export default useVariations;
