import React from "react";
import { Col, Row } from "reactstrap";
import DefaultLayout from "templates/DefaultLayout";
import { Views } from "utilities/enums/Views";
import { usePrinterManagement } from "views/pages/settings/PrinterManagement/usePrinterManagement";
import ActionsTable from "components/Tables/ActionsTable";
import { useTranslation } from "react-i18next";

export const PrinterManagement = () => {
  const { printers, printersError, printersLoading, handleEditClick, activePrinters, entityError, entityLoading } =
    usePrinterManagement();
  const { t } = useTranslation();

  const tableCols = [
    { label: "id" },
    { label: t("Name") },
    { label: t("Description") },
    { label: t("status") },
    { label: t("Active") },
  ];
  const header = (
    <Row>
      <Col xs="12">
        <h3 className="mb-0">{t(Views.PRINTER_MANAGEMENT)}</h3>
      </Col>
    </Row>
  );

  return (
    <>
      <DefaultLayout
        name={Views.PRINTER_MANAGEMENT}
        parentName={Views.SETTINGS}
        isLoading={printersLoading || entityLoading}
        isError={printersError || entityError}
        header={header}
      >
        {printers && printers.length === 0 && <h3>No printers found</h3>}
        {printers?.length && (
          <ActionsTable
            header={header}
            columns={tableCols}
            onRowClick={handleEditClick}
            data={printers.map((printer) => {
              return {
                id: printer.id,
                name: printer.name,
                description: printer.description,
                state: printer.state,
                active: activePrinters?.find(
                  (activePrinter) => parseInt(activePrinter.attributes.printerId) === parseInt(printer.id)
                )
                  ? "Yes"
                  : "No",
              };
            })}
            totalData={printers.length || 0}
            pageSize={30}
            actions={[
              {
                label: t("Edit"),
                handler: handleEditClick,
                isActionVisible: () => true,
                isActionActive: () => true,
              },
            ]}
          />
        )}
      </DefaultLayout>
    </>
  );
};
