import React from "react";
import { useParams } from "react-router";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col } from "reactstrap";
import DefaultLayout from "templates/DefaultLayout";
import { useGetSubCategoriesQuery } from "api/subcategoriesSlice";
import NewOrderFooter from "components/Footers/NewOrderFooter";
import { Views } from "utilities/enums/Views";
import { useTranslation } from "react-i18next";
import { selectCartTable } from "redux/cart/cartSlice";
import { useSelector } from "react-redux";

export default function NewOrderSubCategories() {
  const { table, parentCategory } = useParams();
  const navigate = useNavigate();
  const {
    data: subCategories,
    isLoading,
    isError,
  } = useGetSubCategoriesQuery(parentCategory, {
    skip: !parentCategory,
  });
  const { t } = useTranslation();
  const cartTable = useSelector(selectCartTable);
  const location = useLocation();

  function handleSubCategoryClick(subcategory) {
    navigate(`/admin/table-actions/new-order/${table}/${subcategory.id}/products`, {
      state: { parentCategoryName: subcategory.name },
    });
  }

  let parentCategoryName = location.state?.parentCategoryName;
  let headerContent = parentCategoryName ? parentCategoryName : t(Views.SUBCATEGORIES);
  let header = <h3 className="mb-0">{headerContent}</h3>;
  return (
    <>
      <DefaultLayout
        name={t(Views.SUBCATEGORIES)}
        parentName={Views.NEW_ORDER}
        isLoading={isLoading}
        isError={isError}
        header={header}
        hasSideBar={true}
        hasBackButton={true}
        table={cartTable}
        classNames="cart-sidebar-enabled"
      >
        <Row className="row-shadow">
          {subCategories?.map((subcategory) => {
            return (
              <Col sm="6" md="4" lg="3" key={subcategory.id} onClick={() => handleSubCategoryClick(subcategory)}>
                <span className="subcategory-card-container btn text-center d-flex flex-wrap flex-md-row justify-content-center align-items-center p-1">
                  {subcategory.photo ? (
                    <div className="col-4 col-md-auto image">
                      <img
                        alt="..."
                        className="image-preview rounded shadow"
                        src={`${subcategory.photo?.attributes.url}`}
                      />
                    </div>
                  ) : null}
                  <div
                    className={`${
                      subcategory.photo ? "col-8 justify-content-start" : "col-12 justify-content-center"
                    } col-md-12 d-flex justify-content-md-center mt-1`}
                  >
                    {subcategory.name}
                  </div>
                </span>
              </Col>
            );
          })}
        </Row>
      </DefaultLayout>
      <NewOrderFooter />
    </>
  );
}
