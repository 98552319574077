import React from "react";
import {
  useGetTablesQuery,
  useAddTableMutation,
  useUpdateTableMutation,
  useDeleteTableMutation,
} from "api/tablesSlice";

import { useGetFloorsQuery } from "api/floorsSlice";

const useTablesPage = () => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [inputs, setInputs] = React.useState({ name: "", rank: "", floor: "", errors: {} });
  const [rowClicked, setRowClicked] = React.useState({});
  const [filterModel, setFilterModel] = React.useState("");
  const { data: tables, isLoading, isError } = useGetTablesQuery();
  const { data: floors, isLoading: isLoadingFloors, isError: isErrorFloors } = useGetFloorsQuery();
  const [addTable] = useAddTableMutation();
  const [updateTable] = useUpdateTableMutation();
  const [deleteTable] = useDeleteTableMutation();

  function validateTableFields() {
    let isValid = true;
    let errors = {};
    if (!inputs.name) {
      errors.name = "Name is required";
      isValid = false;
    }
    if (!inputs.floor) {
      errors.floor = "Floor is required";
      isValid = false;
    }
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        errors: errors,
      };
    });

    return isValid;
  }

  function handleEditClose() {
    setEditDialogOpen(false);
  }

  function handleNewTableClose() {
    setDialogOpen(false);
  }

  function checkTableExists() {
    return tables?.some((table) => table.name === inputs.name);
  }

  function handleNewTableOpen() {
    setDialogOpen(true);
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        name: "",
        rank: "",
        floor: floors[0]?.id,
        errors: {},
      };
    });
  }

  function handleEditDialogOpen(rowClicked) {
    setRowClicked(rowClicked);
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        name: rowClicked.name,
        rank: rowClicked.rank,
        floor:
          rowClicked.floor !== "No Floor"
            ? floors.filter((floor) => floor.attributes.name === rowClicked.floor)[0].id
            : "",
        errors: {},
      };
    });
    setEditDialogOpen(true);
  }

  function handleInputChange(event) {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        [event.target.name]: event.target.value,
        errors: {
          ...prevInputs.errors,
          [event.target.name]: "",
        },
      };
    });
  }

  function handleDeleteDialogOpen(rowClicked) {
    setRowClicked(rowClicked);
    setDeleteDialogOpen(true);
  }

  function handleDeleteDialogClose() {
    setDeleteDialogOpen(false);
  }

  function handleDelete() {
    deleteTable(rowClicked);
    setDeleteDialogOpen(false);
  }

  function handleAddTable() {
    if (!validateTableFields()) {
      return;
    }

    if (!checkTableExists()) {
      addTable(inputs);
      setDialogOpen(false);
    } else {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: {
            ...prevInputs.errors,
            name: "Table already exists",
          },
        };
      });
    }
  }

  function handleEditTable() {
    if (!validateTableFields()) {
      return;
    }
    if (checkTableExists()) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: {
            ...prevInputs.errors,
            name: "Table already exists",
          },
        };
      });
    } else {
      updateTable({ id: rowClicked.id, name: inputs.name, rank: inputs.rank, floor: inputs.floor });
      setEditDialogOpen(false);
    }
  }

  return {
    dialogOpen,
    editDialogOpen,
    deleteDialogOpen,
    filterModel,
    inputs,
    rowClicked,
    tables,
    floors,
    isLoading,
    isError,
    isLoadingFloors,
    isErrorFloors,
    setFilterModel,
    handleEditClose,
    handleNewTableClose,
    handleNewTableOpen,
    handleEditDialogOpen,
    handleInputChange,
    handleDeleteDialogOpen,
    handleDeleteDialogClose,
    handleDelete,
    handleAddTable,
    handleEditTable,
  };
};

export default useTablesPage;
