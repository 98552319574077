import React from "react";
import DefaultLayout from "templates/DefaultLayout";
import { Views } from "utilities/enums/Views";
import { Row, Col, FormGroup, Input, Button } from "reactstrap";
import { useTranslation } from "react-i18next";

export default function Home() {
  const { t } = useTranslation();

  const header = (
    <Row>
      <Col xs="6">
        <h3 className="mb-0">{t(Views.GUIDES)}</h3>
      </Col>
    </Row>
  );
  return (
    <>
      <DefaultLayout name={Views.GENERAL_SETTINGS} parentName={Views.SETTINGS} header={header}>
        <h3>{t("Guides_for_notifications")}</h3>
        <p>{t("Notification_guides_subtitle")}</p>
        <p className="text-underline">{t("Computer")}</p>
        <ul>
          <li>
            <a
              href="https://www.digitaltrends.com/computing/how-to-enable-and-disable-chrome-notifications/#dt-heading-adjust-notification-settings-from-a-site"
              target="_blank"
            >
              {t("Allow_notifs_in_browser")}
              <i className="ml-2 fas fa-external-link-alt" />
            </a>
          </li>
        </ul>
        <p className="text-underline">{t("Mobile_phone")}</p>
        <ul>
          <li>
            <a href="#save-app">{t("Save_as_app_in_phone")}</a>
          </li>
          <li>
            <a href="#allow-notifications">{t("Allow_notifs_in_phone")}</a>
          </li>
        </ul>

        <h3 className="mt-5" id="save-app">
          {t("Save_as_app_in_phone")}
        </h3>
        <p>{t("How_to_save_as_app")}</p>
        <ul>
          <li>
            <a
              href="https://support.apple.com/guide/iphone/bookmark-favorite-webpages-iph42ab2f3a7/ios#iph4f9a47bbc"
              target="_blank"
            >
              iOS (Safari only)
              <i className="ml-2 fas fa-external-link-alt" />
            </a>
          </li>
          <li>
            <a
              href="https://www.lambdatest.com/software-testing-questions/how-to-add-shortcuts-to-google-chrome-homepage-on-android"
              target="_blank"
            >
              Android
              <i className="ml-2 fas fa-external-link-alt" />
            </a>
          </li>
        </ul>

        <h3 className="mt-5" id="allow-notifications">
          {t("Allow_notifs_in_phone")}
        </h3>
        <p>{t("Normal_notifs_allow_case")}</p>
        <div className="d-flex">
          <img src="/allow_notifs_iphone.PNG" width="150" height="auto" className="mb-3"></img>
          <img src="/allow_notifs_android.jpg" width="150" height="auto" className="mb-3 ml-2"></img>
        </div>
        <p>{t("Notifs_fallback_case")}</p>
        <ul>
          <li>
            <a
              href="https://support.apple.com/en-us/108781#:~:text=Go%20to%20Settings%20and%20tap,in%20the%20scheduled%20notification%20summary."
              target="_blank"
            >
              iOS
              <i className="ml-2 fas fa-external-link-alt" />
            </a>
          </li>
          <li>
            <a href="https://www.verizon.com/support/knowledge-base-211621/" target="_blank">
              Android
              <i className="ml-2 fas fa-external-link-alt" />
            </a>
          </li>
        </ul>

        <p className="mt-5">{t("Logout_request_message_notifs")}</p>
        <div className="d-flex flex-column flex-lg-row">
          <img src="/bell-icon-arrow.png" width="300" height="100" className="mb-3 ml-0"></img>
          <img src="/users_notif_panel.png" width="300" height="auto" className="mb-3 ml-0"></img>
        </div>

        <h3 className="mt-5" id="allow-notifications">
          {t("additional_notes")}
        </h3>
        <ul>
          <li>{t("no_macos_compatibility_msg")}</li>
          <li>{t("available_notif_users_msg")}</li>
          <li>{t("no_notifs_logout_msg")}</li>
          <li>{t("refresh_if_no_user_msg")}</li>
        </ul>
      </DefaultLayout>
    </>
  );
}
