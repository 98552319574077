import React, { useRef } from "react";
import NotificationAlert from "react-notification-alert";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useGetPrimerDataMutation } from "api/primerSlice";
import { triggerNotification } from "utilities/utils";

const SearchVatButton = ({ vatNumber, setCustomerInputs }) => {
  const [getPrimerData, { isLoading: primerLoading }] = useGetPrimerDataMutation();
  const { t } = useTranslation();
  const notificationAlertRef = useRef(null);

  const handleClick = async () => {
    const response = await getPrimerData(vatNumber);
    let companyInfo = response?.data;
    if (!companyInfo || companyInfo?.error === true || !companyInfo?.data?.vatNumber) {
      triggerNotification(notificationAlertRef, "danger", t("Error"), t("Failed_Find_Vat"));
      setCustomerInputs((prev) => ({
        ...prev,
        postcode: "",
        city: "",
        address: "",
        name: "",
        doy: "",
      }));
      return;
    }
    setCustomerInputs((prev) => ({
      ...prev,
      postcode: companyInfo.data.tk,
      city: companyInfo.data.city,
      address: companyInfo.data.address,
      name: companyInfo.data.name,
      doy: companyInfo.data.doy,
    }));
  };

  return (
    <>
      <Button
        className="btn text-md mr-3 ml-2 icon-sm bg-transparent border-white shadow-none vat-search-button"
        color="secondary"
        size="md"
        disabled={primerLoading || !vatNumber || vatNumber.length !== 9}
        onClick={handleClick}
      >
        <span className="btn-inner--icon">
          <i className={primerLoading ? `fa fa-spinner fa-spin` : `fas fa-search`} />
        </span>
      </Button>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
    </>
  );
};

export default SearchVatButton;
