import React from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const defaultMaskOptions = {
  prefix: "€",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const CurrencyInput = ({ maskOptions, onChange, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  const handleChange = (event) => {
    let floatValue = "";
    if (event.target.value) {
      const rawValue = event.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
      floatValue = parseFloat(rawValue);
    }
    onChange(floatValue);
  };

  return (
    <MaskedInput
      onChange={handleChange}
      className="currency-input-field text-md form-control font-weight-bold text-primary font-weight-bold"
      mask={currencyMask}
      inputMode="decimal"
      type="text"
      {...inputProps}
    />
  );
};

CurrencyInput.defaultProps = {
  maskOptions: {},
};

export default CurrencyInput;
