import React from "react";
import { Button, Col, FormGroup, Row, Card, CardHeader, CardBody, Collapse, Input } from "reactstrap";
import usePayItems from "./usePayItems";
import { formatCurrency, mergeToCommaSepString } from "utilities/utils";
import DefaultLayout from "templates/DefaultLayout";
import ScrollableTable from "components/Tables/ScrollableTable";
import { Views } from "utilities/enums/Views";
import { useTranslation } from "react-i18next";
import PaymentMethodModal from "components/Modals/PaymentMethodModal";
import { selectCartTable } from "redux/cart/cartSlice";
import { useSelector } from "react-redux";
import CalculateChangeModal from "components/Modals/CalculateChangeModal";
import DiscountInput from "components/Inputs/DiscountInput";
import { InvoiceType } from "utilities/enums/InvoiceType";
import { UncontrolledTooltip } from "reactstrap";
import SearchVatButton from "components/Buttons/SearchVatButton";
import CustomerSearch from "components/Modals/CustomerSearch";

function PayItems() {
  const {
    pendingOrders,
    isLoading,
    isError,
    selectedNewOrderItems,
    selectedOrderTotal,
    handleOrderItemQuantityChange,
    handleCheckItemToggle,
    handlePayButtonPress,
    paymentMethodDialogOpen,
    setPaymentMethodDialogOpen,
    payOrder,
    payOrderLoading,
    getProductPrice,
    discount,
    setDiscount,
    errorMessage,
    orderGrandTotal,
    allow_discount,
    hasPredefindDiscount,
    discountAccordionOpen,
    setDiscountAccordionOpen,
    payment_method_enabled,
    cloud_cash_register_enabled,
    manual_cloud_cash_register_enabled,
    discountReason,
    setDiscountReason,
    customerInputs,
    handleCustomerInputChange,
    submitInvoiceType,
    setSubmitInvoiceType,
    wholesale_enabled,
    setCustomerInputs,
    customerFieldsValidator,
    paymentErrorMsg,
    isDelivery,
  } = usePayItems();
  const { t } = useTranslation();
  const cartTable = useSelector(selectCartTable);
  const [calculateModalOpen, setCalculateModalOpen] = React.useState(false);
  const [customerAccordionOpen, setCustomerAccordionOpen] = React.useState(false);
  const isCheckedAll =
    selectedNewOrderItems.length === pendingOrders?.data.map((order) => order.orderDetails).flat().length;
  let header = <h3 className="mb-0">{t(Views.PAY_ITEMS)}</h3>;
  function getSelectedItem(id) {
    return selectedNewOrderItems.find((i) => i.id === id);
  }

  let discountSelector = (
    <>
      <div className="accordion payitems-accordion col-12 mr-auto pl-0 pr-0 ml-0 mb-3 mt-md-4">
        <Card className="card-plain discount-dropdown-card mb-2" key={0}>
          <CardHeader
            className={`p-3`}
            role="tab"
            onClick={() => setDiscountAccordionOpen(!discountAccordionOpen)}
            aria-expanded={discountAccordionOpen}
          >
            <h5 className="mb-0">
              {t("Discount")}{" "}
              <span className="ml-2 text-primary">
                {discount > 0 && !discountAccordionOpen ? `${formatCurrency(discount)}` : ""}
              </span>
            </h5>
          </CardHeader>
          <Collapse role="tabpanel" isOpen={discountAccordionOpen}>
            <CardBody className="p-3">
              <Row>
                <Col className="d-flex flex-column align-items-end justify-content-end pl-3 pr-3">
                  <DiscountInput
                    selectedOrderTotal={selectedOrderTotal}
                    initialDiscount={discount}
                    onDiscountChange={(discounted) => {
                      setDiscount(discounted);
                    }}
                  />

                  {errorMessage && <span className="text-danger text-sm mt-2">{t(errorMessage)}</span>}
                </Col>
              </Row>
              <FormGroup className="mb-1 mt-2">
                <label className="form-control-label" htmlFor="name">
                  {t("Discount_reason")}
                </label>
                <Input
                  className="form-control-alternative"
                  id="name"
                  name="name"
                  placeholder={t("Discount_reason")}
                  type="text"
                  value={discountReason}
                  disabled={!(discount && discount > 0)}
                  onChange={(e) => setDiscountReason(e.target.value)}
                />
              </FormGroup>
            </CardBody>
          </Collapse>
        </Card>
      </div>
    </>
  );

  let customerSelector = (
    <div className="accordion col-12 mr-auto pl-0 pr-0 ml-0 mt-3">
      <Card className="card-plain discount-dropdown-card mb-0" key={0}>
        <CardHeader
          className={`p-3`}
          role="tab"
          onClick={() => setCustomerAccordionOpen(!customerAccordionOpen)}
          aria-expanded={customerAccordionOpen}
        >
          <h5 className="mb-0">{t("Customer_info")}</h5>
        </CardHeader>
        <Collapse role="tabpanel" isOpen={customerAccordionOpen}>
          <CardBody className="p-3">
            <Row>
              <CustomerSearch
                customerInputs={customerInputs}
                setCustomerInputs={setCustomerInputs}
                initialName={customerInputs.name}
              />
              <Col xs="12" md="6" className="pl-2 pr-3">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="name">
                    {t("AFM")}
                  </label>
                  <div className="position-relative">
                    <Input
                      className="form-control-alternative"
                      id=""
                      name="vat_number"
                      placeholder={t("AFM")}
                      type="text"
                      value={customerInputs.vat_number}
                      onChange={handleCustomerInputChange}
                      pattern="[0-9]*"
                    />
                    <SearchVatButton vatNumber={customerInputs.vat_number} setCustomerInputs={setCustomerInputs} />
                  </div>
                  <p className="text-danger text-sm">{customerInputs.errors.vat_number}</p>
                </FormGroup>
              </Col>
              <Col xs="12" md="6" className="pl-2 pr-3">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="doy">
                    {t("DOY")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="doy"
                    name="doy"
                    placeholder={t("DOY")}
                    type="text"
                    value={customerInputs.doy}
                    onChange={handleCustomerInputChange}
                  />
                  <p className="text-danger text-sm">{customerInputs.errors.doy}</p>
                </FormGroup>
              </Col>
              <Col xs="12" md="6" className="pl-2 pr-3">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="gemi_number">
                    {t("GEMH")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="gemi_number"
                    name="gemi_number"
                    placeholder={t("GEMH")}
                    type="text"
                    value={customerInputs.gemi_number}
                    onChange={handleCustomerInputChange}
                  />
                  <p className="text-danger text-sm">{customerInputs.errors.gemi_number}</p>
                </FormGroup>
              </Col>
              <Col xs="12" md="6" className="pl-2 pr-3">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="address">
                    {t("Address")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="address"
                    name="address"
                    placeholder={t("Address")}
                    type="text"
                    value={customerInputs.address}
                    onChange={handleCustomerInputChange}
                  />
                  <p className="text-danger text-sm">{customerInputs.errors.address}</p>
                </FormGroup>
              </Col>
              <Col xs="12" md="6" className="pl-2 pr-3">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="city">
                    {t("City")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="city"
                    name="city"
                    placeholder={t("City")}
                    type="text"
                    value={customerInputs.city}
                    onChange={handleCustomerInputChange}
                  />
                  <p className="text-danger text-sm">{customerInputs.errors.city}</p>
                </FormGroup>
              </Col>
              <Col xs="12" md="6" className="pl-2 pr-3">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="postcode">
                    {t("Postcode")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="postcode"
                    name="postcode"
                    placeholder={t("Postcode")}
                    type="text"
                    value={customerInputs.postcode}
                    onChange={handleCustomerInputChange}
                  />
                  <p className="text-danger text-sm">{customerInputs.errors.postcode}</p>
                </FormGroup>
              </Col>
              <Col xs="12" md="6" className="pl-2 pr-3">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="phone_number">
                    {t("Phone")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="phone_number"
                    name="phone_number"
                    placeholder={t("Phone")}
                    type="text"
                    value={customerInputs.phone_number}
                    onChange={handleCustomerInputChange}
                  />
                  <p className="text-danger text-sm">{customerInputs.errors.phone_number}</p>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Collapse>
      </Card>
    </div>
  );

  let invoiceSelector = cloud_cash_register_enabled && (
    <>
      <div>
        <label className="form-control-label mr-2">{t("Invoice_Type")}</label>

        <div className="d-flex flex-direction-column col-12 pr-0 pl-0 mt-2">
          <Input
            type="select"
            name="select"
            id="exampleSelect"
            value={submitInvoiceType}
            onChange={(e) => setSubmitInvoiceType(e.target.value)}
          >
            {manual_cloud_cash_register_enabled && <option value={""}>{t("Without_Invoice")}</option>}
            <option value={InvoiceType.Retail}>{t("GR_Receipt")}</option>
            {wholesale_enabled && <option value={InvoiceType.Wholesale}>{t(InvoiceType.Wholesale)}</option>}
          </Input>
        </div>
        {submitInvoiceType === InvoiceType.Wholesale && <div className="mt-2 ml-0">{customerSelector}</div>}
      </div>
    </>
  );

  let calculatorBtn = (
    <Button
      id="calculate-change-btn"
      className="btn btn-white calculator-button text-md mr-3"
      color="secondary"
      size="md"
      disabled={
        selectedNewOrderItems.length === 0 ||
        selectedOrderTotal <= 0 ||
        (allow_discount && orderGrandTotal <= 0) ||
        payOrderLoading ||
        payment_method_enabled === null
      }
      onClick={() => setCalculateModalOpen(true)}
    >
      <span className="btn-inner--icon">
        <i className="fas fa-calculator" />
      </span>
    </Button>
  );

  return (
    <DefaultLayout
      name={t(Views.PAY_ITEMS)}
      parentName={Views.TABLE_ACTIONS}
      isLoading={isLoading}
      isError={isError}
      header={header}
      hasBackButton={true}
      table={cartTable}
      classNames="pay-items-view"
    >
      <ScrollableTable id="pay-items-table" className="align-items-center table-flush responsive-table">
        <thead className="thead-light">
          <tr>
            <th onClick={() => handleCheckItemToggle("all")}>
              <div className="custom-control custom-checkbox">
                <input
                  className="custom-control-input"
                  id="checkAll"
                  type="checkbox"
                  onChange={() => handleCheckItemToggle("all")}
                  checked={isCheckedAll}
                />
                <label className="custom-control-label" htmlFor="checkAll"></label>
              </div>
            </th>
            <th scope="col">{t("Product")}</th>
            <th className="text-center" scope="col">
              {t("Total")}
            </th>
          </tr>
        </thead>
        <tbody>
          {pendingOrders &&
            pendingOrders.data.map((item) =>
              item.orderDetails.map((orderItem) => (
                <tr key={orderItem.id}>
                  <td onClick={() => handleCheckItemToggle(orderItem)} className="hoverable">
                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        id={`flexCheckDefault-${orderItem.id}`}
                        type="checkbox"
                        checked={selectedNewOrderItems.filter((i) => i.id === orderItem.id).length > 0}
                        onChange={() => handleCheckItemToggle(orderItem)}
                      />
                      <label className="custom-control-label" htmlFor={`flexCheckDefault-${orderItem.id}`}></label>
                    </div>
                  </td>
                  <td onClick={() => handleCheckItemToggle(orderItem)} className="hoverable">
                    <div className="d-flex">
                      <div className="d-flex flex-column">
                        <p className="mb-0 text-left font-weight-bold">
                          {orderItem.product.data.attributes.name}

                          {orderItem.parent_invoice?.data?.id && (
                            <>
                              <Button
                                className="btn-icon-only rounded-circle ml-1 icon-sm bg-transparent border-white shadow-none"
                                id={`invoiceTooltip-${orderItem.id}`}
                              >
                                <i className="fas fa-check-circle text-primary"></i>
                              </Button>
                              <UncontrolledTooltip delay={0} target={`invoiceTooltip-${orderItem.id}`}>
                                {t("Invoice_sent")}
                              </UncontrolledTooltip>
                            </>
                          )}
                        </p>
                        <p className="product-variants text-left mb-0">
                          {mergeToCommaSepString(
                            orderItem.variant.map((variant) => {
                              if (variant.quantity && variant.quantity > 1) {
                                return `x${variant.quantity} ${variant.variant_option.data.attributes.label}`;
                              }
                              return variant.variant_option.data.attributes.label;
                            })
                          )}
                        </p>
                        <p className="product-notes text-left mb-0">{orderItem.notes}</p>
                        <span className="price text-left mb-0">{getProductPrice(orderItem)}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex flex-column align-items-center ml-2">
                      <div className="d-flex">
                        <Button
                          className="btn-icon-only rounded-circle product-quantity-btn-circle"
                          color="danger"
                          onClick={() => handleOrderItemQuantityChange(orderItem.id, -1)}
                          disabled={!getSelectedItem(orderItem.id) || getSelectedItem(orderItem.id)?.quantity === 1}
                        >
                          <i className="fa fa-minus" />
                        </Button>
                        <p className="m-1 font-weight-bold">
                          {getSelectedItem(orderItem.id) ? getSelectedItem(orderItem.id).quantity : orderItem.quantity}
                        </p>
                        <Button
                          className="btn-icon-only rounded-circle product-quantity-btn-circle"
                          color="success"
                          onClick={() => handleOrderItemQuantityChange(orderItem.id, 1)}
                          disabled={
                            !getSelectedItem(orderItem.id) ||
                            getSelectedItem(orderItem.id)?.quantity === orderItem.quantity
                          }
                        >
                          <i className="fa fa-plus" />
                        </Button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            )}
        </tbody>
      </ScrollableTable>
      <div className="card-footer py-3 py-md-4 pl-2 pr-2 pl-md-4 pr-md-4">
        <>
          <Row className="mb-md-4">
            <Col xs="12" md="6">
              <div className="d-none d-md-block col-8 p-0 mt--1">{invoiceSelector}</div>
            </Col>
            <Col xs="12" md="6" className="d-flex align-items-end justify-content-end">
              <div className="d-flex flex-column align-items-end col-12 p-0">
                <div className="d-block d-md-none col-12 mt--1 p-0">{invoiceSelector}</div>

                <div className=" d-block col-md-8 p-0 mt-3">
                  {allow_discount && !hasPredefindDiscount && discountSelector}
                </div>
                <div className="col-12">
                  {allow_discount && (
                    <>
                      {discount > 0 && !errorMessage && (
                        <p className="text-right total-price">
                          <span className="text-muted">{t("Before_discount")}: </span>
                          <span className="text-primary font-weight-bold">{formatCurrency(selectedOrderTotal)}</span>
                        </p>
                      )}
                      <p className="text-right total-price d-flex justify-content-end align-items-center">
                        {calculatorBtn}
                        <span className="text-muted mr-1">{t("Total")}: </span>
                        <span className="text-primary font-weight-bold">{formatCurrency(orderGrandTotal)}</span>
                      </p>
                    </>
                  )}
                  {!allow_discount && (
                    <>
                      <p className="text-right total-price d-flex justify-content-end align-items-center">
                        {calculatorBtn}
                        <span className="text-muted mr-1">{t("Total")}: </span>
                        <span className="text-primary font-weight-bold">{formatCurrency(selectedOrderTotal)}</span>
                      </p>
                    </>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </>
        <Row>
          <Col lg="12">
            <FormGroup>
              <Button
                id="pay-items-btn"
                className="col-12 col-md-3 btn btn-primary"
                color="primary"
                disabled={
                  selectedNewOrderItems.length === 0 ||
                  selectedOrderTotal <= 0 ||
                  (allow_discount && orderGrandTotal + discount <= 0) ||
                  payOrderLoading ||
                  payment_method_enabled === null
                }
                onClick={() => handlePayButtonPress()}
              >
                {t("Pay_Items")}
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </div>

      <PaymentMethodModal
        isDelivery={isDelivery}
        isOpen={paymentMethodDialogOpen}
        title={t("Payment_method")}
        okText={t("Pay_Items")}
        cancelText={t("Cancel")}
        onCancel={() => setPaymentMethodDialogOpen(false)}
        onOk={payOrder}
        okDisabled={payOrderLoading || selectedNewOrderItems?.length === 0}
        errorMsg={paymentErrorMsg}
        orderTotal={orderGrandTotal}
      />
      <CalculateChangeModal
        isOpen={calculateModalOpen}
        title={t("Calculate_change")}
        okText={t("Done")}
        cancelText={t("Cancel")}
        onCancel={() => setCalculateModalOpen(false)}
        onOk={() => setCalculateModalOpen(false)}
        okDisabled={false}
        total={allow_discount ? orderGrandTotal : selectedOrderTotal}
      />
    </DefaultLayout>
  );
}

export default PayItems;
