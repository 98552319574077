import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "./hooks/useRefresh";
import { store } from "redux/store";
import { getOrganisation } from "api/ApiCallerHelper";
import { useDispatch } from "react-redux";
import { setSettings } from "redux/settings/settings";

/**
 * Component used for Authentication/Authorization
 * Handles the functionality of persisting a login after refresh/visit at a new tab
 * Checks if a user is already logged in in the current browser session
 * If yes, a refresh token is requested from the backend
 */
const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const accessToken = store.getState().auth.token;
  const isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    const setApplicationSettings = async () => {
      let organisation = await getOrganisation();
      dispatch(setSettings(organisation?.attributes));
    };

    const shouldRefreshToken = !accessToken && isLoggedIn;
    shouldRefreshToken ? verifyRefreshToken() : setIsLoading(false);

    // should retrieve application settings if we can login
    if (shouldRefreshToken) {
      setApplicationSettings();
    }

    return () => (isMounted = false);
  }, [accessToken, isLoggedIn, refresh]);

  return <>{isLoading ? <p></p> : <Outlet />}</>;
};

export default PersistLogin;
