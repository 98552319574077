import React from "react";
import Form from "@rjsf/bootstrap-4";
import validator from "@rjsf/validator-ajv8";
import classNames from "classnames";
import { Button, Col, Input, Row } from "reactstrap";

const JSForm = ({ schema, uiSchema, formDataObj, onChange, chartNav }) => {
  const [chartsList, setChartsList] = React.useState(chartNav);
  const [selectedChart, setSelectedChart] = React.useState(0);
  const [editData, setEditData] = React.useState(formDataObj);

  const addChart = () => {
    setChartsList([
      ...chartsList,
      {
        title: "new chart",
      },
    ]);
    setEditData({
      formData: [
        ...editData.formData,
        {
          Chart: {
            title: "new chart",
          },
        },
      ],
    });
    setSelectedChart(chartsList.length === 1 ? 0 : chartsList.length);
    onChange(editData);
  };
  const removeChart = () => {
    setChartsList(chartsList.filter((item, index) => index !== parseInt(selectedChart)));
    setEditData({
      formData: editData.formData.filter((item, index) => index !== parseInt(selectedChart)),
    });

    if (chartsList.length >= 1) {
      setSelectedChart(selectedChart === 0 ? 0 : selectedChart - 1);
    }
    if (chartsList.length === 0) {
      addChart();
    }
    onChange(editData);
  };
  return (
    <Row>
      <Col xs="12">
        <Input
          type="select"
          bsSize="sm"
          className="d-inline-block w-50"
          value={selectedChart}
          onChange={(e) => setSelectedChart(e.target.value)}
        >
          {chartsList &&
            chartsList.map((prop, key) => {
              return (
                <option
                  key={key}
                  className={classNames("py-2 px-3", {
                    active: selectedChart === key,
                  })}
                  href="#pablo"
                  value={key}
                >
                  {prop.title}
                </option>
              );
            })}
        </Input>
        <Button
          color="primary"
          size="sm"
          className="ml-1"
          onClick={() => addChart()}
          disabled={chartsList.length === 3}
        >
          <i className="fas fa-plus" />
        </Button>
        <Button
          color="primary"
          size="sm"
          className="ml-1"
          onClick={() => removeChart()}
          disabled={chartsList.length === 1}
        >
          <i className="fas fa-minus" />
        </Button>
      </Col>
      <Col className="mt-3">
        <Form
          schema={schema}
          uiSchema={uiSchema}
          formData={editData.formData[selectedChart]}
          onChange={({ formData }) => {
            setEditData({
              formData: editData.formData.map((item, index) => {
                if (index === parseInt(selectedChart)) {
                  return formData;
                } else {
                  return item;
                }
              }),
            });
            onChange({
              formData: editData.formData.map((item, index) => {
                if (index === parseInt(selectedChart)) {
                  return formData;
                } else {
                  return item;
                }
              }),
            });
          }}
          validator={validator}
        />
      </Col>
    </Row>
  );
};

export default JSForm;
