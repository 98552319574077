import React, { useEffect, useState } from "react";
import { ButtonGroup, Button, Input, FormGroup } from "reactstrap";
import CurrencyInput from "components/Inputs/CurrencyInput";

const DiscountInput = ({ selectedOrderTotal, initialDiscount, onDiscountChange }) => {
  const [discountType, setDiscountType] = useState("percentage");
  const [discount, setDiscount] = useState(initialDiscount);
  const [discountPercentage, setDiscountPercentage] = useState(0);

  const handleDiscountChange = (value) => {
    setDiscountPercentage(0);
    setDiscount(value);
    onDiscountChange(value);
  };

  const handleDiscountPercentageChange = (value) => {
    const calculatedDiscount = Math.round((value / 100) * selectedOrderTotal * 100) / 100 || 0;
    setDiscountPercentage(value);
    setDiscount(calculatedDiscount);
    onDiscountChange(calculatedDiscount);
  };

  return (
    <div className="d-flex flex-row w-100">
      {discountType === "amount" && (
        <CurrencyInput
          onChange={(e) => handleDiscountChange(e)}
          value={discount === 0 ? "" : discount}
          placeholder="€0.00"
          type="string"
        />
      )}
      {discountType === "percentage" && (
        <Input
          className="w-100"
          type="number"
          inputMode="decimal"
          onChange={(e) => {
            handleDiscountPercentageChange(e.target.value);
          }}
          value={discountPercentage === 0 ? "" : discountPercentage}
          placeholder="0%"
          min="0"
          max="100"
        />
      )}
      <div className="d-flex flex-column align-items-center justify-content-center ml-2">
        <div className="d-flex flex-row">
          <FormGroup className="mb-0">
            <ButtonGroup>
              <Button
                color={discountType === "percentage" ? "primary" : "secondary"}
                onClick={() => setDiscountType("percentage")}
              >
                %
              </Button>
              <Button
                color={discountType === "amount" ? "primary" : "secondary"}
                onClick={() => setDiscountType("amount")}
              >
                €
              </Button>
            </ButtonGroup>
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export default DiscountInput;
