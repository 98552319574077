import { TagTypes } from "utilities/enums/TagTypes";
import { apiSlice } from "./apiSlice";
import { store } from "redux/store";
import { sortByRankAsc } from "utilities/utils";

export const floorSlice = apiSlice.injectEndpoints({
  tagTypes: [TagTypes.Floors],
  endpoints: (builder) => ({
    getFloors: builder.query({
      providesTags: [TagTypes.Floors],
      query: (sortedBy = "id") =>
        `/floors?sort[0]=${sortedBy}&filters[organisation][id]=${store.getState().auth.organisationId}`,
      transformResponse: (responseData) => {
        return sortByRankAsc(responseData.data, true);
      },
    }),
    getFloorsWithTables: builder.query({
      providesTags: [TagTypes.Floors],
      query: () => `/floors?populate=tables&filters[organisation][id]=${store.getState().auth.organisationId}`,
      transformResponse: (responseData) => {
        return responseData.data;
      },
    }),
    addFloor: builder.mutation({
      invalidatesTags: [TagTypes.Floors],
      query: (floor) => {
        return {
          url: `/floors`,
          method: "POST",
          body: {
            data: {
              name: floor.name,
              rank: !floor.rank ? null : floor.rank,
              organisation: store.getState().auth.organisationId,
            },
          },
        };
      },
    }),
    updateFloor: builder.mutation({
      invalidatesTags: [TagTypes.Floors],
      query: (floor) => {
        return {
          url: `/floors/${floor.id}`,
          method: "PUT",
          body: {
            data: {
              name: floor.name,
              rank: !floor.rank ? null : floor.rank,
              organisation: store.getState().auth.organisationId,
            },
          },
        };
      },
    }),
    deleteFloor: builder.mutation({
      invalidatesTags: [TagTypes.Floors],
      query: (floor) => {
        return {
          url: `/floors/${floor.id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetFloorsQuery,
  useGetFloorsWithTablesQuery,
  useAddFloorMutation,
  useUpdateFloorMutation,
  useDeleteFloorMutation,
} = floorSlice;
