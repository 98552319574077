import { OrderStatus } from "utilities/enums/OrderStatus";
import { store } from "redux/store";
import { getNetRevenue } from "utilities/utils";
import { orderBy } from "lodash";

export function getOrderUrl({ page, pageSize, sort, filters }) {
  let includeDisabled =
    filters?.includes("filters[invoice_type][$eq]") || filters?.includes("filters[mydata_gr_cancellation_mark]"); // When we have filter for invoice, we also want the disabled orders!
  let url = "/orders?sort=id:DESC&populate[0]=table";
  url += page ? `&pagination[page]=${page}` : "";
  url += pageSize ? `&pagination[pageSize]=${pageSize}` : "";
  url += sort ? `&sort=${sort}` : `&sort=id%3Adesc`;
  url += filters ? `${filters}` : ``;
  url += includeDisabled ? "" : `&filters[status][$ne]=${OrderStatus.Disabled}`;
  url += `&filters[organisation][id]=${store.getState().auth.organisationId}`;
  return url;
}

export function getOrderObejectfromCartItems(orderDetails, initialDiscount) {
  let total = 0;
  let net_revenue = 0;
  let remainingDiscount = initialDiscount;

  const details = orderDetails.map((item) => {
    const variants = item.variants.map((variant) => {
      return {
        variant: {
          id: variant.variantId,
        },
        variant_option: {
          id: variant.optionId,
        },
        quantity: variant.quantity,
      };
    });

    const itemGrossRevenue = item.price * item.quantity;
    let itemDiscount = 0;
    if (remainingDiscount > 0) {
      itemDiscount = Math.min(remainingDiscount, itemGrossRevenue);
      remainingDiscount -= itemDiscount;
    }

    const discountedGrossRevenue = itemGrossRevenue - itemDiscount;
    total += discountedGrossRevenue;
    net_revenue += getNetRevenue(discountedGrossRevenue, item.vatCategory);
    return {
      quantity: item.quantity,
      price: item.price,
      product: {
        id: item.id,
      },
      variant: variants,
      notes: item.notes,
      item_discount: itemDiscount,
    };
  });

  return {
    details: details,
    total: total,
    net_revenue: net_revenue,
  };
}
export function getOrderObejectFromOrderDetails(orderDetails, initialDiscount) {
  let total = 0;
  let net_revenue = 0;
  let remainingDiscount = initialDiscount; // set initial value
  // sort by vat category desc so [vat_24, vat_13]
  const sortedDetails = orderBy(orderDetails, ["product.data.attributes.vat_category"], ["desc"]);
  // get updated details
  const details = sortedDetails.map((item) => {
    const variants = item.variant.map((variant) => {
      return {
        variant: {
          id: variant?.variant?.data?.id,
        },
        variant_option: {
          id: variant?.variant_option?.data?.id,
        },
        quantity: variant.quantity,
      };
    });

    const productBasePrice = item.product.data.attributes.price;
    // calculate variation price
    const variantsTotalPrice = item.variant.reduce((x, variant) => {
      let variantOptionPrice = variant.variant_option?.data?.attributes?.price;
      if (variant?.quantity && variant?.quantity > 1) {
        variantOptionPrice = variantOptionPrice * variant.quantity;
      }
      return (x += variantOptionPrice);
    }, 0);
    // get total order value
    const grossRevenue = (productBasePrice + variantsTotalPrice) * item.quantity;
    let itemDiscount = 0;
    if (initialDiscount > 0) {
      // get max amount possible to be discounted from product price
      itemDiscount = Math.min(remainingDiscount, grossRevenue);
      // get remaining discount for next item
      remainingDiscount -= itemDiscount;
      // apply discount
      const discountedGrossRevenue = grossRevenue - itemDiscount;
      // update total of order
      total += discountedGrossRevenue;
      // update total net revenue of order
      net_revenue += getNetRevenue(discountedGrossRevenue, item.product.data.attributes.vat_category);
    } else {
      total += grossRevenue;
      net_revenue += getNetRevenue(grossRevenue, item.product.data.attributes.vat_category);
    }

    return {
      quantity: item.quantity,
      price: productBasePrice + variantsTotalPrice,
      product: {
        id: item.product.data.id,
      },
      variant: variants,
      notes: item.notes,
      item_discount: itemDiscount,
      parent_invoice: item.parent_invoice?.data?.id,
    };
  });

  return {
    details: details,
    total: total,
    net_revenue: net_revenue,
  };
}
