// errorMiddleware.js
import { apiSlice } from "./apiSlice";
import * as Sentry from "@sentry/browser";

const handleNetworkErrorsMiddleware = () => (next) => async (action) => {
  if (action.type.startsWith(apiSlice.reducerPath)) {
    let errorCode = action.payload?.data?.error?.status;
    if (action.payload?.data?.error?.status) {
      let errorMessage = `Error at endpoint: ${action?.meta?.arg?.endpointName} error code: ${errorCode}`;
      Sentry.captureException(errorMessage);
      console.log(errorMessage);
    }
  }
  return next(action);
};

export default handleNetworkErrorsMiddleware;
