import React, { useEffect } from "react";
import { useUpdatePrintScenarioMutation } from "api/printScenariosSlice";
import { useGetCategoriesWithProductsQuery } from "api/categoriesSlice";
import { useGetFloorsWithTablesQuery } from "api/floorsSlice";
import { useGetPrintersQuery } from "api/printerSlice";
import { useGetPrintScenarioQuery } from "api/printScenariosSlice";
import { useSelector } from "react-redux";

const useEditPrintScenario = (props) => {
  const { data: categories } = useGetCategoriesWithProductsQuery();
  const { data: floorsWithTables } = useGetFloorsWithTablesQuery();
  const { data: activePrinters } = useGetPrintersQuery();
  const { data: scenario, isFetching: scenarioLoading } = useGetPrintScenarioQuery(props.scenarioId, {
    skip: !props.scenarioId,
  });
  const [inputs, setInputs] = React.useState({
    name: "",
    description: "",
    active: false,
    configurations: [],
    errors: {},
  });
  const [updatePrintScenario, { isLoading: updatePrintScenarioLoading }] = useUpdatePrintScenarioMutation();
  const [openConfigurationIndex, setOpenConfigurationIndex] = React.useState("");
  const [openReceiptGroupIndex, setOpenReceiptGroupIndex] = React.useState("");
  const { cloud_cash_register_enabled } = useSelector((state) => state.settings);
  useEffect(() => {
    let scenarioConfigurations = scenario?.configurations;
    if (scenarioConfigurations) {
      scenarioConfigurations = scenario.configurations.map((config) => {
        let categories_order = new Set(); // using set to avoid duplicates
        config.receiptGroups.forEach((group) => {
          group.categories.forEach((cat) => {
            categories_order.add(cat); // add all the categories in the group
          });
        });
        categories.forEach((cat) => {
          categories_order.add(cat.id); // add rest of the categories
        });

        return {
          ...config,
          categories_order: Array.from(categories_order), // convert set to array to allow sorting
        };
      });
    }
    setInputs({
      name: scenario?.name,
      description: scenario?.description,
      active: scenario?.active,
      configurations: scenarioConfigurations,
      errors: {},
    });
  }, [scenario?.id, props.isOpen]);
  function clearInputs() {
    setInputs({
      name: "",
      description: "",
      active: false,
      configurations: [],
      errors: {},
    });
  }
  function updateConfigurationOrder(configurationId, newOrder) {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        configurations: prevInputs.configurations.map((configuration) => {
          if (configuration.id !== configurationId) return configuration;
          return {
            ...configuration,
            // set the new order of categories that will be printed
            categories_order: newOrder,
            // sort the categories in the receipt groups based on the new order
            receiptGroups: configuration.receiptGroups.map((group) => {
              return {
                ...group,
                // group.categories is unmutable so we need to create a new array and sort it
                categories: [...group.categories].sort((a, b) => newOrder.indexOf(a) - newOrder.indexOf(b)),
              };
            }),
          };
        }),
      };
    });
  }
  function onDragEnd(result, configurationId) {
    if (!result.destination) return;
    const { source, destination } = result;
    // get target configuration by id
    const configuration = inputs.configurations.find((config) => config.id === configurationId);
    if (!configuration) return;
    // set the new order of categories
    const newOrder = [...configuration.categories_order];
    const [removed] = newOrder.splice(source.index, 1);
    newOrder.splice(destination.index, 0, removed);
    // update the categories order for all groups in the configuration
    updateConfigurationOrder(configurationId, newOrder);
  }
  function setDescription(description) {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        description: description,
      };
    });
  }

  function handleInputChange(event) {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        [event.target.name]: event.target.value,
      };
    });
  }

  async function handleUpdateScenarioSubmit() {
    await updatePrintScenario({ id: scenario.id, ...inputs });
    clearInputs();
    props?.onCancel();
  }

  function handleSelectAllClicked(configurationId, group) {
    setInputs((prev) => ({
      ...prev,
      configurations: prev.configurations?.map((configuration) => {
        if (configuration.id !== configurationId) return configuration;
        return {
          ...configuration,
          receiptGroups: configuration.receiptGroups.map((receiptGroup) => {
            if (receiptGroup.id !== group.id) return receiptGroup;

            return {
              ...receiptGroup,
              categories:
                receiptGroup.categories.length === categories.length ? [] : categories.map((category) => category.id),
            };
          }),
        };
      }),
    }));
  }

  function printerGroupCategoryClicked(configurationId, group, category) {
    setInputs((prev) => ({
      ...prev,
      configurations: prev.configurations?.map((configuration) => {
        if (configuration.id !== configurationId) return configuration;

        return {
          ...configuration,
          receiptGroups: configuration.receiptGroups.map((receiptGroup) => {
            if (receiptGroup.id !== group.id) return receiptGroup;

            let inputChecked = receiptGroup.categories.find((cat) => cat === category.id);
            if (!inputChecked) {
              return {
                ...receiptGroup,
                categories: [...receiptGroup.categories, category.id],
              };
            } else {
              return {
                ...receiptGroup,
                categories: receiptGroup.categories.filter((id) => id !== category.id),
              };
            }
          }),
        };
      }),
    }));
  }

  function handleAddConfiguration() {
    setInputs((prevInputs) => ({
      ...prevInputs,
      configurations: [
        ...prevInputs.configurations,
        { id: prevInputs.configurations?.length, printer: null, receiptGroups: [] },
      ],
    }));
  }
  function handleAddGroup(configurationId) {
    setInputs((prevInputs) => ({
      ...prevInputs,
      configurations: prevInputs.configurations?.map((configuration) => {
        if (configurationId === configuration.id) {
          return {
            ...configuration,
            receiptGroups: [
              ...configuration.receiptGroups,
              {
                id: configuration.receiptGroups.length,
                categories: [],
                table_filters: [],
                table_filters_enabled: false,
              },
            ],
          };
        } else {
          return configuration;
        }
      }),
    }));
  }

  function handleFloorCheckboxChange(e, configurationId, groupIndx, floor) {
    const group = inputs.configurations?.find((config) => config.id === configurationId)?.receiptGroups?.[groupIndx];
    const isChecked =
      e.target.checked && !floor.attributes.tables.data.every((table) => group.table_filters.includes(table.id));
    const tableIds = floor.attributes.tables.data.map((table) => table.id);

    if (isChecked) {
      setInputs((prev) => ({
        ...prev,
        configurations: prev.configurations?.map((configuration) => {
          if (configuration.id !== configurationId) return configuration;
          return {
            ...configuration,
            receiptGroups: [
              ...configuration.receiptGroups.slice(0, groupIndx),
              {
                ...group,
                table_filters: [...group.table_filters, ...tableIds],
              },
              ...configuration.receiptGroups.slice(groupIndx + 1),
            ],
          };
        }),
      }));
    } else {
      setInputs((prev) => ({
        ...prev,
        configurations: prev.configurations?.map((configuration) => {
          if (configuration.id !== configurationId) return configuration;
          return {
            ...configuration,
            receiptGroups: [
              ...configuration.receiptGroups.slice(0, groupIndx),
              {
                ...group,
                table_filters: group.table_filters.filter((id) => !tableIds.includes(id)),
              },
              ...configuration.receiptGroups.slice(groupIndx + 1),
            ],
          };
        }),
      }));
    }
  }

  function handleTableFilterChange(e, configurationId, groupIndex) {
    setInputs((prev) => ({
      ...prev,
      configurations: prev.configurations?.map((configuration) => {
        if (configurationId !== configuration.id) return configuration;

        return {
          ...configuration,
          receiptGroups: configuration.receiptGroups.map((receiptGroup, indx) => {
            if (indx !== groupIndex) return receiptGroup;

            return {
              ...receiptGroup,
              table_filters_enabled: e.target.checked,
            };
          }),
        };
      }),
    }));
  }

  function handleRemoveGroup(configurationId, group) {
    setInputs((prevInputs) => ({
      ...prevInputs,
      configurations: prevInputs.configurations?.map((configuration) => {
        if (configuration.id !== configurationId) return configuration;
        return {
          ...configuration,
          receiptGroups: configuration.receiptGroups.filter((currentGroup) => currentGroup.id !== group.id),
        };
      }),
    }));
  }
  function handleRemoveConfiguration(configurationId) {
    setInputs((prevInputs) => ({
      ...prevInputs,
      configurations: prevInputs.configurations?.filter((configuration) => configuration.id !== configurationId),
    }));
  }

  function handleConfigRecieptTypeChange(e, configurationId) {
    setInputs((prevInputs) => ({
      ...prevInputs,
      configurations: prevInputs.configurations?.map((configuration) => {
        if (configuration.id !== configurationId) return configuration;

        return {
          ...configuration,
          receipt_type: e.target.value,
        };
      }),
    }));
  }

  function handleConfigPrinterChange(e, configurationId) {
    let selectedPrinterId = e.target.value;
    let selectedPrinter = activePrinters.find((printer) => printer.id?.toString() === selectedPrinterId);
    setInputs((prevInputs) => ({
      ...prevInputs,
      configurations: prevInputs.configurations?.map((configuration) => {
        if (configuration.id !== configurationId) return configuration;

        return {
          ...configuration,
          printer: selectedPrinter,
        };
      }),
    }));
  }

  function toggleConfigurations(id) {
    if (openConfigurationIndex === id) {
      setOpenConfigurationIndex();
    } else {
      setOpenConfigurationIndex(id);
    }
  }
  function toggleReceiptGroups(id) {
    if (openReceiptGroupIndex === id) {
      setOpenReceiptGroupIndex();
    } else {
      setOpenReceiptGroupIndex(id);
    }
  }

  return {
    handleUpdateScenarioSubmit,
    updatePrintScenarioLoading,
    inputs,
    setInputs,
    handleInputChange,
    handleSelectAllClicked,
    categories,
    printerGroupCategoryClicked,
    floorsWithTables,
    handleAddConfiguration,
    toggleReceiptGroups,
    openReceiptGroupIndex,
    openConfigurationIndex,
    toggleConfigurations,
    handleAddGroup,
    handleFloorCheckboxChange,
    handleTableFilterChange,
    handleRemoveGroup,
    handleRemoveConfiguration,
    activePrinters,
    handleConfigPrinterChange,
    handleConfigRecieptTypeChange,
    scenarioLoading,
    setDescription,
    cloud_cash_register_enabled,
    onDragEnd,
  };
};

export default useEditPrintScenario;
