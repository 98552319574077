import { useDispatch } from "react-redux";
import { setCredentials } from "auth/authSlice";
import { store } from "redux/store";
import { refreshToken } from "api/ApiCallerHelper";

/**
 * Custom Hook used to retrieve JWT refresh Token when the old one expires
 */
const useRefreshToken = () => {
  const dispatch = useDispatch();

  const refresh = async () => {
    const refreshResult = await refreshToken(store.getState().auth.refreshToken);
    if (refreshResult?.jwt) {
      const user = refreshResult?.user;
      // store the new token
      dispatch(setCredentials({ jwt: refreshResult.jwt, user, refreshToken: refreshResult.refreshToken }));
    }
    return refreshResult;
  };

  return refresh;
};

export default useRefreshToken;
