import { apiSlice } from "./apiSlice";

/**
 * RTK Query functions for Authentication/Authorization
 */
export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/auth/local",
        method: "POST",
        body: {
          ...credentials,
        },
      }),
    }),
  }),
});

export const { useLoginMutation } = authApiSlice;
