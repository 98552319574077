import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "api/apiSlice";
import localeSliceReducer from "./locale/locale";
import settingsReducer from "./settings/settings";
import generic from "./generic/generic";
import authReducer from "auth/authSlice";
import productQuantitiesSlice from "redux/product/productQuantitiesSlice";
import cartReducer from "redux/cart/cartSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import handleNetworkErrorsMiddleware from "api/errorMiddleware";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "cart", "productQuantities", "locale", "settings", "genericState"], // which reducer want to store
  blacklist: ["api"], // which reducer don't want to store
};

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  cart: cartReducer,
  locale: localeSliceReducer,
  productQuantities: productQuantitiesSlice,
  settings: settingsReducer,
  genericState: generic,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware, thunk, handleNetworkErrorsMiddleware),
  devTools: true,
});

export const persistor = persistStore(store);
