import React from "react";
import useResubmitOrder from "./useResubmitOrder";
import usePayItems from "../PayItems/usePayItems";
import AlertModal from "components/Modals/AlertModal";
import { Button, Col, FormGroup, Row, Card, CardHeader, Collapse, CardBody } from "reactstrap";
import { formatCurrency, mergeToCommaSepString } from "utilities/utils";
import DefaultLayout from "templates/DefaultLayout";
import ScrollableTable from "components/Tables/ScrollableTable";
import { Views } from "utilities/enums/Views";
import { useTranslation } from "react-i18next";
import { selectCartTable } from "redux/cart/cartSlice";
import { useSelector } from "react-redux";
import NewOrderProduct from "../NewOrder/Product/NewOrderProduct";
import { MODE_RESUBMIT } from "utilities/constants";
import { TextArea } from "components/TextAreas/TextArea";
import { convertOrderProductToCart } from "utilities/utils";

function ResubmitOrder() {
  const {
    isLoading,
    isError,
    resubmitPendingItems,
    selectedOrderTotal,
    handleOrderItemQuantityChange,
    handleCheckItemToggle,
    handleResubmitItems,
    handleProductClick,
    handleProductClose,
    selectedProduct,
    editProduct,
    addOrderLoading,
    isCheckedAll,
    resubmitCheckedItems,
    notes,
    setNotes,
    canCheckItem,
    commentsAccordionOpen,
    setCommentsAccordionOpen,
    errorMsg,
  } = useResubmitOrder();
  const { getProductPrice } = usePayItems();
  const { t } = useTranslation();
  const cartTable = useSelector(selectCartTable);

  let header = <h3 className="mb-0">{t(Views.RESUBMIT_ITEMS)}</h3>;

  return (
    <DefaultLayout
      name={t(Views.RESUBMIT_ITEMS)}
      parentName={Views.TABLE_ACTIONS}
      isLoading={isLoading}
      isError={isError}
      header={header}
      hasBackButton={true}
      table={cartTable}
      classNames="pay-items-view"
    >
      <ScrollableTable id="resubmit-items-table" className="align-items-center table-flush responsive-table">
        <thead className="thead-light">
          <tr>
            <th onClick={() => handleCheckItemToggle("all")}>
              <div className="custom-control custom-checkbox">
                <input
                  className="custom-control-input"
                  id="checkAll"
                  type="checkbox"
                  checked={isCheckedAll}
                  onChange={() => {}}
                />
                <label className="custom-control-label" htmlFor="checkAll"></label>
              </div>
            </th>
            <th scope="col">{t("Product")}</th>
            <th className="text-center" scope="col">
              {t("Total")}
            </th>
          </tr>
        </thead>
        <tbody>
          {resubmitPendingItems?.map((orderItem) => {
            let productData = orderItem?.product?.data?.attributes;
            let productInventoryEnabled = productData?.inventoryEnabled;
            let productInventory = productData?.inventory;
            let canCheck = canCheckItem(orderItem);
            return (
              <tr key={orderItem.id}>
                <td onClick={() => handleCheckItemToggle(orderItem)} className="hoverable">
                  <div className="custom-control custom-checkbox">
                    <input
                      className="custom-control-input"
                      id={`flexCheckDefault-${orderItem.id}`}
                      type="checkbox"
                      checked={orderItem.checked}
                      onChange={() => {}}
                      disabled={!canCheck}
                    />
                    <label className="custom-control-label" htmlFor={`flexCheckDefault-${orderItem.id}`}></label>
                  </div>
                </td>
                <td onClick={() => handleProductClick(orderItem)} className="hoverable">
                  <div className="d-flex">
                    <div className="d-flex flex-column">
                      <p className="mb-0 text-left font-weight-bold">{orderItem.product.data.attributes.name}</p>
                      <p className="product-variants text-left mb-0">
                        {mergeToCommaSepString(
                          orderItem?.variant.map((variant) => {
                            if (variant.quantity && variant.quantity > 1) {
                              return `x${variant.quantity} ${variant.variant_option.data.attributes.label}`;
                            }

                            return variant.variant_option.data.attributes.label;
                          })
                        )}
                      </p>
                      {orderItem.notes && <p className="product-notes mb-0 text-left">Notes: {orderItem.notes}</p>}
                      <span className="price text-left mb-0">{getProductPrice(orderItem)}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex flex-column align-items-center ml-2">
                    <div className="d-flex">
                      <Button
                        className="btn-icon-only rounded-circle product-quantity-btn-circle"
                        color="danger"
                        onClick={() => handleOrderItemQuantityChange(orderItem.id, -1)}
                        disabled={orderItem?.quantity === 1}
                      >
                        <i className="fa fa-minus" />
                      </Button>
                      <p className="m-1 font-weight-bold">{orderItem.quantity}</p>
                      <Button
                        className="btn-icon-only rounded-circle product-quantity-btn-circle"
                        color="success"
                        onClick={() => handleOrderItemQuantityChange(orderItem.id, 1)}
                        disabled={productInventoryEnabled && productInventory <= orderItem?.quantity}
                      >
                        <i className="fa fa-plus" />
                      </Button>
                    </div>

                    <div className="mt-1">
                      {productInventoryEnabled && productInventory <= 0 ? (
                        <div className="quantity text-danger">Out of stock</div>
                      ) : (
                        <div className="quantity text-success">
                          {productInventoryEnabled ? `${t("Quantity")}: ${productInventory}` : null}
                        </div>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </ScrollableTable>
      <div className="card-footer py-3 py-md-4">
        <Row className="mb-4">
          <Col lg="6"></Col>
          <Col
            lg="6"
            className="align-items-start justify-content-center col-lg-6 d-flex p-0 flex-md-column align-items-md-end d-md-block"
          >
            <div className="accordion payitems-accordion col-6 pl-0 pr-0 ml-auto mb-md-3">
              <Card className="card-plain discount-dropdown-card mb-0" key={0}>
                <CardHeader
                  className={`pl-3 pr-3 pt-md-3 pb-md-3 pt-2 pb-2`}
                  role="tab"
                  onClick={() => setCommentsAccordionOpen(!commentsAccordionOpen)}
                  aria-expanded={commentsAccordionOpen}
                >
                  <h5 className="mb-0">{t("Comments")} </h5>
                </CardHeader>
                <Collapse role="tabpanel" isOpen={commentsAccordionOpen}>
                  <CardBody className="p-0">
                    <TextArea
                      className="w-100 textarea-border-left-0 pt-0 form-control"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      placeholder={t("Comments")}
                    />
                  </CardBody>
                </Collapse>
              </Card>
            </div>
            <p className="text-right total-price mb-0 col-6 col-md-12 pr-0 pl-2">
              <span className="text-muted">{t("Total")}: </span>
              <span className="text-primary font-weight-bold">{formatCurrency(selectedOrderTotal)}</span>
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              {errorMsg && <p className="text-danger text-sm">{errorMsg}</p>}

              <Button
                id="transfer-items-btn"
                className="col-12 col-md-3 btn btn-primary"
                color="primary"
                disabled={!resubmitCheckedItems?.length > 0 || addOrderLoading}
                onClick={() => handleResubmitItems()}
              >
                {t("Resubmit")}
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </div>

      <AlertModal
        isOpen={selectedProduct != null}
        onCancel={handleProductClose}
        headerImage={selectedProduct?.image?.attributes.url}
        stickyHeaderEnabled={true}
        header={
          <div className="position-relative w-100 margin-top--35">
            <button
              aria-label="Close"
              className="close position-absolute right-3 top-0"
              data-dismiss="modal"
              type="button"
              onClick={handleProductClose}
            ></button>
            <div className="modal-header pb-0 d-flex flex-column pt-0">
              <h2 className="modal-title pr-4 mt-1" id="modal-title-default">
                {selectedProduct?.product?.data?.attributes?.name}
              </h2>
              <h5 className="modal-subtitle text-muted font-weight-light mt-2">{selectedProduct?.description}</h5>
              <div className="text-primary">{formatCurrency(selectedProduct?.price)}</div>
            </div>
          </div>
        }
        stickyHeader={
          <div>
            <button
              aria-label="Close"
              className="close position-absolute right-3 top-2-5"
              data-dismiss="modal"
              type="button"
              onClick={handleProductClose}
            ></button>
            <div className="modal-header pb-0 d-flex flex-column pt-3">
              <h2 className="modal-title pr-4" id="modal-title-default">
                {selectedProduct?.product?.data?.attributes?.name}
              </h2>
            </div>
          </div>
        }
        body={
          selectedProduct && (
            <NewOrderProduct
              mode={MODE_RESUBMIT}
              productID={selectedProduct.product?.data?.id}
              addproductCallback={editProduct}
              productInfo={() => convertOrderProductToCart(selectedProduct)}
            />
          )
        }
      />
    </DefaultLayout>
  );
}

export default ResubmitOrder;
