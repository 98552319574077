import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import { useLoginMutation } from "api/authSlice";
import { setCredentials, setUserRules, setOrganisation, selectCurrentOrganisation } from "auth/authSlice";
import { setSettings } from "redux/settings/settings";
import { joinOrdersWS } from "config/websocket";
import { logError, urlBase64ToUint8Array, getDefaultRules } from "utilities/utils";
import {
  useLazyGetUserPermissionsQuery,
  useUpdateUserPermissionsMutation,
  useAddUserPermissionsMutation,
} from "api/usersSlice";
import { apiSlice } from "api/apiSlice";
import { TagTypes } from "utilities/enums/TagTypes";
import ROUTES_OBJ from "utilities/enums/Routes";
import { PUBLIC_ROUTES } from "utilities/constants";
import { useSaveSubscriptionMutation } from "api/organisationSlice";
import { setIsSubscribed } from "redux/generic/generic";
import { broadCastUserChangeWS } from "config/websocket";
import { WEB_PUSH_PUBLIC_KEY } from "utilities/constants";
import TopErrorAlert from "components/Headers/TopErrorAlert";

function Login() {
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [isLoginLoading, setIsLoginLoading] = React.useState(false);
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();
  const [userRules] = useLazyGetUserPermissionsQuery();
  const [addUserRules] = useAddUserPermissionsMutation();
  const dispatch = useDispatch();
  const previousOrganisation = useSelector(selectCurrentOrganisation);
  const [updateUserRoles] = useUpdateUserPermissionsMutation();
  const [saveSubscription, { isLoading: isSaving }] = useSaveSubscriptionMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  let username = searchParams.get("username");
  let autologin = searchParams.get("autologin");
  let autologinEnabled =
    process.env.REACT_APP_DEPLOYMENT_ENV === "do-demo" && autologin === "true" && username === "admin_guest";

  useEffect(() => {
    if (autologinEnabled) {
      // set default credentials for autologin, if it is enabled
      setUser("admin_guest");
      setPwd("guest1!234Admin");
    }
  }, []);

  useEffect(() => {
    if (user && pwd && autologinEnabled) {
      //if autologin is enabled and user & password are filled, submit the login form
      handleSubmit();
    }
  }, [user, pwd]);

  async function storeOrganisation(userData) {
    if (userData.user.organisation?.id !== previousOrganisation && userData.user.organisation?.id) {
      dispatch(setOrganisation({ organisationId: userData.user.organisation.id }));
      dispatch(apiSlice.util.invalidateTags(Object.values(TagTypes)));
    }
  }

  async function setApplicationSettings(userData) {
    if (userData.user.organisation) {
      dispatch(setSettings(userData.user.organisation));
    }
  }

  async function addSubscription(userData, organisationId) {
    if ("serviceWorker" in navigator && "PushManager" in window) {
      const registration = await navigator.serviceWorker.getRegistration();
      const permission = await Notification.requestPermission();
      if (!registration || permission !== "granted") {
        dispatch(setIsSubscribed(false));
        return;
      }
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(WEB_PUSH_PUBLIC_KEY),
      });
      const savedSubscription = await saveSubscription({
        ...subscription.toJSON(),
        user: userData.user.id,
        org: organisationId,
      });
      dispatch(setIsSubscribed(true));
    } else {
      dispatch(setIsSubscribed(false));
    }
  }

  function getMissingDbUserRules(userRole, userRuleNames) {
    let missingRules = Object.entries(ROUTES_OBJ)
      .map((route) => `Route:${route[1].path}`)
      .filter((rule_name) => !PUBLIC_ROUTES.includes(rule_name) && !userRuleNames.includes(rule_name))
      .map((rule_name) => {
        return {
          name: rule_name,
          value: userRole === "admin" ? true : false,
        };
      });

    return missingRules;
  }

  async function storeUserRules(userData) {
    const userRulesData = await userRules(userData.user.user_role).unwrap();
    if (userRulesData?.data?.length === 0) {
      // if no user rules exist in the db, create them
      const default_rules = getDefaultRules();
      await default_rules.forEach(async (rule) => {
        await addUserRules({
          ...rule,
        }).unwrap();
      });
      dispatch(
        setUserRules({
          user_rules: default_rules.filter((rule) => rule.name === userData.user.user_role)[0].rules,
        })
      );
    } else {
      // if user rules exist in the db
      let userRole = userRulesData?.data[0]?.attributes?.name;
      let userRules = userRulesData?.data[0].attributes.rules;
      let appPrivateRoutes = Object.entries(ROUTES_OBJ).length - PUBLIC_ROUTES.length;
      //if there are more private routes than the rules defined, we are missing rules in the db so we should add them
      if (userRules.length < appPrivateRoutes) {
        let userRuleNames = userRules.map((rule) => rule.name);
        let missingRules = getMissingDbUserRules(userRole, userRuleNames);
        userRules = [...userRules, ...missingRules];

        await updateUserRoles({
          id: userRulesData.data[0]?.id,
          rules: userRules,
        });
      }
      dispatch(
        setUserRules({
          user_rules: userRules,
        })
      );
    }
  }

  const handleSubmit = async (e) => {
    e?.preventDefault();
    setErrMsg("");
    setIsLoginLoading(true);
    try {
      const userData = await login({ identifier: user, password: pwd }).unwrap();
      // Log the user in only if they are set as "Enabled" - We can prevent staff from logging in outside their shift
      if (userData?.user?.enabled) {
        dispatch(setCredentials({ ...userData, user: userData.user }));
        await storeOrganisation(userData);
        await storeUserRules(userData);
        await setApplicationSettings(userData);
        await addSubscription(userData, userData.user.organisation?.id);
        setUser("");
        setPwd("");
        joinOrdersWS(userData.user.organisation?.id, user);
        broadCastUserChangeWS(userData.user.organisation?.id);
        navigate(userData.user.user_role === "admin" ? "/admin/dashboard" : "/admin/tables");
      } else {
        throw { originalStatus: 401 };
      }
    } catch (err) {
      if (!err?.originalStatus) {
        setErrMsg("No Server Response");
      } else if (err.originalStatus === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.originalStatus === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      logError(err?.data?.error?.message);
    } finally {
      setIsLoginLoading(false);
    }
  };

  const handleUserInput = (e) => setUser(e.target.value);

  const handlePwdInput = (e) => setPwd(e.target.value);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <TopErrorAlert />
      <AuthHeader title="Welcome to DitoApp POS" lead="Login to start managing your business." />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Sign in</small>
                </div>
                <Form id="loginform" role="form" onSubmit={handleSubmit}>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Username"
                        type="text"
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(true)}
                        value={user}
                        onChange={handleUserInput}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(true)}
                        value={pwd}
                        onChange={handlePwdInput}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText style={{ cursor: "pointer" }} onClick={togglePasswordVisibility}>
                          <i className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"} />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button disabled={isLoginLoading} className="my-4" color="info">
                      {isLoginLoading ? "Signing in..." : "Sign in"}
                    </Button>
                  </div>
                </Form>
                <p className="error-msg text-center">{errMsg}</p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
