import React, { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import { Row, Col, Nav, NavItem, NavLink, Alert } from "reactstrap";
import { useDispatch } from "react-redux";
import CustomerTable from "components/CustomerTable";
import { apiOrders, useGetPendingTablesQuery } from "api/ordersSlice";
import { TagTypes } from "utilities/enums/TagTypes";
import { subscribeToOrdersChangeWS } from "config/websocket";
import { useGetTablesQuery } from "api/tablesSlice";
import DefaultLayout from "templates/DefaultLayout";
import { Views } from "utilities/enums/Views";
import { useTranslation } from "react-i18next";
import { useGetFloorsQuery } from "api/floorsSlice";
import { setCartTable } from "redux/cart/cartSlice";
import { setSelectedTablesTab } from "redux/generic/generic";
import { useSelector } from "react-redux";
import { selectCurrentOrganisation } from "auth/authSlice";
import { logError } from "utilities/utils";

function Tables() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: tablesData, isLoading, isError } = useGetTablesQuery();
  const { data: pendingTables } = useGetPendingTablesQuery();
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [customerTablesData, setCustomerTablesData] = useState([]);
  const { data: floorList, isLoading: isLoadingFloors, isError: isErrorFloors } = useGetFloorsQuery("rank");
  const [selectedTable, setSelectedTable] = useState({ id: -1 });
  const { selectedTablesTab, versionError } = useSelector((state) => state.genericState);
  const organisationId = useSelector(selectCurrentOrganisation);
  const { t } = useTranslation();

  function refreshComponent() {
    dispatch(apiOrders.util.invalidateTags([TagTypes.Orders]));
    forceUpdate();
  }

  useEffect(() => {
    if (floorList && tablesData) {
      setCustomerTablesData(
        tablesData.filter((table) => {
          if (selectedTablesTab === 0) return true;
          return table.attributes.floor.data?.attributes.name === floorList[selectedTablesTab - 1]?.attributes.name;
        })
      );
    }
  }, [floorList, selectedTablesTab, tablesData]);

  useEffect(() => {
    subscribeToOrdersChangeWS(organisationId, refreshComponent);
  }, []);

  function tableClick(table) {
    dispatch(setCartTable(table));
    if (window.innerWidth <= 992) {
      navigate(`/admin/table-actions/${table.id}`);
    } else {
      // Mode with sidebar
      if (table?.id === selectedTable?.id) {
        // If we clicked a second time the same table, we go to table actions
        navigate(`/admin/table-actions/${table.id}`);
      } else {
        // If we clicked the first time a table in desktop, set it as selected (view it's pending items in sidebar)
        setSelectedTable(table);
      }
    }
  }

  function floorTabClick(e, index) {
    e.preventDefault();
    dispatch(setSelectedTablesTab(index));
  }

  let header = (
    <Row className="align-items-center">
      <Col lg="3">
        <h3 className="mb-2">{t(Views.TABLES)}</h3>
      </Col>
      <Col xs="12" lg="9" className="pr-1 pl-1">
        <Nav className="justify-content-start justify-content-md-end" tabs>
          <NavItem key="-1">
            <NavLink
              className={classnames("py-2 px-2", {
                active: selectedTablesTab === 0,
              })}
              href="#pablo"
              onClick={(e) => floorTabClick(e, 0)}
            >
              {t("All")}
            </NavLink>
          </NavItem>
          {tablesData &&
            floorList?.map((floor, index) => (
              <NavItem key={index}>
                <NavLink
                  className={classnames("py-2 px-3", {
                    active: selectedTablesTab === index + 1,
                  })}
                  href="#pablo"
                  onClick={(e) => floorTabClick(e, index + 1)}
                >
                  <span className="d-none d-md-block">{floor?.attributes?.name}</span>
                  <span className="d-md-none">{floor?.attributes?.name}</span>
                </NavLink>
              </NavItem>
            ))}
        </Nav>
      </Col>
    </Row>
  );
  return (
    <DefaultLayout
      name=""
      parentName={t(Views.TABLES)}
      isLoading={isLoading}
      isError={isError}
      header={header}
      classNames="tables-view cart-sidebar-enabled"
      headerClassNames={"tables-view-header"}
      hasSideBar={true}
      sidebarMode={selectedTable}
    >
      <Row className="row-shadow">
        {tablesData &&
          (floorList || [])?.map((floor, index) => {
            if (!floor || !floor.attributes?.name) {
              logError(`Invalid floor data at index ${index}:`, floor);
              return null; // Skip this floor if it's invalid
            }

            const floorName = floor.attributes.name;

            return (customerTablesData || [])
              ?.filter((table) => {
                const tableFloorName = table?.attributes?.floor?.data?.attributes?.name;
                if (!tableFloorName) {
                  logError(`Invalid table floor data for table ID ${table?.id}:`, table);
                }
                return tableFloorName === floorName;
              })
              ?.map((table) => {
                if (!table || !table.id || !table.attributes?.name) {
                  logError(`Invalid table data:`, table);
                  return null; // Skip this table if it's invalid
                }

                const foundPendingTable = (pendingTables || [])?.find((pendingTable) => pendingTable.id === table.id);

                return (
                  <CustomerTable
                    key={table.id}
                    hasPendingOrders={!!foundPendingTable}
                    allInvoiced={foundPendingTable?.allInvoiced}
                    name={table.attributes.name}
                    onClick={() => tableClick(table)}
                  />
                );
              });
          })}
      </Row>
    </DefaultLayout>
  );
}

export default Tables;
