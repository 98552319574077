import React, { useEffect, useState } from "react";
import AlertModal from "./AlertModal";
import { t } from "i18next";
import { FormGroup, Input } from "reactstrap";
import { formatCurrency } from "utilities/utils";

export default function CalculateChangeModal(props) {
  const [inputs, setInputs] = useState({
    cash: "",
    total: typeof props.total === "number" ? props.total?.toFixed(2) : 0,
    change: 0,
  });

  useEffect(() => {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        cash: "",
        total: typeof props.total === "number" ? props.total?.toFixed(2) : 0,
        change: 0,
      };
    });
  }, [props.total]);

  useEffect(() => {
    try {
      let cash = parseFloat(inputs.cash);
      let total = parseFloat(inputs.total);
      if (!isNaN(cash) && !isNaN(total)) {
        setInputs((prevInputs) => {
          return {
            ...prevInputs,
            change: cash - total,
          };
        });
      }
    } catch (e) {}
  }, [inputs.total, inputs.cash]);

  function handleInputChange(event) {
    let value = event.target.value;
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        [event.target.name]: value,
      };
    });
  }

  return (
    <AlertModal
      isOpen={props.isOpen}
      title={props.title}
      cancelText={props.cancelText}
      onCancel={props.onCancel}
      okDisabled={props.okDisabled}
      size="sm"
      body={
        <div className="calculator-modal d-flex flex-column justify-content-end">
          <FormGroup className="d-flex">
            <label
              className="pl-0 col-8 d-flex align-items-center text-sm-md font-weight-normal form-control-label m-0"
              htmlFor="name"
            >
              {t("Enter_cash")}
            </label>
            <Input
              className="col-4 form-control-alternative text-right"
              id="cash"
              name="cash"
              placeholder={t("Cash")}
              type="text"
              inputMode="decimal"
              value={inputs.cash}
              size="md"
              onChange={handleInputChange}
            />
          </FormGroup>

          <FormGroup className="d-flex">
            <label
              className="pl-0 d-flex align-items-center col-8 text-sm-md font-weight-normal form-control-label m-0"
              htmlFor="name"
            >
              {t("Order_Total")}
            </label>
            <Input
              className="col-4 form-control-alternative text-right"
              id="name"
              name="total"
              placeholder={t("Order_Total")}
              type="text"
              value={inputs.total}
              size="md"
              inputMode="decimal"
              onChange={handleInputChange}
            />
          </FormGroup>

          <p className="text-md text-right">
            <span className="">{t("Change")}:</span>{" "}
            <span className="text-primary font-weight-bold">{formatCurrency(inputs.change)}</span>
          </p>
        </div>
      }
    />
  );
}
