import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetPendingOrdersByTableQuery } from "api/ordersSlice";
import { formatCurrency } from "utilities/utils";
import { useReprintOrderMutation } from "api/ordersSlice";
import { selectCurrentUser, selectCurrentOrganisation } from "auth/authSlice";
import { selectCartTable } from "redux/cart/cartSlice";
import { useSelector } from "react-redux";
import { triggerNotification } from "utilities/utils";
import { useTranslation } from "react-i18next";
import { logError } from "utilities/utils";

const useTableSummary = () => {
  const { table } = useParams();
  const { data: pendingOrders, isLoading, isError } = useGetPendingOrdersByTableQuery(parseInt(table));
  const [selectedNewOrderItems, setSelectedNewOrderItems] = React.useState([]);
  const [selectedOrderTotal, setSelectedOrderTotal] = React.useState(0);
  const organisationId = useSelector(selectCurrentOrganisation);
  const { username: user } = useSelector(selectCurrentUser);
  const cartTable = useSelector(selectCartTable);
  const { t } = useTranslation();

  const [reprintOrder, { isLoading: handlePrintSummaryLoading }] = useReprintOrderMutation();

  useEffect(() => {
    if (pendingOrders) {
      handleCheckItemToggle("all");
    }
  }, [pendingOrders]);

  useEffect(() => {
    setSelectedOrderTotal(
      selectedNewOrderItems
        .reduce((total, item) => {
          let v = item.variant.reduce((x, variant) => {
            let variantOptionPrice = variant.variant_option.data?.attributes.price;
            if (variant.quantity && variant.quantity > 1) {
              variantOptionPrice = variantOptionPrice * variant.quantity;
            }
            return (x += variantOptionPrice * item.quantity);
          }, 0);
          return total + item.product.data?.attributes.price * item.quantity + v;
        }, 0)
        .toFixed(2)
    );
  }, [selectedNewOrderItems]);

  function handleOrderItemQuantityChange(id, q) {
    setSelectedNewOrderItems(
      selectedNewOrderItems.map((item) => {
        if (item.id === id) {
          return { ...item, quantity: item.quantity + q };
        }
        return item;
      })
    );
  }

  function handleCheckItemToggle(item) {
    if (item === "all") {
      let allOrderDetailsItems = pendingOrders.data.map((order) => order.orderDetails).flat();
      if (selectedNewOrderItems.length === allOrderDetailsItems.length) {
        setSelectedNewOrderItems([]);
      } else {
        setSelectedNewOrderItems(allOrderDetailsItems);
      }
    } else {
      if (selectedNewOrderItems.filter((i) => i.id === item.id).length > 0) {
        setSelectedNewOrderItems(() => {
          return selectedNewOrderItems.filter((i) => i.id !== item.id);
        });
      } else {
        setSelectedNewOrderItems(() => {
          return [...selectedNewOrderItems, item];
        });
      }
    }
  }

  function getProductPrice(orderItem) {
    let basePrice = orderItem?.product?.data?.attributes?.price || 0;

    let productVariants = orderItem?.variant;
    const optionsExtraPrice = productVariants
      ? productVariants.reduce((totalExtra, variant) => {
          let variantOption = variant.variant_option;
          let variantOptionPrice = variantOption.data?.attributes.price;
          if (variantOptionPrice && variant.quantity && variant.quantity > 1) {
            variantOptionPrice = variantOptionPrice * variant.quantity;
          }
          return totalExtra + (variantOptionPrice || 0);
        }, 0)
      : 0;

    return formatCurrency(basePrice + optionsExtraPrice);
  }

  async function handlePrintSummary(notificationAlertRef) {
    let orderData = {
      organisation: {
        id: organisationId,
      },
      submitted_by: user,
      details: selectedNewOrderItems.map((item) => {
        return { ...item, product: item.product.data.attributes };
      }),
      table: {
        id: cartTable?.id,
        name: cartTable?.attributes?.name,
      },
    };

    try {
      let response = await reprintOrder({ reprint_type: "openTableCustom", order_id: null, orderData: orderData });
      if (!response.error) {
        triggerNotification(notificationAlertRef, "success", t("Table_Summary"), t("Table_Summary_print_success"));
      } else {
        triggerNotification(notificationAlertRef, "danger", t("Table_Summary"), t("Table_Summary_print_error"));
        logError({ msg: "Error while printing table summery", orderData: { orderData } });
      }
    } catch (error) {
      triggerNotification(notificationAlertRef, "danger", t("Table_Summary"), t("Table_Summary_print_error"));
      logError({ msg: "Error while printing table summery", orderData: { orderData } });
    }
  }

  return {
    pendingOrders,
    isLoading,
    isError,
    selectedNewOrderItems,
    selectedOrderTotal,
    handleOrderItemQuantityChange,
    handleCheckItemToggle,
    getProductPrice,
    handlePrintSummary,
    cartTable,
    handlePrintSummaryLoading,
  };
};

export default useTableSummary;
