import React from "react";
import { Row, Col } from "reactstrap";

export default function FixedFooter(props) {
  return (
    <Row className="fixed-footer bg-white dynamic-cart-padding-bottom">
      <Col lg="12">
        <div className="d-flex justify-content-end mt-3 ml-3 mr-3">{props.children}</div>
      </Col>
    </Row>
  );
}
