import { useGetSalesOriginQuery } from "api/analyticsSlice";
import { useGetAnalyticsOrdersQuery, useGetAnalyticsSalesQuery } from "api/analyticsSlice";

const useDashboard = ({ reportPeriod }) => {
  const {
    data: salesChartData,
    isLoading: salesChartLoading,
    isError: salesChartError,
  } = useGetAnalyticsSalesQuery({ reportPeriod: reportPeriod });
  const {
    data: ordersChartData,
    isLoading: ordersChartLoading,
    isError: ordersChartError,
  } = useGetAnalyticsOrdersQuery({ reportPeriod: reportPeriod });
  const {
    data: salesOriginChartData,
    isLoading: salesOriginChartLoading,
    isError: salesOriginChartError,
  } = useGetSalesOriginQuery({ reportPeriod: reportPeriod });
  const isLoading = salesChartLoading || ordersChartLoading || salesOriginChartLoading;
  const isError = salesChartError || ordersChartError || salesOriginChartError;
  return {
    salesOriginChartData,
    salesChartData,
    ordersChartData,
    isLoading,
    isError,
  };
};

export default useDashboard;
