import React from "react";
import { Col } from "reactstrap";

const defaultStyle = "";
const redStyle = "text-white bg-danger";
const blueStyle = "text-white btn-info";

export default function CustomerTable({ name, onClick, hasPendingOrders, allInvoiced }) {
  function getTableStyle() {
    let style = defaultStyle;
    if (hasPendingOrders) {
      style = redStyle;

      if (allInvoiced) {
        style = blueStyle;
      }
    }
    return style;
  }

  const tableStyles = getTableStyle();
  return (
    <Col xs="3" lg="2" onClick={onClick} className="p-2 p-md-3 customer-table">
      <span
        className={`table-text-name btn text-center d-flex justify-content-center align-items-center ${tableStyles}`}
      >
        {name}
      </span>
    </Col>
  );
}
