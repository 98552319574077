export const TagTypes = Object.freeze({
  Categories: Symbol("Categories").toString(),
  SubCategories: Symbol("SubCategories").toString(),
  Orders: Symbol("Orders").toString(),
  Products: Symbol("Products").toString(),
  Tables: Symbol("Tables").toString(),
  Floors: Symbol("Floors").toString(),
  Analytics: Symbol("Analytics").toString(),
  Variants: Symbol("Variants").toString(),
  VariantOptions: Symbol("VariantOptions").toString(),
  Users: Symbol("Users").toString(),
  Printers: Symbol("Printers").toString(),
  Upload: Symbol("Upload").toString(),
  Organisation: Symbol("Organisation").toString(),
  PrintScenarios: Symbol("PrintScenarios").toString(),
  PosTerminals: Symbol("PosTerminals").toString(),
  Customers: Symbol("Customers").toString(),
});
