import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "./authSlice";

/**
 * Wrapper component for every Protected View of the App
 * Checks if the current user is logged in, if yes it serves the View
 * Otherwise it redirects to the login view
 */
const RequireAuth = () => {
  const token = useSelector(selectCurrentToken);
  const location = useLocation();
  return token ? <Outlet /> : <Navigate to="/auth/login" state={{ from: location }} replace />;
};
export default RequireAuth;
