import { FormGroup, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import ReactDatetime from "react-datetime";
import { safeToString } from "utilities/utils";

export const DatePicker = ({ selectedDate, startDate, endDate, onChange, placeholder }) => {
  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate && safeToString(startDate._d) !== safeToString(endDate._d)) {
      if (
        new Date(safeToString(endDate._d)) > new Date(safeToString(date._d)) &&
        new Date(safeToString(startDate._d)) < new Date(safeToString(date._d))
      ) {
        return " middle-date";
      }
      if (safeToString(endDate._d) === safeToString(date._d)) {
        return " end-date";
      }
      if (safeToString(startDate._d) === safeToString(date._d)) {
        return " start-date";
      }
    }
    return "";
  };

  return (
    <FormGroup>
      <InputGroup className="mb-3 flex-nowrap">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="ni ni-calendar-grid-58" />
          </InputGroupText>
        </InputGroupAddon>
        <ReactDatetime
          inputProps={{
            placeholder: placeholder,
          }}
          value={selectedDate !== "" ? selectedDate : ""}
          timeFormat={false}
          onChange={onChange}
          renderDay={(props, currentDate) => {
            let classes = `${props.className}${getClassNameReactDatetimeDays(currentDate)}`;
            return (
              <td {...props} className={classes}>
                {currentDate.date()}
              </td>
            );
          }}
          renderInput={(props) => {
            return <input {...props} value={selectedDate !== "" ? props.value : ""} />;
          }}
        />
      </InputGroup>
    </FormGroup>
  );
};
