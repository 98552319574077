import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import {
  useUpdateProductMutation,
  useGetProductByIdQuery,
  useGetCategoriesForProductQuery,
  useGetVariantsForProductQuery,
} from "api/productsSlice";
import { VatCategory } from "utilities/enums/VatCategory";
import { reoderArrayOnDrop } from "utilities/utils";
import { useSelector } from "react-redux";

const useEditProduct = (props) => {
  const navigate = useNavigate();
  const productsPage = "/admin/menu-management/products";

  const id = props?.productId;
  const {
    data: product,
    isFetching: isFetchingProduct,
    isSuccess: isSuccessProduct,
    isError: isErrorProduct,
  } = useGetProductByIdQuery(id?.toString() || "0");
  const [updateProduct, { isLoading: isUpdateProductLoading }] = useUpdateProductMutation();
  const { data: categories } = useGetCategoriesForProductQuery();
  const { data: variants } = useGetVariantsForProductQuery();
  const [inputs, setInputs] = React.useState({
    name: "",
    active: false,
    description: "",
    image: null,
    price: "",
    vat_category: VatCategory.VAT_13.value,
    category: "",
    variants: [],
    inventoryEnabled: false,
    inventoryQuantity: 0,
    errors: {},
    rank: null,
  });

  const [selectImageDialog, setSelectImageDialog] = React.useState(false);
  const [addVariantDialogOpen, setAddVariantDialogOpen] = React.useState(false);
  const { inventory_management_disabled } = useSelector((state) => state.settings);

  useEffect(() => {
    if (product?.id && isSuccessProduct) {
      let productVariants = product.variants?.data.map((variant) => variant.id);
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          name: product.name,
          active: product.enabled,
          description: product.description,
          image: product.image.data
            ? {
                id: product.image.data.id,
                ...product.image.data.attributes,
              }
            : null,
          price: product.price,
          vat_category: product.vat_category ? product.vat_category : prevInputs.vat_category,
          category: product.category,
          variants: [...productVariants],
          inventoryEnabled: product.inventoryEnabled ? true : false,
          inventoryQuantity: product.inventory ? product.inventory : 0,
          avialableVariants: variants?.filter((variant) => {
            return !productVariants.includes(variant.id);
          }),
          rank: product.rank,
          errors: {},
        };
      });
    }
  }, [product, isSuccessProduct, variants]);

  function handleInputChange(event) {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        [event.target.name]: event.target.value,
      };
    });
  }

  function handleAddVariantDialogOpen() {
    setAddVariantDialogOpen(true);
  }

  function handleAddVariantDialogClose() {
    setAddVariantDialogOpen(false);
  }

  function validateFields() {
    let isValid = true;
    if (inputs.name.length === 0) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, name: t("Name_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      if (inputs.errors.name?.length > 0) {
        setInputs((prevInputs) => {
          return {
            ...prevInputs,
            errors: { ...prevInputs.errors, name: "" },
          };
        });
      }
    }

    if (parseFloat(inputs.price) < 0 || inputs.price === "") {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, price: t("Price_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      if (inputs.errors.price?.length > 0) {
        setInputs((prevInputs) => {
          return {
            ...prevInputs,
            errors: { ...prevInputs.errors, price: "" },
          };
        });
      }
    }
    if (inputs.category === "" || inputs.category === null) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, category: t("Category_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      if (inputs.errors.category?.length > 0) {
        setInputs((prevInputs) => {
          return {
            ...prevInputs,
            errors: { ...prevInputs.errors, category: "" },
          };
        });
      }
    }

    if (inputs.inventoryEnabled && (inputs.inventoryQuantity < 0 || inputs.inventoryQuantity === "")) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, inventoryQuantity: t("Inventory_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      if (inputs.errors.inventoryQuantity?.length > 0) {
        setInputs((prevInputs) => {
          return {
            ...prevInputs,
            errors: { ...prevInputs.errors, inventoryQuantity: "" },
          };
        });
      }
    }

    if (inputs.rank && inputs.rank <= 0) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, rank: t("Rank_error_message") },
        };
      });
      isValid = false;
    } else {
      if (inputs.errors.rank?.length > 0) {
        setInputs((prevInputs) => {
          return {
            ...prevInputs,
            errors: { ...prevInputs.errors, rank: "" },
          };
        });
      }
    }

    return isValid;
  }

  function handleEditProductSubmit() {
    if (!validateFields()) {
      return;
    }

    let editProduct = {
      id: id,
      disabled: !inputs.active,
      name: inputs.name,
      description: inputs.description,
      image: inputs.image,
      price: inputs.price,
      vat_category: inputs.vat_category,
      category: inputs.category,
      variants: inputs.variants,
      inventoryEnabled: inputs.inventoryEnabled,
      inventory: inputs.inventoryQuantity,
      rank: inputs.rank != "" ? inputs.rank : null,
    };

    updateProduct(editProduct).then(() => {
      props?.onCancel();
    });
  }

  function handleCancel() {
    navigate(productsPage);
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = reoderArrayOnDrop(inputs.variants, result);
    setInputs({ ...inputs, variants: items });
  };

  return {
    inputs,
    setInputs,
    handleInputChange,
    handleEditProductSubmit,
    handleAddVariantDialogOpen,
    handleAddVariantDialogClose,
    selectImageDialog,
    setSelectImageDialog,
    addVariantDialogOpen,
    product,
    isFetchingProduct,
    isSuccessProduct,
    isErrorProduct,
    categories,
    variants,
    handleCancel,
    isUpdateProductLoading,
    handleDragEnd,
    inventory_management_disabled,
  };
};

export default useEditProduct;
