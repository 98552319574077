import { TagTypes } from "utilities/enums/TagTypes";
import { apiSlice } from "api/apiSlice";
import moment from "moment";
import { store } from "redux/store";

export const apiUsers = apiSlice.injectEndpoints({
  tagTypes: [TagTypes.Users],
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => `/users?filters[organisation][id]=${store.getState().auth.organisationId}&populate=subscriptions`,
      providesTags: [TagTypes.Users],
      transformResponse: (responseData) => {
        return responseData.map((user) => {
          return {
            id: user.id,
            username: user.username,
            email: user.email,
            phone: user.phone,
            enabled: user.enabled,
            user_role: user.user_role,
            created_at: new moment(user.createdAt).format("MMMM Do YYYY, h:mm:ss a"),
            subscriptions: user.subscriptions,
          };
        });
      },
    }),
    updateUser: builder.mutation({
      invalidatesTags: [TagTypes.Users],
      query: (user) => {
        return {
          url: `/users/${user.id}`,
          method: "PUT",
          body: {
            username: user.username,
            email: user.email,
            phone: user.phone,
            enabled: user.enabled,
            organisation: store.getState().auth.organisationId,
          },
        };
      },
    }),
    getUserPermissions: builder.query({
      query: (user_role) =>
        `/user-permissions?filters[name][$eq]=${user_role}&populate=rules&filters[organisation][id]=${
          store.getState().auth.organisationId
        }`,
      providesTags: [TagTypes.Users],
      transformResponse: (responseData) => {
        return responseData;
      },
    }),
    updateUserPermissions: builder.mutation({
      invalidatesTags: [TagTypes.Users],
      query: (user_role) => {
        return {
          url: `/user-permissions/${user_role.id}`,
          method: "PUT",
          body: {
            data: {
              ...user_role,
              organisation: store.getState().auth.organisationId,
            },
          },
        };
      },
    }),
    addUserPermissions: builder.mutation({
      invalidatesTags: [TagTypes.Users],
      query: (user_role) => {
        return {
          url: `/user-permissions`,
          method: "POST",
          body: {
            data: {
              ...user_role,
              organisation: store.getState().auth.organisationId,
            },
          },
        };
      },
    }),
  }),
});

export const {
  useGetUsersQuery,
  useUpdateUserMutation,
  useGetUserPermissionsQuery,
  useLazyGetUserPermissionsQuery,
  useAddUserPermissionsMutation,
  useUpdateUserPermissionsMutation,
} = apiUsers;
