import React, { useState } from "react";
import AlertModal from "./AlertModal";
import { t } from "i18next";
import { Input, FormGroup } from "reactstrap";
export default function GiftConfirmationModal(props) {
  const [reason, setReason] = useState("");

  return (
    <AlertModal
      isOpen={props.isOpen}
      title={props.title}
      okText={props.okText}
      cancelText={props.cancelText}
      onCancel={props.onCancel}
      onOk={() => props.onOk(reason)}
      okDisabled={props.okDisabled}
      body={
        <>
          <div className="mb-4">
            <span className="display-6">{t("Gift_confirmation_message")}</span>
            <span className="ml-2 font-weight-bold">{props.selectedOrderTotal}</span>
          </div>
          <FormGroup>
            <label className="form-control-label" htmlFor="name">
              {t("Enter_gift_reason")}
            </label>
            <Input
              className="form-control-alternative"
              id="name"
              name="name"
              placeholder={t("Gift_reason")}
              type="text"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </FormGroup>
        </>
      }
    />
  );
}
