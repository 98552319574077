import { t } from "i18next";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Modal, Input } from "reactstrap";
import PuffLoader from "react-spinners/PuffLoader";
import { throttle } from "lodash";

export default function AlertModal(props) {
  const [showStickyHeader, setShowStickyHeader] = useState(false);
  const modalContentRef = useRef(null);

  let stickyHeaderPosition = props.headerImage ? 170 : 50;
  const handleModalOpened = () => {
    setShowStickyHeader(false);
    const modalContentElement = modalContentRef.current;
    if (modalContentElement) {
      modalContentElement.addEventListener("scroll", handleScrollThrottled);
    }
  };

  const handleScroll = useCallback(() => {
    if (modalContentRef.current?.scrollTop > stickyHeaderPosition) {
      setShowStickyHeader(true);
    } else {
      setShowStickyHeader(false);
    }
  }, [stickyHeaderPosition]);

  const handleScrollThrottled = useRef(
    throttle(handleScroll, 100) // Throttle the handleScroll function to be called at most once every 100 milliseconds
  ).current;

  useEffect(() => {
    if (props.stickyHeaderEnabled) {
      const modalContentElement = modalContentRef.current;

      modalContentElement?.addEventListener("scroll", handleScrollThrottled);

      return () => {
        modalContentElement?.removeEventListener("scroll", handleScrollThrottled);
      };
    }
  }, [stickyHeaderPosition, props.stickyHeaderEnabled]);

  let closeBtn = (
    <button
      aria-label="Close"
      className="close position-absolute right-3 top-3"
      data-dismiss="modal"
      type="button"
      onClick={props.onCancel}
    ></button>
  );

  let modalHeader = (
    <>
      {closeBtn}
      <div className="modal-header pl-3 pr-3 pb-0 d-flex flex-column">
        <h2 className="modal-title pr-4" id="modal-title-default">
          {props.title}
        </h2>
        <h5 className="modal-subtitle text-muted font-weight-light mt-2">{props.subtitle}</h5>
      </div>
    </>
  );

  const stickyHeader = (
    <div className={`sticky-header${showStickyHeader ? " show" : ""} p-0 pb-0`} onClick={() => window.scrollTo(0, 0)}>
      {props.stickyHeader}
    </div>
  );

  const textFields = props.textFields
    ? props.textFields.map((textField, i) => {
        return (
          <div key={i}>
            <label className="form-control-label">{textField.label}</label>
            <Input
              name={textField.name}
              placeholder={textField.label}
              type="text"
              value={textField.value}
              onChange={textField.handleChange}
            />
            <p className="text-danger">{textField.errorMsg}</p>
          </div>
        );
      })
    : [];

  const selects = props.selects
    ? props.selects.map((select, i) => {
        const selectOptions = select.options?.map((option) => {
          return (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          );
        });

        return (
          <div key={i}>
            <label className="form-control-label">{select.label}</label>
            <Input name={select.name} type="select" value={select.value} onChange={select.handleChange}>
              <option value="">{t("Choose")}</option>
              {selectOptions}
            </Input>
          </div>
        );
      })
    : [];

  let content = <></>;

  if (props.isError) {
    content = <p>{props.errorMsg ? props.errorMsg : "Could not load data. Please retry"}</p>;
  } else if (props.isLoading) {
    content = (
      <div className="w-100 d-flex justify-content-center">
        <PuffLoader
          loading={props.isLoading}
          color="#d9d9d9"
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  } else {
    content = (
      <>
        <div className="modal-body p-3">
          {textFields}
          {selects}
          {props?.body}
        </div>
        {(props.okText || props.cancelText) && (
          <div className="modal-footer p-3">
            <button
              className="btn btn-outline-default ml-0"
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={props.onCancel}
            >
              {props.cancelText}
            </button>
            {props.okText && (
              <Button
                color="primary"
                className="ml-auto mr-0"
                type="button"
                onClick={props.onOk}
                disabled={props.okDisabled}
              >
                {props.okText}
              </Button>
            )}
          </div>
        )}
      </>
    );
  }

  function handleClose() {
    props.onCancel();
    setShowStickyHeader(false);
  }
  return (
    <Modal
      className="modal-dialog-centered position-relative"
      isOpen={props.isOpen}
      toggle={handleClose}
      size={props.size}
      zIndex={props.zIdex}
      modalTransition={{ timeout: 100 }}
      onOpened={handleModalOpened}
    >
      <div className="modal-content position-relative scrollbar-hidden scrollable" ref={modalContentRef}>
        {props.stickyHeaderEnabled && stickyHeader}
        {props.header ? props.header : modalHeader}
        {content}
      </div>
    </Modal>
  );
}
