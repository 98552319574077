import React from "react";
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import { formatCurrency } from "utilities/utils";
import { KeynameToIcon, KeynameToTitle, KeynameToIconColor } from "utilities/enums/Analytics";
import { useTranslation } from "react-i18next";
import { normalizeGreek } from "utilities/utils";

export const CardMetrics = ({ value, percentage, color, key_name, report_period, ...rest }) => {
  const { t } = useTranslation();
  const title = normalizeGreek(t(KeynameToTitle[key_name]));
  const icon = KeynameToIcon[key_name];
  const iconColor = KeynameToIconColor[key_name];
  const currencyRequiredMetrics = [
    "total_sales",
    "total_net_sales",
    "average_revenue_per_customer",
    "total_gifted_orders",
    "total_pending_revenue",
    "total_discount",
    "total_invoiced_revenue",
    "total_cancelled_revenue",
    "total_tip",
    "external_source_efood",
    "external_source_wolt",
  ];

  return (
    <Card className="card-stats mb-3 mb-md-4">
      <CardBody>
        <Row>
          <div className="col-10 col-md-8 pr-0">
            <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
              {title}
            </CardTitle>
            <span className="h2 font-weight-bold mb-0">
              {currencyRequiredMetrics.includes(key_name) ? formatCurrency(value) : value}
            </span>
          </div>
          <Col className="col-2 col-md-4">
            <div className={`icon icon-shape ${iconColor} text-white rounded-circle shadow`}>
              <i className={icon} />
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
