import { TagTypes } from "utilities/enums/TagTypes";
import { apiSlice } from "./apiSlice";
import { REACT_APP_ANALYTICS_URL } from "utilities/constants";
import { store } from "redux/store";
const analyticsUrl = REACT_APP_ANALYTICS_URL;

export const apiAnalytics = apiSlice.injectEndpoints({
  tagTypes: [TagTypes.Analytics],
  endpoints: (builder) => ({
    getAnalyticsCharts: builder.query({
      providesTags: [TagTypes.Analytics],
      query: () => ({
        url: `${analyticsUrl}/chart?filters[organisation][id]=${store.getState().auth.organisationId}`,
      }),
    }),
    getMetrics: builder.query({
      providesTags: [TagTypes.Analytics],
      query: ({ reportPeriod }) => ({
        url: `${analyticsUrl}/analytics-metrics?filters[organisation][id]=${
          store.getState().auth.organisationId
        }&filters[report_period]=${reportPeriod}`,
      }),
    }),
    refreshDashboard: builder.mutation({
      query: () => ({
        url: `${analyticsUrl}/refresh-dashboard/${store.getState().auth.organisationId}`,
        method: "POST",
      }),
    }),
    updateCustomChart: builder.mutation({
      query: (body) => ({
        url: `${analyticsUrl}/custom-charts?filters[organisation][id]=${store.getState().auth.organisationId}`,
        method: "POST",
        body,
      }),
    }),
    runAllAnalytics: builder.mutation({
      query: (body) => ({
        url: `${analyticsUrl}/update-analytics?filters[organisation][id]=${body.organisationId}`,
        method: "POST",
        body,
      }),
    }),
    updateAnalytics: builder.mutation({
      query: (body) => ({
        url: `${analyticsUrl}/update-analytics?filters[organisation][id]=${store.getState().auth.organisationId}`,
        method: "POST",
        body,
      }),
    }),
    getAnalyticsMetrics: builder.query({
      providesTags: [TagTypes.Analytics],
      query: ({ reportPeriod }) => ({
        url: `${analyticsUrl}/metrics?filters[organisation][id]=${
          store.getState().auth.organisationId
        }&filters[report_period]=${reportPeriod}`,
      }),
    }),
    getAnalyticsOrders: builder.query({
      providesTags: [TagTypes.Analytics],
      query: ({ reportPeriod }) => ({
        url: `${analyticsUrl}/orders?filters[organisation][id]=${
          store.getState().auth.organisationId
        }&filters[report_period]=${reportPeriod}`,
      }),
    }),
    getAnalyticsSales: builder.query({
      providesTags: [TagTypes.Analytics],
      query: ({ reportPeriod }) => ({
        url: `${analyticsUrl}/sales?filters[organisation][id]=${
          store.getState().auth.organisationId
        }&filters[report_period]=${reportPeriod}`,
      }),
    }),
    getSalesOrigin: builder.query({
      providesTags: [TagTypes.Analytics],
      query: ({ reportPeriod }) => ({
        url: `${analyticsUrl}/sales-origin?filters[organisation][id]=${
          store.getState().auth.organisationId
        }&filters[report_period]=${reportPeriod}`,
      }),
    }),
    getAnalyticsTopTenProducts: builder.query({
      providesTags: [TagTypes.Analytics],
      query: ({ reportPeriod }) => ({
        url: `${analyticsUrl}/top-ten-products?filters[organisation][id]=${
          store.getState().auth.organisationId
        }&filters[report_period]=${reportPeriod}`,
      }),
    }),
    getAnalyticsTrendingProducts: builder.query({
      providesTags: [TagTypes.Analytics],
      query: ({ reportPeriod }) => ({
        url: `${analyticsUrl}/trending-products?filters[organisation][id]=${
          store.getState().auth.organisationId
        }&filters[report_period]=${reportPeriod}`,
      }),
    }),
    getAnalyticsUserSales: builder.query({
      providesTags: [TagTypes.Analytics],
      query: ({ reportPeriod }) => ({
        url: `${analyticsUrl}/user-sales?filters[organisation][id]=${
          store.getState().auth.organisationId
        }&filters[report_period]=${reportPeriod}`,
      }),
    }),
    updateUserSales: builder.mutation({
      query: (body) => ({
        url: `${analyticsUrl}/user-sales?filters[organisation][id]=${store.getState().auth.organisationId}`,
        method: "POST",
        body,
      }),
    }),
    getAnalyticsProdcutSales: builder.query({
      providesTags: [TagTypes.Analytics],
      query: ({ reportPeriod }) => ({
        url: `${analyticsUrl}/product-sales?filters[organisation][id]=${
          store.getState().auth.organisationId
        }&filters[report_period]=${reportPeriod}`,
      }),
    }),
    updateProductSales: builder.mutation({
      query: (body) => ({
        url: `${analyticsUrl}/product-sales?filters[organisation][id]=${store.getState().auth.organisationId}`,
        method: "POST",
        body,
      }),
    }),
    updateSalesOverview: builder.mutation({
      query: (body) => ({
        url: `${analyticsUrl}/sales-overview?filters[organisation][id]=${store.getState().auth.organisationId}`,
        method: "POST",
        body,
      }),
    }),
    updateOrdersOverview: builder.mutation({
      query: (body) => ({
        url: `${analyticsUrl}/orders-overview?filters[organisation][id]=${store.getState().auth.organisationId}`,
        method: "POST",
        body,
      }),
    }),
    getAnalyticsChart: builder.query({
      providesTags: [TagTypes.Analytics],
      query: (chartId) =>
        `${analyticsUrl}/chart/${chartId}?filters[organisation][id]=${store.getState().auth.organisationId}`,
    }),
    getProductsTable: builder.query({
      providesTags: [TagTypes.Analytics],
      query: ({ keyname, reportPeriod, limit, page, sortBy, sortDir }) => ({
        url: `${analyticsUrl}/products-table?filters[organisation][id]=${
          store.getState().auth.organisationId
        }&filters[report_period]=${reportPeriod}&limit=${limit}&page=${page}&sortBy=${sortBy}&sortDir=${sortDir}&keyname=${keyname}`,
      }),
    }),
    updateChartById: builder.mutation({
      query: ({ chartId, body }) => ({
        url: `${analyticsUrl}/chart/${chartId}?filters[organisation][id]=${store.getState().auth.organisationId}`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useGetAnalyticsChartsQuery,
  useGetAnalyticsChartQuery,
  useGetAnalyticsMetricsQuery,
  useGetAnalyticsOrdersQuery,
  useGetAnalyticsSalesQuery,
  useGetAnalyticsTopTenProductsQuery,
  useGetAnalyticsTrendingProductsQuery,
  useUpdateCustomChartMutation,
  useGetAnalyticsUserSalesQuery,
  useUpdateUserSalesMutation,
  useGetAnalyticsProdcutSalesQuery,
  useUpdateProductSalesMutation,
  useUpdateAnalyticsMutation,
  useRunAllAnalyticsMutation,
  useUpdateSalesOverviewMutation,
  useUpdateOrdersOverviewMutation,
  useGetMetricsQuery,
  useGetSalesOriginQuery,
  useUpdateChartByIdMutation,
  useLazyGetProductsTableQuery,
  useGetProductsTableQuery,
  useRefreshDashboardMutation,
} = apiAnalytics;
