import React from "react";
import { useGetSubCategoriesQuery, useDeleteSubCategoryMutation } from "api/subcategoriesSlice";
import { useGetAllCategoriesQuery, useUpdateCategoryMutation } from "api/categoriesSlice";

const useSubCategories = () => {
  const [newSubcategoryDialogOpen, setNewSubcategoryDialogOpen] = React.useState(false);
  const [editSubcategoryDialogOpen, setEditSubcategoryDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const { data: categories, isLoading: isLoadingCategories, isError: isErrorCategories } = useGetAllCategoriesQuery();
  const [selectedRow, setSelectedRow] = React.useState({});
  const [filterModel, setFilterModel] = React.useState("");
  const {
    data: subcategories,
    isLoading,
    isError,
  } = useGetSubCategoriesQuery(filterModel ? parseInt(filterModel) : "");
  const [deleteSubCategory] = useDeleteSubCategoryMutation();
  const [updateCategory] = useUpdateCategoryMutation();

  function handleEditDialogOpen(selectedRow) {
    setSelectedRow(selectedRow);
    setEditSubcategoryDialogOpen(true);
  }

  function handleDeleteDialogOpen(selectedRow) {
    setSelectedRow(selectedRow);
    setDeleteDialogOpen(true);
  }

  function handleDeleteSubCategory() {
    const parentCategory = categories.filter((category) => category.name === selectedRow.parent_category)[0];
    deleteSubCategory(selectedRow.id).then(() => {
      // delete the deleted subcategory from the "categories" array of the parent category
      updateCategory({
        ...parentCategory,
        categories: parentCategory.categories.filter((category) => category.id !== selectedRow.id),
      });
    });
    setDeleteDialogOpen(false);
  }

  return {
    categories,
    handleEditDialogOpen,
    deleteDialogOpen,
    handleDeleteDialogOpen,
    handleDeleteSubCategory,
    filterModel,
    subcategories,
    selectedRow,
    setFilterModel,
    isLoading,
    isError,
    setDeleteDialogOpen,
    newSubcategoryDialogOpen,
    setNewSubcategoryDialogOpen,
    editSubcategoryDialogOpen,
    setEditSubcategoryDialogOpen,
    isLoadingCategories,
    isErrorCategories,
  };
};

export default useSubCategories;
