import { createSlice } from "@reduxjs/toolkit";
import { ProductService } from "api/productsSlice";
import { logError } from "utilities/utils";

const initialState = {
  productQuantities: [],
};

export const productQuantitiesSlice = createSlice({
  name: "productQuantities",
  initialState: initialState,
  reducers: {
    getCurrentProductQuantity: (state, action) => {
      return state.productQuantities?.filter((item) => item.id === action.payload.id);
    },
  },
  extraReducers: (builder) => {
    // Reducer is bound to the RTK Endpoint automatically by using Matcher pattern.
    // After the RTK call the reducer is automatically called, and updates the Redux store depending on the newly fetched data
    builder.addMatcher(ProductService.endpoints.getAllEnabledProducts.matchFulfilled, (state, { payload }) => {
      if (!payload) {
        return;
      }

      state.productQuantities = payload
        ?.filter((item) => item.inventoryEnabled === true)
        ?.map((item) => {
          if (!state.productQuantities?.find((product) => product.id === item.id)) {
            return {
              id: item.id,
              quantity: item.inventory,
              inventoryEnabled: item.inventoryEnabled,
            };
          } else {
            return {
              ...state.productQuantities?.find((product) => product.id === item.id),
              quantity: item.inventory,
              inventoryEnabled: item.inventoryEnabled,
            };
          }
        });
    });
    builder.addMatcher(ProductService.endpoints.getProductById.matchFulfilled, (state, { payload }) => {
      if (!payload) {
        return;
      }

      if (payload?.inventoryEnabled === true) {
        if (!state.productQuantities?.find((product) => product.id === payload.id)) {
          state.productQuantities = [
            ...state.productQuantities,
            {
              id: payload.id,
              quantity: payload.inventory,
              inventoryEnabled: payload.inventoryEnabled,
            },
          ];
        } else {
          state.productQuantities = state.productQuantities?.map((item) => {
            if (item.id === payload.id) {
              return {
                ...item,
                quantity: payload.inventory,
                inventoryEnabled: payload.inventoryEnabled,
              };
            } else {
              return item;
            }
          });
        }
      }
    });
  },
});

export const { getCurrentProductQuantity } = productQuantitiesSlice.actions;

export default productQuantitiesSlice.reducer;

export const selectProductQuantities = (state) => state.productQuantities?.productQuantities;
