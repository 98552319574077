import React from "react";
import { useGetPosTerminalsQuery, useDeletePosTerminalMutation } from "api/posTerminalSlice";
import { useUpdatePrintScenarioMutation } from "api/printScenariosSlice";

const usePosTerminals = () => {
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [newPosTerminalDialogOpen, setNewPosTerminalDialogOpen] = React.useState(false);
  const [editPosTerminalDialogOpen, setEditPosTerminalDialogOpen] = React.useState(false);
  const [rowClicked, setRowClicked] = React.useState({});
  const [selectedPosTerminal, setSelectedPosTerminal] = React.useState(null);
  const { data: posTerminals, isLoading: posTerminalsLoading, isError: posTerminalsError } = useGetPosTerminalsQuery();
  const [deletePosTerminal, { isLoading: deletePosTerminalLoading }] = useDeletePosTerminalMutation();
  const [updatePrintScenario] = useUpdatePrintScenarioMutation();

  function handleEditCategoryClick(row) {
    setRowClicked(row);
  }

  async function handleDeletePosTerminal() {
    await deletePosTerminal(selectedPosTerminal.id);
    setDeleteDialogOpen(false);
  }

  function handleScenarioClick(scenario) {
    setEditPosTerminalDialogOpen(true);
    setSelectedPosTerminal(scenario);
  }

  function handleDeleteDialogOpen(scenario) {
    setSelectedPosTerminal(scenario);
    setDeleteDialogOpen(true);
  }

  async function handleUpdateToggleScenario(scenarioInfo) {
    await updatePrintScenario({ id: scenarioInfo.id, active: !scenarioInfo.active });
  }
  async function handleNewPosTerminalSubmit() {}
  async function addPosTerminalLoading() {}

  return {
    newPosTerminalDialogOpen,
    setNewPosTerminalDialogOpen,
    handleEditCategoryClick,
    deleteDialogOpen,
    setDeleteDialogOpen,
    handleDeleteDialogOpen,
    handleDeletePosTerminal,
    rowClicked,
    posTerminalsLoading,
    posTerminalsError,
    posTerminals,
    selectedPosTerminal,
    handleScenarioClick,
    editPosTerminalDialogOpen,
    setEditPosTerminalDialogOpen,
    deletePosTerminalLoading,
    handleUpdateToggleScenario,
    handleNewPosTerminalSubmit,
    addPosTerminalLoading,
  };
};

export default usePosTerminals;
