/**
 * Stores generic state for the Application.
 * Global state for components not large enough for a dedicated slice of Redux Store
 */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedTablesTab: 0,
  isSubscribed: false,
  versionError: false,
};

export const genericStateSlice = createSlice({
  name: "generic",
  initialState: initialState,
  reducers: {
    setSelectedTablesTab: (state, action) => {
      state.selectedTablesTab = action.payload;
    },
    setIsSubscribed: (state, action) => {
      state.isSubscribed = action.payload;
    },
    setVersionError: (state, action) => {
      state.versionError = action.payload;
    },
  },
});

export const { setSelectedTablesTab } = genericStateSlice.actions;
export const { setIsSubscribed } = genericStateSlice.actions;
export const { setVersionError } = genericStateSlice.actions;

export default genericStateSlice.reducer;
