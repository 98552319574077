import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetPendingOrdersByTableQuery,
  useGetOtherTablesQuery,
  useUpdateOrderStatusMutation,
  useAddOrderMutation,
} from "api/ordersSlice";
import { OrderStatus } from "utilities/enums/OrderStatus";
import { broadCastOrdersChangeWS } from "config/websocket";
import { selectCurrentUser, selectCurrentOrganisation } from "auth/authSlice";
import { useSelector } from "react-redux";

const useTransferOrder = () => {
  const { table } = useParams();
  const navigate = useNavigate();
  const { data: pendingOrders, isLoading, isError } = useGetPendingOrdersByTableQuery(parseInt(table));
  const { data: otherTables } = useGetOtherTablesQuery(parseInt(table));
  const { username: user } = useSelector(selectCurrentUser);
  const organisationId = useSelector(selectCurrentOrganisation);

  const [selectedNewOrderItems, setSelectedNewOrderItems] = React.useState([]);
  const [selectedTable, setSelectedTable] = React.useState("");
  const [selectedOrderTotal, setSelectedOrderTotal] = React.useState(0);
  const [updateOrderStatus, { isLoading: updateOrderStatusLoading }] = useUpdateOrderStatusMutation();
  const [addTransFeredOrder, { isLoading: addTransferedOrderLoading }] = useAddOrderMutation();

  useEffect(() => {
    setSelectedOrderTotal(
      selectedNewOrderItems
        .reduce((total, item) => {
          let v = item.variant.reduce((x, variant) => {
            let variantOptionPrice = variant.variant_option.data.attributes.price;
            if (variant.quantity && variant.quantity > 1) {
              variantOptionPrice = variantOptionPrice * variant.quantity;
            }
            return (x += variantOptionPrice * item.quantity);
          }, 0);
          return total + item.product.data.attributes.price * item.quantity + v;
        }, 0)
        .toFixed(2)
    );
  }, [selectedNewOrderItems]);

  function handleChangeSelectedTable(newTable) {
    setSelectedTable(newTable.target.value);
  }

  function handleOrderItemQuantityChange(id, q) {
    setSelectedNewOrderItems(
      selectedNewOrderItems.map((item) => {
        if (item.id === id) {
          return { ...item, quantity: item.quantity + q };
        }
        return item;
      })
    );
  }

  function handleCheckItemToggle(item) {
    if (item === "all") {
      let allOrderDetailsItems = pendingOrders.data.map((order) => order.orderDetails).flat();
      if (selectedNewOrderItems.length === allOrderDetailsItems.length) {
        setSelectedNewOrderItems([]);
      } else {
        setSelectedNewOrderItems(allOrderDetailsItems);
      }
    } else {
      if (selectedNewOrderItems.filter((i) => i.id === item.id).length > 0) {
        setSelectedNewOrderItems(() => {
          return selectedNewOrderItems.filter((i) => i.id !== item.id);
        });
      } else {
        setSelectedNewOrderItems(() => {
          return [...selectedNewOrderItems, item];
        });
      }
    }
  }

  function updateQuantityDifferenceObject(array1, array2) {
    return array1.map((object1) => {
      return array2.map((object2) => {
        if (object1.id === object2.id && object1.quantity !== object2.quantity) {
          return {
            ...object1,
            quantity: object2.quantity < object1.quantity ? object1.quantity - object2.quantity : 0, // if he increase quantity, old quantity is 0 else old quantity is old quantity - new quantity
          };
        } else if (object1.id === object2.id && object1.quantity === object2.quantity) {
          return {
            ...object1,
            quantity: 0,
          };
        }
        return null;
      });
    });
  }

  function handleOrderTransferItems() {
    const orderstoBeDisabled = pendingOrders.data.filter((order) =>
      order.orderDetails.some(
        (item) => selectedNewOrderItems.filter((i) => parseInt(i.id) === parseInt(item.id)).length > 0
      )
    );

    const newOrder = {
      tableId: parseInt(selectedTable),
      details: selectedNewOrderItems,
      submittedBy: user,
    };

    //get all orderDetails
    const allItems = [];
    //get only partily updated
    orderstoBeDisabled.forEach((order) => allItems.push(...order.orderDetails));
    //get all orderDetails that are not in the new order
    const quantityChangedItems = updateQuantityDifferenceObject(allItems, selectedNewOrderItems)
      .flat(1)
      .filter((item) => item != null)
      .filter((item) => item.quantity > 0);

    const oldItems = allItems.filter(
      (item) => selectedNewOrderItems.filter((i) => parseInt(i.id) === parseInt(item.id)).length === 0
    );

    const oldOrderItems = quantityChangedItems.concat(oldItems);

    const oldOrder = {
      tableId: parseInt(table),
      details: oldOrderItems,
      submittedBy: user,
    };

    const uniqueOrderIds = [...new Set(orderstoBeDisabled.map((order) => order.id))];
    addTransFeredOrder(newOrder)
      .unwrap()
      .then(() => {
        var asyncTasks = [];
        uniqueOrderIds.forEach((orderId) => {
          asyncTasks.push(updateOrderStatus({ orderId: orderId, status: OrderStatus.Disabled }));
        });

        if (oldOrderItems.length > 0) {
          // create a new pending order only if oldorderitems has products
          asyncTasks.push(addTransFeredOrder(oldOrder));
        }

        Promise.all(asyncTasks).then(() => {
          broadCastOrdersChangeWS(organisationId);
          navigate("/admin/tables");
        });
      });
  }

  return {
    pendingOrders,
    isLoading,
    isError,
    selectedNewOrderItems,
    selectedOrderTotal,
    selectedTable,
    handleChangeSelectedTable,
    handleOrderItemQuantityChange,
    handleCheckItemToggle,
    handleOrderTransferItems,
    otherTables,
    updateOrderStatusLoading,
    addTransferedOrderLoading,
  };
};

export default useTransferOrder;
