import { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { ProductService } from "api/productsSlice";
import { TagTypes } from "utilities/enums/TagTypes";
import { apiOrders } from "api/ordersSlice";
import { subscribeToOrdersChangeWS } from "config/websocket";
import { selectCartItems } from "redux/cart/cartSlice";
import { selectCartTable } from "redux/cart/cartSlice";
import { selectCurrentOrganisation } from "auth/authSlice";
import { useGetAllEnabledProductsQuery } from "api/productsSlice";

const useNewOrderProducts = () => {
  const { table, parentCategory } = useParams();

  const cartItems = useSelector(selectCartItems);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: products, isLoading, isError } = useGetAllEnabledProductsQuery(parentCategory);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [selectedProduct, setselectedProduct] = useState(null);
  const cartTable = useSelector(selectCartTable);
  const organisationId = useSelector(selectCurrentOrganisation);

  function refreshComponent() {
    dispatch(apiOrders.util.invalidateTags([TagTypes.Orders]));
    dispatch(
      ProductService.util.invalidateTags([TagTypes.Products, TagTypes.Categories, TagTypes.Variants, TagTypes.Orders])
    );
    forceUpdate();
  }

  function handleProductClick(product) {
    setselectedProduct(product);
  }

  function handleProductClose() {
    setselectedProduct(null);
  }

  const getTotalAlreadyOrdered = (cartProduct) => {
    const totalAlreadyOrdered = cartItems.reduce((total, item) => {
      return total + (item.id === cartProduct.id ? item.quantity : 0);
    }, 0);
    return totalAlreadyOrdered;
  };
  const getItemQuantity = (cartProduct) => {
    const item = cartItems.find((item) => item.id === cartProduct.id);
    return item ? item.quantity : 0;
  };

  useEffect(() => {
    subscribeToOrdersChangeWS(organisationId, refreshComponent);
  }, []);

  return {
    cartItems,
    dispatch,
    navigate,
    products,
    isLoading,
    isError,
    table,
    parentCategory,
    forceUpdate,
    refreshComponent,
    handleProductClick,
    getTotalAlreadyOrdered,
    getItemQuantity,
    selectedProduct,
    handleProductClose,
    cartTable,
  };
};

export default useNewOrderProducts;
