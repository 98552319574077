import { useGetAnalyticsMetricsQuery } from "api/analyticsSlice";
import { useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { subscribeToChartsChangeWS } from "config/websocket";
import { useEffect, useReducer, useState } from "react";
import { useDispatch } from "react-redux";
import { TagTypes } from "utilities/enums/TagTypes";
import { apiAnalytics } from "api/analyticsSlice";
import { DEFAULT_METRICS } from "utilities/analytics/charts";
import PuffLoader from "react-spinners/PuffLoader";
import { t } from "i18next";
import { CardMetrics } from "components/CardMetrics";
import { selectCurrentOrganisation } from "auth/authSlice";
export const KeyMetrics = ({ reportPeriod, metricsToRender, defaultSize = "3" }) => {
  const dispatch = useDispatch();

  const {
    data: metrics,
    isLoading: metricsLoading,
    isError: metricsError,
  } = useGetAnalyticsMetricsQuery({
    reportPeriod: reportPeriod,
  });
  const {
    customers_per_order_enabled,
    discounts_enabled,
    manual_cloud_cash_register_enabled,
    cloud_cash_register_enabled,
    pos_integration_enabled,
    external_source_types_enabled,
  } = useSelector((state) => state.settings);
  const organisationId = useSelector(selectCurrentOrganisation);

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  function refreshComponent() {
    dispatch(apiAnalytics.util.invalidateTags([TagTypes.Analytics]));
    forceUpdate();
  }
  useEffect(() => {
    subscribeToChartsChangeWS(organisationId, refreshComponent);
  });
  /**
   * Finds out if we should render a metric depending on the Application Settings
   * So far conditions:
   * 1) If the "Customers per table" setting is disabled, we do not show the related metrics
   */
  function shouldRenderMetric(metric) {
    return !(
      (!customers_per_order_enabled && (metric === "total_customers" || metric === "average_revenue_per_customer")) ||
      (!discounts_enabled && metric === "total_discount") ||
      ((!manual_cloud_cash_register_enabled || !cloud_cash_register_enabled) && metric === "total_invoiced_revenue") ||
      (!pos_integration_enabled && metric === "total_tip") ||
      (!external_source_types_enabled && (metric === "external_source_wolt" || metric === "external_source_efood"))
    );
  }

  if (metricsLoading) {
    <PuffLoader loading={metricsLoading} color="#d9d9d9" size={50} aria-label="Loading Spinner" data-testid="loader" />;
  }
  if (metricsError) {
    return (
      <Row className="row-grid">
        <Col>Error</Col>
      </Row>
    );
  }

  return (
    <Card>
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <Col xs="12">
            {<h6 className="text-light text-uppercase ls-1 mb-1">{t("Key_metrics_subtitle")}</h6>}
            <h5 className="h3 mb-0">{t("Key_metrics")}</h5>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="d-flex flex-wrap p-2 pt-3">
        {!metricsLoading &&
          !metricsError &&
          Object.keys(metrics).length === 0 &&
          DEFAULT_METRICS &&
          metricsToRender.map(
            (metric) =>
              shouldRenderMetric(DEFAULT_METRICS[metric]?.key_name) && (
                <Col key={DEFAULT_METRICS[metric]?.id} xs="12" lg={defaultSize} className="mt-0 pl-2 pr-2">
                  <CardMetrics {...DEFAULT_METRICS[metric]?.chartData} key_name={metric} report_period={reportPeriod} />
                </Col>
              )
          )}
        {metrics &&
          Object.keys(metrics).length > 0 &&
          metricsToRender &&
          metricsToRender.map(
            (metric) =>
              shouldRenderMetric(metric) && (
                <Col key={metrics[metric]?.id} xs="12" lg={defaultSize} className="mt-0 pl-2 pr-2">
                  <CardMetrics {...metrics[metric]?.chartData} key_name={metric} report_period={reportPeriod} />
                </Col>
              )
          )}
      </CardBody>
    </Card>
  );
};
