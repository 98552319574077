import React from "react";
import useTransferOrder from "./useTransferOrder";
import usePayItems from "../PayItems/usePayItems";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import { formatCurrency, mergeToCommaSepString } from "utilities/utils";
import DefaultLayout from "templates/DefaultLayout";
import ScrollableTable from "components/Tables/ScrollableTable";
import { Views } from "utilities/enums/Views";
import { useTranslation } from "react-i18next";
import { selectCartTable } from "redux/cart/cartSlice";
import { useSelector } from "react-redux";

function TransferOrder() {
  const {
    pendingOrders,
    isLoading,
    isError,
    selectedNewOrderItems,
    selectedOrderTotal,
    selectedTable,
    handleChangeSelectedTable,
    handleOrderItemQuantityChange,
    handleCheckItemToggle,
    handleOrderTransferItems,
    otherTables,
    updateOrderStatusLoading,
    addTransferedOrderLoading,
  } = useTransferOrder();
  const { getProductPrice } = usePayItems();
  const { t } = useTranslation();
  const cartTable = useSelector(selectCartTable);

  const isCheckedAll =
    selectedNewOrderItems.length === pendingOrders?.data.map((order) => order.orderDetails).flat().length;

  let header = <h3 className="mb-0">{t(Views.TRANSFER_ITEMS)}</h3>;

  function getSelectedItem(id) {
    return selectedNewOrderItems.find((i) => i.id === id);
  }

  return (
    <DefaultLayout
      name={t(Views.TRANSFER_ITEMS)}
      parentName={Views.TABLE_ACTIONS}
      isLoading={isLoading}
      isError={isError}
      header={header}
      hasBackButton={true}
      table={cartTable}
      classNames="transfer-items-view"
    >
      <ScrollableTable id="transfer-items-table" className="align-items-center table-flush responsive-table">
        <thead className="thead-light">
          <tr>
            <th onClick={() => handleCheckItemToggle("all")}>
              <div className="custom-control custom-checkbox">
                <input
                  className="custom-control-input"
                  id="checkAll"
                  type="checkbox"
                  onChange={() => handleCheckItemToggle("all")}
                  checked={isCheckedAll}
                />
                <label className="custom-control-label" htmlFor="checkAll"></label>
              </div>
            </th>
            <th scope="col">{t("Product")}</th>
            <th className="text-center" scope="col">
              {t("Total")}
            </th>
          </tr>
        </thead>
        <tbody>
          {pendingOrders &&
            pendingOrders.data.map((item) =>
              item.orderDetails.map((orderItem) => (
                <tr key={orderItem.id}>
                  <td onClick={() => handleCheckItemToggle(orderItem)} className="hoverable">
                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        id={`flexCheckDefault-${orderItem.id}`}
                        type="checkbox"
                        checked={selectedNewOrderItems.filter((i) => i.id === orderItem.id).length > 0}
                        onChange={() => handleCheckItemToggle(orderItem)}
                      />
                      <label className="custom-control-label" htmlFor={`flexCheckDefault-${orderItem.id}`}></label>
                    </div>
                  </td>
                  <td onClick={() => handleCheckItemToggle(orderItem)} className="hoverable">
                    <div className="d-flex">
                      <div className="d-flex flex-column">
                        <p className="mb-0 text-left font-weight-bold">{orderItem.product.data.attributes.name}</p>
                        <p className="product-variants text-left mb-0">
                          {mergeToCommaSepString(
                            orderItem.variant.map((variant) => {
                              if (variant.quantity && variant.quantity > 1) {
                                return `x${variant.quantity} ${variant.variant_option.data.attributes.label}`;
                              }

                              return variant.variant_option.data.attributes.label;
                            })
                          )}
                        </p>
                        <p className="product-notes text-left mb-0">{orderItem.notes}</p>
                        <span className="price text-left mb-0">{getProductPrice(orderItem)}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex flex-column align-items-center ml-2">
                      <div className="d-flex">
                        <Button
                          className="btn-icon-only rounded-circle product-quantity-btn-circle"
                          color="danger"
                          onClick={() => handleOrderItemQuantityChange(orderItem.id, -1)}
                          disabled={!getSelectedItem(orderItem.id) || getSelectedItem(orderItem.id)?.quantity === 1}
                        >
                          <i className="fa fa-minus" />
                        </Button>
                        <p className="m-1 font-weight-bold">
                          {getSelectedItem(orderItem.id) ? getSelectedItem(orderItem.id).quantity : orderItem.quantity}
                        </p>
                        <Button
                          className="btn-icon-only rounded-circle product-quantity-btn-circle"
                          color="success"
                          onClick={() => handleOrderItemQuantityChange(orderItem.id, 1)}
                          disabled={
                            !getSelectedItem(orderItem.id) ||
                            getSelectedItem(orderItem.id)?.quantity === orderItem.quantity
                          }
                        >
                          <i className="fa fa-plus" />
                        </Button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            )}
        </tbody>
      </ScrollableTable>
      <div className="card-footer py-4">
        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="exampleFormControlSelect1">
                {t("Select_table")}
              </label>
              <Input
                type="select"
                name="select"
                id="exampleSelect"
                value={selectedTable}
                onChange={handleChangeSelectedTable}
              >
                <option value="">{t("Select_table")}</option>
                {otherTables &&
                  otherTables.data.map((table) => (
                    <option key={table.id} value={table.id}>
                      {table.name}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="6" className="d-flex flex-direction-column align-items-end justify-content-end">
            <p className="text-right total-price">
              <span className="text-muted">{t("Total")}: </span>
              <span className="text-primary font-weight-bold">{formatCurrency(selectedOrderTotal)}</span>
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <Button
                id="transfer-items-btn"
                className="col-12 col-md-3 btn btn-primary"
                color="primary"
                disabled={
                  selectedNewOrderItems.length === 0 ||
                  selectedTable === "" ||
                  updateOrderStatusLoading ||
                  addTransferedOrderLoading
                }
                onClick={() => handleOrderTransferItems()}
              >
                {t("Transfer")}
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </DefaultLayout>
  );
}

export default TransferOrder;
