import { useDispatch } from "react-redux";
import { apiAnalytics, useRefreshDashboardMutation } from "api/analyticsSlice";
import { Button } from "reactstrap";
import { TagTypes } from "utilities/enums/TagTypes";
import { useTranslation } from "react-i18next";

export const RefreshDashboard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [refreshDashboard, { isLoading }] = useRefreshDashboardMutation();

  return (
    <Button
      className="btn-icon border-0 bg-transparent text-neutral shadow-none p-0 mt-0"
      type="button"
      title={t("Refresh_analytics")}
      onClick={async () => {
        await refreshDashboard();
        dispatch(apiAnalytics.util.invalidateTags([TagTypes.Analytics]));
      }}
      disabled={isLoading}
    >
      <span className="text-md">
        <i className={isLoading ? `fas fa-sync fa-spin` : `fas fa-redo`} />
      </span>
    </Button>
  );
};
