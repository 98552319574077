import { TagTypes } from "utilities/enums/TagTypes";
import { apiSlice } from "./apiSlice";
import { store } from "redux/store";

export const customerSlice = apiSlice.injectEndpoints({
  tagTypes: [TagTypes.Customers],
  endpoints: (builder) => ({
    searchCustomers: builder.query({
      query: (searchTerm) => {
        const encodedSearchTerm = encodeURIComponent(searchTerm);
        return `customers?filters[organisation][id]=${
          store.getState().auth.organisationId
        }&filters[name][$containsi]=${encodedSearchTerm}&populate=*&sort[0]=name:asc&pagination[limit]=10`;
      },
      providesTags: [TagTypes.Customers],
      transformResponse: (responseData) => {
        return responseData.data;
      },
    }),
  }),
});

export const { useLazySearchCustomersQuery } = customerSlice;
