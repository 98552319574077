import { useGetActivePrintersQuery, useGetPrintServerPrintersQuery } from "api/printerSlice";
import { useNavigate } from "react-router-dom";

export const usePrinterManagement = () => {
  const navigate = useNavigate();
  const { data: printers, isError: printersError, isLoading: printersLoading } = useGetPrintServerPrintersQuery();
  const { data: activePrinters, isError: entityError, isLoading: entityLoading } = useGetActivePrintersQuery();

  const handleEditClick = async (printer) => {
    navigate(`/admin/settings/edit-printer/${printer.id}`);
  };

  return {
    printers,
    printersError,
    printersLoading,
    handleEditClick,
    activePrinters,
    entityError,
    entityLoading,
  };
};
