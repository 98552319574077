import React, { useState } from "react";
import AlertModal from "./AlertModal";
import { t } from "i18next";
import { Input } from "reactstrap";
import { FormGroup } from "reactstrap";
export default function CancellationReasonModal(props) {
  const [reason, setReason] = useState("");
  const [returnInventory, setReturnInventory] = useState(false);
  return (
    <AlertModal
      isOpen={props.isOpen}
      title={props.title}
      okText={props.okText}
      cancelText={props.cancelText}
      onCancel={props.onCancel}
      onOk={() => props.onOk(reason, returnInventory)}
      okDisabled={props.okDisabled}
      body={
        <div className="payment-method-dialog d-flex flex-column">
          <FormGroup>
            <label className="form-control-label" htmlFor="name">
              {t("Enter_cancellation_reason")}
            </label>
            <Input
              className="form-control-alternative"
              id="name"
              name="name"
              placeholder={t("Cancellation_reason")}
              type="text"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </FormGroup>
          {props.showReturnInventory && (
            <FormGroup>
              <p className="mb-2 text-sm">{t("Cancel_inventory_message")}</p>
              <div className="custom-control custom-checkbox d-inline">
                <input
                  className="custom-control-input"
                  type="checkbox"
                  id="return_inventory"
                  name="return_inventory"
                  checked={returnInventory}
                  onChange={(e) => setReturnInventory(e.target.checked)}
                />
                <label className="custom-control-label" htmlFor="return_inventory">
                  {t("Return_inventory")}
                </label>
              </div>
            </FormGroup>
          )}
        </div>
      }
    />
  );
}
