import React from "react";
import { Row, Button, Col, FormGroup, Input } from "reactstrap";
import useNewCategory from "./useNewCategory";
import MediaManager from "components/MediaManager/MediaManager";
import { useTranslation } from "react-i18next";
import AlertModal from "components/Modals/AlertModal";

function NewCategory(props) {
  const {
    inputs,
    setInputs,
    handleNewCategorySubmit,
    handleInputChange,
    addCategoryLoading,
    selectImageDialog,
    setSelectImageDialog,
  } = useNewCategory(props);
  const { t } = useTranslation();

  return (
    <>
      <AlertModal
        isOpen={props.isOpen}
        title={t("New_Category")}
        onCancel={props.onCancel}
        okText={t("Add")}
        cancelText={t("Cancel")}
        onOk={handleNewCategorySubmit}
        okDisabled={addCategoryLoading}
        body={
          <>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    {t("Name")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="newCategory"
                    name="newCategory"
                    placeholder={t("Name")}
                    type="text"
                    value={inputs.newCategory}
                    onChange={handleInputChange}
                  />
                  <p className="text-danger">{inputs.errors.newCategory}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="mb-4">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="name">
                    {t("Rank_label")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="rank"
                    name="rank"
                    min="1"
                    placeholder={t("Rank_label")}
                    type="number"
                    value={inputs.rank ? inputs.rank : ""}
                    onChange={handleInputChange}
                  />
                  <p className="text-danger m-0">{inputs.errors.rank}</p>
                </FormGroup>
                <div className="hint">{t("Rank_product_field_hint")}</div>
              </Col>
            </Row>
          </>
        }
      ></AlertModal>
      <MediaManager
        isOpen={selectImageDialog}
        onCancel={() => {
          setSelectImageDialog(false);
        }}
        onOk={() => {
          setSelectImageDialog(false);
        }}
        selectedImage={inputs.categoryImage}
        setSelectedImage={(image) => {
          setInputs({ ...inputs, categoryImage: image });
        }}
      />
    </>
  );
}

export default NewCategory;
