import React from "react";
import {
  Row,
  Button,
  Col,
  FormGroup,
  Input,
  Table,
  Card,
  CardHeader,
  Collapse,
  CardBody,
  ListGroup,
  ListGroupItem,
  ListGroupItemText,
} from "reactstrap";
import DefaultLayout from "templates/DefaultLayout";
import { Views } from "utilities/enums/Views";
import useEditPrinter from "./useEditPrinter";
import { useTranslation } from "react-i18next";

function EditPrinter() {
  const {
    inputs,
    setInputs,
    categories,
    handleAddOrUpdatePrinter,
    handleCancelClick,
    printerLoading,
    printerError,
    toggle,
    printerGroupCategoryClicked,
    handleSelectAllClicked,
    handleAddGroup,
    handleRemoveGroup,
    open,
    cloud_cash_register_enabled,
    helper_wholesale_receipts_enabled,
    floorsWithTables,
    handleFloorCheckboxChange,
    updatePrinterLoading,
    resultMsg,
    course_stages_enabled,
  } = useEditPrinter();
  const { t } = useTranslation();
  let header = <h3 className="mb-0">{t(Views.EDIT_PRINTER)}</h3>;

  return (
    <>
      <DefaultLayout
        name={Views.EDIT_PRINTER}
        parentName={Views.PRINTER_MANAGEMENT}
        header={header}
        hasBackButton={true}
        isLoading={printerLoading}
        isError={printerError}
        classNames="edit-printers-view"
      >
        <Row>
          <Col lg="12" className="mt-2 ml-1">
            <label className="form-control-label mr-2">{t("Name")}</label>
            <div className="form-control-value">{inputs?.name}</div>
          </Col>

          <Col xs="12" md="4" className="mt-4">
            <FormGroup className="ml-4 mb-0">
              <Input
                className="form-control-alternative"
                type="checkbox"
                id="activePrinter"
                checked={inputs.activePrinter}
                onChange={(e) => {
                  setInputs((prev) => ({
                    ...prev,
                    activePrinter: e.target.checked,
                  }));
                }}
              />
              <label htmlFor="activePrinter">{t("Active_male")}</label>
            </FormGroup>
          </Col>
        </Row>
        {(cloud_cash_register_enabled || helper_wholesale_receipts_enabled) && (
          <>
            <Row>
              <Col xs="12" md="4" className="mt-1">
                <FormGroup className="ml-4 mb-0">
                  <Input
                    className="form-control-alternative"
                    type="checkbox"
                    id="receiptPrinter"
                    checked={inputs.receiptPrinter}
                    onChange={(e) => {
                      setInputs((prev) => ({
                        ...prev,
                        receiptPrinter: e.target.checked,
                      }));
                    }}
                  />
                  <label htmlFor="receiptPrinter">{t("Is_receipt_printer")}</label>
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col xs="12" md="4" className="mt-1">
            <FormGroup className="ml-4 mb-0">
              <Input
                className="form-control-alternative"
                type="checkbox"
                id="openTablePrinter"
                checked={inputs.openTablePrinter}
                onChange={(e) => {
                  setInputs((prev) => ({
                    ...prev,
                    openTablePrinter: e.target.checked,
                  }));
                }}
              />
              <label htmlFor="openTablePrinter">{t("Is_open_table_printer")}</label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="4" className="mt-1">
            <FormGroup className="ml-4 mb-0">
              <Input
                className="form-control-alternative"
                type="checkbox"
                id="printSoundNotif"
                checked={inputs.printSoundNotif}
                onChange={(e) => {
                  setInputs((prev) => ({
                    ...prev,
                    printSoundNotif: e.target.checked,
                  }));
                }}
              />
              <label htmlFor="printSoundNotif">{t("Allow_print_sound_notif")}</label>
            </FormGroup>
          </Col>
        </Row>
        {course_stages_enabled && (
          <Row>
            <Col xs="12" md="4" className="mt-1">
              <FormGroup className="ml-4 mb-2">
                <Input
                  className="form-control-alternative"
                  type="checkbox"
                  id="courseStagesPrinter"
                  checked={inputs.courseStagesPrinter}
                  onChange={(e) => {
                    setInputs((prev) => ({
                      ...prev,
                      courseStagesPrinter: e.target.checked,
                    }));
                  }}
                />
                <label htmlFor="courseStagesPrinter">{t("Is_course_stages_printer")}</label>
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs="12" md="4" className="pl-0 mb-0">
            <FormGroup className="ml-3">
              <label htmlFor="extraTopSpace">{t("add_extra_top_space")}</label>
              <Input
                className="form-control-alternative"
                type="number"
                id="extraTopSpace"
                value={inputs.extraTopSpace}
                onChange={(e) => {
                  setInputs((prev) => ({
                    ...prev,
                    extraTopSpace: parseInt(e.target.value),
                  }));
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="4" className="pl-0 mb-0">
            <FormGroup className="ml-3">
              <label htmlFor="extraBottomSpace">{t("add_extra_bottom_space")}</label>
              <Input
                className="form-control-alternative"
                type="number"
                id="extraBottomSpace"
                value={inputs.extraBottomSpace}
                onChange={(e) => {
                  setInputs((prev) => ({
                    ...prev,
                    extraBottomSpace: parseInt(e.target.value),
                  }));
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="4" className="pl-0 mb-2">
            <FormGroup className="ml-3">
              <label htmlFor="columnWidth">{t("Column_width")}</label>
              <Input
                className="form-control-alternative"
                type="number"
                id="columnWidth"
                value={inputs.columnWidth}
                min="32"
                max="72"
                onChange={(e) => {
                  setInputs((prev) => ({
                    ...prev,
                    columnWidth: e.target.value,
                  }));
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="4" className="pl-0 mb-2">
            <FormGroup className="ml-3">
              <label className="form-control-label" htmlFor="name">
                {t("Print_font_size")}
              </label>
              <Input
                className="form-control-alternative"
                id="printFontSize"
                name="printFontSize"
                placeholder={t("Print_font_size")}
                type="select"
                bsSize="sm"
                value={inputs.printFontSize}
                onChange={(e) => {
                  setInputs((prev) => ({
                    ...prev,
                    printFontSize: e.target.value,
                  }));
                }}
              >
                <option value="small">Small</option>
                <option value="medium">Medium (default)</option>
                <option value="large">Large</option>
              </Input>
            </FormGroup>
            <FormGroup className="ml-3">
              <label className="form-control-label" htmlFor="name">
                {t("Printer_Encoding")}
              </label>
              <Input
                className="form-control-alternative"
                id="printer_encoding"
                name="printer_encoding"
                placeholder={t("Printer_Encoding")}
                type="select"
                bsSize="sm"
                value={inputs.printer_encoding}
                onChange={(e) => {
                  setInputs((prev) => ({
                    ...prev,
                    printer_encoding: e.target.value,
                  }));
                }}
              >
                <option value="cp437">cp437 (Greek)</option>
                <option value="cp-437">cp-437 (Greek)</option>
                <option value="cp-737">cp-737 (Greek)</option>
                <option value="cp-775">cp-775 (Baltic)</option>
                <option value="cp-850">cp-850 (Western Europe)</option>
                <option value="cp-852">cp-852 (Central Europe)</option>
                <option value="cp-855">cp-855 (Cyrillic)</option>
                <option value="cp-857">cp-857 (Turkish)</option>
                <option value="cp-858">cp-858 (Western Europe)</option>
                <option value="cp-860">cp-860 (Portuguese)</option>
                <option value="cp-861">cp-861 (Icelandic)</option>
                <option value="cp-862">cp-862 (Hebrew)</option>
                <option value="cp-863">cp-863 (Canadian-French)</option>
                <option value="cp-864">cp-864 (Arabic)</option>
                <option value="cp-865">cp-865 (Nordic)</option>
                <option value="iso-8859-1">iso-8859-1 (Western Europe)</option>
                <option value="iso-8859-2">iso-8859-2 (Central Europe)</option>
                <option value="iso-8859-3">iso-8859-3 (Latin 3)</option>
                <option value="iso-8859-4">iso-8859-4 (Baltic)</option>
                <option value="iso-8859-5">iso-8859-5 (Cyrillic)</option>
                <option value="iso-8859-6">iso-8859-6 (Arabic)</option>
                <option value="iso-8859-7">iso-8859-7 (Greek)</option>
                <option value="iso-8859-8">iso-8859-8 (Hebrew)</option>
                <option value="iso-8859-9">iso-8859-9 (Turkish)</option>
                <option value="iso-8859-10">iso-8859-10 (Nordic)</option>
                <option value="iso-8859-11">iso-8859-11 (Thai)</option>
                <option value="iso-8859-13">iso-8859-13 (Baltic)</option>
                <option value="cp1253">WCP1253 (Greek)</option>
                <option value="cp-1253">WCP1253-2 (Greek)</option>
                <option value="win1253">WCP1253-3 (Greek)</option>
                <option value="windows1253">Windows-1253 (Greek)</option>
                <option value="win1253">Win1253 (Greek)</option>
                <option value="cp1253">Cp1253 (Greek)</option>
                <option value="cp-1253">Cp1253 (Greek)</option>
                <option value="ibm737">IBM737 (Greek)</option>
                <option value="ibm-737">IBM-737 (Greek)</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
        {/* <Row className="mt-3">
          <Col xs="12" md="5">
            <Card className="card-plain mb-2">
              <CardHeader className="d-flex justify-content-between align-items-center">
                <h5 className="mb-0">{t("Internal_receipt_settings")}</h5>
                <Button className="btn-round btn-icon" color="primary" id="add-btn" onClick={handleAddGroup} size="sm">
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-plus" />
                  </span>
                  <span className="btn-inner--text">{t("Add")}</span>
                </Button>
              </CardHeader>
              <CardBody className="p-2">
                <div className="accordion printer-groups-accordion permissions-accordion">
                  {inputs.receiptGroups.map((group, index) => {
                    return (
                      <Card className="card-plain mb-2" key={index}>
                        <CardHeader
                          className={`p-3`}
                          role="tab"
                          onClick={() => toggle(index)}
                          aria-expanded={open === index}
                        >
                          <h5 className="mb-0">{`${t("Internal_receipt_setting")} ${index + 1}`}</h5>
                        </CardHeader>

                        <Collapse role="tabpanel" isOpen={open === index}>
                          <CardBody className="pt-0 pl-2 pr-2 pb-0">
                            <FormGroup className="ml-1 mt-4 mb-0">
                              <div className="custom-control custom-checkbox d-inline">
                                <input
                                  className="custom-control-input"
                                  type="checkbox"
                                  name={`table_filters_enabled-${index}`}
                                  id={`table_filters_enabled-${index}`}
                                  onChange={(e) => {
                                    setInputs((prev) => ({
                                      ...prev,
                                      receiptGroups: prev.receiptGroups.map((receiptGroup, indx) => {
                                        if (indx !== index) return receiptGroup;

                                        return {
                                          ...receiptGroup,
                                          table_filters_enabled: e.target.checked,
                                        };
                                      }),
                                    }));
                                  }}
                                  checked={group.table_filters_enabled}
                                />
                                <label className="custom-control-label" htmlFor={`table_filters_enabled-${index}`}>
                                  {t(`Table_filter_msg`)}
                                </label>
                              </div>
                            </FormGroup>
                            {group.table_filters_enabled && (
                              <Row>
                                <Col xs="12" className="mt-2">
                                  <FormGroup className="ml-1 mb-0">
                                    <ListGroup>
                                      {floorsWithTables.map((floor) => (
                                        <ListGroupItem key={floor.id} className="p-2 d-flex">
                                          <div className="custom-control custom-checkbox d-inline w-100">
                                            <input
                                              className="custom-control-input"
                                              id={`floor-${floor.id}-group-${index}`}
                                              type="checkbox"
                                              onChange={(e) => handleFloorCheckboxChange(e, index, floor)}
                                              checked={floor.attributes.tables.data.every((table) =>
                                                group.table_filters?.includes(table.id)
                                              )}
                                            />
                                            <label
                                              className="custom-control-label w-100"
                                              htmlFor={`floor-${floor.id}-group-${index}`}
                                            >
                                              {floor.attributes.name}
                                            </label>
                                          </div>
                                        </ListGroupItem>
                                      ))}
                                    </ListGroup>
                                  </FormGroup>
                                </Col>
                              </Row>
                            )}
                            {categories && (
                              <Table className="group-categories-table">
                                <thead>
                                  <tr onClick={() => handleSelectAllClicked(group)}>
                                    <th>{t("Select_categories")}</th>
                                    <th className="d-flex justify-content-end">
                                      <div className="custom-control custom-checkbox">
                                        <input
                                          className="custom-control-input"
                                          id={`selectAll`}
                                          type="checkbox"
                                          onChange={() => handleSelectAllClicked(group)}
                                          checked={group.categories.length === categories.length}
                                        />
                                        <label className="custom-control-label" htmlFor={`selectAll`}></label>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {categories.map((category) => (
                                    <tr
                                      key={category.id}
                                      onClick={(e) => {
                                        printerGroupCategoryClicked(group, category);
                                      }}
                                    >
                                      <td>{category.name}</td>
                                      <td className="d-flex justify-content-end">
                                        <div className="custom-control custom-checkbox">
                                          <input
                                            className="custom-control-input"
                                            id={`category-${category.id}`}
                                            type="checkbox"
                                            onChange={(e) => {
                                              printerGroupCategoryClicked(group, category);
                                            }}
                                            checked={group.categories.includes(category.id)}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={`category-${category.id}`}
                                          ></label>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            )}
                            <div className="card-footer w-100 d-flex justify-content-end pb-0 pt-3 pr-3">
                              <p
                                className="delete-group-btn font-weight-bold ml-1 text-danger"
                                onClick={() => handleRemoveGroup(group)}
                              >
                                {t("Delete")}
                              </p>
                            </div>
                          </CardBody>
                        </Collapse>
                      </Card>
                    );
                  })}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row> */}
        <Row>
          <Col xs="12" md="6" className="mt-5">
            <Button
              color="primary"
              type="button"
              disabled={updatePrinterLoading}
              onClick={() => {
                handleAddOrUpdatePrinter();
              }}
            >
              {t("Save")}
            </Button>
            <button
              color="primary"
              className="btn btn-outline-default"
              type="button"
              onClick={() => {
                handleCancelClick();
              }}
            >
              {t("Cancel")}
            </button>
            <p className={`mt-2 ${resultMsg.isError ? "text-danger" : "text-success"}`}>{resultMsg.msg}</p>
          </Col>
        </Row>
      </DefaultLayout>
    </>
  );
}

export default EditPrinter;
