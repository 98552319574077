import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
// react library for routing
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.1";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { store, persistor } from "redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import RequireAuth from "auth/RequireAuth";
import PersistLogin from "auth/PersistLogin";
// import * as serviceWorkerRegistration from "serviceWorkerRegistration";
import reportWebVitals from "reportWebVitals";
import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";
import { I18nextProvider } from "react-i18next";
import i18n from "i18n/i18n";
import { PullToRefresh, PullDownContent, ReleaseContent, RefreshContent } from "react-js-pull-to-refresh";
import { ClipLoader } from "react-spinners";

process.env.NODE_ENV === "production" &&
  Sentry.init({
    dsn: "https://6001ff1cff3b31a4467305bd52512f53@o4505945296928768.ingest.sentry.io/4505951505547264",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost:3000",
          /^https:\/\/pos-staging\.ditoapp\.gr/,
          /^https:\/\/pos\.ditoapp\.gr/,
        ],
      }),
      new CaptureConsole({
        levels: ["error"],
      }),
      new Sentry.Replay({
        networkDetailAllowUrls: ["https://backend.pos.ditoapp.gr", "https://backend.pos-staging.ditoapp.gr"],
        networkRequestHeaders: ["X-Custom-Header"],
        networkResponseHeaders: ["X-Custom-Header"],
      }),
    ],
    environment: process.env.NODE_ENV,
    // Performance Monitoring
    tracesSampleRate: 0.1, // For 1.0: Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

// Capture console errors and send them to Sentry
window.addEventListener("error", (event) => {
  console.log("error!");
  Sentry.captureException(event.error);
});

// Capture unhandled promise rejections and send them to Sentry
window.addEventListener("unhandledrejection", (event) => {
  console.log("rejection!");
  Sentry.captureException(event.reason);
});

const root = ReactDOM.createRoot(document.getElementById("root"));
console.log(`React version: ${React.version}`);
root.render(
  <Suspense fallback="Loading..">
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18n} defaultNS={"translation"}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Navigate replace to="/admin/dashboard" />} />
              <Route path="/auth/*" element={<AuthLayout />} />
              <Route element={<PersistLogin />}>
                {/* Protected Routes */}
                <Route element={<RequireAuth />}>
                  <Route
                    path="/admin/*"
                    element={
                      <PullToRefresh
                        pullDownContent={<PullDownContent />}
                        releaseContent={<ReleaseContent />}
                        refreshContent={<RefreshContent />}
                        onRefresh={() => {
                          return new Promise((resolve, reject) => {
                            window?.location?.reload();
                          });
                        }}
                        pullDownThreshold={100}
                        startInvisible={true}
                        triggerHeight={70}
                      >
                        <AdminLayout />
                      </PullToRefresh>
                    }
                  />
                </Route>
              </Route>
            </Routes>
          </BrowserRouter>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  </Suspense>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// TODO: Change to register() when ready to deploy offline
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
