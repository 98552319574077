import React from "react";
import { useGetAllCategoriesQuery, useDeleteCategoryMutation } from "api/categoriesSlice";

const useCategories = () => {
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [newCategoryDialogOpen, setNewCategoryDialogOpen] = React.useState(false);
  const [editCategoryDialogOpen, setEditCategoryDialogOpen] = React.useState(false);
  const [rowClicked, setRowClicked] = React.useState({});
  const { data: categories, isLoading, isError } = useGetAllCategoriesQuery();
  const [deleteCategory] = useDeleteCategoryMutation();

  function handleEditCategoryClick(row) {
    setRowClicked(row);
    setEditCategoryDialogOpen(true);
  }

  function handleDeleteCategory() {
    deleteCategory(rowClicked.id);
    setDeleteDialogOpen(false);
  }

  function handleDeleteDialogOpen(rowClicked) {
    setRowClicked(rowClicked);
    setDeleteDialogOpen(true);
  }

  return {
    newCategoryDialogOpen,
    setNewCategoryDialogOpen,
    editCategoryDialogOpen,
    setEditCategoryDialogOpen,
    handleEditCategoryClick,
    deleteDialogOpen,
    setDeleteDialogOpen,
    handleDeleteDialogOpen,
    handleDeleteCategory,
    categories,
    rowClicked,
    isLoading,
    isError,
  };
};

export default useCategories;
