import React from "react";
import { Row, Button, Col, FormGroup, Input } from "reactstrap";
import MediaManager from "components/MediaManager/MediaManager";
import useEditSubcategory from "./useEditSubcategory";
import { useTranslation } from "react-i18next";
import AlertModal from "components/Modals/AlertModal";

function EditSubcategory(props) {
  const {
    inputs,
    selectImageDialog,
    handleImageDialogOpen,
    handleImageDialogClose,
    handleImageChange,
    handleInputChange,
    categories,
    handleEditSubmit,
    isSubcategoryFetching,
    updateSubcategoryLoading,
    updateCategoryLoading,
  } = useEditSubcategory(props);
  const { t } = useTranslation();

  return (
    <>
      <AlertModal
        isLoading={isSubcategoryFetching}
        isOpen={props.isOpen}
        title={t("Edit_Subcategory")}
        okText={t("Save")}
        cancelText={t("Cancel")}
        onOk={handleEditSubmit}
        onCancel={props?.onCancel}
        okDisabled={updateCategoryLoading || updateSubcategoryLoading}
        body={
          <>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    {t("Name")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="editSubcategory"
                    name="editSubcategory"
                    placeholder={t("Name")}
                    type="text"
                    value={inputs.editSubcategory}
                    onChange={handleInputChange}
                  />
                  <p className="text-danger">{inputs.errors.name}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="mb-4">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="name">
                    {t("Rank_label")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="rank"
                    name="rank"
                    min="1"
                    placeholder={t("Rank_label")}
                    type="number"
                    value={inputs.rank ? inputs.rank : ""}
                    onChange={handleInputChange}
                  />
                  <p className="text-danger m-0">{inputs.errors.rank}</p>
                </FormGroup>
                <div className="hint">{t("Rank_product_field_hint")}</div>
              </Col>
            </Row>

            <Row>
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    {t("Category")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="parentCategory"
                    name="parentCategory"
                    placeholder="Category"
                    type="select"
                    value={inputs.parentCategory}
                    onChange={handleInputChange}
                  >
                    <option value="">{t("Choose_category")}</option>
                    {categories?.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </Input>
                  <p className="text-danger">{inputs.errors.parentCategory}</p>
                </FormGroup>
              </Col>
            </Row>
          </>
        }
      ></AlertModal>
      <MediaManager
        isOpen={selectImageDialog}
        onCancel={() => {
          handleImageDialogClose();
        }}
        onOk={() => {
          handleImageDialogClose();
        }}
        selectedImage={inputs.photo}
        setSelectedImage={(image) => {
          handleImageChange(image);
        }}
      />
    </>
  );
}

export default EditSubcategory;
