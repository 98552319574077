import React from "react";
import { useDispatch } from "react-redux";
import FixedFooter from "components/Footers/FixedFooter";
import CartButton from "components/Buttons/CartButton";
import { toggleCartOpen } from "redux/cart/cartSlice";
import { useTranslation } from "react-i18next";

export default function NewOrderFooter() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <FixedFooter>
      <CartButton
        id="show-cart-btn"
        text={t("Cart")}
        onClick={() => {
          dispatch(toggleCartOpen());
        }}
      />
    </FixedFooter>
  );
}
