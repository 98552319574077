import React from "react";
import { Button, Col, Row } from "reactstrap";
import DefaultLayout from "templates/DefaultLayout";
import { Views } from "utilities/enums/Views";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import usePrintScenarios from "./usePrintScenarios";
import NewPrintScenario from "./NewPrintScenario/NewPrintScenario";
import EditPrintScenario from "./EditPrintScenario/EditPrintScenario";
import CrudTable from "components/Tables/CrudTable";
import AlertModal from "components/Modals/AlertModal";

export const PrintScenarios = () => {
  const {
    printScenarios,
    scenariosError,
    scenariosLoading,
    handleScenarioClick,
    newPrintScenarioDialogOpen,
    selectedScenario,
    setNewPrintScenarioDialogOpen,
    editScenarioDialogOpen,
    setEditScenarioDialogOpen,
    deleteDialogOpen,
    setDeleteDialogOpen,
    handleDeleteDialogOpen,
    handleDeleteScenario,
    deleteScenarioLoading,
  } = usePrintScenarios();
  const { t } = useTranslation();

  const tableCols = [
    { label: t("ID"), hidden: true },
    { label: t("Name") },
    { label: t("Description"), formatter: parse, mobileVisible: false },
    { label: t("status"), slug: "active-label" },
    { label: t("Actions") },
  ];

  let header = (
    <Row>
      <Col xs="6">
        <h3 className="mb-0">{t(Views.PRINT_SCENARIOS)}</h3>
      </Col>
      <Col className="text-right" xs="6">
        <Button
          className="btn-round btn-icon"
          color="primary"
          id="add-btn"
          onClick={() => setNewPrintScenarioDialogOpen(true)}
          size="sm"
        >
          <span className="btn-inner--icon mr-1">
            <i className="fas fa-user-edit" />
          </span>
          <span className="btn-inner--text">{t("Add")}</span>
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      <DefaultLayout
        name={Views.PRINT_SCENARIOS}
        parentName={Views.SETTINGS}
        isLoading={scenariosLoading}
        isError={scenariosError}
        header={header}
      >
        {printScenarios && printScenarios.length === 0 && <h3>{t("No_print_scenarios_msg")}</h3>}
        {printScenarios?.length && (
          <div className="print-scenarios-table-container">
            <CrudTable
              header={header}
              columns={tableCols}
              onEdit={handleScenarioClick}
              data={printScenarios.map((scenario) => {
                return {
                  id: scenario.id,
                  name: scenario.attributes.name,
                  description: scenario.attributes.description,
                  active: scenario.attributes.active,
                };
              })}
              totalData={printScenarios.length || 0}
              pageSize={7}
              onDelete={handleDeleteDialogOpen}
            />
          </div>
        )}
      </DefaultLayout>
      <NewPrintScenario isOpen={newPrintScenarioDialogOpen} onCancel={() => setNewPrintScenarioDialogOpen(false)} />
      {selectedScenario && (
        <EditPrintScenario
          isOpen={editScenarioDialogOpen}
          scenarioId={selectedScenario?.id}
          onCancel={() => setEditScenarioDialogOpen(false)}
        />
      )}

      <AlertModal
        isOpen={deleteDialogOpen}
        title={`${t("Delete_scenario_msg")} "${selectedScenario?.name}"?`}
        okText={t("Delete")}
        cancelText={t("Cancel")}
        onCancel={() => setDeleteDialogOpen(false)}
        onOk={handleDeleteScenario}
        okDisabled={deleteScenarioLoading}
      />
    </>
  );
};
