import React from "react";
import i18n from "i18n/i18n";
import { changeLocale } from "redux/locale/locale";
import { useDispatch, useSelector } from "react-redux";
import ReactFlagsSelect from "react-flags-select";

const languageToCountryCode = {
  el: "GR",
  en: "GB",
};

const countryCodeToLanguage = {
  GR: "el",
  GB: "en",
};

export default function LanguageSelect() {
  const dispatch = useDispatch();
  const { locale } = useSelector((state) => state.locale);
  const [selected, setSelected] = React.useState(
    languageToCountryCode[locale]
  );

  function onChange(code) {
    const newLanguage = countryCodeToLanguage[code];
    dispatch(changeLocale(newLanguage));
    i18n.changeLanguage(newLanguage);
  }

  React.useEffect(() => {
    setSelected(languageToCountryCode[locale]);
    i18n.changeLanguage(locale);
  }, [locale]);

  return (
    <ReactFlagsSelect
      id="language-selector"
      countries={["GB", "GR"]}
      showSelectedLabel={false}
      showSecondarySelectedLabel={false}
      showOptionLabel={false}
      selected={selected}
      onSelect={(code) => onChange(code)}
    />
  );
}
