import React from "react";
import { Container } from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { Views } from "utilities/enums/Views";
import PuffLoader from "react-spinners/PuffLoader";

export default function DashboardLayout(props) {
  let content;
  if (props.isLoading) {
    content = (
      <div className="w-100 d-flex justify-content-center">
        <PuffLoader
          loading={props.isLoading}
          color="#d9d9d9"
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }
  if (props.isError) {
    content = <div>error...</div>;
  } else {
    content = props.children;
  }
  return (
    <>
      <SimpleHeader name="" parentName={Views.DASHBOARD} />
      <Container className={`mt-${props.isLoading ? "6" : "-6"}`} fluid>
        {content}
      </Container>
    </>
  );
}
