import React from "react";
import DefaultLayout from "templates/DefaultLayout";
import { Views } from "utilities/enums/Views";
import { OrdersTable } from "components/OrdersTable/OrdersTable";
import { useTranslation } from "react-i18next";

function Orders() {
  const { t } = useTranslation();
  const ordersPageSize = 7;

  return (
    <DefaultLayout name="" parentName={Views.ORDERS} classNames="orders-view">
      <OrdersTable title={t(Views.ORDERS)} subtitle={t("live_orders_table")} pageSize={ordersPageSize} />
    </DefaultLayout>
  );
}

export default Orders;
