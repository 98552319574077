import React from "react";
import { checkSubCategoryExistsUpdate } from "api/ApiCallerHelper";
import { useUpdateSubCategoryMutation, useGetSubCategoryQuery } from "api/subcategoriesSlice";
import { useGetAllCategoriesQuery, useUpdateCategoryMutation } from "api/categoriesSlice";
import { t } from "i18next";

const useEditSubcategory = (props) => {
  const id = props?.subcategory;
  const {
    data: subcategory,
    isSuccess: isSuccessSubcategory,
    isFetching: isSubcategoryFetching,
    isError,
  } = useGetSubCategoryQuery(id?.toString(), {
    skip: !id,
  });
  const [selectImageDialog, setSelectImageDialog] = React.useState(false);
  const { data: categories } = useGetAllCategoriesQuery();
  const [inputs, setInputs] = React.useState({
    editSubcategory: "",
    photo: null,
    parentCategory: "",
    rank: null,
    errors: {},
  });
  const [updateSubCategory, { isLoading: updateSubcategoryLoading }] = useUpdateSubCategoryMutation();
  const [updateCategory, { isLoading: updateCategoryLoading }] = useUpdateCategoryMutation();

  React.useEffect(() => {
    if (subcategory?.id && isSuccessSubcategory) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          editSubcategory: subcategory.name,
          photo: subcategory.photo
            ? {
                id: subcategory.photo.id,
                ...subcategory.photo.attributes,
              }
            : null,
          parentCategory: subcategory.parent_category.id,
          rank: subcategory.rank,
        };
      });
    }
  }, [subcategory, isSuccessSubcategory]);

  function handleInputChange(event) {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        [event.target.name]: event.target.value,
      };
    });
  }

  function handleEditSubmit(event) {
    event.preventDefault();
    if (!validateEditSubcategoryFields()) {
      return;
    }

    const existsCheck = checkSubCategoryExistsUpdate(inputs.parentCategory, inputs.editSubcategory, id);
    existsCheck.then((res) => {
      if (res) {
        alert("Subcategory already exists in this category");
      } else {
        handleEditSubcategory();
      }
    });
  }

  const handleImageDialogOpen = () => {
    setSelectImageDialog(true);
  };

  const handleImageDialogClose = () => {
    setSelectImageDialog(false);
  };

  async function handleEditSubcategory() {
    const newParenCategory = categories.filter((category) => category.id === parseInt(inputs.parentCategory))[0];
    const prevParentCategory = categories.filter((category) => category.id === subcategory.parent_category.id)[0];

    // update the subcategory
    await updateSubCategory({
      id: id,
      name: inputs.editSubcategory,
      parent_category: parseInt(inputs.parentCategory),
      rank: inputs.rank || null,
      photo: inputs.photo,
    }).then(async () => {
      handleCancel();

      // update the "categories" of the previous parent category, to not contain the subcategory's id
      await updateCategory({
        ...prevParentCategory,
        categories: prevParentCategory.categories.filter((category) => category.id !== id),
      });

      // update the "categories" of the new parent category, to contain the subcategory's id
      await updateCategory({
        ...newParenCategory,
        categories: [...newParenCategory.categories, id],
      });
    });
  }

  const handleImageChange = (image) => {
    setInputs((inputs) => ({
      ...inputs,
      photo: image,
    }));
  };

  function validateEditSubcategoryFields() {
    let isValid = true;
    if (inputs.editSubcategory.length === 0) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, name: t("Name_invalid_msg") },
        };
      });
      isValid = false;
    } else if (inputs.rank && inputs.rank <= 0) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, rank: t("Rank_error_message") },
        };
      });
      isValid = false;
    } else {
      if (inputs.errors.name?.length > 0) {
        setInputs((prevInputs) => {
          return {
            ...prevInputs,
            errors: { ...prevInputs.errors, name: "" },
          };
        });
      }
    }

    if (inputs.parentCategory === "") {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, parentCategory: "The Parent Category field cannot be empty" },
        };
      });
      isValid = false;
    } else {
      if (inputs.errors.parentCategory?.length > 0) {
        setInputs((prevInputs) => {
          return {
            ...prevInputs,
            errors: { ...prevInputs.errors, parentCategory: "" },
          };
        });
      }
    }

    return isValid;
  }

  function clearInputs() {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        parentCategory: "",
        editSubcategory: "",
        photo: null,
        rank: null,
        errors: {},
      };
    });
  }

  function handleCancel() {
    clearInputs();
    props.onCancel();
  }

  return {
    inputs,
    selectImageDialog,
    handleImageDialogOpen,
    handleImageDialogClose,
    handleImageChange,
    handleInputChange,
    categories,
    handleEditSubmit,
    handleCancel,
    isSubcategoryFetching,
    isError,
    updateSubcategoryLoading,
    updateCategoryLoading,
  };
};

export default useEditSubcategory;
