import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./usePagination";
import { PaginationLink, PaginationItem, Pagination as ReactstrapPagination } from "reactstrap";

const Pagination = (props) => {
  const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, className } = props;
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <>
      <ReactstrapPagination
        className="pagination justify-content-center justify-content-md-end mb-0"
        listClassName="justify-content-end mb-0"
      >
        <PaginationItem disabled={currentPage <= 1}>
          <PaginationLink onClick={onPrevious} tabIndex="-1">
            <i className="fas fa-angle-left" />
            <span className="sr-only">Previous</span>
          </PaginationLink>
        </PaginationItem>

        {paginationRange.map((pageNumber, mapIndex) => {
          if (pageNumber === DOTS) {
            return (
              <li key={mapIndex} className="pagination-item dots">
                &#8230;
              </li>
            );
          }

          return (
            <PaginationItem key={mapIndex} active={pageNumber === currentPage}>
              <PaginationLink onClick={() => onPageChange(pageNumber)}>{pageNumber}</PaginationLink>
            </PaginationItem>
          );
        })}

        <PaginationItem disabled={currentPage === lastPage}>
          <PaginationLink onClick={onNext} tabIndex="-1">
            <i className="fas fa-angle-right" />
            <span className="sr-only">Next</span>
          </PaginationLink>
        </PaginationItem>
      </ReactstrapPagination>
      <ul className={classnames("pagination-container", { [className]: className })}></ul>
    </>
  );
};

export default Pagination;
