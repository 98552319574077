import React from "react";
import { useGetAllProductsQuery, useGetCategoriesForProductQuery, useDeleteProductMutation } from "api/productsSlice";

const useProducts = () => {
  const [deleteProductDialogOpen, setDeleteProductDialogOpen] = React.useState(false);
  const [editProductParams, setEditProductParams] = React.useState({
    isOpen: false,
    productId: -1,
  });
  const [newProductOpen, setNewProductOpen] = React.useState(false);
  const [filterCategory, setFilterCategory] = React.useState("");
  const [selectedProduct, setSelectedProduct] = React.useState({});
  const {
    data: products,
    isLoading,
    isError,
  } = useGetAllProductsQuery(filterCategory ? parseInt(filterCategory) : "all");
  const { data: categories } = useGetCategoriesForProductQuery();
  const [deleteProduct] = useDeleteProductMutation();

  function handleDeleteProductClose() {
    setDeleteProductDialogOpen(false);
  }

  function handleEditProductClick(selectedRow) {
    setEditProductParams({ isOpen: true, productId: selectedRow.id });
  }

  function handleEditProductClose() {
    setEditProductParams({ isOpen: false, productId: -1 });
  }
  function handleDeleteProductSubmit() {
    deleteProduct(selectedProduct.id);
    setDeleteProductDialogOpen(false);
  }

  function handleDeleteProductClick(selectedRow) {
    setSelectedProduct(selectedRow);
    setDeleteProductDialogOpen(true);
  }

  function handleFilterCategoryChange(event) {
    setFilterCategory(event.target.value);
  }

  return {
    deleteProductDialogOpen,
    products,
    isLoading,
    isError,
    filterCategory,
    categories,
    selectedProduct,
    handleDeleteProductClose,
    handleDeleteProductSubmit,
    handleEditProductClick,
    handleDeleteProductClick,
    handleFilterCategoryChange,
    editProductParams,
    handleEditProductClose,
    newProductOpen,
    setNewProductOpen,
  };
};

export default useProducts;
