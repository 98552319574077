import React from "react";
import { Bar } from "react-chartjs-2";
import { colors } from "variables/charts";

export const BarChart = ({ data }) => {
  const tooltipLabels = function (item, data) {
    var label = data.datasets[item.datasetIndex].label || "";
    var yLabel = item.yLabel;
    var content = "";
    if (data.datasets.length > 1) {
      content += label;
    }
    content += yLabel;
    return content;
  };
  const chartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          maxBarThickness: 10,
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: colors.gray[200],
            zeroLineColor: colors.gray[200],
          },
          ticks: {
            callback: function (value) {
              if (!(value % 10)) {
                return value;
              }
            },
          },
        },
      ],
    },
    plugins: {
      datalabels:{
        display: false,
      }
    },
    tooltips: {
      callbacks: {
        label: tooltipLabels,
      },
    },
  };
  return <Bar data={data} options={chartOptions} id="chart-bars" className="chart-canvas" />;
};
