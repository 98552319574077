import React from "react";
import { formatCurrency, mergeToCommaSepString } from "utilities/utils";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { ListItem } from "@mui/material";
import { increaseCartQuantity, decreaseCartQuantity } from "redux/cart/cartSlice";
import { useTranslation } from "react-i18next";

export default function CartListItem({ cartItem, onCartItemClick }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { productQuantities } = useSelector((state) => state.productQuantities);
  const { cartItems } = useSelector((state) => state.cart);
  const inventoryEnabled = productQuantities?.find((product) => product.id === cartItem.id)?.inventoryEnabled;
  const totalAlreadyOrdered = cartItems.reduce((total, item) => {
    return total + (item.id === cartItem.id ? item.quantity : 0);
  }, 0);
  const getProductLimit = (cartProduct) => {
    const productQuantity = productQuantities?.find((item) => item.id === cartProduct.id);
    return productQuantity ? productQuantity.quantity : 0;
  };

  const productLimit = getProductLimit(cartItem);

  function handleClick() {
    let productInfo = {
      id: cartItem.id,
      quantity: cartItem.quantity,
      variants: cartItem.variants,
      notes: cartItem.notes,
      category: cartItem.category,
      vat_category: cartItem.vat_category,
      name: cartItem.name,
      price: cartItem.price,
    };
    onCartItemClick(productInfo);
  }
  return (
    <>
      <ListItem
        className="cart-item pb-3 hoverable"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: 0,
          paddingRight: 0,
        }}
        onClick={handleClick}
      >
        <div className="d-flex">
          <div className="d-flex flex-column">
            <p className="mb-0 font-weight-bold mb-0 text-md">{cartItem?.name}</p>
            <p className="product-variants mb-0">
              {mergeToCommaSepString(
                cartItem.variants.map((variant) => {
                  if (variant.quantity && variant.quantity > 1) {
                    return `x${variant.quantity} ${variant.optionLabel}`;
                  } else {
                    return variant.optionLabel;
                  }
                })
              )}
            </p>
            {cartItem.notes && <p className="product-notes mb-0">Notes: {cartItem.notes}</p>}
            <span className="price mb-0">{formatCurrency(cartItem.price)}</span>
          </div>
        </div>
        <div className="d-flex flex-column align-items-center ml-2">
          <div className="d-flex">
            <Button
              className="btn-icon-only rounded-circle product-quantity-btn-circle"
              color="danger"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(decreaseCartQuantity(cartItem));
              }}
              disabled={cartItem.quantity < 1}
            >
              <i className="fa fa-minus" />
            </Button>
            <p className="m-1 font-weight-bold">{cartItem.quantity}</p>
            <Button
              className="btn-icon-only rounded-circle product-quantity-btn-circle"
              color="success"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(increaseCartQuantity(cartItem));
              }}
              disabled={inventoryEnabled && productLimit <= totalAlreadyOrdered}
            >
              <i className="fa fa-plus" />
            </Button>
          </div>
          {inventoryEnabled && productLimit <= 0 ? (
            <div className="quantity text-danger">Out of stock</div>
          ) : (
            <div className="quantity text-success">{inventoryEnabled ? `${t("Quantity")}: ${productLimit}` : null}</div>
          )}
        </div>
      </ListItem>
      {/* <Divider /> */}
    </>
  );
}
