import React from "react";
import { Table, CardFooter, UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu } from "reactstrap";
import Pagination from "./Pagination/Pagination";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function ActionsTable(props) {
  const pageSize = props.pageSize;
  const totalRecords = props.totalData ? props.totalData : props.data.length;
  const currentPage = props.page ? props.page : 0;
  const { t } = useTranslation();
  const { locale } = useSelector((state) => state.locale);

  const mobileHiddenClass = "d-none d-md-table-cell";

  const headers = props.columns.map((column, i) => {
    return (
      <th key={i} className={column.mobileVisible === false ? mobileHiddenClass : ""}>
        {column.label}
      </th>
    );
  });

  const rows = props.data.map((row) => {
    const cols = Object.values(row).map((td, dataIndex) => {
      let value = td;
      if (props.columns && props.columns.length > dataIndex) {
        const formatter = props.columns[dataIndex].formatter;
        const translateDate = props.columns[dataIndex].translateDate; // if the col is a date that should be translated
        value = formatter ? (translateDate ? t("Date", { date: formatter(value, locale) }) : formatter(value)) : value;
      }
      return (
        <td key={dataIndex} className={props.columns[dataIndex].mobileVisible === false ? mobileHiddenClass : ""}>
          {t(value)}
        </td>
      );
    });

    return (
      <tr key={row.id} className="hoverable-table-row" onClick={() => props.onRowClick(row)}>
        {cols}
        <td className="text-right">
          <UncontrolledDropdown>
            <DropdownToggle
              className="btn-icon-only text-light"
              color=""
              role="button"
              size="sm"
              onClick={(e) => e.stopPropagation()}
            >
              <i className="fas fa-ellipsis-v" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              {props.actions?.map((action, index) => {
                return (
                  (action.isActionVisible ? action.isActionVisible(row) : true) && (
                    <DropdownItem
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        action.handler(row);
                      }}
                      disabled={action.isActionActive ? !action.isActionActive(row) : false}
                    >
                      {action.label}
                    </DropdownItem>
                  )
                );
              })}
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
    );
  });

  return (
    <>
      <Table className="responsive-table align-items-center table-flush" responsive striped>
        <thead className="thead-light">
          <tr>
            {headers}
            <th />
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>

      <CardFooter className="py-4 px-0">
        <Pagination
          className="pagination-bar"
          currentPage={currentPage + 1}
          totalCount={totalRecords}
          pageSize={pageSize}
          onPageChange={(page) => props?.paginationAction(page - 1)}
        />
      </CardFooter>
    </>
  );
}
