import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useGetPendingOrdersByTableQuery, useAddOrderMutation } from "api/ordersSlice";
import { selectCurrentUser, selectCurrentOrganisation } from "auth/authSlice";
import { useSelector } from "react-redux";
import { convertCartProductToOrder } from "utilities/utils";
import { useTranslation } from "react-i18next";
import { generateUUID } from "utilities/utils";

const useResubmitOrder = () => {
  const { table } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: pendingOrders, isLoading, isError } = useGetPendingOrdersByTableQuery(parseInt(table));
  const { username: user } = useSelector(selectCurrentUser);
  const [resubmitPendingItems, setResubmitPendingItems] = React.useState([]);
  const [selectedOrderTotal, setSelectedOrderTotal] = React.useState(0);
  const [notes, setNotes] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState("");
  const [addOrder, { isLoading: addOrderLoading }] = useAddOrderMutation();
  const organisationId = useSelector(selectCurrentOrganisation);
  const [selectedProduct, setselectedProduct] = React.useState(null);
  const isCheckedAll =
    resubmitPendingItems?.filter((item) => item.checked === false && canCheckItem(item) === true)?.length === 0;
  const resubmitCheckedItems = resubmitPendingItems?.filter((item) => item.checked === true);
  const [commentsAccordionOpen, setCommentsAccordionOpen] = React.useState(false);
  useEffect(() => {
    setSelectedOrderTotal(
      resubmitCheckedItems
        ?.reduce((total, item) => {
          let v = item.variant.reduce((x, variant) => {
            let variantOptionPrice = variant.variant_option.data.attributes.price;
            if (variant.quantity && variant.quantity > 1) {
              variantOptionPrice = variantOptionPrice * variant.quantity;
            }
            return (x += variantOptionPrice * item.quantity);
          }, 0);
          return total + item.product.data.attributes.price * item.quantity + v;
        }, 0)
        .toFixed(2)
    );
  }, [resubmitCheckedItems]);

  useEffect(() => {
    let allOrderDetailsItems = pendingOrders?.data
      .map((order) => {
        return order.orderDetails.map((detail) => {
          return { ...detail, checked: false };
        });
      })
      .flat();

    setResubmitPendingItems(allOrderDetailsItems);
  }, [pendingOrders]);

  function handleOrderItemQuantityChange(id, q) {
    setResubmitPendingItems(
      resubmitPendingItems.map((item) => {
        if (item.id === id) {
          return { ...item, quantity: item.quantity + q };
        }
        return item;
      })
    );
  }

  function handleCheckItem(item, value) {
    setResubmitPendingItems((prevItems) => {
      return prevItems.map((prevItem) => {
        if (prevItem.id === item.id && canCheckItem(prevItem)) {
          return {
            ...prevItem,
            checked: value,
          };
        } else {
          return prevItem;
        }
      });
    });
  }

  function getSelectedInventoryOfItem(item) {
    return resubmitCheckedItems?.reduce((accumulator, checkedItem) => {
      if (checkedItem?.product?.data?.id === item?.product?.data?.id) {
        return accumulator + checkedItem?.quantity;
      }
      return accumulator;
    }, 0);
  }

  /**
   * Checks if a product can be selected for resubmit
   * If a product is already checked, it can be unchecked
   * If a product has inventory but the currently selected items of it cover the inventory, we cannot select it
   * A product cannot be resubmitted if it is out of stock
   */
  function canCheckItem(item) {
    let productData = item?.product?.data?.attributes;
    let productInventoryEnabled = productData?.inventoryEnabled;
    let productInventory = productData?.inventory;
    return !(productInventoryEnabled && productInventory <= 0);
  }

  function canIncreaseItemQuantity(item) {
    if (!item?.checked) return false;

    let productData = item?.product?.data?.attributes;
    let productInventoryEnabled = productData?.inventoryEnabled;
    if (!productInventoryEnabled) return true;

    let productAvailableInventory = productData?.inventory;
    const totalCheckedInventory = getSelectedInventoryOfItem(item);
    productAvailableInventory = productAvailableInventory - totalCheckedInventory;

    return productAvailableInventory >= 1;
  }

  function handleCheckItemToggle(item) {
    if (item === "all") {
      setResubmitPendingItems((prevItems) => {
        return prevItems.map((item) => {
          if (!canCheckItem(item)) return item;
          return { ...item, checked: !isCheckedAll };
        });
      });
    } else {
      handleCheckItem(item, !item.checked);
    }
  }

  function validateProductInventories() {
    let validationObj = { valid: true, errorMsg: "" };
    // take only the checked items with inventoryEnabled
    // take the sum of quantities added for the inventoryEnabled checked items
    // see if the sum of quantities is larger than the inventory
    const productTotals = new Map();
    resubmitCheckedItems?.forEach((item) => {
      const productId = item?.product?.data?.id;
      let productData = item?.product?.data?.attributes;
      const quantity = item?.quantity;
      const inventory = productData?.inventory;

      if (productData?.inventoryEnabled) {
        if (productTotals.has(productId)) {
          // Increment the total quantity for this product.id
          productTotals.set(productId, productTotals.get(productId) + quantity);
        } else {
          // Initialize the total quantity for this product.id
          productTotals.set(productId, quantity);
        }

        // Check if the total quantity for this product.id exceeds the inventory
        if (productTotals.get(productId) > inventory) {
          // Handle the case where inventory is exceeded (e.g., set a flag, show a message)
          validationObj = { valid: false, errorMsg: `${t("Exceeded_inventory_for_product")}: ${productData?.name}` };
        }
      }
    });

    return validationObj;
  }

  function handleResubmitItems() {
    let validationObj = validateProductInventories();
    if (!validationObj?.valid) {
      setErrorMsg(validationObj.errorMsg);
      return;
    }
    const UUID = generateUUID(user, organisationId);

    const newOrder = {
      uuid: UUID,
      tableId: parseInt(table),
      details: resubmitCheckedItems.map((item) => {
        return { ...item, parent_invoice: null }; // resubmitted items are completely new, without parent invoice
      }),
      submittedBy: user,
      notes: notes,
      origin: "new",
      uuid: generateUUID(user, organisationId),
    };

    addOrder(newOrder);
    navigate("/admin/tables");
  }

  function handleProductClick(product) {
    handleCheckItem(product, true);
    setselectedProduct(product);
  }

  function handleProductClose() {
    setselectedProduct(null);
  }

  function editProduct(callbackObj) {
    let newItem = callbackObj.newItem;
    setResubmitPendingItems((prevItems) => {
      return prevItems.map((item) => {
        if (item.id === selectedProduct.id) {
          return {
            ...item,
            ...convertCartProductToOrder(newItem),
          };
        } else {
          return item;
        }
      });
    });
    handleProductClose();
  }

  return {
    pendingOrders,
    isLoading,
    isError,
    resubmitPendingItems,
    selectedOrderTotal,
    handleOrderItemQuantityChange,
    handleCheckItemToggle,
    handleResubmitItems,
    handleProductClick,
    selectedProduct,
    editProduct,
    handleProductClose,
    addOrderLoading,
    isCheckedAll,
    handleCheckItem,
    resubmitCheckedItems,
    notes,
    setNotes,
    canCheckItem,
    commentsAccordionOpen,
    setCommentsAccordionOpen,
    canIncreaseItemQuantity,
    errorMsg,
  };
};

export default useResubmitOrder;
