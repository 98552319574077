import React from "react";
import { Row, Col, FormGroup, Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import AlertModal from "components/Modals/AlertModal";
import { TextArea } from "components/TextAreas/TextArea";
import useNewPosTerminal from "./useNewPosTerminal";

function NewPosTerminal(props) {
  const { handleNewPosTerminalSubmit, addPosTerminalLoading, inputs, setInputs, handleInputChange, setDescription } =
    useNewPosTerminal(props);
  const { t } = useTranslation();

  return (
    <>
      <AlertModal
        size="lg"
        isOpen={props.isOpen}
        title={t("New_pos_terminal")}
        onCancel={props.onCancel}
        okText={t("Add")}
        cancelText={t("Cancel")}
        onOk={handleNewPosTerminalSubmit}
        okDisabled={addPosTerminalLoading}
        body={
          <>
            <Row>
              <Col xs="12">
                <Col xs="12" md="6" className="mt-0 p-0">
                  <FormGroup className="mb-0">
                    <label className="form-control-label" htmlFor="input-enabled">
                      {t("Enabled")}
                    </label>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <label className="custom-toggle mr-1">
                      <input
                        name="active"
                        type="checkbox"
                        checked={inputs.active}
                        onChange={(e) => setInputs({ ...inputs, active: e.target.checked })}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off={t("No")}
                        data-label-on={t("Yes")}
                      />
                    </label>
                  </FormGroup>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="name">
                      {t("Name")}
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="name"
                      name="name"
                      placeholder={t("Name")}
                      type="text"
                      value={inputs.name}
                      onChange={handleInputChange}
                    />
                    <p className="text-danger">{inputs.errors.name}</p>
                  </FormGroup>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="name">
                      {t("terminalId")}
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="terminal_id"
                      name="terminal_id"
                      placeholder={t("terminal_id")}
                      type="text"
                      value={inputs.terminal_id}
                      onChange={handleInputChange}
                    />
                    <p className="text-danger">{inputs.errors.terminal_id}</p>
                  </FormGroup>

                  <FormGroup>
                    <label className="form-control-label" htmlFor="description">
                      {t("Description")}
                    </label>
                    <TextArea
                      className="w-100 form-control"
                      name="description"
                      value={inputs.description}
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder={t("Description")}
                    />
                  </FormGroup>
                </Col>
              </Col>
            </Row>
          </>
        }
      ></AlertModal>
    </>
  );
}

export default NewPosTerminal;
