import { useGetUsersQuery, useUpdateUserMutation } from "api/usersSlice";
import { useState } from "react";
import { validateEmail } from "utilities/utils";

export const useUserManagement = () => {
  const { data: users, isError, isLoading } = useGetUsersQuery();
  const [updateUser] = useUpdateUserMutation();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [rowClicked, setRowClicked] = useState({});
  const [inputs, setInputs] = useState({
    username: "",
    email: "",
    phone: "",
    enabled: false,
    errors: {},
  });

  function clearInputs() {
    setInputs({
      username: "",
      email: "",
      phone: "",
      enabled: false,
      errors: {},
    });
  }

  function handleEditClose() {
    setEditDialogOpen(false);
    clearInputs();
  }

  function handleEditOpen(row) {
    handleRowClick(row);
    setEditDialogOpen(true);
  }

  function handleRowClick(row) {
    setRowClicked(row);
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        username: row.username !== null ? row.username : "",
        email: row.email !== null ? row.email : "",
        phone: row.phone !== null ? row.phone : "",
        enabled: row.enabled === "Enabled" ? true : false,
      };
    });
  }

  function handleInputChange(event) {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        [event.target.name]: event.target.value,
      };
    });
  }

  async function handleUpdateUser() {
    if (validateInputs()) {
      await updateUser({
        id: rowClicked.id,
        username: inputs.username,
        email: inputs.email,
        phone: inputs.phone,
        enabled: inputs.enabled,
      });
      handleEditClose();
    }
  }

  function validateInputs() {
    let errors = {};
    let isValid = true;

    if (!inputs.username) {
      errors.username = "Username is required";
      isValid = false;
    }

    if (!inputs.email) {
      errors.email = "Email is required";
      isValid = false;
    }
    if (inputs.email) {
      if (!validateEmail(inputs.email)) {
        errors.email = "Email is invalid";
        isValid = false;
      }

      if (users) {
        if (users.some((user) => user.email === inputs.email && user.id !== rowClicked.id)) {
          errors.email = "Email already exists";
          isValid = false;
        }
      }
    }

    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        errors: errors,
      };
    });

    return isValid;
  }

  return {
    users,
    isError,
    isLoading,
    editDialogOpen,
    handleEditClose,
    handleEditOpen,
    handleInputChange,
    handleUpdateUser,
    inputs,
    setInputs,
  };
};
