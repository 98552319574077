import React, { useEffect, useState, useRef } from "react";
import { useLazySearchCustomersQuery } from "api/customerSlice";
import { Col, FormGroup, Input } from "reactstrap";
import { useTranslation } from "react-i18next";

export default function CustomerSearch({ customerInputs, setCustomerInputs, initialName }) {
  const { t } = useTranslation();
  const [activeSearch, setActiveSearch] = useState(false);
  const [searchValue, setSearchValue] = useState(initialName);
  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const [searchCustomers, { data: searchResults, isLoading: isLoadingSearch }] = useLazySearchCustomersQuery();

  useEffect(() => {
    if (initialName) {
      setSearchValue(initialName);
    }
  }, [initialName]);

  useEffect(() => {
    if (searchValue !== "") {
      searchCustomers(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isClickInsideModal = event.target.closest(".modal-dialog");
      if (containerRef.current && !containerRef.current.contains(event.target) && !isClickInsideModal) {
        // Clicked outside the component, hide search results
        setActiveSearch(false);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <Col xs="12" md="6" className="pl-2 pr-3">
      <FormGroup className="mb-2">
        <label className="form-control-label" htmlFor="name">
          {t("Business_name")}
        </label>
        <div className="loading-inner-input">
          <Input
            ref={inputRef}
            className="form-control-alternative"
            id="name"
            name="name"
            autoComplete="off"
            placeholder={t("Business_name")}
            type="text"
            value={searchValue}
            onChange={(e) => {
              setCustomerInputs({
                ...customerInputs,
                name: e.target.value,
              });
              setSearchValue(e.target.value);
              setActiveSearch(true);
            }}
            onFocus={() => setActiveSearch(true)}
          />
        </div>
        {searchValue && activeSearch && (
          <div className="search-cust-container">
            {(!isLoadingSearch || !searchResults || (searchResults && searchResults.length === 0)) && (
              <div ref={containerRef} className="search-results">
                <div>
                  <span className="text-bold">{t("No_results_found")}</span>
                </div>
              </div>
            )}
            {searchResults && searchResults.length > 0 && (
              <div ref={containerRef} className="search-results">
                {searchResults.map((customer) => (
                  <div
                    key={customer.id}
                    className="search-result"
                    onClick={() => {
                      setCustomerInputs((prev) => {
                        return {
                          ...prev,
                          name: customer.attributes.name,
                          vat_number: customer.attributes.vat_number,
                          doy: customer.attributes.doy,
                          gemi_number: customer.attributes.gemi_number || "",
                          address: customer.attributes.address,
                          city: customer.attributes.city,
                          postcode: customer.attributes.postcode || "",
                          phone_number: customer.attributes.phone_number || "",
                        };
                      });
                      setSearchValue(customer.attributes.name);
                      setActiveSearch(false);
                    }}
                  >
                    {customer.attributes.name && (
                      <div>
                        <span className="text-bold">{customer.attributes.name}</span>
                      </div>
                    )}
                    {customer.attributes.vat_number && (
                      <span className="text-muted">{customer.attributes.vat_number}</span>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <p className="text-danger text-sm">{customerInputs.errors.name}</p>
      </FormGroup>
    </Col>
  );
}
