import React, { useState } from "react";
import { Table, PaginationLink, PaginationItem, CardFooter, Pagination } from "reactstrap";
import { useTranslation } from "react-i18next";

export default function BaseTable(props) {
  const [currentPage, setCurrentPage] = useState(0);
  const pagesCount = props.data ? Math.ceil(props.data.length / props.pageSize) : 0;
  const { t } = useTranslation();
  const mobileHiddenClass = "d-none d-md-table-cell";

  const headers = props.columns.map((column, i) => {
    return (
      <th
        className={`${column.hidden ? "d-none" : ""} ${column.mobileVisible === false ? mobileHiddenClass : ""}`}
        key={i}
      >
        {t(column.label)}
      </th>
    );
  });

  function handlePaginationClick(e, index) {
    e.preventDefault();
    setCurrentPage(index);
  }

  const rows = props.data
    ?.slice(currentPage * props.pageSize, (currentPage + 1) * props.pageSize)
    .map((row, rowIndex) => {
      const cols = Object.values(row).map((td, dataIndex) => {
        return (
          <td
            className={`${props.columns[dataIndex].hidden ? "d-none" : ""} ${
              props.columns[dataIndex].mobileVisible === false ? mobileHiddenClass : ""
            }`}
            key={dataIndex}
          >
            {t(td)}
          </td>
        );
      });

      return <tr key={rowIndex}>{cols}</tr>;
    });

  return (
    <>
      <Table className="align-items-center table-flush mb-0" responsive striped>
        <thead className="thead-light">
          <tr>{headers}</tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>

      <CardFooter className="py-4">
        <nav aria-label="...">
          <Pagination className="pagination justify-content-end mb-0" listClassName="justify-content-end mb-0">
            <PaginationItem disabled={currentPage <= 0}>
              <PaginationLink href="#pablo" onClick={(e) => handlePaginationClick(e, currentPage - 1)} tabIndex="-1">
                <i className="fas fa-angle-left" />
                <span className="sr-only">Previous</span>
              </PaginationLink>
            </PaginationItem>

            {[...Array(pagesCount)].map((page, i) => (
              <PaginationItem active={i === currentPage} key={i}>
                <PaginationLink onClick={(e) => handlePaginationClick(e, i)} href="#">
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ))}

            <PaginationItem disabled={currentPage >= pagesCount - 1}>
              <PaginationLink href="#pablo" onClick={(e) => handlePaginationClick(e, currentPage + 1)}>
                <i className="fas fa-angle-right" />
                <span className="sr-only">Next</span>
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </nav>
      </CardFooter>
    </>
  );
}
