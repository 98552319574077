import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import LocalStorageBackend from "i18next-localstorage-backend";
import { EN } from "./translationEn";
import { EL } from "./translationEl";
import "moment/locale/el";
import "moment/locale/en-gb";

const translationEn = { translation: EN };
const translationEl = { translation: EL };

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: translationEn,
      el: translationEl,
    },
    backend: {
      backends: [LocalStorageBackend],
      cacheHitMode: "refreshAndUpdateStore",
    },
    lng: "el",
    fallbackLng: "el",
    interpolation: { escapeValue: false },
    react: {
      bindI18n: "languageChanged",
      bindI18nStore: "",
      transEmptyNodeValue: "",
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
      useSuspense: true,
    },
  });

export default i18n;
