import React, { useEffect, useReducer } from "react";
import { Col } from "reactstrap";
import { useGetAnalyticsUserSalesQuery, apiAnalytics } from "api/analyticsSlice";
import { DEFAULT_CHARTS_FORM_DATA } from "utilities/enums/Analytics";
import { CardTable } from "components/CardTable";
import { useDispatch } from "react-redux";
import { TagTypes } from "utilities/enums/TagTypes";
import { subscribeToChartsChangeWS } from "config/websocket";
import { selectCurrentOrganisation } from "auth/authSlice";
import { useSelector } from "react-redux";

function UserSalesTable({ reportPeriod = "day" }) {
  const {
    data: userSalesData,
    isLoading: userSalesLoading,
    isError: userSalesError,
  } = useGetAnalyticsUserSalesQuery({ reportPeriod: reportPeriod });
  const dispatch = useDispatch();
  const organisationId = useSelector(selectCurrentOrganisation);

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  function refreshComponent() {
    dispatch(apiAnalytics.util.invalidateTags([TagTypes.Analytics]));
    forceUpdate();
  }
  useEffect(() => {
    subscribeToChartsChangeWS(organisationId, refreshComponent);
  });

  return (
    <>
      {!userSalesLoading && !userSalesError && (
        <Col md="6" className="user-sales-table">
          {userSalesData && (
            <CardTable
              key_name={userSalesData.key_name}
              chartData={userSalesData.chartData.data}
              chartNav={userSalesData.chartData.chartNav}
              chartType={userSalesData.chartData.chartType}
              chartFormData={userSalesData?.formData || DEFAULT_CHARTS_FORM_DATA}
              report_period={userSalesData?.report_period || reportPeriod}
            />
          )}
        </Col>
      )}
    </>
  );
}

export default UserSalesTable;
