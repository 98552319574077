import React, { useState } from "react";
import VariantCheckbox from "./VariantCheckbox";
export default function VariantsCheckboxGroup({
  title,
  options,
  handleChange,
  componentID,
  checkedOptions,
  removeVariationOption,
  addVariationOption,
}) {
  const [lastSelectedElement, setLastSelectedElement] = useState(-1);
  function handleClick(option) {
    setLastSelectedElement(option.id);
  }

  return (
    <>
      <h4 className="mb-3">{title}</h4>
      {options.map((option) => {
        return (
          <VariantCheckbox
            key={option.id}
            option={option}
            handleChange={handleChange}
            checkedOptions={checkedOptions}
            componentID={componentID}
            title={title}
            removeVariationOption={removeVariationOption}
            addVariationOption={addVariationOption}
            isSelectedLast={lastSelectedElement === option.id}
            handleClick={handleClick}
          />
        );
      })}
    </>
  );
}
