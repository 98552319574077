import React, { useState, useEffect } from "react";
import { formatCurrency } from "utilities/utils";
import { Col, Row, Button } from "reactstrap";

export default function VariantCheckbox({
  option,
  handleChange,
  checkedOptions,
  componentID,
  removeVariationOption,
  addVariationOption,
  title,
  isSelectedLast,
  handleClick,
}) {
  const [timer, setTimer] = useState(5000);
  const [timerRunning, setTimerRunning] = useState(true);

  let quantitySupport = option?.attributes?.quantity_support;
  const quantityHandlingVisible = quantitySupport && timerRunning && isSelectedLast;

  let quantity = 0;
  const isChecked = checkedOptions.find((checkedOption) => checkedOption.optionId === option?.id.toString()) || false;
  if (isChecked) {
    quantity = isChecked.quantity;
  }

  useEffect(() => {
    if (timerRunning) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1000);
      }, 1000);

      if (timer === 0) {
        clearInterval(countdown);
        setTimerRunning(false);
      }

      return () => clearInterval(countdown);
    }
  }, [timerRunning, timer]);

  const handleCheckboxChange = (...args) => {
    if (quantitySupport) {
      resetTimeout();
      handleClick(option);
      if (!isChecked) {
        //   // if it's the initial click, set as checked
        handleChange(...args);
      } else {
        increaseQuantity();
      }
    } else {
      handleChange(...args);
    }
  };

  const resetTimeout = () => {
    setTimer(5000);
    setTimerRunning(true);
  };

  function increaseQuantity() {
    resetTimeout();
    if (quantity === 9) return;
    addVariationOption(option, componentID);
  }

  function decreaseQuantity() {
    resetTimeout();
    if (quantity == 0) return;
    removeVariationOption(option);
  }
  return (
    <div className="quantity-support custom-control custom-checkbox mb-3" key={option.id}>
      <input
        className="custom-control-input"
        id={`customCheck-${option.id}`}
        type="checkbox"
        value={option.id}
        checked={isChecked}
        disabled={!option?.attributes?.enabled}
        onChange={(e) => handleCheckboxChange(e, componentID, option.attributes.price, title, option.attributes.label)}
      />
      {quantityHandlingVisible && (
        <div className="quatity-btn-group">
          <Button color="secondary" size="sm" type="button" onClick={decreaseQuantity}>
            -
          </Button>
          <Button color="secondary" size="sm" type="button" onClick={increaseQuantity}>
            +
          </Button>
        </div>
      )}
      <label className="custom-control-label large w-100" htmlFor={`customCheck-${option.id}`}>
        {isChecked && quantitySupport && <div className="number">{quantity}</div>}

        <Row>
          <Col>
            <Row>
              <Col xs="8" className="label-text">
                {option.attributes.label}
              </Col>
              <Col xs="4" className="pl-0">
                <p className="text-sm text-right text-muted mb-0">+ {formatCurrency(option.attributes.price)}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </label>
    </div>
  );
}
