import React from "react";
import {
  useGetFloorsQuery,
  useAddFloorMutation,
  useUpdateFloorMutation,
  useDeleteFloorMutation,
} from "api/floorsSlice";

const useFloors = () => {
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [newFloorDialogOpen, setNewFloorDialogOpen] = React.useState(false);
  const [inputs, setInputs] = React.useState({ newFloor: "", editFloor: "", rank: "", errors: {} });
  const [rowClicked, setRowClicked] = React.useState({});
  const { data: floors, isLoading, isError } = useGetFloorsQuery("rank");
  const [addFloor] = useAddFloorMutation();
  const [updateFloor] = useUpdateFloorMutation();
  const [deleteFloor] = useDeleteFloorMutation();

  function handleEditClose() {
    setEditDialogOpen(false);
  }

  function handleNewFloorClose() {
    setNewFloorDialogOpen(false);
  }

  function handleNewFloorOpen() {
    setNewFloorDialogOpen(true);
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        newFloor: "",
        rank: "",
        errors: {
          ...prevInputs.errors,
          name: "",
        },
      };
    });
  }

  function handleEditDialogOpen(rowClicked) {
    setRowClicked(rowClicked);
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        editFloor: rowClicked.name,
        rank: rowClicked.rank,
        errors: {},
      };
    });
    setEditDialogOpen(true);
  }

  function handleInputChange(event) {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        [event.target.name]: event.target.value,
      };
    });
  }

  function floorExists() {
    return floors?.some((floor) => {
      if (floor.attributes.name === inputs.newFloor) {
        return true;
      }
      return false;
    });
  }

  function validateNewFloorFields() {
    let isValid = true;
    if (!inputs.newFloor) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: {
            ...prevInputs.errors,
            name: "Floor name is required",
          },
        };
      });
      isValid = false;
    } else if (floorExists()) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: {
            ...prevInputs.errors,
            name: "Floor already exists",
          },
        };
      });
      isValid = false;
    }
    return isValid;
  }

  function validateEditFloorFields() {
    let isValid = true;
    if (!inputs.editFloor) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: {
            ...prevInputs.errors,
            name: "Floor name is required.",
          },
        };
      });
      isValid = false;
    }
    return isValid;
  }

  function handleNewFloorSubmit(event) {
    event.preventDefault();
    if (validateNewFloorFields()) {
      addFloor({
        name: inputs.newFloor,
        rank: inputs.rank,
      });
      setNewFloorDialogOpen(false);
    }
  }

  function handleEditSubmit(event) {
    event.preventDefault();
    if (validateEditFloorFields()) {
      updateFloor({ id: rowClicked.id, name: inputs.editFloor, rank: inputs.rank });
      setEditDialogOpen(false);
    }
  }

  function handleDeleteOpen(row) {
    setRowClicked(row);
    setDeleteDialogOpen(true);
  }

  function handleDeleteClose() {
    setDeleteDialogOpen(false);
  }

  function handleDeleteSubmit() {
    deleteFloor({
      id: rowClicked.id,
    });
    setDeleteDialogOpen(false);
  }

  return {
    floors,
    isLoading,
    isError,
    deleteDialogOpen,
    editDialogOpen,
    newFloorDialogOpen,
    inputs,
    rowClicked,
    handleEditClose,
    handleEditDialogOpen,
    handleNewFloorClose,
    handleNewFloorOpen,
    handleInputChange,
    handleNewFloorSubmit,
    handleEditSubmit,
    handleDeleteOpen,
    handleDeleteClose,
    handleDeleteSubmit,
  };
};

export default useFloors;
