import React from "react";
import DefaultLayout from "templates/DefaultLayout";
import useVariationOptions from "./useVariationOptions";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import AlertModal from "components/Modals/AlertModal";
import CrudTable from "components/Tables/CrudTable";
import { formatCurrency } from "utilities/utils";
import { Views } from "utilities/enums/Views";
import { useTranslation } from "react-i18next";

function VariationOptions() {
  const {
    inputs,
    options,
    isLoading,
    isError,
    selectedOption,
    addOptionDialogOpen,
    editOptionDialogOpen,
    deleteOptionDialogOpen,
    handleAddOptionDialogOpen,
    handleAddOptionDialogClose,
    handleEditOptionDialogOpen,
    handleEditOptionDialogClose,
    handleDeleteOptionDialogOpen,
    handleDeleteOptionDialogClose,
    handleInputChange,
    handleCheckboxChange,
    handleAddOption,
    handleUpdateOption,
    handleDeleteOption,
  } = useVariationOptions();
  const { t } = useTranslation();

  const tableCols = [
    { label: "id", mobileVisible: false },
    { label: "label", hidden: true },
    { label: t("Name") },
    { label: t("price"), formatter: formatCurrency, mobileVisible: false },
    { label: t("Enabled") },
    { label: t("Quantity_support"), hidden: true },
    { label: t("Actions"), hidden: true },
  ];

  let header = (
    <Row>
      <Col xs="8">
        <h3 className="mb-0">{t(Views.VARIATION_OPTIONS)}</h3>
      </Col>
      <Col xs="4" className="d-flex justify-content-end">
        <Button color="primary" size="sm" onClick={handleAddOptionDialogOpen}>
          <span className="btn-inner--icon mr-1">
            <i className="fas fa-user-edit" />
          </span>
          <span className="btn-inner--text">{t("Add")}</span>
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      <DefaultLayout
        name={Views.VARIATION_OPTIONS}
        parentName={Views.MENU_MANAGEMENT}
        isLoading={isLoading}
        isError={isError}
        header={header}
      >
        {options?.length > 0 ? (
          <CrudTable columns={tableCols} data={options} onEdit={handleEditOptionDialogOpen} pageSize={7} />
        ) : (
          <div className="p-3">{t("No_options_available")}</div>
        )}
      </DefaultLayout>

      <AlertModal
        title={t("New_option")}
        isOpen={addOptionDialogOpen}
        onCancel={handleAddOptionDialogClose}
        onOk={handleAddOption}
        okText={t("Add")}
        cancelText={t("Cancel")}
        body={
          <>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    {t("Name")}
                  </label>
                  <Input
                    type="text"
                    name="name"
                    placeholder={t("Name")}
                    value={inputs.name}
                    onChange={handleInputChange}
                  />
                  <p className="text-danger">{inputs.errors.name}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="label">
                    {t("Cust_label")}
                  </label>
                  <Input
                    type="text"
                    name="label"
                    placeholder={t("Cust_label")}
                    value={inputs.label}
                    onChange={handleInputChange}
                  />
                  <p className="text-danger">{inputs.errors.label}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="price">
                    {t("price")}
                  </label>
                  <Input
                    type="number"
                    name="price"
                    placeholder={t("price")}
                    value={inputs.price}
                    invalid={inputs.price < 0}
                    onChange={handleInputChange}
                  />
                  <p className="text-danger">{inputs.errors.price}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="12">
                <FormGroup className="mb-0">
                  <label className="form-control-label" htmlFor="input-enabled">
                    {t("Enabled")}
                  </label>
                </FormGroup>
                <FormGroup className="mb-0">
                  <label className="custom-toggle custom-toggle-primary">
                    <input
                      type="checkbox"
                      name="enabled"
                      id="enabled"
                      onChange={handleCheckboxChange}
                      checked={inputs.enabled}
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off={t("No")}
                      data-label-on={t("Yes")}
                    />
                  </label>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="12">
                <FormGroup className="mb-0">
                  <label className="form-control-label" htmlFor="input-enabled">
                    {t("Quantity_support")}
                  </label>
                </FormGroup>
                <FormGroup className="mb-0">
                  <label className="custom-toggle custom-toggle-primary">
                    <input
                      type="checkbox"
                      name="quantity_support"
                      id="quantity_support"
                      onChange={handleCheckboxChange}
                      checked={inputs.quantity_support || false}
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off={t("No")}
                      data-label-on={t("Yes")}
                    />
                  </label>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="12">
                <FormGroup className="mb-0">
                  <label className="form-control-label" htmlFor="input-enabled">
                    {t("Is_fee")}
                  </label>
                </FormGroup>
                <FormGroup>
                  <label className="custom-toggle custom-toggle-primary">
                    <input
                      type="checkbox"
                      name="is_fee"
                      id="is_fee"
                      onChange={handleCheckboxChange}
                      checked={inputs.is_fee}
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off={t("No")}
                      data-label-on={t("Yes")}
                    />
                  </label>
                </FormGroup>
              </Col>
            </Row>
          </>
        }
      />
      <AlertModal
        title={t("Edit_Option")}
        isOpen={editOptionDialogOpen}
        onCancel={handleEditOptionDialogClose}
        onOk={handleUpdateOption}
        okText={t("Save")}
        cancelText={t("Cancel")}
        body={
          <>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    {t("Name")}
                  </label>
                  <Input
                    type="text"
                    name="name"
                    placeholder={t("Name")}
                    value={inputs.name}
                    onChange={handleInputChange}
                  />
                  <p className="text-danger">{inputs.errors.name}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="label">
                    {t("Cust_label")}
                  </label>
                  <Input
                    type="text"
                    name="label"
                    placeholder={t("Cust_label")}
                    value={inputs.label}
                    onChange={handleInputChange}
                  />
                  <p className="text-danger">{inputs.errors.label}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="price">
                    {t("price")}
                  </label>
                  <Input
                    type="number"
                    name="price"
                    placeholder={t("price")}
                    value={inputs.price}
                    invalid={inputs.price < 0}
                    onChange={handleInputChange}
                  />
                  <p className="text-danger">{inputs.errors.price}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="12">
                <FormGroup className="mb-0">
                  <label className="form-control-label" htmlFor="input-enabled">
                    {t("Enabled")}
                  </label>
                </FormGroup>
                <FormGroup className="mb-0">
                  <label className="custom-toggle custom-toggle-primary">
                    <input
                      type="checkbox"
                      name="enabled"
                      id="enabled"
                      onChange={handleCheckboxChange}
                      checked={inputs.enabled}
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off={t("No")}
                      data-label-on={t("Yes")}
                    />
                  </label>
                </FormGroup>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col xs="12">
                <FormGroup className="mb-0">
                  <label className="form-control-label" htmlFor="input-enabled">
                    {t("Quantity_support")}
                  </label>
                </FormGroup>
                <FormGroup className="mb-0">
                  <label className="custom-toggle custom-toggle-primary">
                    <input
                      type="checkbox"
                      name="quantity_support"
                      id="quantity_support"
                      onChange={handleCheckboxChange}
                      checked={inputs.quantity_support || false}
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off={t("No")}
                      data-label-on={t("Yes")}
                    />
                  </label>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="12">
                <FormGroup className="mb-0">
                  <label className="form-control-label" htmlFor="input-enabled">
                    {t("Is_fee")}
                  </label>
                </FormGroup>
                <FormGroup>
                  <label className="custom-toggle custom-toggle-primary">
                    <input
                      type="checkbox"
                      name="is_fee"
                      id="is_fee"
                      onChange={handleCheckboxChange}
                      checked={inputs.is_fee}
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off={t("No")}
                      data-label-on={t("Yes")}
                    />
                  </label>
                </FormGroup>
              </Col>
            </Row>
          </>
        }
      />
      <AlertModal
        title={`${t("Delete_option_msg")} "${selectedOption.name}"?`}
        isOpen={deleteOptionDialogOpen}
        onCancel={handleDeleteOptionDialogClose}
        onOk={handleDeleteOption}
        okText={t("Delete")}
        cancelText={t("Cancel")}
      />
    </>
  );
}

export default VariationOptions;
