import React from "react";
import { Col, Row, Input, FormGroup } from "reactstrap";
import AlertModal from "components/Modals/AlertModal";
import CrudTable from "components/Tables/CrudTable";
import DefaultLayout from "templates/DefaultLayout";
import { Views } from "utilities/enums/Views";
import { useUserManagement } from "./useUserManagement";
import { useTranslation } from "react-i18next";

function UserManagement() {
  const {
    editDialogOpen,
    inputs,
    users,
    isError,
    isLoading,
    setInputs,
    handleEditClose,
    handleEditOpen,
    handleInputChange,
    handleUpdateUser,
  } = useUserManagement();
  const { t } = useTranslation();

  const tableCols = [
    { label: "id" },
    { label: t("User") },
    { label: "Email" },
    { label: t("phone"), hidden: true },
    { label: t("Enabled") },
    { label: t("Role") },
    { label: "created at", hidden: true },
    { label: "" },
  ];

  const header = (
    <Row>
      <Col xs="12">
        <h3 className="mb-0">{t(Views.USER_MANAGEMENT)}</h3>
      </Col>
    </Row>
  );

  return (
    <>
      <DefaultLayout
        name={Views.USER_MANAGEMENT}
        parentName={Views.SETTINGS}
        isLoading={isLoading}
        isError={isError}
        header={header}
      >
        <CrudTable
          header={header}
          columns={tableCols}
          data={users?.map((user) => {
            return {
              ...Object.keys(user)
                .filter((k) => !["subscriptions"].includes(k))
                .reduce((acc, key) => {
                  acc[key] = user[key];
                  return acc;
                }, {}),
              enabled: user.enabled ? "Enabled" : "Disabled",
            };
          })}
          pageSize={7}
          onEdit={handleEditOpen}
          disabledEditCondition={(row) => row.user_role === "admin"}
        />
      </DefaultLayout>
      <AlertModal
        isOpen={editDialogOpen}
        title={t("Edit_user")}
        body={
          <>
            <div>
              <label htmlFor="username" className="form-control-label">
                {t("Username")}
              </label>
              <Input
                type="text"
                name="username"
                id="username"
                placeholder={t("Username")}
                value={inputs.username}
                onChange={handleInputChange}
              />
              <p className="text-danger">{inputs.errors.username}</p>
            </div>
            <div>
              <label htmlFor="email" className="form-control-label">
                Email
              </label>
              <Input
                type="text"
                name="email"
                id="email"
                placeholder="Email"
                value={inputs.email}
                onChange={handleInputChange}
              />
              <p className="text-danger">{inputs.errors.email}</p>
            </div>
            <div>
              <label htmlFor="phone" className="form-control-label">
                {t("Phone")}
              </label>
              <Input
                type="text"
                name="phone"
                id="phone"
                placeholder={t("Phone")}
                value={inputs.phone}
                onChange={handleInputChange}
              />
              <p className="text-danger">{inputs.errors.phone}</p>
            </div>
            <div>
              <FormGroup className="mb-0">
                <label className="form-control-label" htmlFor="input-enabled">
                  {t("Enabled")}
                </label>
              </FormGroup>
              <FormGroup>
                <label className="custom-toggle custom-toggle-primary">
                  <input
                    type="checkbox"
                    name="enabled"
                    id="enabled"
                    onChange={(e) => setInputs({ ...inputs, enabled: e.target.checked })}
                    checked={inputs.enabled}
                  />
                  <span
                    className="custom-toggle-slider rounded-circle"
                    data-label-off={t("No")}
                    data-label-on={t("Yes")}
                  />
                </label>
              </FormGroup>
            </div>
          </>
        }
        onCancel={handleEditClose}
        onOk={handleUpdateUser}
        okText={t("Save")}
        cancelText={t("Cancel")}
      />
    </>
  );
}

export default UserManagement;
