import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { logOut, selectCurrentUser } from "auth/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { store } from "redux/store";
import { useTranslation } from "react-i18next";

import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Media,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";
import ROUTES_OBJ from "utilities/enums/Routes";
import LanguageSelect from "components/LanguageSelect";
import SearchModal from "components/Modals/SearchModal";
import { useSelector } from "react-redux";
import { useRemoveUserSubscriptionsMutation } from "api/organisationSlice";
import { PushNotification } from "components/PushNotification/PushNotification";
import { broadCastUserChangeWS } from "config/websocket";
import TopErrorAlert from "components/Headers/TopErrorAlert";
import { RefreshDashboard } from "components/RefreshDashboard";

function AdminNavbar({ theme, sidenavOpen, toggleSidenav }) {
  const naigate = useNavigate();
  const dispatch = useDispatch();
  const userName = store.getState()?.auth?.user?.username;
  const { t } = useTranslation();
  const organisationId = useSelector((state) => state.auth.organisationId);
  const currentuser = useSelector(selectCurrentUser);
  const { user_notifications_enabled } = useSelector((state) => state.settings);
  const [removeUserSubscriptions] = useRemoveUserSubscriptionsMutation();
  const location = useLocation();
  const handleLogout = () => {
    removeUserSubscriptions(currentuser.id);
    broadCastUserChangeWS(organisationId);
    dispatch(logOut());
    naigate("/auth/login");
  };

  return (
    <>
      {location.pathname.includes("table-actions") && <TopErrorAlert />}
      <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-dark bg-info": theme === "dark" },
          { "navbar-light bg-secondary": theme === "light" }
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <Form
              className={classnames(
                "navbar-search form-inline ml-sm-3",
                { "navbar-search-light": theme === "dark" },
                { "navbar-search-dark": theme === "light" }
              )}
            ></Form>
            <SearchModal className="d-none d-xl-flex ml--2" collapsible={false} />
            <Nav className="align-items-center" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler",
                    { active: sidenavOpen },
                    { "sidenav-toggler-dark": theme === "dark" }
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
            </Nav>
            <Nav className="align-items-center ml-auto" navbar>
              <SearchModal className="mobile d-flex d-xl-none" />
              {location.pathname.includes("dashboard") && <RefreshDashboard />}
              {user_notifications_enabled && <PushNotification />}
              <LanguageSelect />
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a" id="profile-btn">
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img alt="..." src={require("assets/img/theme/profile.png")} />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">{userName}</span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">
                      {t("Hello_no_specialchars")} {userName}!
                    </h6>
                  </DropdownItem>
                  <DropdownItem onClick={() => naigate("/admin/" + ROUTES_OBJ.SETTINGS_GENERAL.path)}>
                    <i className="ni ni-settings-gear-65" />
                    <span>{t("Settings")}</span>
                  </DropdownItem>
                  <DropdownItem onClick={() => naigate("/admin/" + ROUTES_OBJ.GUIDES.path)}>
                    <i className="ni ni-support-16" />
                    <span>{t("Guides")}</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem id="logout" onClick={handleLogout}>
                    <i className="ni ni-user-run" />
                    <span>{t("Logout")}</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
