import React, { useRef } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";
import DefaultLayout from "templates/DefaultLayout";
import { Views } from "utilities/enums/Views";
import { setCartTable } from "redux/cart/cartSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectCartTable } from "redux/cart/cartSlice";
import ROUTES_OBJ from "utilities/enums/Routes";
import { selectCurrentUserRules } from "auth/authSlice";
import { useNotifyCourseStageMutation } from "api/ordersSlice";
import { triggerNotification } from "utilities/utils";
import { selectCurrentUser } from "auth/authSlice";
import NotificationAlert from "react-notification-alert";

export default function TableActions() {
  const { tableId: table } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cartTable = useSelector(selectCartTable);
  const user_rules = useSelector(selectCurrentUserRules);
  const { course_stages_enabled } = useSelector((state) => state.settings);
  const [notifyCourseChange, { isLoading: notifyCourseChangeLoading }] = useNotifyCourseStageMutation();
  const { username: user } = useSelector(selectCurrentUser);
  const notificationAlertRef = useRef(null);

  const cancelGiftRuleEnabled = user_rules.find(
    (rule) => rule.name === `Route:${ROUTES_OBJ.TABLE_ACTIONS_CANCEL_GIFT.path}`
  )?.value;
  const resubmitRuleEnabled = user_rules.find(
    (rule) => rule.name === `Route:${ROUTES_OBJ.TABLE_ACTIONS_RESUBMIT.path}`
  )?.value;
  const tableSummaryRuleEnabled = user_rules.find(
    (rule) => rule.name === `Route:${ROUTES_OBJ.TABLE_ACTIONS_TABLE_SUMMARY.path}`
  )?.value;

  function handleNewOrderAction() {
    dispatch(setCartTable(cartTable));
  }

  function sendPrintCourse(notificationAlertRef) {
    notifyCourseChange({ table: cartTable, user: user }).then(() => {
      triggerNotification(notificationAlertRef, "success", t("Course_stages"), t("Course_stages_msg_success"));
    });
  }

  let header = (
    <h3 className="mb-0">
      {t("table")} {cartTable?.attributes?.name}
    </h3>
  );

  return (
    <>
      <DefaultLayout
        name=""
        parentName={Views.TABLE_ACTIONS}
        isLoading={false}
        isError={false}
        header={header}
        hasSideBar={true}
        hasBackButton={true}
        backPath="/admin/tables"
        classNames="cart-sidebar-enabled"
        sidebarMode={{ id: table }}
      >
        <Row className="row-shadow">
          <Col md="6" lg="6">
            <Link to={`/admin/table-actions/new-order/${table}`} onClick={() => handleNewOrderAction(table)}>
              <span className="btn text-center">{t("New_Order")}</span>
            </Link>
          </Col>
        </Row>
        <Row className="row-shadow">
          <Col md="6" lg="6">
            <Link id="pay-items-action" to={`/admin/table-actions/pay-items/${table}`}>
              <span className="btn text-center">{t("Pay_Items")}</span>
            </Link>
          </Col>
        </Row>
        <Row className="row-shadow">
          <Col md="6" lg="6">
            <Link id="transfer-items-action" to={`/admin/table-actions/transfer/${table}`}>
              <span className="btn text-center">{t("Transfer")}</span>
            </Link>
          </Col>
        </Row>
        {course_stages_enabled && (
          <Row className="row-shadow">
            <Col md="6" lg="6">
              <Link
                id="transfer-items-action"
                onClick={() => {
                  sendPrintCourse(notificationAlertRef);
                }}
              >
                <span className="btn text-center">{t("Course_stages")}</span>
              </Link>
            </Col>
          </Row>
        )}
        {cancelGiftRuleEnabled && (
          <Row className="row-shadow">
            <Col md="6" lg="6">
              <Link id="transfer-items-action" to={`/admin/table-actions/cancel-gift/${table}`}>
                <span className="btn text-center">{t("Cancel_Gift")}</span>
              </Link>
            </Col>
          </Row>
        )}
        {tableSummaryRuleEnabled && (
          <Row className="row-shadow">
            <Col md="6" lg="6">
              <Link id="resubmit-items-action" to={`/admin/table-actions/table-summary/${table}`}>
                <span className="btn text-center">{t("Table_Summary")}</span>
              </Link>
            </Col>
          </Row>
        )}
        {resubmitRuleEnabled && (
          <Row className="row-shadow">
            <Col md="6" lg="6">
              <Link id="resubmit-items-action" to={`/admin/table-actions/resubmit/${table}`}>
                <span className="btn text-center">{t("Resubmit")}</span>
              </Link>
            </Col>
          </Row>
        )}
        <div className="rna-wrapper">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
      </DefaultLayout>
    </>
  );
}
