import React, { useEffect } from "react";
import { useGetPosTerminalQuery, useUpdatePosTerminalMutation } from "api/posTerminalSlice";

const useEditPosTerminal = (props) => {
  const { data: posTerminal, isFetching: posTerminalLoading } = useGetPosTerminalQuery(props.posTerminalId, {
    skip: !props.posTerminalId,
  });
  const [updatePosTerminal, { isLoading: updatePosTerminalLoading }] = useUpdatePosTerminalMutation();
  const [inputs, setInputs] = React.useState({
    name: "",
    description: "",
    active: false,
    terminal_id: "",
    errors: {},
  });

  useEffect(() => {
    setInputs({
      name: posTerminal?.name,
      description: posTerminal?.description,
      active: posTerminal?.active,
      terminal_id: posTerminal?.terminal_id,
      errors: {},
    });
  }, [posTerminal?.id, props.isOpen]);

  function clearInputs() {
    setInputs({
      name: "",
      description: "",
      active: false,
      configurations: [],
      errors: {},
    });
  }

  function setDescription(description) {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        description: description,
      };
    });
  }

  function handleInputChange(event) {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        [event.target.name]: event.target.value,
      };
    });
  }

  async function handleUpdatePosTerminalSubmit() {
    await updatePosTerminal({ id: posTerminal.id, ...inputs });
    clearInputs();
    props?.onCancel();
  }

  return {
    inputs,
    setInputs,
    handleInputChange,
    setDescription,
    posTerminalLoading,
    handleUpdatePosTerminalSubmit,
    updatePosTerminalLoading,
  };
};

export default useEditPosTerminal;
