import { useEffect } from "react";
import { useGetVersionQuery } from "api/appSlice";
import { CURRENT_UI_VERSION } from "utilities/constants";
import { checkVersionGreaterThan, logError } from "utilities/utils";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setVersionError } from "redux/generic/generic";
import { Alert } from "reactstrap";
import { el } from "date-fns/locale";

const TopErrorAlert = () => {
  const { data: uiversion, isLoading: uiversionLoading } = useGetVersionQuery(
    { count: 5 },
    { refetchOnMountOrArgChange: true }
  );
  const { t } = useTranslation();
  const { versionError } = useSelector((state) => state.genericState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (checkVersionGreaterThan(uiversion?.version, CURRENT_UI_VERSION)) {
      dispatch(setVersionError(true));
    } else if (uiversion?.version && uiversion?.version !== CURRENT_UI_VERSION) {
      dispatch(setVersionError(false));
      logError(
        `UI version is not up to date with backend, UI VERSION: ${CURRENT_UI_VERSION}, Backend: ${uiversion?.version}`
      );
    } else {
      // same version
      dispatch(setVersionError(false));
    }
  }, [uiversion]);

  return (
    <>
      {versionError && (
        <div className=" fixed-top-error">
          <Alert
            color="danger"
            className="d-flex flex-column flex-md-row align-items-md-center align-items-start justify-content-between p-1 w-100 border-0 m-0 "
          >
            <span className="alert-text ml-1 mb-0 mb-md-0 mr-md-4 text-center w-100">
              <strong>{t("VERSION_ERROR")}</strong>
            </span>
          </Alert>
        </div>
      )}
    </>
  );
};

export default TopErrorAlert;
